import * as React from 'react';
import { FieldProps } from 'formik';

import { SearchSelect } from '../Select';
import { LocationQueryChangeFunc } from 'root/scenes/Home/components/Sidebar/components/SidebarTabs/components/AreaAndStyleTab/components/LocationTab/components/LocationQueryFormSection/hooks';

export type FormSearchInputProps = {
    options: IOption[];
    onLocalizeMeClick: () => void;
    isLocalizeMeButtonDisabled: boolean;
    onElementClick: (option: IOption) => void;
    onChange: LocationQueryChangeFunc;
    isLoading: boolean;
} & FieldProps;

const FormSearchInput = ({ field, form, ...props }: FormSearchInputProps) => {
    const value = { value: form.values.locationValue, label: form.values.locationQuery };

    return (
        <SearchSelect
            name={field.name}
            isClearable={true}
            options={props.options}
            placeholder={'Search for a location'}
            value={value.value ? value : null}
            defaultValue={value.value ? value : null}
            emptyMessage="No location"
            onChange={props.onElementClick}
            changeInputFunc={props.onChange}
            isLoading={props.isLoading}
            onLocalizeMeClick={props.onLocalizeMeClick}
            isLocalizeMeButtonDisabled={props.isLocalizeMeButtonDisabled}
        />
    );
};

export default FormSearchInput;
