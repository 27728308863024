import { combineEpics } from 'redux-observable';

import { mapEpics } from 'features/map';
import { locationEpics } from 'features/location';
import { createProductJourneyEpics } from 'features/createProductJourney';
import { productOptionEpics } from 'features/productOptions';
import { customisationEpics } from 'features/customisation';
import { coverEpics } from 'features/cover';
import { imageCropEpics } from 'features/imageCrop';
import { mediaQueryEpics } from 'features/mediaQuery';
import { productPreviewEpics } from 'features/productPreview';
import { tourGuideEpics } from 'features/tourGuide';
import { mapStylesEpics } from 'root/features/mapStyles';
import { routeEpics } from 'root/features/route';

export default combineEpics(
    mapEpics,
    mapStylesEpics,
    routeEpics,
    locationEpics,
    createProductJourneyEpics,
    productOptionEpics,
    customisationEpics,
    coverEpics,
    imageCropEpics,
    mediaQueryEpics,
    productPreviewEpics,
    tourGuideEpics
);
