import React, { FC } from 'react';
import { ButtonStyle } from 'root/modules/components';

import { useTranslation } from 'root/modules/utils/locales';
import * as P from './parts';

type CustomerCareTextProps = {
  onPreviewClick: () => void;
};

export const CustomerCareText: FC<CustomerCareTextProps> = ({ onPreviewClick }) => {
  const [t] = useTranslation();

  return (
    <P.CustomerCareText>
      <P.CheckSummaryHeader>
        {t(({ orderSummary }) => orderSummary.checkSummary.header1)}
        <P.PreviewButton
          buttonStyle={ButtonStyle.Ghost}
          text={t(({ orderSummary }) => orderSummary.checkSummary.preview)}
          onClick={onPreviewClick}
        />
        {t(({ orderSummary }) => orderSummary.checkSummary.header2)}
      </P.CheckSummaryHeader>
      <P.CheckSummaryText>{t(({ orderSummary }) => orderSummary.checkSummary.text)}</P.CheckSummaryText>
    </P.CustomerCareText>
  );
};
