import * as React from 'react';
import { connect } from 'react-redux';
import * as classNames from 'classnames';

import * as Types from 'Types';
import { customistaionSelectors } from 'features/customisation';
import { mapSelectors } from 'features/map';
import { MapType, ProductId, Size } from 'root/model/map/MapEnums';
import styles from './index.module.scss';

interface ICoverTitlesProps {
  titleFirstLine: string;
  titleSecondLine: string;
  titleThirdLine: string;
  subtitleFirstLine: string;
  subtitleSecondLine: string;
  spine: string;
  isWithSpine?: boolean;
  productId: ProductId;
  mapSize: Size;
  mapType?: number;
}

const CoverTitles: React.FunctionComponent<ICoverTitlesProps> = ({
  titleFirstLine,
  titleSecondLine,
  titleThirdLine,
  subtitleFirstLine,
  subtitleSecondLine,
  spine,
  isWithSpine,
  productId,
  mapSize,
  mapType,
}) => {
  const isRectangleFramed =
    mapType === MapType.CANVAS_AND_FRAMED &&
    [Size.SMALL, Size.MEDIUM].includes(mapSize);

  const isPaperSquare =
    mapType === MapType.PAPER &&
    mapSize === Size.LARGE &&
    productId === ProductId.FLAT_MAP;
  const isSmallFolded =
    mapType === MapType.PAPER &&
    [Size.SMALL].includes(mapSize) &&
    productId === ProductId.FOLDED_MAP;
  const isLargeFolded =
    mapType === MapType.PAPER &&
    [Size.LARGE].includes(mapSize) &&
    productId === ProductId.FOLDED_MAP;

  const coverTitlesCssClasses = classNames(styles['cover-titles'], {
    [styles['cover-titles--folded']]: productId === ProductId.FOLDED_MAP,
    [styles['cover-titles--rectangle']]: isRectangleFramed,
  });

  const coverTilesContainerCssClasses = classNames(
    styles['cover-titles__titles-container'],
    {
      [styles['cover-titles__titles-container--small']]: mapSize === Size.SMALL,
      [styles['cover-titles__titles-container--rectangle']]: isRectangleFramed,
      [styles['cover-titles__titles-container--paper-square']]: isPaperSquare,
      [styles['cover-titles__titles-container--folded-large']]: isLargeFolded,
      [styles['cover-titles__titles-container--folded-small']]: isSmallFolded,
    }
  );

  const coverTilesTitleLineCssClasses = classNames(
    styles['cover-titles__title-line'],
    {
      [styles['cover-titles__title-line--small']]: mapSize === Size.SMALL,
      [styles['cover-titles__title-line--rectangle']]: isRectangleFramed,
    }
  );

  const coverTilesSubTitleLineCssClasses = classNames(
    styles['cover-titles__subtitle-line'],
    {
      [styles['cover-titles__subtitle-line--small']]: mapSize === Size.SMALL,
      [styles['cover-titles__subtitle-line--rectangle']]: isRectangleFramed,
    }
  );

  return (
    <div className={coverTitlesCssClasses}>
      {isWithSpine && mapSize !== Size.SMALL && !isRectangleFramed && (
        <p className={styles['cover-titles__spine']}>{spine}</p>
      )}
      <div className={coverTilesContainerCssClasses}>
        <p className={coverTilesTitleLineCssClasses}>{titleFirstLine}</p>
        {!isRectangleFramed && (
          <p className={coverTilesTitleLineCssClasses}>{titleSecondLine}</p>
        )}
        {mapSize !== Size.SMALL && !isRectangleFramed && (
          <p className={coverTilesTitleLineCssClasses}>{titleThirdLine}</p>
        )}
        <p className={coverTilesSubTitleLineCssClasses}>{subtitleFirstLine}</p>
        {mapSize !== Size.SMALL && !isRectangleFramed && (
          <p className={coverTilesSubTitleLineCssClasses}>
            {subtitleSecondLine}
          </p>
        )}
      </div>
    </div>
  );
};

CoverTitles.defaultProps = {
  isWithSpine: true,
};

export default connect((state: Types.RootState) => ({
  ...customistaionSelectors.getTitles(state),
  productId: mapSelectors.getProductId(state),
  mapSize: mapSelectors.getMapSize(state),
  mapType: mapSelectors.getMapType(state),
}))(CoverTitles);
