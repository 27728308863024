import { RoutePath } from 'root/appConfig/routes/enums';

export enum CreateProductJourneyStep {
    AREA_AND_STYLE = 'CreateProductJourneyStep/areaAndStyle',
    CUSTOMISE = 'CreateProductJourneyStep/customise',
    CONFIRM_ORDER = 'CreateProductJourneyStep/confirmOrder',
    PRODUCT_TYPE = 'CreateProductJourneyStep/productType',
}

export const tourGuideStepFromJourney = {
    [CreateProductJourneyStep.AREA_AND_STYLE]: 1,
    [CreateProductJourneyStep.CUSTOMISE]: 2,
    [CreateProductJourneyStep.PRODUCT_TYPE]: 0,
    [CreateProductJourneyStep.CONFIRM_ORDER]: 3,
};

export enum CreateProductJourneyAction {
    NEXT = 'CreateProductJourneyAction/nextStep',
    BACK = 'CreateProductJourneyAction/backStep',
    REDIRECT = 'CreateProductJourneyAction/Redirect',
    CONTINUE_SHOPPING = 'CreateProductJourneyAction/CONTINUE_SHOPPING',
}

export interface ICreateProductJourneyStepMulti {
    [key: string]: CreateProductJourneyStep;
}

export interface ICreateProductJourneyTransition {
    from: CreateProductJourneyStep;
    to: CreateProductJourneyStep | ICreateProductJourneyStepMulti;
    on: CreateProductJourneyAction;
    redirect?: RoutePath;
}

export type CreateProductJourney = ICreateProductJourneyTransition[];

export const createProductJourney: CreateProductJourney = [
    {
        from: CreateProductJourneyStep.PRODUCT_TYPE,
        to: CreateProductJourneyStep.AREA_AND_STYLE,
        on: CreateProductJourneyAction.NEXT,
    },
    {
        from: CreateProductJourneyStep.AREA_AND_STYLE,
        to: CreateProductJourneyStep.PRODUCT_TYPE,
        on: CreateProductJourneyAction.BACK,
    },
    {
        from: CreateProductJourneyStep.AREA_AND_STYLE,
        to: CreateProductJourneyStep.CUSTOMISE,
        on: CreateProductJourneyAction.NEXT,
    },
    {
        from: CreateProductJourneyStep.CUSTOMISE,
        to: CreateProductJourneyStep.AREA_AND_STYLE,
        on: CreateProductJourneyAction.BACK,
    },
    {
        from: CreateProductJourneyStep.CUSTOMISE,
        to: CreateProductJourneyStep.CONFIRM_ORDER,
        on: CreateProductJourneyAction.REDIRECT,
        redirect: RoutePath.CONFIRM_ORDER,
    },
    {
        from: CreateProductJourneyStep.CONFIRM_ORDER,
        to: CreateProductJourneyStep.CUSTOMISE,
        on: CreateProductJourneyAction.REDIRECT,
        redirect: RoutePath.ROOT,
    },
    {
        from: CreateProductJourneyStep.CONFIRM_ORDER,
        to: CreateProductJourneyStep.PRODUCT_TYPE,
        on: CreateProductJourneyAction.CONTINUE_SHOPPING,
        redirect: RoutePath.ROOT,
    },
];
