import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ControlPosition, DraggableEventHandler } from 'react-draggable';

import { resetMapLocationParams, setAreaAndStyleCoordinates, setMapLocationParams } from 'root/features/map/actions';
import {
    getMapLanguage,
    getMapLocationCoordinates,
    getMapScale,
    getMapSize,
    getMapStyle,
    getMapType,
    getProductId,
} from 'root/features/map/selectors';
import { getOptions } from 'root/features/productOptions/selectors';
import { useDebounced, useWindowSize } from 'root/hooks';
import { MapLanguage, MapStyle, MapType, Proportions, Scale, Size } from 'root/model/map/MapEnums';
import { Map } from 'root/components/Map';
import { MapVisibleBoundsType } from 'root/components/Map/map';

import { MAP_STYLE_TO_FRAME_URL } from '../../MapPreviewContainer/components/MapPreview/constants';
import { Bounds } from '../types';

const initialBounds = {
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
};

export const useMapSelectionContainer = () => {
    const map = useRef<HTMLDivElement>(null);
    const mapRef = useRef<Map>(null);
    const zoomRef = useRef<HTMLDivElement>(null);

    const [showUkOverview, setShowUkOverview] = useState(false);
    const [bounds, setBounds] = useState<Bounds>(initialBounds);
    const [glassLocation, setGlassLocation] = useState<ControlPosition>({ x: 0, y: 0 });
    const [mapVisibleBounds, setMapVisibleBounds] = useState<MapVisibleBoundsType>();
    const [showMagnifyingGlass, setShowMagnifyingGlass] = useState<boolean>(false);
    const [showResetMap, setShowResetMap] = useState<boolean>(false);

    const [isGlassDragged, setIsGlassDragged] = useState(false);
    const [zoomIsHovered, setZoomIsHovered] = useState(false);
    const [isMoreToolActive, setMoreToolActive] = useState<boolean>(false);
    const [isSwitchMapStyleActive, setSwitchMapStyleActive] = useState<boolean>(false);

    const windowSize = useWindowSize();

    const mapLocationCoordinates = useSelector(getMapLocationCoordinates);
    const mapSize = useSelector(getMapSize) as Size;
    const mapLanguage = useSelector(getMapLanguage);
    const mapType = useSelector(getMapType);
    const mapOptions = useSelector(getOptions);
    const productId = useSelector(getProductId);
    const mapStyle = useSelector(getMapStyle) as MapStyle;
    const mapScale = useSelector(getMapScale) as Scale;

    const dispatch = useDispatch();

    let frameUrl =
        mapSize === Size.LARGE
            ? MAP_STYLE_TO_FRAME_URL[Proportions.SQUARE][mapStyle][mapLanguage as MapLanguage]
            : MAP_STYLE_TO_FRAME_URL[Proportions.RECTANGLE][mapStyle];
    if (mapSize === Size.SMALL && mapType === MapType.PAPER) {
        frameUrl = '';
    }

    const mapDetails = mapOptions.find((element) => element.size === mapSize && element.productId === productId);
    const mapDimension = mapDetails && mapDetails.dimension;
    const isGlassCentered = useMemo(() => !glassLocation || (glassLocation.x === 0 && glassLocation.y === 0), [
        glassLocation,
    ]);

    const debouncedGlassLocation = useDebounced(glassLocation, 0);
    const debouncedMapLocationCoordinates = useDebounced(mapLocationCoordinates, 0);
    const debouncedMapScale = useDebounced(mapStyle, 0);

    const moveMap = (centerCoordinates: { x: number; y: number }) => {
        dispatch(setAreaAndStyleCoordinates(centerCoordinates));
        dispatch(resetMapLocationParams());
        dispatch(setMapLocationParams({ coordinates: centerCoordinates }));

        setMapVisibleBounds(mapRef.current?.calculateBoundingNarrowPoints(mapScale));
    };

    const onSwitchMapStyle = useCallback(() => {
        setMoreToolActive(false);
        setSwitchMapStyleActive((state) => !state);
    }, []);

    const onMoreToolsChange = useCallback(() => {
        setSwitchMapStyleActive(false);
        setMoreToolActive((state) => !state);
    }, []);

    const onMagnifierOpen = useCallback((flag: boolean) => {
        setMoreToolActive(false);
        setZoomIsHovered(true);
        setShowMagnifyingGlass(flag);
    }, []);

    const onCenterMapClicked = useCallback(() => {
        if (!map.current || !mapRef.current) return;
        const rect = map.current.getBoundingClientRect();
        const glassCoordinates = mapRef.current?.getCoordinateFromPixel(
            rect.width / 2 + glassLocation.x,
            rect.height / 2 + glassLocation.y
        );

        if (!glassCoordinates) return;
        moveMap({
            x: glassCoordinates.x,
            y: glassCoordinates.y,
        });
        setGlassLocation({ x: 0, y: 0 });
    }, [glassLocation]);

    const onGlassDrag = useCallback<DraggableEventHandler>((e, data) => {
        setIsGlassDragged(true);
        setGlassLocation({ x: data.x, y: data.y });
    }, []);

    const onMapDrag = useCallback(() => {
        setIsGlassDragged(true);
        if (mapRef.current) {
            moveMap(mapRef.current.getCenter());
        }
    }, []);

    const onGlassDragStart = useCallback(() => {
        setIsGlassDragged(true);
    }, []);

    const zoomHoverStarts = useCallback(() => {
        setZoomIsHovered(true);
    }, []);

    const zoomHoverEnds = useCallback(() => {
        setZoomIsHovered(false);
    }, []);

    const toggleUkMap = useCallback((open: boolean) => {
        setMoreToolActive(false);
        setSwitchMapStyleActive(false);
        setShowUkOverview(open);
    }, []);

    const calculateGlassBounds = useCallback(() => {
        if (map.current) {
            const rect = map.current.getBoundingClientRect();
            setBounds({
                left: -rect.width / 2,
                right: rect.width / 2,
                top: -rect.height / 2,
                bottom: rect.height / 2,
            });
        }
    }, []);

    const onCenterGlassClicked = useCallback(() => {
        setIsGlassDragged(true);
        setGlassLocation({ x: 0, y: 0 });
    }, []);

    useEffect(() => {
        calculateGlassBounds();
    }, [windowSize]);

    useEffect(() => {
        if (!map.current) return;
        if (!mapRef.current) return;

        const rect = map.current.getBoundingClientRect();
        const zoomCenterOnMainMapX = rect.width / 2 + debouncedGlassLocation.x;
        const zoomCenterOnMainMapY = rect.height / 2 + debouncedGlassLocation.y;
        const zoomCoordinate = mapRef.current.getCoordinateFromPixel(zoomCenterOnMainMapX, zoomCenterOnMainMapY);

        if (zoomCoordinate) {
            const newCoordinates = { x: zoomCoordinate.x, y: zoomCoordinate.y };
            setMapVisibleBounds(mapRef.current.calculateBoundingNarrowPoints(mapScale));
            dispatch(setMapLocationParams({ detailedViewCoordinates: newCoordinates }));
            setIsGlassDragged(false);
        }
    }, [debouncedGlassLocation, debouncedMapLocationCoordinates, debouncedMapScale]);

    return {
        map,
        mapRef,
        zoomRef,
        bounds,
        glassLocation,
        mapVisibleBounds,
        showMagnifyingGlass,
        showResetMap,
        setShowResetMap,
        mapSize,
        mapType,
        mapDimension,
        frameUrl,
        toggleUkMap,
        showUkOverview,
        isGlassDragged,
        isGlassCentered,
        zoomIsHovered,
        onMapDrag,
        onGlassDrag,
        onGlassDragStart,
        zoomHoverStarts,
        zoomHoverEnds,
        onMagnifierOpen,
        onCenterGlassClicked,
        onCenterMapClicked,
        isMoreToolActive,
        isSwitchMapStyleActive,
        onMoreToolsChange,
        onSwitchMapStyle,
    };
};
