import * as React from 'react';
import { connect } from 'react-redux';
import { FormikProps, withFormik } from 'formik';
import { flow } from 'lodash';

import { locationActions } from 'features/location';
import { mapSelectors } from 'features/map';
import { ILocationFormValues, LOCATION_FORM_FIELD_NAMES } from 'root/model/location/LocationFormValues';
import * as Types from 'Types';

import styles from './index.module.scss';
import { LocationQueryFormSection } from '../LocationQueryFormSection';

interface ILocationFormProps {
    mapLocationQuery: string;
    submitLocationForm: typeof locationActions.submitLocationForm;
}

const LocationForm: React.FunctionComponent<ILocationFormProps & FormikProps<ILocationFormValues>> = (props) => {
    const { submitLocationForm, ...rest } = props;

    return (
        <form onSubmit={props.handleSubmit} autoComplete={'off'} className={styles['location-form']}>
            <LocationQueryFormSection {...rest} />
        </form>
    );
};

export default flow([
    withFormik<ILocationFormProps, ILocationFormValues>({
        mapPropsToValues: (props) => {
            return {
                [LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY]: props.mapLocationQuery,
                [LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE]: props.mapLocationQuery,
                [LOCATION_FORM_FIELD_NAMES.LOCATION_SEARCH]: '',
            };
        },
        handleSubmit: (values, { props }) => {
            props.submitLocationForm(values);
        },
        validateOnBlur: false,
        validateOnChange: false,
        enableReinitialize: true,
    }),
    connect(
        (state: Types.RootState) => ({
            mapLocationQuery: mapSelectors.getMapLocationQuery(state),
        }),
        {
            submitLocationForm: locationActions.submitLocationForm,
        }
    ),
])(LocationForm);
