import * as React from 'react';
import { connect } from 'react-redux';
import { value as popValue } from 'popmotion';

import { productOptionsActions } from 'features/productOptions';
import SidebarScrollArea from './components/SidebarScrollArea';
import SidebarTabs from './components/SidebarTabs';

import { getSlideComponent, getSidebarContainerComponent, POSES, SIDEBAR_MIN_HEIGHT } from './constants';
import styles from './index.module.scss';
import { ValidationProvider } from './providers/validation';

interface ISidebarState {
  isCollapsed: boolean;
  bottomBound: number;
  sidebarElement: HTMLDivElement | null;
}

export interface ISidebarProps {
  getProductsOptions: typeof productOptionsActions.getProductsOptions;
}

class Sidebar extends React.Component<ISidebarProps, ISidebarState> {
  public readonly state: ISidebarState;
  private readonly Slide: React.ComponentType<any>;
  private readonly SidebarContainer: React.ComponentType<any>;
  private y = popValue(0);

  constructor(props: ISidebarProps) {
    super(props);

    this.state = {
      isCollapsed: false,
      bottomBound: 0,
      sidebarElement: null,
    };
    this.Slide = getSlideComponent();
    this.SidebarContainer = getSidebarContainerComponent();
  }

  public componentDidMount() {
    window.addEventListener('resize', this.updateBottomBound);
    this.props.getProductsOptions();
  }

  public componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<ISidebarState>) {
    if (this.state.sidebarElement !== prevState.sidebarElement) {
      this.updateBottomBound();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateBottomBound);
  }

  public render() {
    const { isCollapsed, bottomBound, sidebarElement } = this.state;
    const Slide = this.Slide;
    const SidebarContainer = this.SidebarContainer;
    const valuesMap = { y: this.y };

    return (
      <SidebarContainer className={styles['sidebar']} ref={this.setSidebarElement} parentValues={valuesMap}>
        {sidebarElement && (
          <Slide
            onDragEnd={this.onDragEnd}
            pose={isCollapsed ? POSES.COLLAPSED : POSES.EXPANDED}
            bottomBound={bottomBound}
            values={valuesMap}
            className={styles['sidebar__slide']}
          >
            <SidebarScrollArea isHidden={isCollapsed} onScrollAreaClick={this.onScrollButtonClick} />
          </Slide>
        )}
        <ValidationProvider>
          <div className={styles['sidebar__inner']}>
            <SidebarTabs onTabSelect={this.onTabSelect} />
          </div>
        </ValidationProvider>
      </SidebarContainer>
    );
  }

  private getBottomBound = () => {
    const { sidebarElement } = this.state;

    return sidebarElement ? sidebarElement.offsetHeight - SIDEBAR_MIN_HEIGHT : 0;
  };

  private updateBottomBound = () => {
    this.setState({ isCollapsed: false, bottomBound: this.getBottomBound() });
  };

  private onDragEnd = () => {
    this.setState({ isCollapsed: true });
  };

  private onScrollButtonClick = () => {
    this.setState({ isCollapsed: true });
  };

  private onTabSelect = () => {
    this.setState({ isCollapsed: false });
  };

  private setSidebarElement = (element: HTMLDivElement) => {
    return this.setState({ sidebarElement: element });
  };
}

export default connect(null, {
  getProductsOptions: productOptionsActions.getProductsOptions,
})(Sidebar);
