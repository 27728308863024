import * as React from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IProductOption } from 'root/model/product/ProductOption';

import styles from './index.module.scss';
import { Size, MapLanguage, Framing, Scale, MapType, ProductId } from 'root/model/map/MapEnums';
import { ValidationContext, ValidationContextType } from 'root/scenes/Home/components/Sidebar/providers/validation';
import { getMapScale, getMapType, getMemorizedFraming, getProductId } from 'root/features/map/selectors';
import { setMapOptionsParams, setMapStyleParams } from 'root/features/map/actions';
import { Tile } from 'root/components/Tile';

export interface IOptionProps extends IProductOption {
    active: boolean;
}

export const Option = ({
    active,
    price,
    size,
    productId,
    material,
    dimension,
    framings,
    shortDescription,
    currency,
    name,
    delivery,
}: IOptionProps) => {
    const { displayValidation, setDisplayValidation } = useContext(ValidationContext) as ValidationContextType;
    const mapType = useSelector(getMapType);
    const memorizedFraming = useSelector(getMemorizedFraming);
    const mapScale = useSelector(getMapScale);
    const globalProductId = useSelector(getProductId);
    const dispatch = useDispatch();

    const priceStrings = price.toFixed(2).toString().split('.');

    const onClick = () => {
        if (size === Size.SMALL || size === Size.MEDIUM) {
            dispatch(setMapStyleParams({ mapLanguage: MapLanguage.ENGLISH }));
        }

        // TODO - temporary removed to check if it is necessary
        // if (
        //     mapScale === Scale.OS_EXPLORER_ENLARGED &&
        //     mapType === MapType.PAPER &&
        //     [Size.SMALL, Size.LARGE].includes(size) &&
        //     productId === ProductId.FOLDED_MAP
        // ) {
        //     dispatch(setMapStyleParams({ mapScale: Scale.OS_EXPLORER }));
        // }

        setDisplayValidation(false);

        dispatch(
            setMapOptionsParams({
                productId,
                material,
                framing: framings.includes(memorizedFraming) ? memorizedFraming : Framing.NONE,
                framings,
                size,
                dimension,
                price,
                shortDescription,
                currency,
                name,
            })
        );
    };

    return (
        <Tile
            variant="primary"
            className={styles['option']}
            action={onClick}
            isActive={active}
            isError={globalProductId === ProductId.NONE && displayValidation}
        >
            <div className={styles['option__description']}>
                <span className={styles['option__description-first-line']}>
                    <strong>{name}</strong>
                </span>
                <div className={styles['option__description-second-line']}>
                    <div>
                        <span className={styles['option__price-integer']}>
                            <span className={styles['option__price-integer__currency']}>&#xa3;</span>
                            {priceStrings[0]}
                        </span>
                        <span className={styles['option__price-decimal']}>.{priceStrings[1]}</span>
                    </div>
                    {dimension && (
                        <span className={styles['option__dimension']}>
                            {dimension}
                            <span className={styles['option__dimension__asteriks']}>*</span>
                        </span>
                    )}
                </div>
                {delivery && <div className={styles['option--delivery']}>{delivery}</div>}
            </div>
        </Tile>
    );
};
