export const SET_TITLE_LINE = 'customisation/SET_TITLE_LINE';
export const SET_TITLE_FIRST_LINE = 'customisation/SET_TITLE_FIRST_LINE';
export const SET_TITLE_SECOND_LINE = 'customisation/SET_TITLE_SECOND_LINE';
export const SET_TITLE_THIRD_LINE = 'customisation/SET_TITLE_THIRD_LINE';
export const SET_SUBTITLE_FIRST_LINE = 'customisation/SET_SUBTITLE_FIRST_LINE';
export const SET_SUBTITLE_SECOND_LINE =
  'customisation/SET_SUBTITLE_SECOND_LINE';
export const SET_SPINE = 'customisation/SET_SPINE';
export const SET_SPINE_FROM_TITLES = 'customisation/SET_SPINE_FROM_TITLES';
export const SET_LINK_TO_IMAGE = 'cover/SET_LINK_TO_IMAGE';
export const SET_IS_LOADING_IMAGE = 'cover/SET_IS_LOADING_IMAGE';
