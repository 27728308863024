import { combineEpics, Epic } from 'redux-observable';
import { of, EMPTY } from 'rxjs';
import {
    filter,
    debounceTime,
    distinctUntilChanged,
    switchMap,
    map,
    catchError,
    withLatestFrom,
    mergeMap,
} from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import Types from 'Types';
import { LOCATION_FORM_FIELD_NAMES } from 'root/model/location/LocationFormValues';
import { mapActions } from 'features/map';
import { MapAction } from 'features/map/reducer';
import { locationConstants, locationActions, locationSelectors } from 'features/location';

import { LocationAction } from './reducer';
import { NationalGridToEastingNorthing } from 'features/location/utils';
import { getLocationLabel } from 'root/scenes/Home/components/Sidebar/components/SidebarTabs/components/AreaAndStyleTab/components/LocationTab/components/LocationQueryFormSection/utils';

const splittingCondition = (data: any) => {
    if (data.length === 2 && !isNaN(Number(data[0])) && !isNaN(Number(data[1]))) {
        return true;
    } else {
        return false;
    }
};

const isEastingNorthing = (payload: string) => {
    let newPayload = '';
    const splitForComma = payload.split(',');
    const withComma = splittingCondition(splitForComma);
    if (withComma) {
        newPayload = payload;
    }

    const splitForSpace = payload.split(' ');
    const withSpace = splittingCondition(splitForSpace);
    if (withSpace) {
        newPayload = payload.replace(' ', ',');
    }

    const oneWord = payload.length === 12 && !isNaN(Number(payload));
    if (oneWord) {
        newPayload = payload.slice(0, 6) + ',' + payload.slice(6, payload.length);
    }

    return { isEastingNorthing: withComma || withSpace || oneWord, newPayload };
};

const dataForApi = (payload: string) => {
    const fromNationalGrid = NationalGridToEastingNorthing(payload);
    const eastingNorthing = isEastingNorthing(payload);
    if (fromNationalGrid) {
        return { query: fromNationalGrid, isNearestEndpoint: true };
    } else if (eastingNorthing.isEastingNorthing) {
        return { query: eastingNorthing.newPayload, isNearestEndpoint: true };
    } else {
        return { query: payload, isNearestEndpoint: false };
    }
};

export const findLocationAction: Epic<LocationAction, LocationAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { location }
) =>
    action$.pipe(
        filter(isOfType(locationConstants.FIND_LOCATIONS)),
        debounceTime(250),
        filter((action) => action.payload.length >= 3 || action.payload.length === 0),
        distinctUntilChanged(),
        switchMap((action) => {
            const { resolve, reject } = action.meta;
            const newPayload = dataForApi(action.payload);

            if (action.payload) {
                return location.findLocationsFromAPI(newPayload.query, newPayload.isNearestEndpoint).pipe(
                    map((result) => {
                        if (resolve) {
                            resolve();
                        }

                        return locationActions.setLocations(result);
                    }),
                    catchError((error) => {
                        if (reject) {
                            reject(error);
                        }

                        return EMPTY;
                    })
                );
            } else {
                return of(locationActions.setLocations([]));
            }
        })
    );

export const submitLocationFormAction: Epic<Types.RootAction, MapAction, Types.RootState, Types.Services> = (
    action$,
    state$
) =>
    action$.pipe(
        filter(isOfType(locationConstants.SUBMIT_LOCATION_FORM)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const values = action.payload;
            const locationQuery = values[LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY];
            const locationValue = values[LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE];
            const locations = locationSelectors.getLocations(state);
            const location = locations.find((el) => getLocationLabel(el) === locationValue);

            if (location) {
                const locationCoordinates = {
                    x: location.geometryX,
                    y: location.geometryY,
                };

                return [
                    mapActions.setCentreLocationCoordinates(locationCoordinates),
                    mapActions.setMapLocationParams({
                        query: locationQuery,
                        coordinates: locationCoordinates,
                        detailedViewCoordinates: locationCoordinates,
                    }),
                ];
            }

            return EMPTY;
        })
    );

export const localizeUserAction: Epic<
    Types.RootAction,
    LocationAction | MapAction[] | MapAction,
    Types.RootState,
    Types.Services
> = (action$, state$, { location }) =>
    action$.pipe(
        filter(isOfType(locationConstants.LOCALIZE_USER)),
        switchMap((action) => {
            const { resolve, reject } = action.meta;

            return location.getUserGeoLocationCoordinates().pipe(
                mergeMap((coordinates) => {
                    if (resolve) {
                        resolve();
                    }

                    return [
                        mapActions.setMapLocationParams({
                            query: '',
                            coordinates,
                            detailedViewCoordinates: coordinates,
                        }),
                        mapActions.setCentreLocationCoordinates(coordinates),
                    ];
                }),
                catchError((error) => {
                    if (reject) {
                        reject(error);
                    }

                    return of(locationActions.setGeoLocalizationEnabled(false));
                })
            );
        })
    );

export default combineEpics(findLocationAction, submitLocationFormAction, localizeUserAction);
