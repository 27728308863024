import * as React from 'react';
import { connect } from 'react-redux';
import * as Types from 'Types';
import { IMapLocationCoordinates } from 'root/model/map/MapParams';
import { mapSelectors, mapActions } from 'features/map';
import MapPreview from './components/MapPreview';
import styles from './index.module.scss';

interface IMapPreviewContainerProps {
  mapLocationCoordinates: IMapLocationCoordinates;
  initializeMapParamsFromSearchParams: typeof mapActions.initializeMapParamsFromSearchParams;
  initializeMapParamsFromProductOptions: typeof mapActions.initializeMapParamsFromProductOptions;
}

class MapPreviewContainer extends React.Component<IMapPreviewContainerProps> {
  public componentDidMount() {
    this.initializeMapParams();
  }

  public render() {
    const { mapLocationCoordinates } = this.props;

    return (
      <div className={styles['map-preview-container']}>
        {mapLocationCoordinates && (
          <MapPreview mapLocationCoordinates={mapLocationCoordinates} />
        )}
      </div>
    );
  }

  private initializeMapParams = () => {
    const {
      initializeMapParamsFromSearchParams,
      initializeMapParamsFromProductOptions,
    } = this.props;

    initializeMapParamsFromSearchParams();
    initializeMapParamsFromProductOptions();
  };
}

export default connect(
  (state: Types.RootState) => ({
    mapLocationCoordinates: mapSelectors.getMapLocationCoordinates(state),
  }),
  {
    initializeMapParamsFromSearchParams:
      mapActions.initializeMapParamsFromSearchParams,
    initializeMapParamsFromProductOptions:
      mapActions.initializeMapParamsFromProductOptions,
  }
)(MapPreviewContainer);
