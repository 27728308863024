import topExplorerBackground from 'src/modules/assets/foldedCover/topExplorerBackground.jpg';
import topLandrangerBackground from 'src/modules/assets/foldedCover/topLandrangerBackground.jpg';
import topSmallExplorerBackground from 'src/modules/assets/foldedCover/topSmallExplorerBackground.jpg';
import topSmallLandrangerBackground from 'src/modules/assets/foldedCover/topSmallLandrangerBackground.jpg';
import coverBackground from 'src/modules/assets/foldedCover/coverBackground.png';

import { PAPER_MAP } from '../../types';
import { MapStyle, Scale } from 'root/model/map/MapEnums';

export const FOLDED_COVER = {
    [PAPER_MAP.SMALL]: {
        [MapStyle.OS_EXPLORER]: topSmallExplorerBackground,
        [MapStyle.OS_LANDRANGER]: topSmallLandrangerBackground,
    },
    [PAPER_MAP.LARGE]: {
        [MapStyle.OS_EXPLORER]: topExplorerBackground,
        [MapStyle.OS_LANDRANGER]: topLandrangerBackground,
    },
};

export const COVER_BACKGROUND = coverBackground;
