import { get, trim } from 'lodash';
import * as searchParams from 'search-params';
import { createSelector } from 'reselect';

import * as Types from 'Types';
import { IMapParams } from 'root/model/map/MapParams';
import { parseMapScale, parseProductId } from 'features/map/utils';
import { SEARCH_QUERY_PARAMS } from 'root/constants';
import { initialMapLocationParams } from './reducer';
import { Framing } from 'root/model/map/MapEnums';

export const getMapParamsFromSearchParams = (state: Types.RootState): Partial<IMapParams> => {
    const locationSearch = state.router.location.search;
    const searchParamsObj = searchParams.parse(trim(locationSearch, '?'));
    const querySearchParam = get(searchParamsObj, SEARCH_QUERY_PARAMS.LOCATION_QUERY) as string;
    const mapScaleSearchParam = parseMapScale(get(searchParamsObj, SEARCH_QUERY_PARAMS.MAP_TYPE) as string);
    const productIdSearchParam = parseProductId(get(searchParamsObj, SEARCH_QUERY_PARAMS.PRODUCT_ID) as string);

    return Object.assign(
        {},
        querySearchParam && {
            location: {
                query: querySearchParam,
            },
        },
        mapScaleSearchParam && {
            style: {
                mapScale: mapScaleSearchParam,
            },
        },
        productIdSearchParam && {
            options: {
                productId: productIdSearchParam,
            },
        }
    );
};

export const getMapLocationQuery = (state: Types.RootState) => state.map.mapLocationParams.query;

export const getMapLocationCoordinates = (state: Types.RootState) =>
    state.map.mapLocationParams.coordinates
        ? state.map.mapLocationParams.coordinates
        : initialMapLocationParams.coordinates;

export const getMapOptionsParams = (state: Types.RootState) => state.map.mapOptionsParams;

export const getProductId = (state: Types.RootState) => state.map.mapOptionsParams.productId;

export const getMaterial = (state: Types.RootState) => state.map.mapOptionsParams.material;

export const getFramings = (state: Types.RootState) => state.map.mapOptionsParams.framings;

export const getFraming = (state: Types.RootState) => state.map.mapOptionsParams.framing || Framing.NONE;

export const getMemorizedFraming = (state: Types.RootState) =>
    state.map.mapOptionsParams.memorizedFraming || Framing.NONE;

export const getPrice = (state: Types.RootState) => state.map.mapOptionsParams.price;

export const getMapDimension = (state: Types.RootState) => state.map.mapOptionsParams.dimension;

export const getName = (state: Types.RootState) => state.map.mapOptionsParams.name;

export const getMapShortDescription = (state: Types.RootState) => state.map.mapOptionsParams.shortDescription;

export const getMapCurrency = (state: Types.RootState) => state.map.mapOptionsParams.currency;

export const getMapSize = (state: Types.RootState) => state.map.mapOptionsParams.size;

export const getMapStyleParams = (state: Types.RootState) => state.map.mapStyleParams;

export const getMapScale = (state: Types.RootState) => state.map.mapStyleParams.mapScale;

export const getMapStyle = (state: Types.RootState) => state.map.mapStyleParams.mapStyle;

export const getSelectedMapStyle = (state: Types.RootState) => state.map.mapStyleParams.selectedMapStyle;

export const getMapLanguage = (state: Types.RootState) => state.map.mapStyleParams.mapLanguage;

export const getMapType = (state: Types.RootState) => state.map.mapStyleParams.mapType;

export const getProduct = createSelector(
    [getProductId, getMaterial, getFramings, getMapSize, getFraming],
    (productId, material, framings, size, framing) => ({
        productId,
        material,
        framings,
        size,
        framing,
    })
);

export const getDetailedViewCoordinates = (state: Types.RootState) =>
    state.map.mapLocationParams.detailedViewCoordinates
        ? state.map.mapLocationParams.detailedViewCoordinates
        : initialMapLocationParams.detailedViewCoordinates;

export const getProductParams = (state: Types.RootState) => state.map.productParams;

export const getProductPrice = (state: Types.RootState) => state.map.productParams.price;

export const getAreaAndStyleTab = (state: Types.RootState) => state.map.areaAndStyleTab;

export const getAreaAndStyleSelectedTab = (state: Types.RootState) => state.map.areaAndStyleTab.selectedTab;

export const getIsMiniMapOpen = (state: Types.RootState) => state.map.areaAndStyleTab.isMiniMapOpen;
