import React from 'react';
import cn from 'classnames';
import { Tooltip } from '@material-ui/core';

import styles from './index.module.scss';
import mainStyles from '../../../../RouteTab.module.scss';
import { COLOR_LIST } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { getRouteSettingColor } from 'root/features/route/selectors';
import { changeRouteSetting } from 'root/features/route/actions';

export const ColorSelector = () => {
    const active = useSelector(getRouteSettingColor);
    const dispatch = useDispatch();

    return (
        <>
            <span className={mainStyles['style-section__title']}>Line Colour</span>

            <div className={styles['container']}>
                {COLOR_LIST.map(({ name, classname, label, value }, index) => {
                    return (
                        <div className={styles['color-wrapper']} key={`color-${name}-${index}`}>
                            <Tooltip title={label} placement="top" arrow>
                                <button
                                    className={cn(styles['color-button'], {
                                        [styles['active']]: active === value,
                                        [styles[`color-button--${classname}`]]: true,
                                    })}
                                    aria-label={`list item - ${label}`}
                                    onClick={() => dispatch(changeRouteSetting({ lineColourHex: value }))}
                                />
                            </Tooltip>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
