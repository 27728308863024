import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import {
  SET_TITLE_FIRST_LINE,
  SET_TITLE_SECOND_LINE,
  SET_TITLE_THIRD_LINE,
  SET_SUBTITLE_FIRST_LINE,
  SET_SUBTITLE_SECOND_LINE,
  SET_SPINE,
  SET_LINK_TO_IMAGE,
  SET_IS_LOADING_IMAGE,
} from './constants';

export type CustomisationActions = ActionType<typeof actions>;

export const titleFirstLineReducer = (
  state = '',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_TITLE_FIRST_LINE:
      return action.payload;
    default:
      return state;
  }
};

export const titleSecondLineReducer = (
  state = '',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_TITLE_SECOND_LINE:
      return action.payload;
    default:
      return state;
  }
};

export const titleThirdLineReducer = (
  state = '',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_TITLE_THIRD_LINE:
      return action.payload;
    default:
      return state;
  }
};

export const subtitleFirstLineReducer = (
  state = '',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_SUBTITLE_FIRST_LINE:
      return action.payload;
    default:
      return state;
  }
};

export const subtitleSecondLineReducer = (
  state = '',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_SUBTITLE_SECOND_LINE:
      return action.payload;
    default:
      return state;
  }
};

export const spineReducer = (
  state = 'OS Custom Made',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_SPINE:
      return action.payload;
    default:
      return state;
  }
};

export const setLinkToImageReducer = (
  state = '',
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_LINK_TO_IMAGE:
      return action.payload;
    default:
      return state;
  }
};

export const isLoadingImageReducer = (
  state = { isLoading: false, index: -1 },
  action: CustomisationActions
) => {
  switch (action.type) {
    case SET_IS_LOADING_IMAGE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  titleFirstLine: titleFirstLineReducer,
  titleSecondLine: titleSecondLineReducer,
  titleThirdLine: titleThirdLineReducer,
  subtitleFirstLine: subtitleFirstLineReducer,
  subtitleSecondLine: subtitleSecondLineReducer,
  spine: spineReducer,
  linkToImage: setLinkToImageReducer,
  isLoadingImage: isLoadingImageReducer,
});
