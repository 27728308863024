import * as React from 'react';
import { TabList, Tab, Tabs } from 'react-tabs';
import * as classNames from 'classnames';

import OrderForm from './components/OrderForm';
import styles from './index.module.scss';
import { TabContent as renderTabContent } from './components/TabContent';
import { useSideBarTabs } from './hooks';

type SidebarTabsProps = {
    onTabSelect: () => void;
};

const SideBarTabs = ({ onTabSelect }: SidebarTabsProps) => {
    const { getDefaultTabsIndex, onSelect, isCustomiseStep } = useSideBarTabs(onTabSelect);

    const tabCssClasses = styles['sidebar-tabs__tab'];

    return (
        <Tabs
            className={styles['sidebar-tabs']}
            selectedTabClassName={styles['sidebar-tabs__tab--selected']}
            selectedIndex={getDefaultTabsIndex()}
            onSelect={onSelect}
        >
            <TabList className={styles['sidebar-tabs__tab-list']}>
                <Tab className={tabCssClasses}>
                    <i
                        className={classNames(
                            styles['sidebar-tabs__tab-icon'],
                            styles['sidebar-tabs__tab-icon--options']
                        )}
                    />
                    Product
                </Tab>

                <Tab className={tabCssClasses}>
                    <i
                        className={classNames(
                            styles['sidebar-tabs__tab-icon'],
                            styles['sidebar-tabs__tab-icon--location']
                        )}
                    />
                    Area and style
                </Tab>

                <Tab className={tabCssClasses}>
                    <i
                        className={classNames(
                            styles['sidebar-tabs__tab-icon'],
                            styles['sidebar-tabs__tab-icon--customise']
                        )}
                    />
                    Customise
                </Tab>
            </TabList>

            {isCustomiseStep ? (
                <OrderForm>
                    {
                        // related issue https://github.com/reactjs/react-tabs/issues/481
                        renderTabContent({ isCustomiseStep })
                    }
                </OrderForm>
            ) : (
                // related issue https://github.com/reactjs/react-tabs/issues/481
                renderTabContent({ isCustomiseStep })
            )}
        </Tabs>
    );
};

export default SideBarTabs;
