enum OptionalCookiesState {
  accepted = 'accepted',
  revoked = 'revoked',
}

export enum OptionalCookies {
  performance = 'performance',
  functionality = 'functionality',
  targeting = 'targeting',
}

export enum ExternalCookies {
  DataDog = '_dd_s',
}

type CookieType = {
  consentDate: number;
  consentExpiry: number;
  interactedWith: boolean;
  necessaryCookies: string[];
  optionalCookies: Record<OptionalCookies, OptionalCookiesState>;
  statement: { shown: boolean; updated: string };
  user: string;
};

const getCookie = (cookieName: string): string | undefined => {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookies = decodedCookie.split('; ');

  return cookies.find((cookie) => cookie.startsWith(name))?.split(name)[1];
};

const removeCookie = (cookieName: string) => {
  const cookie = getCookie(cookieName);
  if (!cookie) return;

  document.cookie = `${cookieName}=${cookie};max-age=0`;
};

export const cookieBasedInitializer = (
  optionalCookie: OptionalCookies,
  initializer: () => void,
  removeCookies?: string[]
) => {
  const cookie = getCookie('CookieControl');
  if (!cookie) return;

  let accepted = false;

  try {
    accepted = (JSON.parse(cookie) as CookieType).optionalCookies?.[optionalCookie] === OptionalCookiesState.accepted;
  } catch (error) {
    accepted = !!cookie.match(`${optionalCookie}:${OptionalCookiesState.accepted}`);
  }

  if (accepted) {
    return initializer();
  }

  if (removeCookies?.length) removeCookies.forEach((cookie) => removeCookie(cookie));
};
