import { RouteProps } from 'react-router';

import { Home } from 'root/scenes/Home';
import NotFound from 'root/scenes/NotFound';
import OrderConfirmation from 'root/scenes/OrderConfirmation';

import { RoutePath } from './enums';

interface IRouteProps extends RouteProps {
    title: string;
}

const routes: { [key: string]: IRouteProps } = {
    [RoutePath.ROOT]: {
        path: RoutePath.ROOT,
        component: Home,
        exact: true,
        title: 'OS Custom Made Maps - Home',
    },
    [RoutePath.AREA_AND_STYLE]: {
        path: RoutePath.AREA_AND_STYLE,
        component: Home,
        exact: true,
        title: 'OS Custom Made Maps - Home',
    },
    [RoutePath.CUSTOMISE]: {
        path: RoutePath.CUSTOMISE,
        component: Home,
        exact: true,
        title: 'OS Custom Made Maps - Home',
    },
    [RoutePath.CONFIRM_ORDER]: {
        path: RoutePath.CONFIRM_ORDER,
        component: OrderConfirmation,
        exact: true,
        title: 'OS Custom Made Maps - Confirm Order',
    },
    default: {
        title: 'OS Custom Made Maps - Not found',
        component: NotFound,
    },
};

export default routes;
