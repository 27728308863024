import React, { forwardRef } from 'react';
import { StyledIconProps } from '@styled-icons/styled-icon';
import styled from 'styled-components';

import { ReactComponent } from './assets/center.svg';

const Icon = styled(ReactComponent)`
  & * {
    fill: currentColor;
  }
`;

export const CenterMapIcon = forwardRef<SVGSVGElement, StyledIconProps>(({ size, ...props }, ref) => (
  <Icon ref={ref} width={size} height={size} viewBox="0 0 30 30" {...props} />
));
