import { fromEvent, EMPTY } from 'rxjs';
import { filter, take, timeout, map } from 'rxjs/operators';
import { typeCheck } from 'type-check';

import { IMapLocationCoordinates } from 'root/model/map/MapParams';
import { ICustomisationTitle } from 'root/model/customisation/CustomisationTitles';
import { RouteForBasketType } from 'root/model/route/Route';

export const addToBasket = (
    SKU: string,
    mapLocationCoordinates: IMapLocationCoordinates,
    customisationTitle: ICustomisationTitle,
    linkToImage: string | null,
    quantity: number,
    routeSpecification: RouteForBasketType | undefined
) => {
    const message = {
        sku: SKU,
        quantity,
        geometry: {
            type: 'Point',
            coordinates: [mapLocationCoordinates.x, mapLocationCoordinates.y],
        },
        routeSpecification,
        title: customisationTitle.title,
        subtitle: customisationTitle.subtitle,
        ...(customisationTitle.spine !== null ? { spine: customisationTitle.spine } : {}),
        ...(linkToImage !== null ? { cover: linkToImage } : {}),
    };

    const response = fromEvent(window, 'message').pipe(
        filter((event: MessageEvent) => {
            if (process.env.CMFA_APP_OS_SEND_ORDER_URL?.endsWith('/')) {
                console.warn(
                    'Basket target does not support non-domain based URLs eg. with slash at the end. Wrong target will cause an error in Chrome'
                );
            }

            return (
                event.origin === process.env.CMFA_APP_OS_SEND_ORDER_URL &&
                typeCheck('{success: Boolean, error_code: Maybe Number,error_message: Maybe string}', event.data)
            );
        }),
        map((event) => {
            if (event.data.success) {
                return EMPTY;
            } else {
                throw new Error();
            }
        }),
        take(1),
        timeout(10000)
    );

    if (process.env.CMFA_APP_OS_SEND_ORDER_URL) {
        window.parent.postMessage(message, process.env.CMFA_APP_OS_SEND_ORDER_URL);
    }

    return response;
};
