import * as React from 'react';
import * as classNames from 'classnames';
import { connect } from 'react-redux';
import Types from 'Types';
import { PRODUCT_PREVIEW_RESOLUTION } from 'root/constants';
import { productPreviewActions } from 'features/productPreview';
import Button, { ButtonType } from 'root/components/Button';
import styles from './index.module.scss';
import { mapSelectors } from 'root/features/map';
import { MapType, Size } from 'root/model/map/MapEnums';

interface IProductPreviewErrorMessagProps {
  isCover?: boolean;
  getProductPreview: typeof productPreviewActions.getProductPreview;
  mapSize: Size;
  mapType?: number;
}

const ProductPreviewErrorMessage: React.FunctionComponent<IProductPreviewErrorMessagProps> = ({
  isCover = true,
  getProductPreview,
  mapSize,
  mapType,
}) => {
  const isRectangleFramed =
    mapType === MapType.CANVAS_AND_FRAMED &&
    [Size.SMALL, Size.MEDIUM].includes(mapSize);

  const coverDropzoneContentCssClasses = classNames(
    styles['product-preview-error-message'],
    {
      [styles['product-preview-error-message']]: isCover,
      [styles['product-preview-error-message--rectangle']]: isRectangleFramed,
    }
  );

  const onTryAgain = () => {
    getProductPreview(PRODUCT_PREVIEW_RESOLUTION);
  };

  return (
    <div className={coverDropzoneContentCssClasses}>
      <div className={styles['product-preview-error-message__container']}>
        <i className={styles['product-preview-error-message__map-icon']} />
        <i />
        <p className={styles['product-preview-error-message__description']}>
          Your map preview could not be loaded at this moment. Please{' '}
          <Button
            text="try again"
            onClick={onTryAgain}
            type={ButtonType.LINK}
            className={styles['product-preview-error-message__button']}
          />{' '}
          or continue to next step.
        </p>
      </div>
    </div>
  );
};

export default connect(
  (state: Types.RootState) => ({
    mapSize: mapSelectors.getMapSize(state),
    mapType: mapSelectors.getMapType(state),
  }),
  {
    getProductPreview: productPreviewActions.getProductPreview,
  }
)(ProductPreviewErrorMessage);
