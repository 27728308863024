import React, { ReactNode, SyntheticEvent } from 'react';

import { Modal as MuiModal } from '@material-ui/core';
import { ModalProps as MaterialModalProps } from '@material-ui/core/Modal';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './popper.module.scss';

export type MobilePopperProps = Omit<MaterialModalProps, 'title'> & {
    id: string | undefined;
    open: boolean;
    title?: ReactNode;
    onClose?: (event: SyntheticEvent<Element, Event>) => void;
    ariaLabelCloseBtn?: string;
    ariaLabel?: string;
    subtitle?: ReactNode;
};

export const MobilePopper = ({
    children,
    id,
    open,
    title,
    ariaLabel,
    ariaLabelCloseBtn,
    onClose,
    subtitle,
    ...props
}: MobilePopperProps) => {
    return (
        <MuiModal {...props} aria-label={ariaLabel} id={id} open={open}>
            <div className={`${styles['modal']} ${styles['modal--mobile']}`}>
                <div className={styles['modal__header']}>
                    {title && <div className={styles['modal__header__title']}>{title}</div>}

                    {subtitle && <div className={styles['modal__header__subtitle']}>{subtitle}</div>}

                    {onClose && (
                        <button
                            className={styles['modal__header__close-button']}
                            onClick={onClose}
                            aria-label={ariaLabelCloseBtn ? ariaLabelCloseBtn : 'Close pop up'}
                        >
                            <CloseIcon name="os-close" />
                        </button>
                    )}
                </div>

                <div className={styles['modal__content']}>{children}</div>
            </div>
        </MuiModal>
    );
};
