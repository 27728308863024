import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { BREAKPOINTS } from './constants';

export const getMediaQuery = (windowWidth: number) => {
  if (windowWidth < BREAKPOINTS.SMALL) {
    return MediaQuery.XSMALL;
  }

  if (windowWidth < BREAKPOINTS.MEDIUM) {
    return MediaQuery.SMALL;
  }

  if (windowWidth < BREAKPOINTS.LARGE) {
    return MediaQuery.MEDIUM;
  }

  if (windowWidth < BREAKPOINTS.XLARGE) {
    return MediaQuery.LARGE;
  }

  return MediaQuery.XLARGE;
};

export const widerThanOrEqual = (actual: MediaQuery, comparedTo: MediaQuery) =>
  BREAKPOINTS[actual] >= BREAKPOINTS[comparedTo];

export const thinnerThan = (actual: MediaQuery, comparedTo: MediaQuery) =>
  BREAKPOINTS[actual] < BREAKPOINTS[comparedTo];
