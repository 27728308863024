import React, { createContext, FC, useState } from 'react';

export type ValidationContextType = {
  displayValidation: boolean;
  setDisplayValidation: React.Dispatch<React.SetStateAction<boolean>>;
};

export const ValidationContext = createContext<ValidationContextType | null>(null);

export const ValidationProvider: FC = ({ children }) => {
  const [displayValidation, setDisplayValidation] = useState<boolean>(false);

  return (
    <ValidationContext.Provider value={{ displayValidation, setDisplayValidation }}>
      {children}
    </ValidationContext.Provider>
  );
};
