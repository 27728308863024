import { get } from 'lodash';
import { AnyLayer, GeoJSONSource, Map } from 'mapbox-gl';
import { FeatureType, RouteVM } from 'root/model/route/Route';
import {
    FEATURE,
    FEATURE_COLLECTION,
    GEOMETRY_COORDINATES,
    LAYER_ORDER,
    LINE_COLOR,
    LINE_OPACITY,
    LINE_STRING,
    LINE_WIDTH,
    PROPERTIES_KIND,
    TRACK_SEGMENT,
} from './constants';
import { RouteStyleType } from './types';

export const getRouteTrackSegments = (features: FeatureType[]) =>
    features.filter((feature: FeatureType) => get(feature, PROPERTIES_KIND, '') === TRACK_SEGMENT);

export const getFeatureCoordinates = (feature: FeatureType) => get(feature, GEOMETRY_COORDINATES, []);

export const getRouteTrackSegmentsCoordinates = (features: FeatureType[]) =>
    getRouteTrackSegments(features).flatMap(getFeatureCoordinates);

export const reorderLayers = (map: Map) => {
    LAYER_ORDER.forEach((layer) => map.getLayer(layer) && map.moveLayer(layer));
};

export const getRoutePaintFromFromRouteStyle = (routeStyle: RouteStyleType) => ({
    [LINE_COLOR]: routeStyle.lineColourHex,
    [LINE_WIDTH]: routeStyle.lineThickness,
    [LINE_OPACITY]: routeStyle.lineOpacity,
});

export const upsertRouteLineSource = (map: Map, featureCollection: RouteVM | undefined, sourceId: string) => {
    const source = map.getSource(sourceId) as GeoJSONSource;
    const routeTrackSegmentsCoordinates =
        featureCollection && getRouteTrackSegmentsCoordinates(featureCollection.features);
    const path = routeTrackSegmentsCoordinates;

    const data = {
        type: FEATURE_COLLECTION,
        features: [
            {
                type: FEATURE,
                geometry: {
                    type: LINE_STRING,
                    coordinates: path,
                },
            },
        ],
    } as GeoJSON.FeatureCollection;

    if (source) {
        source.setData(data);
    } else {
        map.addSource(sourceId, {
            data,
            type: 'geojson',
        });
    }
};

export const addRouteLineLayer = (map: Map, sourceId: string, layerId: string, routeStyle: RouteStyleType) => {
    reorderLayers(map);

    if (map.getLayer(layerId)) {
        return;
    }
    const paint = getRoutePaintFromFromRouteStyle(routeStyle);

    const layer = {
        id: layerId,
        type: 'line',
        source: sourceId,
        layout: {
            'line-join': 'bevel',
            'line-cap': 'square',
        },
        paint,
    } as AnyLayer;

    map.addLayer(layer);
};

export const updateRouteLineLayer = (map: Map, layerId: string, routeStyle: RouteStyleType) => {
    reorderLayers(map);

    if (!map.getLayer(layerId)) {
        return;
    }

    map.setPaintProperty(layerId, 'line-color', routeStyle.lineColourHex);
    map.setPaintProperty(layerId, 'line-width', routeStyle.lineThickness);
    map.setPaintProperty(layerId, 'line-opacity', routeStyle.lineOpacity);
};
