export enum PopupPosition {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top',
}

export enum PopupOn {
  Hover = 'hover',
  Click = 'click',
  Focus = 'focus',
  RightClick = 'right-click',
  None = 'none',
}
