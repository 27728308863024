import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { findLocations, localizeUser } from 'root/features/location/actions';
import { getLocations, getGeoLocalizationEnabled } from 'root/features/location/selectors';
import { resetMapLocationParams } from 'root/features/map/actions';
import { LOCATION_FORM_FIELD_NAMES } from 'root/model/location/LocationFormValues';
import { LocationNameType, LocationQueryFormSectionProps } from '../model';
import { getLocationLabel, getLocationNames } from '../utils';

export type LocationQueryChangeFunc = (newValue: string) => Promise<void>;

export const useLocationQueryFormSection = ({
    mapLocationQuery,
    handleSubmit,
    setFieldError,
    setFieldTouched,
    setFieldValue,
    handleChange,
    values,
    setValues,
}: LocationQueryFormSectionProps) => {
    const [locationNames, setLocationNames] = useState<LocationNameType[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const locations = useSelector(getLocations);
    const localizationEnabled = useSelector(getGeoLocalizationEnabled);
    const dispatch = useDispatch();

    const handleLocationQueryFieldError = useCallback(
        async (promise: Promise<unknown>) => {
            try {
                await promise;
                setFieldError(LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY, '');
            } catch (error) {
                setFieldTouched(LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY);
                setFieldError(LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY, error);
            }
        },
        [setFieldError]
    );

    const handleFindFieldLocation = useCallback(
        async (query: string) => {
            await handleLocationQueryFieldError(
                new Promise((resolve, reject) => {
                    setIsLoading(true);
                    dispatch(findLocations(query, { resolve, reject }));
                }).finally(() => {
                    setIsLoading(false);
                })
            );
        },
        [handleLocationQueryFieldError, findLocations]
    );

    const initializeLocationValue = useCallback(
        (mapLocationQuery: string) => {
            const locationValue = locations.findIndex((location) => {
                return getLocationLabel(location) === mapLocationQuery;
            });

            if (locationValue !== -1) {
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE, locationValue);
            }
        },
        [locations, getLocationLabel]
    );

    const onLocalizeMeClick = useCallback(async () => {
        await handleLocationQueryFieldError(
            new Promise((resolve, reject) => {
                dispatch(localizeUser({ resolve, reject }));
            })
        );
    }, [handleLocationQueryFieldError, localizeUser]);

    const onLocationNameClick = useCallback(
        (option: IOption) => {
            if (isEmpty(option)) {
                // onClearClick
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY, '');
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE, '');
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_SEARCH, '');

                dispatch(resetMapLocationParams());
            } else {
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY, option.label);
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE, option.value);
                setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_SEARCH, '');
                setValues({ locationQuery: option.label, locationValue: option.value, locationSearch: '' });
                handleSubmit();
            }
        },
        [values, handleSubmit, setFieldValue, LOCATION_FORM_FIELD_NAMES]
    );

    const onLocationQueryChange: LocationQueryChangeFunc = useCallback(
        async (newValue) => {
            setFieldValue(LOCATION_FORM_FIELD_NAMES.LOCATION_SEARCH, newValue);

            await handleFindFieldLocation(newValue);
        },
        [handleChange, setFieldValue, handleFindFieldLocation, LOCATION_FORM_FIELD_NAMES]
    );

    useEffect(() => {
        const initialize = async () => {
            if (mapLocationQuery && locations.length === 0) {
                await handleFindFieldLocation(mapLocationQuery);
                initializeLocationValue(mapLocationQuery);
                handleSubmit();
            }
        };

        initialize();
    }, []);

    useEffect(() => {
        setLocationNames(getLocationNames(locations));
    }, [locations]);

    return {
        isLoading,
        locationNames,
        localizationEnabled,
        onLocalizeMeClick,
        onLocationNameClick,
        onLocationQueryChange,
    };
};
