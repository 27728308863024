import { ActionType } from 'typesafe-actions';
import * as actions from 'features/userInfoModal/actions';
import {
  HIDE_USER_INFO_MODAL,
  SHOW_USER_INFO_MODAL,
  SHOW_SUCCESS_MESSAGE_MODAL,
  HIDE_SUCCESS_MESSAGE_MODAL,
} from 'features/userInfoModal/constants';
import { MessageType } from 'root/services/fileHelpers/consts';

export type UserInfoModalActions = ActionType<typeof actions>;

interface IUserInfoModalState {
  readonly isVisible: boolean;
  readonly headerText: string;
  readonly contentType: MessageType;
  readonly successMessageVisible: boolean;
}

const initialState: IUserInfoModalState = {
  isVisible: false,
  headerText: '',
  contentType: MessageType.NO_MESSAGE,
  successMessageVisible: false,
};

export const userInfoModalReducer = (
  state = initialState,
  action: UserInfoModalActions
) => {
  switch (action.type) {
    case SHOW_USER_INFO_MODAL:
      return { ...state, isVisible: true, ...action.payload };
    case HIDE_USER_INFO_MODAL:
      return {
        ...state,
        headerText: '',
        contentType: MessageType.NO_MESSAGE,
        isVisible: false,
      };
    case SHOW_SUCCESS_MESSAGE_MODAL:
      return { ...state, successMessageVisible: true };
    case HIDE_SUCCESS_MESSAGE_MODAL:
      return { ...state, successMessageVisible: false };
    default:
      return state;
  }
};

export default userInfoModalReducer;
