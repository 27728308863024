export enum MapStyle {
    OS_EXPLORER = 'OS_EXPLORER',
    OS_LANDRANGER = 'OS_LANDRANGER',
}

export enum SelectedMapStyle {
    AUTO = 'auto',
    OS_EXPLORER = 'OS_EXPLORER',
    OS_LANDRANGER = 'OS_LANDRANGER',
}

export enum Scale {
    /** Deprecated */
    NONE = 'NONE',

    /** Deprecated 1:20000 */
    OS_EXPLORER = 'OS_EXPLORER',

    /** Deprecated 1:8000 */
    OS_EXPLORER_ENLARGED = 'OS_EXPLORER_ENLARGED',

    /** Deprecated 1:40000 */
    OS_LANDRANGER = 'OS_LANDRANGER',

    OS_UK = 'OS_UK',

    /** 1:10000 */
    OS_EXPLORER_10K = 'OS_EXPLORER_10K',

    /** 1:12500 */
    OS_EXPLORER_12_5K = 'OS_EXPLORER_12_5K',

    /** 1:25000 */
    OS_EXPLORER_25K = 'OS_EXPLORER_25K',

    /** 1:40000 */
    OS_EXPLORER_40K = 'OS_EXPLORER_40K',

    /** 1:25000 */
    OS_EXPLORER_50K = 'OS_EXPLORER_50K',

    /** 1:25000 */
    OS_LANDRANGER_25K = 'OS_LANDRANGER_25K',

    /** 1:40000 */
    OS_LANDRANGER_40K = 'OS_LANDRANGER_40K',

    /** 1:50000 */
    OS_LANDRANGER_50K = 'OS_LANDRANGER_50K',
}

export enum ProductId {
    NONE = 'NONE',
    FOLDED_MAP = 'FOLDED_MAP',
    FLAT_MAP = 'FLAT_MAP',
}

export enum Material {
    PAPER = 'PAPER',
    CANVAS = 'CANVAS',
    NONE = 'NONE',
}

export enum Framing {
    NONE = 'NONE',
    LIGHT_OAK = 'LIGHT_OAK',
    BLACK = 'BLACK',
}

export interface IProduct {
    framing: Framing;
    productId?: ProductId;
    material?: Material;
    size?: Size;
    framings?: string[];
}

export enum MapLanguage {
    ENGLISH = 'EN',
    WELSH = 'CY',
}

export enum MapType {
    NONE = 0,
    PAPER = 1,
    CANVAS_AND_FRAMED = 2,
}

export enum Size {
    SMALL = 'SMALL',
    MEDIUM = 'MEDIUM',
    LARGE = 'LARGE',
    NONE = 'NONE',
}

export enum SheetSize {
    SMALL = 'A3',
    MEDIUM = 'A2',
    LARGE = 'LARGE',
    NONE = 'NONE',
}

export const convertSizeToSheetSize = (size?: Size): SheetSize => {
    if (size === Size.SMALL) {
        return SheetSize.SMALL;
    } else if (size === Size.MEDIUM) {
        return SheetSize.MEDIUM;
    } else if (size === Size.LARGE) {
        return SheetSize.LARGE;
    } else {
        return SheetSize.NONE;
    }
};

export enum SizeType {
    LARGE_FLAT = 'LARGE_FLAT',
    LARGE_FOLDED = 'LARGE_FOLDED',
    A3_FOLDED = 'A3_FOLDED',
    A3_FLAT = 'A3_FLAT',
    A2_FLAT = 'A2_FLAT',
}

export enum Proportions {
    SQUARE = 'SQUARE',
    RECTANGLE = 'RECTANGLE',
}
