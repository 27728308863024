import React, { FC, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

import { coverSelectors } from 'root/features/cover';
import { useTranslation } from 'root/modules/utils/locales';
import { COVER_BACKGROUND } from 'root/modules/utils/assets';
import { MAP_FORMAT } from 'root/modules/types';
import { MapFormatType, WidthType } from 'root/modules/styles';
import * as P from './parts';

const { getCoverUrl } = coverSelectors;

type Props = HTMLAttributes<HTMLDivElement> &
  WidthType &
  MapFormatType & {
    coverSrc: string;
  };

export const FoldedCover: FC<Props> = ({ coverSrc, width, mapFormat, ...props }) => {
  const photoUrl = useSelector(getCoverUrl);

  const [t] = useTranslation();

  return (
    <P.FoldedCover {...props}>
      <P.AspectRatioHolder aspectRatio={mapFormat} />
      {mapFormat === MAP_FORMAT.SQUARE && (
        <P.CustomMadeText width={width}>
          {t(({ productPreview }) => productPreview.foldedCover.osCustomMade)}
        </P.CustomMadeText>
      )}
      <P.CoverWrapper>
        <P.TitleWrapper width={width} mapFormat={mapFormat} />
        <P.TopCover src={coverSrc} width={width} />
        {photoUrl ? <P.ImageCover src={photoUrl} /> : <P.ImageCoverPlaceholder src={COVER_BACKGROUND} />}
      </P.CoverWrapper>
    </P.FoldedCover>
  );
};
