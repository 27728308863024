import * as React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';

import Types from 'Types';
import { coverActions, coverSelectors } from 'root/features/cover';

import styles from './index.module.scss';
import { userInfoModalActions } from 'features/userInfoModal';
import { ALLOWED_MIME_TYPE_FOR_IMAGE, MessageType } from 'root/services/fileHelpers/consts';
import { FileRejection } from 'root/model/fileRejection/FIleRejection';
import Button from '../Button';
import { Size } from 'root/model/map/MapEnums';
import { mapSelectors } from 'root/features/map';
import { customisationActions } from 'features/customisation';

interface ICoverDropButtonProps {
    isCover?: boolean;
    isValid?: boolean;
    coverUrl: string;
    mapSize: Size;
    imageSize: Size;
    isPredefinedImage: boolean;
    onChangeCover?: () => void;
    handleCoverUpload: typeof coverActions.handleCoverUpload;
    showUserInfoModal: typeof userInfoModalActions.showUserInfoModal;
    setImageSize: typeof coverActions.setImageSize;
    setIsPredefinedImage: typeof coverActions.setIsPredefinedImage;
    setCoverUrl: typeof coverActions.setCoverUrl;
    setLinkToImage: typeof customisationActions.setLinkToImage;
}

interface ICoverDropButtonState {
    isHovering: boolean;
}

class CoverDropButton extends React.Component<ICoverDropButtonProps, ICoverDropButtonState> {
    public static defaultProps = {
        isCover: false,
        isValid: true,
    };

    constructor(props: ICoverDropButtonProps) {
        super(props);
        this.state = {
            isHovering: false,
        };
    }

    public handleMouseOver = () => {
        this.setState({ isHovering: !this.state.isHovering });
    };

    public componentDidMount() {
        const { mapSize, imageSize, isPredefinedImage, setCoverUrl, setLinkToImage, setImageSize } = this.props;
        if (imageSize !== Size.NONE && mapSize !== imageSize && !isPredefinedImage) {
            setLinkToImage('');
            setCoverUrl('');
        }
        setImageSize(mapSize);
    }

    private onDrop = async (accepted: File[], rejected: FileRejection[]) => {
        const { onChangeCover } = this.props;

        try {
            await new Promise((resolve, reject) => {
                this.props.handleCoverUpload(accepted, rejected, { resolve, reject });
            });

            this.props.setIsPredefinedImage(true);
            if (onChangeCover) {
                onChangeCover();
            }
        } catch (error) {
            this.props.showUserInfoModal({
                contentType: MessageType.UPLOAD_IMAGE_ERROR,
                headerText: 'Error while uploading image',
            });
        }
    };

    public render() {
        return (
            <Dropzone onDrop={this.onDrop} accept={ALLOWED_MIME_TYPE_FOR_IMAGE}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        {...getRootProps()}
                        className={styles['cover-drop-button']}
                        onMouseEnter={this.handleMouseOver}
                        onMouseLeave={this.handleMouseOver}
                    >
                        <input {...getInputProps()} multiple={false} />
                        <Button text="SELECT YOUR IMAGE" className={styles['cover-drop-button__button']} />
                    </div>
                )}
            </Dropzone>
        );
    }
}

export default connect(
    (state: Types.RootState) => ({
        coverUrl: coverSelectors.getCoverUrl(state),
        mapSize: mapSelectors.getMapSize(state),
        imageSize: coverSelectors.getImageSize(state),
        isPredefinedImage: coverSelectors.getIsPredefinedImage(state),
    }),
    {
        showUserInfoModal: userInfoModalActions.showUserInfoModal,
        handleCoverUpload: coverActions.handleCoverUpload,
        setImageSize: coverActions.setImageSize,
        setIsPredefinedImage: coverActions.setIsPredefinedImage,
        setCoverUrl: coverActions.setCoverUrl,
        setLinkToImage: customisationActions.setLinkToImage,
    }
)(CoverDropButton);
