export enum TEXT_TYPE {
    TITLE = 'title',
    SUBTITLE = 'subtitle',
    SPINE = 'spine',
}

export interface LetterModel {
    [TEXT_TYPE.TITLE]: number;
    [TEXT_TYPE.SUBTITLE]: number;
    [TEXT_TYPE.SPINE]: number;
}

type LetterType = {
    value: {
        [key: string]: Partial<LetterModel>;
    };
};

export interface ILetterWidthModel {
    LARGE_FLAT: LetterType;
    LARGE_FOLDED: LetterType;
    A3_FOLDED: LetterType;
    A3_FLAT: LetterType;
    A2_FLAT: LetterType;
}

export const MAX_FIELD_WIDTH = 1.0;

export const ROUNDING_FACTOR = 100;
