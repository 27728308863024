import React, { PropsWithChildren, RefObject } from 'react';

import HorizontalTooltip from 'root/components/HorizontalTooltip';
import { Map } from 'root/components/Map';

import { PreviewMap } from '../PreviewMap';
import { useProductPreview } from './hooks';

type ProductPreviewContainer = {
    showMagnifyingGlass: boolean;
    showResetMap: boolean;
    isGlassCentered: boolean;
    showUkOverview: boolean;
    map: RefObject<HTMLDivElement>;
    mapRef: RefObject<Map>;
    frameUrl: string;
    zoomIsHovered: boolean;
    onMapDrag: () => void;
};

export const ProductPreviewContainer = ({
    children,
    showMagnifyingGlass,
    showResetMap,
    isGlassCentered,
    showUkOverview,
    map,
    mapRef,
    frameUrl,
    zoomIsHovered,
    onMapDrag,
}: PropsWithChildren<ProductPreviewContainer>) => {
    const { containerClassName, spacerClassName, mapClassName, tooltipClassName } = useProductPreview(
        showMagnifyingGlass,
        showResetMap
    );

    return (
        <div className={containerClassName}>
            <div className={spacerClassName} style={{ backgroundImage: `url(${frameUrl})` }}>
                <div className={mapClassName} ref={map}>
                    <PreviewMap onDrag={onMapDrag} mapRef={mapRef} />

                    {showMagnifyingGlass && isGlassCentered && zoomIsHovered && (
                        <HorizontalTooltip
                            pretext="Drag magnifier"
                            text="&nbsp;to change zoom position"
                            className={tooltipClassName}
                            vertical={true}
                            alwaysVisible={false}
                        >
                            &nbsp;
                        </HorizontalTooltip>
                    )}
                    {showMagnifyingGlass && !showUkOverview && children}
                </div>
            </div>
        </div>
    );
};
