import * as React from 'react';
import ReactSelect from 'react-select';
import { formatGroupLabel } from './constants';

import styles from './index.module.scss';
import { SelectProps } from './model';

export const Select = ({
    options,
    placeholder,
    isClearable,
    name,
    onChange,
    defaultValue,
    emptyMessage,
    menuIsOpen,
}: SelectProps) => {
    return (
        <div className={styles['select-component']}>
            <ReactSelect
                className="select-component"
                classNamePrefix="select"
                classNames={{
                    control: (state) => (state.isFocused ? 'control-focused' : ''),
                    option: (state) => {
                        const className = [];

                        if (state.isSelected) {
                            className.push('option-selected');
                        }

                        if (state.isFocused) {
                            className.push('option-focused');
                        }

                        return className.join(' ');
                    },
                }}
                options={options}
                defaultValue={defaultValue}
                placeholder={placeholder}
                isClearable={isClearable}
                name={name}
                onChange={onChange}
                noOptionsMessage={() => emptyMessage}
                formatGroupLabel={formatGroupLabel}
                menuIsOpen={menuIsOpen}
                components={{}}
            />
        </div>
    );
};
