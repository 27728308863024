import React, { FC, HTMLAttributes, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { timer } from 'rxjs';

import { PRODUCT_PREVIEW_RESOLUTION } from 'root/constants';
import { productPreviewActions, productPreviewSelectors } from 'root/features/productPreview';

import { MilisTime } from 'root/modules/utils/constants';
import { useTranslation } from 'root/modules/utils/locales';
import { MapFormatType, WidthType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import { MapMagnifier, MapMagnifierProps, ZoomBase } from './MapMagnifier';
import * as P from './parts';

export const CENTER_IMAGE_ZOOM = {
  [MAP_FORMAT.SQUARE]: 4,
  [MAP_FORMAT.RECTANGLE]: 2.5,
};

const {
  getProductPreviewImageUrl,
  getProductPreviewError,
  getProductPreviewCenterImage,
  getProductPreviewCenterImageError,
} = productPreviewSelectors;

const {
  getProductPreview,
  productPreviewActions: { request },
} = productPreviewActions;

export type MapPreviewProps = HTMLAttributes<HTMLDivElement> &
  MapMagnifierProps &
  MapFormatType &
  WidthType & {
    placeholderProps?: HTMLAttributes<HTMLDivElement>;
    mapImageProps?: HTMLAttributes<HTMLImageElement>;
    isMagnifierOpen?: boolean;
    showMapMagnifier?: boolean;
    dispatchZoomRequest?: boolean;
    dispatchRequestOnError?: boolean;
  };

export const MapPreview: FC<MapPreviewProps> = ({
  mapFormat,
  placeholderProps,
  mapImageProps,
  width,
  dispatchZoomRequest,
  dispatchRequestOnError,
  isMagnifierOpen,
  showMapMagnifier,
  zoom,
  onZoomChange,
  disableZoomControl,
  ...props
}) => {
  const mapUrl = useSelector(getProductPreviewImageUrl);
  const productPreviewError = useSelector(getProductPreviewError);
  const centerUrl = useSelector(getProductPreviewCenterImage);
  const centerError = useSelector(getProductPreviewCenterImageError);

  const dispatch = useDispatch();
  const [t] = useTranslation();

  const getCenterImage = useCallback(() => {
    dispatch(request({ resolution: PRODUCT_PREVIEW_RESOLUTION, zoom: CENTER_IMAGE_ZOOM[mapFormat] }));
  }, [mapFormat]);

  useEffect(() => {
    if (dispatchZoomRequest) getCenterImage();
  }, [dispatchZoomRequest]);

  useEffect(() => {
    if (!dispatchRequestOnError) return;
    if (!centerError && centerUrl) return;
    const subscription = timer(MilisTime.medium).subscribe(() => getCenterImage());

    return () => subscription.unsubscribe();
  }, [dispatchRequestOnError, centerError]);

  const handleMapImageError = useCallback(() => void dispatch(getProductPreview(PRODUCT_PREVIEW_RESOLUTION)), []);

  return (
    <P.MapPreview {...props}>
      <P.AspectRatioHolder aspectRatio={mapFormat} />
      {mapUrl && !productPreviewError ? (
        <>
          <P.MapImage src={mapUrl} onError={handleMapImageError} {...mapImageProps} />
          {isMagnifierOpen && showMapMagnifier && (
            <MapMagnifier
              zoom={zoom}
              onZoomChange={onZoomChange}
              zoomBase={centerUrl ? ZoomBase.Basic : ZoomBase.Extra}
              enlarge={!centerUrl}
              disableZoomControl={disableZoomControl}
            >
              <P.MapImage src={centerUrl ?? mapUrl} />
            </MapMagnifier>
          )}
        </>
      ) : (
        <P.Placeholder {...placeholderProps}>
          <P.ErrorMessageWrapper>
            <P.StyledMapIcon width={width} />
            <P.ErrorMessage width={width}>
              <P.ErrorMessageText>
                {t(({ productPreview }) => productPreview.mapPreviewErrorMessage.text1)}
              </P.ErrorMessageText>
              <P.ErrorMessageButton onClick={handleMapImageError}>
                {t(({ productPreview }) => productPreview.mapPreviewErrorMessage.link)}
              </P.ErrorMessageButton>
              <P.ErrorMessageText>
                {t(({ productPreview }) => productPreview.mapPreviewErrorMessage.text2)}
              </P.ErrorMessageText>
            </P.ErrorMessage>
          </P.ErrorMessageWrapper>
        </P.Placeholder>
      )}
    </P.MapPreview>
  );
};
