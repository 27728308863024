import React, { FC } from 'react';
import styled from 'styled-components';

import { breakpoint, headerFontSize } from 'root/modules/styles';
import { HeaderProps } from './types';

const H3 = styled.h3`
  font-weight: 600;
  ${headerFontSize.h3}
  ${breakpoint.small(headerFontSize.h3s)}

  margin-bottom: 5px;
`;

export const Header3: FC<HeaderProps> = ({ text, children, ...props }) => {
  return <H3 {...props}>{text ?? children}</H3>;
};
