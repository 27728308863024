import { Map } from 'mapbox-gl';

import { convertProjection, PROJECTIONS } from 'root/services/projection';

const rotationAngleForMercatorMapForCoordinate = (mapCenterPoint: {
  lng: number;
  lat: number;
}): number => {
  const bngPoint = convertProjection(
    PROJECTIONS.EPSG_4326,
    PROJECTIONS.EPSG_27700,
    { x: mapCenterPoint.lng, y: mapCenterPoint.lat }
  );

  const a0 = bngPoint.y * 0.00000132952129 + 3.11308606;
  const m = (a0 - 0.00151) / 300000;

  let angle = m * bngPoint.x + (a0 - m * 700000);
  angle *= -1;

  if (angle < 0) {
    angle += 360;
  }

  return angle;
};

export const rotationEquation = (mapboxMap: Map) => {
  const newBearing =
    360 - rotationAngleForMercatorMapForCoordinate(mapboxMap.getCenter());
  mapboxMap.setBearing(newBearing);
};
