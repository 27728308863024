import React, { ReactNode, SyntheticEvent } from 'react';

import { Popper as MuiPopper } from '@material-ui/core';
import { PopperPlacementType, PopperProps as MaterialPopperProps } from '@material-ui/core/Popper';

import { ReactComponent as CloseIcon } from './assets/close.svg';

import styles from './popper.module.scss';

export type PopperProps = Omit<MaterialPopperProps, 'title'> & {
    id: string | undefined;
    open: boolean;
    anchorEl: HTMLElement | null;
    title?: ReactNode;
    onClose?: (event: SyntheticEvent<Element, Event>) => void;
    ariaLabelCloseBtn?: string;
    ariaLabel?: string;
    placement?: PopperPlacementType;
    subtitle?: ReactNode;
};

export const Popper = ({
    children,
    id,
    open,
    anchorEl,
    title,
    ariaLabel,
    ariaLabelCloseBtn,
    onClose,
    placement,
    subtitle,
    ...props
}: PopperProps) => {
    return (
        <MuiPopper {...props} aria-label={ariaLabel} id={id} open={open} anchorEl={anchorEl} placement={placement}>
            <div className={styles['modal']}>
                <div className={styles['modal__header']}>
                    {title && <div className={styles['modal__header__title']}>{title}</div>}

                    {subtitle && <div className={styles['modal__header__subtitle']}>{subtitle}</div>}

                    {onClose && (
                        <button
                            className={styles['modal__header__close-button']}
                            onClick={onClose}
                            aria-label={ariaLabelCloseBtn ? ariaLabelCloseBtn : 'Close pop up'}
                        >
                            <CloseIcon name="os-close" />
                        </button>
                    )}
                </div>

                <div className={styles['modal__content']}>{children}</div>
            </div>
        </MuiPopper>
    );
};
