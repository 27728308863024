import { ProductId, Size } from 'root/model/map/MapEnums';

export const PRODUCT_PREVIEW_API_URL = `${process.env.CMFA_APP_PLATFORM_ORDER_CAPTOR_URL}/preview`;

export const MapSizeToApiSize = {
    [Size.SMALL]: 'A3',
    [Size.MEDIUM]: 'A2',
    [Size.LARGE]: 'LARGE',
};

export const ProductIdToApiType = {
    [ProductId.FLAT_MAP]: 'FLAT',
    [ProductId.FOLDED_MAP]: 'FOLDED',
};
