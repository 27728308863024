import * as React from 'react';
import { connect } from 'react-redux';
import { useCallback } from 'react';

import Modal from 'root/components/Modal';
import { userInfoModalActions } from 'features/userInfoModal';
import * as Types from 'Types';
import * as classNames from 'classnames';
import Button, { ButtonType } from 'root/components/Button';
import { MessageType } from 'root/services/fileHelpers/consts';
import UploadImageError from 'root/components/UserInfoModal/components/UploadImageError';
import SendOrderError from 'root/components/UserInfoModal/components/SendOrderError';
import SendOrderSuccess from 'root/components/UserInfoModal/components/SendOrderSuccess';

import styles from './index.module.scss';
import { isError, isSuccess } from 'root/components/UserInfoModal/utils';
import { createProductJourneyActions } from 'features/createProductJourney';
import { CreateProductJourneyAction } from 'root/model/createProductJourney/CreateProductJourney';
import RotateImageError from './components/RotateImageError';
import DefaultLocationWarning from './components/DefaultLocationWarning';

interface IUserInfoModal {
    isVisible: boolean;
    headerText: string;
    contentType: MessageType;
    hideModal: typeof userInfoModalActions.hideUserInfoModal;
    dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
}

const onCloseModal = (contentType: MessageType, hideModal: () => void) => {
    if (contentType !== MessageType.SEND_ORDER_SUCCESS) {
        hideModal();
    }
};

const onContinueShopping = (dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction) => {
    dispatchJourneyAction(CreateProductJourneyAction.CONTINUE_SHOPPING);
};

const onProceedToCheckout = () => {
    if (window && window.top) {
        window.top.location.href = process.env.CMFA_APP_CHECKOUT_ADDRESS as string;
    }
};

const UserInfoModal: React.FunctionComponent<IUserInfoModal> = ({
    isVisible,
    headerText,
    contentType,
    hideModal,
    dispatchJourneyAction,
}) => {
    const closeModal = useCallback(() => {
        onCloseModal(contentType, hideModal);
    }, [contentType, hideModal]);

    const continueShopping = useCallback(() => {
        hideModal();
        onContinueShopping(dispatchJourneyAction);
    }, [dispatchJourneyAction]);

    const proceedToCheckout = useCallback(() => {
        hideModal();
        onProceedToCheckout();
    }, []);

    const buttons = () =>
        isSuccess(contentType) ? (
            <>
                <Button
                    text="Continue shopping"
                    onClick={continueShopping}
                    type={ButtonType.SECONDARY}
                    isLoading={false}
                />
                <Button
                    text="Proceed to checkout"
                    onClick={proceedToCheckout}
                    type={ButtonType.PRIMARY}
                    isLoading={false}
                />
            </>
        ) : (
            <Button text="Close" onClick={closeModal} type={ButtonType.ERROR} isLoading={false} />
        );

    const renderIcon = () => {
        const modalIconStyle = classNames(
            { [styles['user-info-modal__success-icon']]: isSuccess(contentType) },
            { [styles['user-info-modal__error-warning']]: isError(contentType) }
        );
        return <i className={modalIconStyle} />;
    };

    const renderMessage = useCallback(() => {
        switch (contentType) {
            case MessageType.UPLOAD_IMAGE_ERROR:
                return <UploadImageError />;
            case MessageType.SEND_ORDER_SUCCESS:
                return <SendOrderSuccess />;
            case MessageType.SEND_ORDER_ERROR:
                return <SendOrderError />;
            case MessageType.ROTATE_IMAGE_ERROR:
                return <RotateImageError />;
            case MessageType.DEFAULT_LOCATION_WARNING:
                return <DefaultLocationWarning />;
            default:
                return null;
        }
    }, [contentType]);

    return (
        <Modal
            buttons={buttons}
            showModal={isVisible}
            danger={isError(contentType)}
            performingConfirm={false}
            closeModal={closeModal}
            hideCloseMark={contentType === MessageType.SEND_ORDER_SUCCESS}
            onOpenOptions={{ disableDocumentScroll: true }}
        >
            <div className={styles['user-info-modal']}>
                {renderIcon()}
                <p className={styles['user-info-modal__header']}>{headerText}</p>
            </div>
            {renderMessage()}
        </Modal>
    );
};

export default connect(
    (state: Types.RootState) => ({
        isVisible: state.userInfoModal.isVisible,
        contentType: state.userInfoModal.contentType,
        headerText: state.userInfoModal.headerText,
    }),
    {
        hideModal: userInfoModalActions.hideUserInfoModal,
        dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
    }
)(UserInfoModal);
