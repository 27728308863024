import { LineThicknessType } from 'root/model/route/Route';

export const THICKNESS_LIST = [
    {
        name: 'extra-thin',
        classname: 'extra-thin',
        label: 'Extra thin',
        value: LineThicknessType.ExtraThin,
    },
    {
        name: 'very-thin',
        classname: 'very-thin',
        label: 'Very thin',
        value: LineThicknessType.VeryThin,
    },
    {
        name: 'thin',
        classname: 'thin',
        label: 'Thin',
        value: LineThicknessType.Thin,
    },
    {
        name: 'regular',
        classname: 'regular',
        label: 'Regular',
        value: LineThicknessType.Regular,
    },
    {
        name: 'thick',
        classname: 'thick',
        label: 'Thick',
        value: LineThicknessType.Thick,
    },
];
