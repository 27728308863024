import * as React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

interface IHorizontalTooltipProps extends IClassNameProp {
  pretext?: string;
  text: string;
  vertical?: boolean;
  alwaysVisible?: boolean;
}

const HorizontalTooltip: React.FunctionComponent<IHorizontalTooltipProps> = ({
  pretext,
  text,
  className,
  children,
  vertical,
  alwaysVisible,
}) => {
  const [timeoutVisible, setTimeoutVisible] = useState(true);

  const mainClass = classNames(className, styles['htooltip'], {
    [styles['htooltip--vertical']]: vertical,
  });
  const containerClass = classNames(styles['htooltip__container'], {
    [styles['htooltip__container--vertical']]: vertical,
  });
  const containerInnerClass = classNames(styles['htooltip__container__inner'], {
    [styles['htooltip__container__inner--vertical']]: vertical,
  });
  const textClass = classNames(styles['htooltip__text'], {
    [styles['htooltip__text--vertical']]: vertical,
    [styles['htooltip__text--timeout-invisible']]: !timeoutVisible,
    [styles['htooltip__text--always-visible']]: alwaysVisible,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={mainClass}>
      <div className={containerClass}>
        <div className={containerInnerClass}>
          <div className={textClass}>
            <div className={styles['htooltip__text__inner']}>
              {pretext && (
                <span className={styles['htooltip__text__pre']}>{pretext}</span>
              )}
              {text}
            </div>
          </div>
        </div>
      </div>
      <div className={styles['htooltip__children']}>{children}</div>
    </div>
  );
};

export default HorizontalTooltip;
