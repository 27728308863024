import { Scale, Proportions, Size, MapType, MapStyle, SelectedMapStyle } from 'root/model/map/MapEnums';
import { getMapStyleFromScale } from 'root/modules/utils/functions';
import { defaultScaleParams, scaleLogicMap, scaleLogicMapMaxOrder, scaleLogicMapMinOrder } from './scale-logic-map';

const getScaleList = (selectedMapStyle: SelectedMapStyle) => {
    if (selectedMapStyle === SelectedMapStyle.AUTO) {
        return [...scaleLogicMap[MapStyle.OS_EXPLORER], ...scaleLogicMap[MapStyle.OS_LANDRANGER]];
    }

    return [...scaleLogicMap[MapStyle[selectedMapStyle]]];
};

type ScaleChangeType = '+' | '-';

export const getNewScale = (
    scale: Scale | undefined,
    scaleChangeType: ScaleChangeType,
    selectedMapStyle: SelectedMapStyle
) => {
    const isScaleExist = !scale;
    const isScaleDeprecated = [
        Scale.OS_EXPLORER,
        Scale.OS_LANDRANGER,
        Scale.OS_UK,
        Scale.OS_EXPLORER_ENLARGED,
        Scale.NONE,
    ].includes(scale as Scale);

    if (isScaleExist || isScaleDeprecated) {
        return defaultScaleParams.mapScale;
    }

    let newScale = scale;
    const mapStyle = getMapStyleFromScale(scale);
    const scaleLogicStyleMap = scaleLogicMap[mapStyle];
    const scaleList = getScaleList(selectedMapStyle);

    for (const scaleParams of scaleLogicStyleMap) {
        if (scaleParams.mapScale === scale) {
            const order = scaleParams.order;

            if (scaleChangeType === '+') {
                const nextOrder = order + 1;

                if (nextOrder <= scaleLogicMapMaxOrder) {
                    const newScaleParam = scaleList.find((el) => el.order === nextOrder);
                    if (newScaleParam) {
                        newScale = newScaleParam.mapScale;
                    }
                }
            }

            if (scaleChangeType === '-') {
                const prevOrder = order - 1;

                if (prevOrder >= scaleLogicMapMinOrder) {
                    const newScaleParam = scaleList.find((el) => el.order === prevOrder);
                    if (newScaleParam) {
                        newScale = newScaleParam.mapScale;
                    }
                }
            }

            break;
        }
    }

    return newScale;
};

export const getScaleParams = (mapScale: Scale) => {
    const mapStyle = getMapStyleFromScale(mapScale);

    return scaleLogicMap[mapStyle as MapStyle].find((el) => el.mapScale === mapScale);
};

export const getBounding = (mapScale: Scale) => {
    const scaleParams = getScaleParams(mapScale);

    return scaleParams ? scaleParams.bounding : defaultScaleParams.bounding;
};

// Accomodates for main map not showing proper area with regular bounding
export const getMainMapBounding = (mapScale: Scale) => {
    const scaleParams = getScaleParams(mapScale);

    return scaleParams
        ? scaleParams.bounding - scaleParams.mainMapBounding
        : defaultScaleParams.bounding - defaultScaleParams.mainMapBounding;
};

export const getProportion = (mapSize: Size, mapType: MapType) => {
    const mapProportion =
        mapSize === Size.SMALL || mapSize === Size.MEDIUM ? Proportions.RECTANGLE : Proportions.SQUARE;
    if (mapProportion === Proportions.SQUARE) {
        return { x: 1, y: 1 };
    } else {
        if (mapType === MapType.CANVAS_AND_FRAMED) {
            if (mapSize === Size.SMALL) {
                return { x: 0.4875, y: 0.3023 };
            } else {
                return { x: 0.65, y: 0.403 };
            }
        } else {
            return { x: 0.525, y: 0.3701 };
        }
    }
};
