import { css, CSSProperties, DefaultTheme, ThemedCssFunction } from 'styled-components';

import { getReturnValue } from '../utils/functions';
import {
  BorderRadius,
  BoxShadow,
  Breakpoint,
  FontFamilyType,
  FontSize,
  HeaderFontSize,
  HeaderLineHeight,
} from './constants';
import { SourceType, WidthType } from './types';

export type CssReturnType = ReturnType<ThemedCssFunction<DefaultTheme>>;

type SelectableInterpolation<T extends {}, I = CssReturnType> = Record<keyof T, I>;

export const backgroundImage = css<SourceType>(
  ({ src }) =>
    css`
      background-image: url(${src});
    `
);

export const OSFontFamily = css`
  font-family: 'OSGill', sans-serif;
`;

export const getMediaMaxWidth = (breakpoint: Breakpoint) => (content: CssReturnType) => css`
  @media screen and (max-width: ${breakpoint}px) {
    ${content}
  }
`;

/**
 * @function small (max-width: 420),
 * @function smallMedium (max-width: 540),
 * @function medium (max-width: 840),
 * @function large (max-width: 1160),
 */
export const breakpoint: SelectableInterpolation<typeof Breakpoint, ReturnType<typeof getMediaMaxWidth>> = {
  small: getMediaMaxWidth(Breakpoint.small),
  smallMedium: getMediaMaxWidth(Breakpoint.smallMedium),
  medium: getMediaMaxWidth(Breakpoint.medium),
  large: getMediaMaxWidth(Breakpoint.large),
};

export const withMaxInnerWidth = (breakpoint: number) => (content: CssReturnType) => <P>({ width }: WidthType & P) =>
  width <= breakpoint && content;

export const getBoxShadow = (boxShadow: BoxShadow = BoxShadow.medium) => css({ boxShadow });

export const boxShadow: SelectableInterpolation<typeof BoxShadow, ReturnType<typeof getBoxShadow>> = {
  light: getBoxShadow(BoxShadow.light),
  medium: getBoxShadow(BoxShadow.medium),
};

export const getBorderRadius = (radius: BorderRadius) => css`
  border-radius: ${radius};
`;

/**
 * @property medium = '3px',
 * @property round = '50%',
 */
export const borderRadius: SelectableInterpolation<typeof BorderRadius> = {
  medium: getBorderRadius(BorderRadius.medium),
  round: getBorderRadius(BorderRadius.round),
};

export const getFontSize = (fontSize: FontSize | HeaderFontSize, lineHeight?: HeaderLineHeight) =>
  css({ fontSize, lineHeight });

/**
 * @property xSmall = '12px',
 * @property small = '14px',
 * @property medium = '16px',
 * @property large = '18px',
 * @property xLarge = '22px',
 * @property xxLarge = '24px',
 */
export const fontSize: SelectableInterpolation<typeof FontSize> = {
  xSmall: getFontSize(FontSize.xSmall),
  small: getFontSize(FontSize.small),
  medium: getFontSize(FontSize.medium),
  large: getFontSize(FontSize.large),
  xLarge: getFontSize(FontSize.xLarge),
  xxLarge: getFontSize(FontSize.xxLarge),
};

/**
 * @property h1 = '32px' / '40px'
 * @property h1m = '30px' / '40px'
 * @property h3 = '18px' / '24px,
 * @property h3s = '16px' / '24px,
 */
export const headerFontSize: SelectableInterpolation<typeof HeaderFontSize> = {
  h1: getFontSize(HeaderFontSize.h1, HeaderLineHeight.h1),
  h1s: getFontSize(HeaderFontSize.h1s, HeaderLineHeight.h1),
  h3: getFontSize(HeaderFontSize.h3, HeaderLineHeight.h3),
  h3s: getFontSize(HeaderFontSize.h3s, HeaderLineHeight.h3),
};

export const px = (value: number | (() => number)): string => `${getReturnValue(value)}px`;

export const important = <S extends keyof CSSProperties>(style: S, value: CSSProperties[S]) =>
  css({ [style]: `${value} !important;` });

export const getFontFamily = (fontFamily: FontFamilyType) => css`
  font-family: '${fontFamily}', sans-serif;
`;

export const fontFamily: SelectableInterpolation<typeof FontFamilyType> = {
  osgill: getFontFamily(FontFamilyType.osgill),
  sourcesanspro: getFontFamily(FontFamilyType.sourcesanspro),
};
