import { FormikErrors, getIn } from 'formik';
import { trimStart } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCoverUrl } from 'root/features/cover/selectors';
import { setSpineFromTitles, setTitleLine } from 'root/features/customisation/actions';
import { getLinkToImage, getSpine } from 'root/features/customisation/selectors';
import { getMapSize, getMapType, getProductId } from 'root/features/map/selectors';
import { useCharacterValidation } from 'root/hooks';
import { ITitleFormValues, TITLE_FORM_FIELD_NAMES } from 'root/model/customisation/TitleFormValues';
import { MapType, Size } from 'root/model/map/MapEnums';

import { IFormikConnectPartProps } from '../index';

export const useTitlesForm = ({ formik }: IFormikConnectPartProps) => {
    const [isRectangleFramed, setIsRectangleFramed] = useState<boolean>(false);
    const dispatch = useDispatch();

    const spine = useSelector(getSpine);
    const productId = useSelector(getProductId);
    const coverUrl = useSelector(getCoverUrl);
    const flatMapCoverUrl = useSelector(getLinkToImage);
    const mapSize = useSelector(getMapSize) as Size;
    const mapType = useSelector(getMapType);

    const { showSpineText, titleValidator, subtitleValidator, spineValidator } = useCharacterValidation();

    const setSpineField = useCallback((spine: string) => {
        const { setFieldValue, setFieldError, setFieldTouched } = formik;

        setFieldValue(TITLE_FORM_FIELD_NAMES.SPINE, spine, false);
        const error = spineValidator(spine, false);

        if (error) {
            setFieldError(TITLE_FORM_FIELD_NAMES.SPINE, error);
            setFieldTouched(TITLE_FORM_FIELD_NAMES.SPINE, true);
        }
    }, []);

    const getError = useCallback(
        (fieldName: string, value: string) => {
            const nameToError = {
                [TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE]: titleValidator(value, false),
                [TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE]: titleValidator(value, false),
                [TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE]: titleValidator(value, false),
                [TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE]: subtitleValidator(value, false),
                [TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE]: subtitleValidator(value, false),
                [TITLE_FORM_FIELD_NAMES.SPINE]: spineValidator(value, false),
            };

            // @ts-ignore
            return nameToError[fieldName];
        },
        [TITLE_FORM_FIELD_NAMES]
    );

    const getStyles = useCallback((errors: FormikErrors<ITitleFormValues>, fieldName: string) => {
        if (getIn(errors, fieldName)) {
            return {
                border: '1px solid red',
            };
        } else {
            return null;
        }
    }, []);

    const setFieldsTouched = useCallback(() => {
        const { values, setFieldTouched } = formik;
        Object.keys(values).forEach((key) => {
            // @ts-ignore
            if (values[key]) {
                setFieldTouched(key);
            }
        });
    }, []);

    const setTitleField = useCallback((fieldName: string, value: string) => {
        const { setFieldValue, setFieldTouched } = formik;
        const error = getError(fieldName, value);

        if (!error || value === '') {
            dispatch(setTitleLine(value, fieldName));
            setFieldValue(fieldName as TITLE_FORM_FIELD_NAMES, value, false);
            setFieldTouched(fieldName, true);
        }
    }, []);

    // in the old code it was used for input titles, but not sure why it was supposed to change the spine text
    const onChangeTitles = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { touched } = formik;

        onChange(event);
        dispatch(setSpineFromTitles(!touched[TITLE_FORM_FIELD_NAMES.SPINE]));
    }, []);

    const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const { value, name: fieldName } = event.target;

        const trimmedValue = trimStart(value);
        setTitleField(fieldName, trimmedValue);
    }, []);

    const onChangeCover = useCallback(() => {
        formik.validateForm();
        formik.setFieldTouched(TITLE_FORM_FIELD_NAMES.COVER_IMAGE, true);
    }, []);

    useEffect(() => {
        setSpineField(spine);
        formik.validateForm();
    }, [spine, coverUrl, flatMapCoverUrl]);

    useEffect(() => {
        const newIsRectangleFramed =
            mapType === MapType.CANVAS_AND_FRAMED && [Size.SMALL, Size.MEDIUM].includes(mapSize);
        setIsRectangleFramed(newIsRectangleFramed);
        formik.validateForm();
        setFieldsTouched();
    }, [mapType, mapSize]);

    return {
        productId,
        mapSize,
        onChangeCover,
        isRectangleFramed,
        showSpineText,
        titleValidator,
        subtitleValidator,
        spineValidator,
        getStyles,
        onChange,
    };
};
