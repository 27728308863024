import React, { FC, HTMLAttributes } from 'react';

import { MapFormatType, WidthType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import * as P from './parts';

export type MapFrameProps = WidthType &
  MapFormatType & {
    woodFrameSrc?: string;
    descriptionFrameSrc?: string;
    woodFrameHTMLProps?: HTMLAttributes<HTMLDivElement>;
    descriptionFrameHTMLProps?: HTMLAttributes<HTMLDivElement>;
  };

export const MapFrame: FC<MapFrameProps> = ({
  woodFrameSrc,
  descriptionFrameSrc,
  descriptionFrameHTMLProps,
  mapFormat,
  woodFrameHTMLProps,
  width,
  children,
}) => {
  return (
    <P.WoodMapFrame src={woodFrameSrc} mapFormat={mapFormat} {...woodFrameHTMLProps}>
      <P.DescriptionMapFrame src={descriptionFrameSrc} mapFormat={mapFormat} {...descriptionFrameHTMLProps}>
        {mapFormat === MAP_FORMAT.SQUARE && <P.TitleWrapper mapFormat={mapFormat} width={width} />}
        {children}
      </P.DescriptionMapFrame>
    </P.WoodMapFrame>
  );
};
