import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getMapSize, getMapType } from 'root/features/map/selectors';
import { getMediaQuery } from 'root/features/mediaQuery/selectors';

import { MapType, Size } from 'root/model/map/MapEnums';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { thinnerThan } from 'root/services/mediaQuery';

import styles from '../index.module.scss';

export const useMapSelectionContainerClassNames = (showMagnifyingGlass: boolean) => {
    const mapSize = useSelector(getMapSize) as Size;
    const mapType = useSelector(getMapType);
    const mediaQuery = useSelector(getMediaQuery);

    const isMobile = thinnerThan(mediaQuery, MediaQuery.MEDIUM);
    const isRectangle = mapSize === Size.SMALL || mapSize === Size.MEDIUM;
    const baseClassName = 'map-selection-container';

    const containerClassName = classNames(
        styles[baseClassName],
        isRectangle ? styles[`${baseClassName}--rectangle`] : null,
        showMagnifyingGlass && isMobile && !isRectangle ? styles[`${baseClassName}--mobile-magnifier-open`] : null,
        isRectangle && showMagnifyingGlass && isMobile
            ? styles[`${baseClassName}--rectangle-mobile-magnifier-open`]
            : null
    );

    const verticalLineClassName = classNames(
        styles[`${baseClassName}__vertical-line`],
        mapType
            ? styles[
                  `${baseClassName}__vertical-line--map-size-` +
                      Size[mapSize].toLowerCase() +
                      '-map-type-' +
                      MapType[mapType].toString().toLowerCase()
              ]
            : null,
        false ? styles[`${baseClassName}__vertical-line--hidden`] : null
    );

    return {
        containerClassName,
        verticalLineClassName,
    };
};
