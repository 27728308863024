import * as React from 'react';
import * as Types from 'Types';
import { connect } from 'react-redux';
import * as classNames from 'classnames';

import { MapLanguage, MapStyle, MapType, Proportions, Size } from 'root/model/map/MapEnums';
import { mapActions, mapSelectors } from 'root/features/map';

import styles from './index.module.scss';
import { MAP_STYLE_TO_FRAME_URL } from 'root/scenes/Home/components/ProductPreview/components/MapPreviewContainer/components/MapPreview/constants';

export interface IStyleItemProps {
    icon: string;
    title: string;
    description: string;
    mapStyle: MapStyle;
    active: boolean;
    setMapStyleParams: typeof mapActions.setMapStyleParams;
    isDisabled: boolean;
    mapSize: Size;
    mapLanguage: MapLanguage;
    setActive: () => void;
    mapType?: number;
}

export class StyleItem extends React.Component<IStyleItemProps> {
    public render() {
        const { icon, title, description, active, mapStyle, setActive } = this.props;

        const newTitle = title.split(',');

        const className = classNames(styles['style-option'], styles['style-option--scale-' + mapStyle], {
            [styles['style-option--inactive']]: !active,
        });

        const handleOnClick = () => {
            this.onClick();
            setActive();
        };

        return (
            <div className={className} onClick={handleOnClick}>
                <div
                    className={classNames(styles['style-option__bookmark'], {
                        [styles['style-option__bookmark--active']]: active,
                    })}
                />
                <img
                    src={icon}
                    alt={title}
                    className={classNames(styles['style-option__image'], {
                        [styles['style-option__image--active']]: active,
                    })}
                />{' '}
                <div className={styles['style-option__description']}>
                    <div className={styles['style-option__description-first-line']}>
                        <div>{`${newTitle[0]}`}</div>&nbsp;<div>{newTitle[1]}</div>
                    </div>
                    <div className={styles['style-option__description-second-line']}>{description}</div>
                </div>
            </div>
        );
    }

    private preloadFrame() {
        const { mapSize, mapStyle, mapLanguage, mapType } = this.props;
        let frameUrl =
            mapSize === Size.LARGE
                ? MAP_STYLE_TO_FRAME_URL[Proportions.SQUARE][mapStyle][mapLanguage]
                : MAP_STYLE_TO_FRAME_URL[Proportions.RECTANGLE][mapStyle];
        if (mapSize === Size.SMALL && mapType === MapType.PAPER) {
            frameUrl = '';
        }
        if (frameUrl) {
            const img = new Image();
            img.src = frameUrl;
        }
    }

    private onClick = () => {
        if (!this.props.isDisabled && !this.props.active) {
            const { setMapStyleParams, mapStyle } = this.props;
            setMapStyleParams({ mapStyle });
            this.preloadFrame();
        }
    };
}

export default connect(
    (state: Types.RootState) => ({
        mapSize: mapSelectors.getMapSize(state),
        mapLanguage: mapSelectors.getMapLanguage(state),
        mapType: mapSelectors.getMapType(state),
    }),
    {
        setMapStyleParams: mapActions.setMapStyleParams,
    }
)(StyleItem);
