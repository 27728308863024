import * as React from 'react';
import Dropzone from 'react-dropzone';
import * as classNames from 'classnames';

import { FileRejection } from 'root/model/fileRejection/FIleRejection';

import styles from './index.module.scss';

import { PropsWithChildren } from 'react';

type CoverDropzoneProps = {
    size: 'auto' | 'large';
    isUploaded: boolean;
    onDrop: (accepted: File[], rejected: FileRejection[]) => Promise<void> | void;
    mimeType?: string | string[];
};

export const CoverDropzone = ({
    size,
    isUploaded,
    onDrop,
    children,
    mimeType,
}: PropsWithChildren<CoverDropzoneProps>) => {
    const baseClassName = 'cover-dropzone';

    const coverDropzoneCssClasses = classNames(
        styles[baseClassName],
        styles[`${baseClassName}--cover`],
        styles[`${baseClassName}--${size}`],
        {
            [styles[`${baseClassName}--uploaded`]]: isUploaded,
        }
    );

    return (
        <Dropzone onDrop={onDrop} accept={mimeType}>
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={coverDropzoneCssClasses}>
                    <input {...getInputProps()} multiple={false} />

                    {children}
                </div>
            )}
        </Dropzone>
    );
};
