import React, { useCallback, useMemo } from 'react';

import { ReactComponent as PlusIcon } from './assets/plus.svg';
import { ReactComponent as MinusIcon } from './assets/minus.svg';

import { useTranslation } from 'root/modules/utils/locales';

import styles from './zoomcontrol.module.scss';
import { ScaleColorValue } from './components';
import { useDispatch } from 'react-redux';
import { decrementZoom, incrementZoom } from 'root/features/map/actions';

const STEP = 0.1;

export const ZoomControl = () => {
    const [t] = useTranslation();
    const dispatch = useDispatch();

    //   const zoom = useSelector(getZoom);
    //   const zoomRange = useSelector(getZoomRange);

    //   const setValue = useCallback(zoom => {
    //     dispatch(mapParamsActions.setZoom(zoom));
    //   }, []);

    //   const onChange = useCallback((event: ChangeEvent<{}>, value: number | number[]) => setValue(+value), []);

    const increment = useCallback(() => {
        dispatch(incrementZoom());
        //   }, [zoom]);
    }, []);

    const decrement = useCallback(() => {
        dispatch(decrementZoom());
        //   }, [zoom]);
    }, []);

    const zoomInLabel = useMemo(() => t(({ button }) => button.zoomIn), []);
    const zoomOutLabel = useMemo(() => t(({ button }) => button.zoomOut), []);

    const ariaLabels = useMemo(
        () => ({
            zoomBar: t(({ button }) => button.zoomBar),
            zoomIn: zoomInLabel,
            zoomOut: zoomOutLabel,
        }),
        []
    );

    return (
        <div aria-label={ariaLabels.zoomBar} className={styles['zoom__container']}>
            <button
                className={styles['zoom__button']}
                title={zoomInLabel}
                // disabled={zoom >= zoomRange.maxZoom}
                onClick={increment}
                aria-label={ariaLabels.zoomIn}
            >
                <PlusIcon className={styles['zoom__button__icon']} name="os-plus-thin" />
            </button>

            <ScaleColorValue />

            <button
                className={styles['zoom__button']}
                title={zoomOutLabel}
                // disabled={zoom <= zoomRange.minZoom}
                onClick={decrement}
                aria-label={ariaLabels.zoomOut}
            >
                <MinusIcon name="os-minus-thin" />
            </button>
        </div>
    );
};
