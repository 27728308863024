import * as React from 'react';
import * as classNames from 'classnames';

import styles from './index.module.scss';

interface ILoadingIndicatorProps {
  className?: string;
  elementClassName?: string;
}

const LoadingIndicator: React.FunctionComponent<ILoadingIndicatorProps> = ({
  className,
  elementClassName,
}) => {
  const _className = classNames(styles['loading-indicator'], className);
  const _elementClassName = classNames(
    styles['loading-indicator__element'],
    elementClassName
  );

  return (
    <div className={_className}>
      <div className={_elementClassName} />
      <div className={_elementClassName} />
      <div className={_elementClassName} />
      <div className={_elementClassName} />
    </div>
  );
};

export default LoadingIndicator;
