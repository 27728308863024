import classNames from 'classnames';
import * as React from 'react';
import { FC } from 'react';

import styles from './index.module.scss';

type ICircleButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  icon: string;
  text?: string;
  className?: string;
};

const CircleButton: FC<ICircleButtonProps> = ({
  onClick,
  icon,
  text,
  className,
}) => {
  return (
    <div className={classNames(className, styles['circle-button'])}>
      <button
        type="button"
        onClick={onClick}
        className={styles['circle-button__button']}
      >
        <img src={icon} alt="" />
      </button>
      {text && <div className={styles['circle-button__text']}>{text}</div>}
    </div>
  );
};

export default CircleButton;
