import { action } from 'typesafe-actions';
import {
  HIDE_USER_INFO_MODAL,
  SHOW_USER_INFO_MODAL,
  SHOW_SUCCESS_MESSAGE_MODAL,
  HIDE_SUCCESS_MESSAGE_MODAL,
} from 'features/userInfoModal/constants';
import { IUserInfo } from 'root/model/userInfoModal/UserInfo';

export const showUserInfoModal = (userInfo: IUserInfo) =>
  action(SHOW_USER_INFO_MODAL, userInfo);

export const hideUserInfoModal = () => action(HIDE_USER_INFO_MODAL);

export const showSuccessMessage = () => action(SHOW_SUCCESS_MESSAGE_MODAL);

export const hideSuccessMessage = () => action(HIDE_SUCCESS_MESSAGE_MODAL);
