export const SEARCH_QUERY_PARAMS = {
  LOCATION_QUERY: 'q',
  MAP_TYPE: 'mapType',
  PRODUCT_ID: 'productId',
};

export const MAP_PROJECTION_CODE = 'EPSG:4356'; // EPSG:27700

export const MAP_PROJECTION_DEFS =
  '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 ' +
  '+x_0=400000 +y_0=-100000 +ellps=airy ' +
  '+towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 ' +
  '+units=m +no_defs';

export const MAP_EXTENT: [number, number, number, number] = [-238375.0, 0, 680000, 1376256];

export const MAP_RESTRICTED_EXTENT: [number, number, number, number] = [0, 0, 680000, 1376256];

export const MAP_RESOLUTIONS = [896, 448, 224, 112, 56, 28, 14, 7, 3.5, 1.75, 0.875, 0.4375, 0.21875, 0.109375];

export const CMFA_APP_OS_MAPBOX_TOKEN = `${process.env.CMFA_APP_OS_MAPBOX_TOKEN}`;
export const OS_MAPS_TILES_STYLE = `${process.env.CMFA_APP_OS_MAPS_TILES_STYLE}`;

export const PRODUCT_PREVIEW_RESOLUTION = 1500;

export const SIDEBAR_TAB_CONTENT_ID = 'SIDEBAR_TAB_CONTENT';
