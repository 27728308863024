import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { MapStyle, Scale, SelectedMapStyle } from 'root/model/map/MapEnums';
import { getMapScale, getMapStyle, getSelectedMapStyle } from 'root/features/map/selectors';

import { ReactComponent as LockIcon } from './assets/lock.svg';

import styles from './scalecolorvalue.module.scss';
import { getMapScaleValue } from 'root/modules/utils/functions';

export const ScaleColorValue = () => {
    const mapStyle = useSelector(getMapStyle) as MapStyle;
    const selectedMapStyle = useSelector(getSelectedMapStyle) as SelectedMapStyle;
    const mapScale = useSelector(getMapScale) as Scale;

    const shouldDisplayLockIcon = selectedMapStyle !== SelectedMapStyle.AUTO;

    const zoomColorClassName = classNames(styles['scale-color'], {
        [styles['scale-color--explorer']]: mapStyle === MapStyle.OS_EXPLORER && !shouldDisplayLockIcon,
        [styles['scale-color--landranger']]: mapStyle === MapStyle.OS_LANDRANGER && !shouldDisplayLockIcon,
        [styles['scale-color--explorer-lock']]: mapStyle === MapStyle.OS_EXPLORER && shouldDisplayLockIcon,
        [styles['scale-color--landranger-lock']]: mapStyle === MapStyle.OS_LANDRANGER && shouldDisplayLockIcon,
    });

    const zoomColorValueClassName = 'scale-color__value';

    const arrowClassName = classNames(styles[`${zoomColorValueClassName}__arrow`], {
        [styles[`${zoomColorValueClassName}__arrow--explorer`]]: mapStyle === MapStyle.OS_EXPLORER,
        [styles[`${zoomColorValueClassName}__arrow--landranger`]]: mapStyle === MapStyle.OS_LANDRANGER,
    });

    const textClassName = classNames(styles[`${zoomColorValueClassName}__text`], {
        [styles[`${zoomColorValueClassName}__text--explorer`]]: mapStyle === MapStyle.OS_EXPLORER,
        [styles[`${zoomColorValueClassName}__text--landranger`]]: mapStyle === MapStyle.OS_LANDRANGER,
    });

    const value = getMapScaleValue(mapScale);

    return (
        <div className={zoomColorClassName}>
            {shouldDisplayLockIcon && <LockIcon className={styles['scale-color__icon']} />}

            <div className={styles[zoomColorValueClassName]}>
                <div className={arrowClassName} />
                <div className={textClassName}>
                    <div>{`1:${value}`}</div>
                </div>
            </div>
        </div>
    );
};
