import * as React from 'react';

const SendOrderError: React.FunctionComponent = () => {
  return (
    <div>
      Please try again later, or contact our Customer Services team on{' '}
      <a href="mailto:customerservices@os.uk?subject=OS%20GPS%20tile%20error">
        customerservices@os.uk
      </a>
    </div>
  );
};

export default SendOrderError;
