import { action } from 'typesafe-actions';

import {
    IMapLocationParams,
    IMapStyleParams,
    IMapOptionsParams,
    IMapLocationCoordinates,
} from 'root/model/map/MapParams';

import {
    INITIALIZE_MAP_PARAMS_FROM_SEARCH_PARAMS,
    INITIALIZE_MAP_PARAMS_FROM_PRODUCT_OPTIONS,
    SET_MAP_LOCATION_PARAMS,
    RESET_MAP_LOCATION_PARAMS,
    SET_MAP_STYLE_PARAMS,
    SET_MAP_OPTIONS_PARAMS,
    SET_PRODUCT_PARAMS,
    SET_PRODUCT,
    SET_AREA_AND_STYLE_TAB,
    SET_AREA_AND_STYLE_COORDINATES,
    SET_CENTRE_LOCATION_COORDINATES,
    SET_ROUTE_COORDINATES,
    TOGGLE_MINI_MAP,
    INCREMENT_ZOOM,
    DECREMENT_ZOOM,
    CHANGE_SELECTED_MAP_STYLE,
} from './constants';
import { SelectedMapStyle } from 'root/model/map/MapEnums';

export const initializeMapParamsFromSearchParams = () => action(INITIALIZE_MAP_PARAMS_FROM_SEARCH_PARAMS);

export const initializeMapParamsFromProductOptions = () => action(INITIALIZE_MAP_PARAMS_FROM_PRODUCT_OPTIONS);

export const setMapLocationParams = (mapLocationParams: IMapLocationParams) =>
    action(SET_MAP_LOCATION_PARAMS, mapLocationParams);

export const resetMapLocationParams = () => action(RESET_MAP_LOCATION_PARAMS);

export const changeSelectedMapStyle = (selectedMapStyle: SelectedMapStyle) =>
    action(CHANGE_SELECTED_MAP_STYLE, selectedMapStyle);

export const setMapStyleParams = (mapStyleParams: IMapStyleParams) => action(SET_MAP_STYLE_PARAMS, mapStyleParams);

export const setMapOptionsParams = (mapOptionsParams: IMapOptionsParams) =>
    action(SET_MAP_OPTIONS_PARAMS, mapOptionsParams);

export const setProduct = () => action(SET_PRODUCT);

export const setProductParams = (product: { sku: string; price: number }) => action(SET_PRODUCT_PARAMS, product);

export const setAreaAndStyleTab = (tab: number) => action(SET_AREA_AND_STYLE_TAB, tab);

export const setAreaAndStyleCoordinates = (coordinates: IMapLocationCoordinates) =>
    action(SET_AREA_AND_STYLE_COORDINATES, coordinates);

export const toggleMiniMap = (flag?: boolean) => action(TOGGLE_MINI_MAP, flag);

export const setCentreLocationCoordinates = (coordinates: IMapLocationCoordinates) =>
    action(SET_CENTRE_LOCATION_COORDINATES, coordinates);

export const setRouteCoordinates = (coordinates: IMapLocationCoordinates) => action(SET_ROUTE_COORDINATES, coordinates);

export const incrementZoom = () => action(INCREMENT_ZOOM);

export const decrementZoom = () => action(DECREMENT_ZOOM);
