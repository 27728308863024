import * as React from 'react';
import { Map as MapBoxMap, Point as MapBoxPoint, GeoJSONSource } from 'mapbox-gl';

import { convertProjection, PROJECTIONS } from 'root/services/projection';
import { IMapLocationCoordinates } from './../../model/map/MapParams';
import { ViewOptions } from 'root/model/map/ViewOptions';
import { rotationEquation } from './utils/mapbox-rotate-leisure';
import { getBounding } from './utils/utils';
import { Scale } from 'root/model/map/MapEnums';
import { CMFA_APP_OS_MAPBOX_TOKEN } from 'root/constants';
import { getMapStylePrefix } from 'root/modules/utils/functions';
import { RouteVM } from 'root/model/route/Route';
import mapboxgl from 'mapbox-gl';
import { addRouteLineLayer, updateRouteLineLayer, upsertRouteLineSource } from './utils/route';
import { LAYER, RouteStyleType, SOURCE } from './utils/types';

interface IMapEvents {
    onPointerDrag: (event: any) => void;
}

interface IMapProps {
    className: string;
    view: ViewOptions;
    events?: IMapEvents;
    bounding?: number;
    feature?: { source?: any; sourceName?: any; layer: any };
    proportion: { x: number; y: number };
    interactive: boolean;
    mapStyles: mapboxgl.Style | undefined;
    mapScale: Scale;
    route?: RouteVM;
    routeSetting?: RouteStyleType;
    isRouteActive?: boolean;
}

export type MapVisibleBoundsType = { p1: IMapLocationCoordinates; p2: IMapLocationCoordinates } | undefined;

class Map extends React.PureComponent<IMapProps> {
    private map?: mapboxgl.Map;
    private mapElement: HTMLDivElement;

    constructor(props: IMapProps) {
        super(props);
    }

    public componentDidMount() {
        const latLong = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, this.props.view.center);

        this.map = new MapBoxMap({
            container: this.mapElement,
            accessToken: CMFA_APP_OS_MAPBOX_TOKEN,
            center: [latLong.x, latLong.y],
            zoom: this.props.view.zoom,
            bearingSnap: 0,
            dragRotate: false,
            doubleClickZoom: false,
            touchZoomRotate: false,
            scrollZoom: false,
            style: this.props.mapStyles,
            keyboard: false,
        });

        this.map.on('load', () => {
            if (this.map && this.props.feature) {
                this.map.addSource(this.props.feature.sourceName, this.props.feature.source);
                this.map.addLayer(this.props.feature.layer);
            }

            if (this.map && this.props.route && this.props.routeSetting && this.props.isRouteActive) {
                upsertRouteLineSource(this.map, this.props.route, SOURCE.singleRouteLine);
                addRouteLineLayer(this.map, SOURCE.singleRouteLine, LAYER.singleRouteLine, this.props.routeSetting);
            }
        });
        this.initializeEvents();

        this.setBounding();
    }

    private jumpTo(center: IMapLocationCoordinates) {
        if (!this.map) {
            return;
        }

        const latLong = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, center);

        this.map.jumpTo({ center: [latLong.x, latLong.y] });
        rotationEquation(this.map);
    }

    toggleGrabCursor(showGrabCursor: boolean) {
        if (this.mapElement) {
            const interactiveLayer = this.mapElement.querySelector<HTMLElement>(
                'div.mapboxgl-canvas-container.mapboxgl-interactive'
            );
            if (interactiveLayer) {
                interactiveLayer.style.cursor = showGrabCursor ? '' : 'default';
            }
        }
    }

    public componentDidUpdate(prevProps: IMapProps) {
        if (!this.map) {
            return;
        }

        const { view: prevView } = prevProps;
        const { view: currentView } = this.props;

        if (prevView.center && currentView.center && currentView.center !== prevView.center) {
            this.jumpTo(currentView.center);
        }

        if (prevView.zoom && currentView.zoom && currentView.zoom !== prevView.zoom && !this.props.bounding) {
            this.map.jumpTo({ zoom: currentView.zoom });
        }

        if (prevProps.feature && this.props.feature && this.props.feature.source !== prevProps.feature.source) {
            const mapSource = this.map.getSource(this.props.feature.sourceName) as GeoJSONSource;
            if (mapSource) {
                mapSource.setData(this.props.feature.source.data);
            }
        }
        if (prevProps.mapScale !== this.props.mapScale) {
            const olLayerPrefix = getMapStylePrefix(prevProps.mapScale, 'layer');
            this.map.setLayoutProperty(olLayerPrefix, 'visibility', 'none');

            const newLayerPrefix = getMapStylePrefix(this.props.mapScale, 'layer');
            this.map.setLayoutProperty(newLayerPrefix, 'visibility', 'visible');
        }

        if (this.props.interactive !== prevProps.interactive) {
            if (this.props.interactive) {
                this.map.dragPan.enable();
                // INFO: Disable arrow keys
                // this.map.keyboard.enable();
            } else {
                this.map.dragPan.disable();
                // INFO: Disable arrow keys
                // this.map.keyboard.disable();
            }
        }

        const layer = this.map.getLayer(LAYER.singleRouteLine);

        if (
            this.map &&
            this.props.route &&
            this.props.routeSetting &&
            this.props.isRouteActive &&
            (this.props.routeSetting !== prevProps.routeSetting ||
                this.props.route !== prevProps.route ||
                this.props.isRouteActive !== prevProps.isRouteActive)
        ) {
            if (prevProps.route === null || (!prevProps.isRouteActive && this.props.isRouteActive)) {
                upsertRouteLineSource(this.map, this.props.route, SOURCE.singleRouteLine);
                addRouteLineLayer(this.map, SOURCE.singleRouteLine, LAYER.singleRouteLine, this.props.routeSetting);
            } else if (prevProps.route?.id !== this.props.route.id) {
                if (layer) {
                    this.map.removeLayer(LAYER.singleRouteLine);
                }
                upsertRouteLineSource(this.map, this.props.route, SOURCE.singleRouteLine);
                addRouteLineLayer(this.map, SOURCE.singleRouteLine, LAYER.singleRouteLine, this.props.routeSetting);
            } else {
                updateRouteLineLayer(this.map, LAYER.singleRouteLine, this.props.routeSetting);
            }
        } else if (
            (this.props.route === null && prevProps.route !== null) ||
            (!this.props.isRouteActive && prevProps.isRouteActive)
        ) {
            if (layer) {
                this.map.removeLayer(LAYER.singleRouteLine);
            }
        }

        this.setBounding();
    }

    public setBounding() {
        const { view: currentView } = this.props;
        if (this.map && currentView && currentView.center && this.props.bounding) {
            const bounding = this.props.bounding;

            const coordinates = this.calculateBoundingBox(bounding, this.props.proportion, currentView.center);

            const left = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, coordinates.leftBottom);

            const right = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, coordinates.rightTop);

            this.map.fitBounds(
                [
                    [left.x, left.y],
                    [right.x, right.y],
                ],
                { duration: 0 }
            );

            rotationEquation(this.map);
        }
    }

    public getCoordinateFromPixel(x: number, y: number): IMapLocationCoordinates {
        if (!this.map) {
            return { x: 0, y: 0 };
        }

        const { lng, lat } = this.map?.unproject(new MapBoxPoint(x, y));

        return convertProjection(PROJECTIONS.EPSG_4326, PROJECTIONS.EPSG_27700, {
            x: lng,
            y: lat,
        } as IMapLocationCoordinates);
    }

    public getCenter(): IMapLocationCoordinates {
        if (!this.map) {
            return { x: 0, y: 0 };
        }

        const { lng, lat } = this.map.getCenter();

        return convertProjection(PROJECTIONS.EPSG_4326, PROJECTIONS.EPSG_27700, {
            x: lng,
            y: lat,
        });
    }

    public resize() {
        this.map?.resize();
    }

    public calculateBoundingNarrowPoints(mapScale: Scale): MapVisibleBoundsType {
        if (!this.map) {
            return { p1: { x: 0, y: 0 }, p2: { x: 0, y: 0 } };
        }

        const centerPoint = this.getCenter();
        const coordinates = this.calculateBoundingBox(getBounding(mapScale), this.props.proportion, centerPoint);

        return { p1: coordinates.leftBottom, p2: coordinates.rightTop };
    }

    public calculateBoundingBox(
        bounding: number,
        proportion: { x: number; y: number },
        centerPoint: IMapLocationCoordinates
    ) {
        const radius = {
            x: (bounding * proportion.x) / 2,
            y: (bounding * proportion.y) / 2,
        };

        return {
            leftBottom: {
                x: centerPoint.x - radius.x,
                y: centerPoint.y - radius.y,
            },
            rightTop: {
                x: centerPoint.x + radius.x,
                y: centerPoint.y + radius.y,
            },
        };
    }

    public render() {
        return <div className={this.props.className} ref={this.setMapElement} />;
    }

    private setMapElement = (element: HTMLDivElement) => {
        this.mapElement = element;
    };

    private initializeEvents = () => {
        const { events } = this.props;

        if (this.map && events) {
            this.map.on('dragend', events.onPointerDrag);
        }
    };
}

export default Map;
