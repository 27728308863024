import { combineEpics, Epic, ofType } from 'redux-observable';
import { EMPTY, from, of } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { catchError, filter, mergeMap, switchMap, withLatestFrom, map, toArray } from 'rxjs/operators';
import { ActionType } from 'typesafe-actions';
import { isArray } from 'lodash';

import Types from 'Types';

import { ConfigType, RecomendationStatus, RouteListVM, RouteType } from 'root/model/route/Route';
import { convertLatLongToBritishNationGrid } from 'root/services/projection';
import { convertSizeToSheetSize, ProductId, SheetSize } from 'root/model/map/MapEnums';

import {
    GET_ROUTE_LIST,
    HANDLE_ROUTE_UPLOAD,
    REQUEST_ROUTE_UPLOAD,
    RESTORE_ROUTE_COORDINATES,
    RESTORE_ROUTE_SCALE,
    SELECT_ROUTE,
    SET_SELECTED_ROUTE,
} from './constants';

import {
    getRouteList as getRouteListAction,
    resetRouteList,
    setRouteList,
    selectRoute as selectRouteAction,
    setSelectedRoute,
    restoreRouteScale,
    restoreRouteCoordinates,
    handleRouteUpload,
    changeUploadedRouteError,
    changeUploadedRouteFilename,
    requestRouteUpload,
    changeUploadedRouteFile,
    resetUploadedRoute,
} from './actions';

import { getRouteList as getRouteListService, getRouteById, postRouteUpload, ROUTE_ERRORS } from '../../services/route';
import { setMapLocationParams, setMapStyleParams, setRouteCoordinates } from '../map/actions';
import { FILE_CONSTS } from 'root/services/fileHelpers/consts';

type GetRouteListAction = ActionType<typeof getRouteListAction>;

export const getRouteList: Epic<Types.RootAction, Types.RootAction, Types.RootState> = ($action, $state) =>
    $action.pipe(
        ofType<GetRouteListAction>(GET_ROUTE_LIST),
        withLatestFrom($state),
        switchMap(([action, state]) => {
            return getRouteListService().pipe(
                switchMap((ajaxResponse) => {
                    return of(ajaxResponse);
                }),
                catchError((e) => {
                    console.error(e);
                    return of(e);
                })
            );
        }),
        switchMap(({ response }) => {
            if (response === null) {
                return of(resetRouteList());
            }

            return from(response.routes).pipe(
                mergeMap((route: RouteType, index: number) => {
                    return getRouteById(route.id).pipe(
                        switchMap((ajaxResponse) => {
                            return of(ajaxResponse);
                        }),
                        catchError((e) => {
                            console.error(e);
                            return of(e);
                        })
                    );
                }),
                filter((el: AjaxResponse) => {
                    return el.status === 200;
                }),
                map((el: AjaxResponse, index: number) => {
                    const bngCoordinates = convertLatLongToBritishNationGrid(el.response.bbox);
                    const routeWithConfig = response.routes.find((route: RouteType) => route.id === el.response.id);

                    const extendedConfig = routeWithConfig.config.reduce((acc: ConfigType[], config: ConfigType) => {
                        if (config.sheetSize === SheetSize.MEDIUM || config.sheetSize === SheetSize.SMALL) {
                            // The Excel table below shows Small and Medium without division into Framed and Folded.
                            // Unfortunately the API only returns a framed, so we need to duplicate it and add folded
                            // https://os-spyro.atlassian.net/browse/RQQ-263

                            const configForFolded = { ...config, type: 'FOLDED' };

                            return [...acc, config, configForFolded];
                        }

                        return [...acc, config];
                    }, []);

                    return {
                        ...routeWithConfig,
                        ...el.response,
                        bngCoordinates,
                        config: extendedConfig,
                    };
                }),
                toArray()
            );
        }),
        switchMap((result: RouteListVM) => {
            if (!isArray(result)) {
                return of(resetRouteList());
            }

            return of(setRouteList(result));
        })
    );

type SelectRouteAction = ActionType<typeof selectRouteAction>;

export const selectRoute: Epic<Types.RootAction, Types.RootAction, Types.RootState> = ($action, $state) =>
    $action.pipe(
        ofType<SelectRouteAction>(SELECT_ROUTE),
        withLatestFrom($state),
        switchMap(([action, state]) => {
            const route = state.route.routeList.find((el) => el.id === action.payload?.value);

            if (route) {
                return of(setSelectedRoute({ ...route, routeType: 'list' }));
            } else {
                return of(setSelectedRoute(null));
            }
        })
    );

type SetSelectedRouteAction = ActionType<typeof setSelectedRoute>;

export const setNewRouteCoordinates: Epic<Types.RootAction, Types.RootAction, Types.RootState> = ($action, $state) =>
    $action.pipe(
        ofType<SetSelectedRouteAction>(SET_SELECTED_ROUTE),
        withLatestFrom($state),
        switchMap(([action]) => {
            const route = action.payload;

            if (route) {
                return of(
                    setRouteCoordinates(route.bngCoordinates),
                    setMapLocationParams({
                        coordinates: route.bngCoordinates,
                    })
                );
            }

            return EMPTY;
        })
    );

type RestoreRouteScaleAction = ActionType<typeof restoreRouteScale>;

export const restoreDefaultRouteScale: Epic<Types.RootAction, Types.RootAction, Types.RootState> = ($action, $state) =>
    $action.pipe(
        ofType<RestoreRouteScaleAction>(RESTORE_ROUTE_SCALE),
        withLatestFrom($state),
        switchMap(([, state]) => {
            const sheetSize = convertSizeToSheetSize(state.map.mapOptionsParams.size);
            const productId = state.map.mapOptionsParams.productId;

            const recommendedScale: ConfigType[] = (state.route.selectedRoute?.config || []).filter((config) => {
                if (productId === ProductId.FOLDED_MAP && config.type === 'FOLDED') {
                    return (
                        sheetSize === config.sheetSize && config.recommendationStatus === RecomendationStatus.Default
                    );
                } else if (config.type === 'FRAMED') {
                    return (
                        sheetSize === config.sheetSize && config.recommendationStatus === RecomendationStatus.Default
                    );
                }

                return false;
            });

            if (recommendedScale && recommendedScale[0]) {
                return of(setMapStyleParams({ mapScale: recommendedScale[0].scale }));
            }

            return EMPTY;
        })
    );

type RestoreRouteCoordinatesAction = ActionType<typeof restoreRouteCoordinates>;

export const restoreDefaultRouteCoordinates: Epic<Types.RootAction, Types.RootAction, Types.RootState> = (
    $action,
    $state
) =>
    $action.pipe(
        ofType<RestoreRouteCoordinatesAction>(RESTORE_ROUTE_COORDINATES),
        withLatestFrom($state),
        switchMap(([, state]) => {
            const selectedRoute = state.route.selectedRoute;

            if (selectedRoute) {
                return of(
                    setRouteCoordinates(selectedRoute.bngCoordinates),
                    setMapLocationParams({
                        coordinates: selectedRoute.bngCoordinates,
                    })
                );
            }

            return EMPTY;
        })
    );

type HandleRouteUpload = ActionType<typeof handleRouteUpload>;

export const handleRouteUploadEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$
) =>
    action$.pipe(
        ofType<HandleRouteUpload>(HANDLE_ROUTE_UPLOAD),
        switchMap((action) => {
            const { accepted, rejected } = action.payload;

            if (rejected.length) {
                const errors = rejected[0].errors;
                console.error(errors);

                if (errors[0].code === 'file-invalid-type') {
                    return of(resetUploadedRoute(), changeUploadedRouteError(ROUTE_ERRORS.INVALID_GPX_FILE));
                }

                return of(resetUploadedRoute(), changeUploadedRouteError(ROUTE_ERRORS.GENERIC));
            }

            if (accepted.length) {
                const file = accepted[0];

                if (file.size > FILE_CONSTS.MAX_ROUTE_FILE_SIZE) {
                    return of(resetUploadedRoute(), changeUploadedRouteError(ROUTE_ERRORS.FILE_TOO_LARGE));
                }

                const data = new FormData();
                data.append('file', file);

                return of(requestRouteUpload(data, file.name));
            }

            return EMPTY;
        })
    );

type RequestRouteUpload = ActionType<typeof requestRouteUpload>;

export const requestRouteUploadEpic: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$
) =>
    action$.pipe(
        ofType<RequestRouteUpload>(REQUEST_ROUTE_UPLOAD),
        switchMap((action) => {
            const { data, filename } = action.payload;

            return postRouteUpload(data).pipe(
                switchMap(({ response }) => {
                    const bngCoordinates = convertLatLongToBritishNationGrid(response.route.bbox);

                    return of(
                        setSelectedRoute({
                            ...response.route,
                            config: response.config,
                            bngCoordinates,
                            routeType: 'gpx',
                        }),
                        changeUploadedRouteFilename(filename),
                        changeUploadedRouteFile(response),
                        changeUploadedRouteError(null)
                    );
                }),
                catchError((error) => {
                    console.error(error);

                    return of(resetUploadedRoute(), changeUploadedRouteError(ROUTE_ERRORS.GENERIC));
                })
            );
        })
    );

export default combineEpics(
    getRouteList,
    selectRoute,
    setNewRouteCoordinates,
    restoreDefaultRouteScale,
    restoreDefaultRouteCoordinates,
    handleRouteUploadEpic,
    requestRouteUploadEpic
);
