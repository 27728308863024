import * as React from 'react';
import * as classNames from 'classnames';
import styles from './index.module.scss';

interface ICoverDropzoneImageContentProps {
    isCover?: boolean;
}

const CoverDropzoneImageContent: React.FunctionComponent<ICoverDropzoneImageContentProps> = ({ isCover = false }) => {
    const baseClassName = 'cover-dropzone-image-content';

    const coverDropzoneBackgroundCssClasses = classNames(styles[`${baseClassName}__background`], {
        [styles[`${baseClassName}__background--cover`]]: true,
    });

    const coverDropzoneContentCssClasses = classNames(styles[baseClassName], {
        [styles[`${baseClassName}--cover`]]: isCover,
    });
    const containerCssClasses = classNames(styles[`${baseClassName}__container`], {
        [styles[`${baseClassName}__container--cover`]]: isCover,
    });
    const sunIconCssClasses = classNames(styles[`${baseClassName}__sun-icon`], {
        [styles[`${baseClassName}__sun-icon--cover`]]: isCover,
    });
    const hillIconCssClasses = classNames(styles[`${baseClassName}__hill-icon`], {
        [styles[`${baseClassName}__hill-icon--cover`]]: isCover,
    });
    const titleCssClasses = classNames(styles[`${baseClassName}__title`], {
        [styles[`${baseClassName}__title--cover`]]: isCover,
    });
    const descriptionCssClasses = classNames(styles[`${baseClassName}__description`], {
        [styles[`${baseClassName}__description--cover`]]: isCover,
    });

    return (
        <div className={coverDropzoneBackgroundCssClasses}>
            <div className={coverDropzoneContentCssClasses}>
                <div className={containerCssClasses}>
                    <i className={sunIconCssClasses} />

                    <i className={hillIconCssClasses} />

                    <p className={titleCssClasses}>Upload your own cover image</p>

                    <p className={descriptionCssClasses}>
                        It must be larger than 200kb and less than 10Mb and in JPEG or PNG format.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default CoverDropzoneImageContent;
