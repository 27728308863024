import React from 'react';

import TabHeader from 'root/components/TabHeader';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { Tile } from 'root/components/Tile';

import { LocationTab } from './components/LocationTab/LocationTab';
import { RouteTab } from './components';
import { RouteValidatorProvider } from './providers/routeValidator';
import location from './assets/location.svg';
import route from './assets/route.svg';
import styles from './index.module.scss';
import { useAreaAndStyleTab } from './hooks';

export const AreaAndStyleTab = () => {
    const { selectedTab, handleTileAction, scrollElement } = useAreaAndStyleTab();

    return (
        <div tour-guide="area_1" className={styles['area-and-style-tab']}>
            <section>
                <RouteValidatorProvider>
                    <TabHeader tabTitle="Area and style" tabDescription="" />

                    <div ref={scrollElement}>
                        <Tile
                            className={styles['area-and-style-tab__tile']}
                            action={() => handleTileAction(AreaAndStyleSelectedTab.CentreLocation)}
                            isActive={selectedTab === AreaAndStyleSelectedTab.CentreLocation}
                            isError={false}
                            variant={'primary'}
                        >
                            <div className={styles['area-and-style-tab__tile-content']}>
                                <img className={styles['area-and-style-tab__tile-content__icon']} src={location} />

                                <div className={styles['area-and-style-tab__tile-content__wrapper']}>
                                    <div className={styles['area-and-style-tab__tile-content__title']}>
                                        Choose a Location
                                    </div>

                                    <div className={styles['area-and-style-tab__tile-content__description']}>
                                        Centre the map on a chosen place.
                                    </div>
                                </div>
                            </div>
                        </Tile>

                        <Tile
                            className={styles['area-and-style-tab__tile']}
                            action={() => handleTileAction(AreaAndStyleSelectedTab.Route)}
                            isActive={selectedTab === AreaAndStyleSelectedTab.Route}
                            isError={false}
                            variant={'third'}
                        >
                            <div className={styles['area-and-style-tab__tile-content']}>
                                <img className={styles['area-and-style-tab__tile-content__icon']} src={route} />

                                <div className={styles['area-and-style-tab__tile-content__wrapper']}>
                                    <div className={styles['area-and-style-tab__tile-content__title']}>
                                        Display a Route
                                    </div>

                                    <div className={styles['area-and-style-tab__tile-content__description']}>
                                        Upload or choose a route.
                                    </div>
                                </div>
                            </div>
                        </Tile>

                        {selectedTab === AreaAndStyleSelectedTab.CentreLocation && <LocationTab />}

                        {selectedTab === AreaAndStyleSelectedTab.Route && <RouteTab />}
                    </div>
                </RouteValidatorProvider>
            </section>
        </div>
    );
};
