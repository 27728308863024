import { useState } from 'react';
import { Keyframes } from 'styled-components';

import { getReturnValue } from 'root/modules/utils/functions';
import { useStateChanged } from 'root/modules/utils/hooks';
import { CssReturnType } from '../styles';

export type AnimationControls = { show: boolean; canStart?: boolean };

export type CssWithKeyframes = (name: Keyframes) => CssReturnType;

type AnimationCss = CssReturnType | CssWithKeyframes;

type AnimationType<T> = { onShow: T; onHide?: T };

function IsAnimationType<T>(interpolation: T | AnimationType<T>): interpolation is AnimationType<T> {
  return !!(interpolation as AnimationType<T>).onShow;
}

export const animation = (
  animationCss: AnimationCss | AnimationType<AnimationCss>,
  animationKeyframes: Keyframes | AnimationType<Keyframes>,
  beforeStart?: CssReturnType,
  onStart?: CssReturnType
) => ({ show, canStart }: AnimationControls): CssReturnType => {
  const { onShow: showAnimation, onHide: hideAnimation }: AnimationType<Keyframes> = IsAnimationType(animationKeyframes)
    ? animationKeyframes
    : { onShow: animationKeyframes, onHide: animationKeyframes };

  const { onShow, onHide }: AnimationType<AnimationCss> = IsAnimationType(animationCss)
    ? animationCss
    : { onShow: animationCss };

  return [
    canStart ? onStart : beforeStart,
    show ? getReturnValue(onShow, showAnimation) : getReturnValue(onHide, hideAnimation),
  ];
};

export const useAnimationProps = (show: boolean, start?: boolean, defaultShow?: boolean): AnimationControls => {
  const [defaultState] = useState(defaultShow ?? show);
  const startAnimation = useStateChanged(start ?? show);

  return {
    show,
    canStart: defaultState || startAnimation,
  };
};
