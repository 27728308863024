import * as React from 'react';

import mainStyles from '../../index.module.scss';
import styles from './index.module.scss';
import LocationForm from './components/LocationForm';
import hand from '../../assets/hand.svg';

export const LocationTab = () => {
    return (
        <div className={mainStyles['area-and-style-tab__columns']}>
            <div>
                <LocationForm />

                <div className={styles['tip']}>
                    <img src={hand} />
                    <div>
                        <b>Pro tip! </b>You can change the location by moving the map preview.
                    </div>
                </div>
            </div>
        </div>
    );
};
