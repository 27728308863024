import * as React from 'react';
import TabHeaderQuestionIcon from './components/TabHeaderQuestionIcon';
import styles from './index.module.scss';

export interface ITabHeaderProps {
  tabTitle: string;
  tabDescription: string;
  hideQuestionIcon?: boolean;
}

const TabHeader: React.FunctionComponent<ITabHeaderProps> = ({
  tabTitle,
  tabDescription,
  hideQuestionIcon,
}) => (
  <div className={styles['tab-header']}>
    <div>
      <h2 className={styles['tab-header__title']}>{tabTitle}</h2>
      <p className={styles['tab-header__description']}>{tabDescription}</p>
    </div>
    {!hideQuestionIcon && <TabHeaderQuestionIcon />}
  </div>
);

export default TabHeader;
