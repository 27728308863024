import { createSelector } from 'reselect';

import Types from 'Types';

export const getTitleFirstLine = (state: Types.RootState) =>
  state.customisation.titleFirstLine;

export const getTitleSecondLine = (state: Types.RootState) =>
  state.customisation.titleSecondLine;

export const getTitleThirdLine = (state: Types.RootState) =>
  state.customisation.titleThirdLine;

export const getSubtitleFirstLine = (state: Types.RootState) =>
  state.customisation.subtitleFirstLine;

export const getSubtitleSecondLine = (state: Types.RootState) =>
  state.customisation.subtitleSecondLine;

export const getSpine = (state: Types.RootState) => state.customisation.spine;

export const getSpineFromTitles = (state: Types.RootState) => {
  const {
    titleFirstLine,
    titleSecondLine,
    titleThirdLine,
  } = state.customisation;
  let result = titleFirstLine ? `${titleFirstLine}` : '';
  result = titleSecondLine ? `${result} ${titleSecondLine}` : result;
  result = titleThirdLine ? `${result} ${titleThirdLine}` : result;
  return result.trim();
};

export const getTitles = createSelector(
  [
    getTitleFirstLine,
    getTitleSecondLine,
    getTitleThirdLine,
    getSubtitleFirstLine,
    getSubtitleSecondLine,
    getSpine,
  ],
  (
    titleFirstLine,
    titleSecondLine,
    titleThirdLine,
    subtitleFirstLine,
    subtitleSecondLine,
    spine
  ) => ({
    titleFirstLine,
    titleSecondLine,
    titleThirdLine,
    subtitleFirstLine,
    subtitleSecondLine,
    spine,
  })
);

export const getLinkToImage = (state: Types.RootState) =>
  state.customisation.linkToImage;

export const getIsLoadingImage = (state: Types.RootState) =>
  state.customisation.isLoadingImage;
