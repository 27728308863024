import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import {
  IProductOption,
  IPredefinedCoverImage,
} from 'root/model/product/ProductOption';

import * as actions from './actions';
import { SET_PRODUCTS_OPTIONS, SET_PREDEFINED_COVER_IMAGES } from './constants';

export type ProductOptionsActions = ActionType<typeof actions>;

interface IProductOptionsState {
  options: IProductOption[];
  predefinedCoverImages: {
    SMALL: IPredefinedCoverImage[];
    LARGE: IPredefinedCoverImage[];
  };
}

export const optionsReducer = (
  state: IProductOption[] = [],
  action: ProductOptionsActions
) => {
  switch (action.type) {
    case SET_PRODUCTS_OPTIONS:
      return action.payload;
    default:
      return state;
  }
};

export const predefinedCoverImagesReducer = (
  state: { SMALL: IPredefinedCoverImage[]; LARGE: IPredefinedCoverImage[] } = {
    SMALL: [],
    LARGE: [],
  },
  action: ProductOptionsActions
) => {
  switch (action.type) {
    case SET_PREDEFINED_COVER_IMAGES:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers<IProductOptionsState, ProductOptionsActions>({
  options: optionsReducer,
  predefinedCoverImages: predefinedCoverImagesReducer,
});
