import { OS_MAPS_TILES_STYLE } from 'root/constants';
import { ajax } from 'rxjs/ajax';

export const getMapStyles = () => {
  return ajax({
    method: 'GET',
    url: OS_MAPS_TILES_STYLE,
    crossDomain: true,
  });
};
