import * as React from 'react';
import { styled, Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@material-ui/core';

type SwitchProps = Pick<MuiSwitchProps, 'checked' | 'id'> & {
    ariaLabel: string;
};

const StyledSwitch = styled(MuiSwitch)(() => ({
    width: 42,
    height: 26,
    padding: 0,

    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',

        '&:hover': {
            backgroundColor: 'transparent',
        },

        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#453c90',

            '& + .MuiSwitch-track': {
                backgroundColor: '#bebcd5',
                opacity: 1,
                border: 0,
            },

            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },

            '&:hover': {
                backgroundColor: 'transparent',
            },
        },

        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },

    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: '#E7E5E4',
        opacity: 1,
        transition: 'all 0.24s ease-in-out',
    },
}));

export const Switch = ({ checked, id, ariaLabel }: SwitchProps) => {
    return (
        <StyledSwitch
            id={id}
            checked={checked}
            inputProps={{ 'aria-label': ariaLabel }}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
        />
    );
};
