import * as history from './history';
import * as location from './location';
import * as formValidators from './formValidators';
import * as createProductJourney from './createProductJourney';
import * as productOptions from './productOptions';
import * as file from './fileHelpers';
import * as imageCrop from './imageCrop';
import * as mediaQuery from './mediaQuery';
import * as cover from './cover';
import * as productPreview from './productPreview';
import * as basket from './basket';
import * as rotateImage from './rotateImage';

export default {
  history,
  location,
  formValidators,
  createProductJourney,
  productOptions,
  file,
  imageCrop,
  mediaQuery,
  cover,
  productPreview,
  basket,
  rotateImage,
};
