export enum TITLE_FORM_FIELD_NAMES {
  TITLE_FIRST_LINE = 'titleFirstLine',
  TITLE_SECOND_LINE = 'titleSecondLine',
  TITLE_THIRD_LINE = 'titleThirdLine',
  SUBTITLE_FIRST_LINE = 'subtitleFirstLine',
  SUBTITLE_SECOND_LINE = 'subtitleSecondLine',
  SPINE = 'spine',
  COVER_IMAGE = 'coverImage',
}

export interface ITitleFormValues {
  [TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE]: string;
  [TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE]: string;
  [TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE]: string;
  [TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE]: string;
  [TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE]: string;
  [TITLE_FORM_FIELD_NAMES.SPINE]: string;
  [TITLE_FORM_FIELD_NAMES.COVER_IMAGE]: string;
}
