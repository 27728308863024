import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';

import ChooseLocation from './assets/choose_location.jpg';
import ChooseRoute from './assets/choose_route.jpg';

export const NON_SELECTED_PREVIEW_TAB_LIST = [
    {
        title: 'Choose a Location',
        description: 'Search by town, city, postcode or grid reference to centre the map on your favourite place.',
        id: AreaAndStyleSelectedTab.CentreLocation,
        image: ChooseLocation
    },
    {
        title: 'Choose a Route',
        description: 'Either upload a your own route (using a GPX file) or choose from our list of recommended routes, perfect for celebrating your favourite challenge!',
        id: AreaAndStyleSelectedTab.Route,
        image: ChooseRoute
    }
];
