import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from 'root/services/history';

import { createProductJourneyReducer } from 'features/createProductJourney';
import { locationReducer } from 'features/location';
import { mapReducer } from 'features/map';
import { productOptionsReducer } from 'features/productOptions';
import { customisationReducer } from 'features/customisation';
import { coverReducer } from 'features/cover';
import { imageCropReducer } from 'features/imageCrop';
import { mediaQueryReducer } from 'features/mediaQuery';
import { userInfoModalReducer } from 'features/userInfoModal';
import { productPreviewReducer } from 'features/productPreview';
import { tourGuideReducer } from 'features/tourGuide';
import { checkoutOptionsReducer } from 'features/checkoutOptions';
import { RESET_STATE } from './constants';
import { mapStylesReducer } from 'features/mapStyles/reducer';
import { routeReducer } from 'root/features/route';

const appReducer = combineReducers({
    router: connectRouter(history),
    map: mapReducer,
    mapStyles: mapStylesReducer,
    route: routeReducer,
    location: locationReducer,
    createProductJourney: createProductJourneyReducer,
    productOptions: productOptionsReducer,
    customisation: customisationReducer,
    cover: coverReducer,
    imageCrop: imageCropReducer,
    mediaQuery: mediaQueryReducer,
    userInfoModal: userInfoModalReducer,
    productPreview: productPreviewReducer,
    tourGuide: tourGuideReducer,
    checkoutOptions: checkoutOptionsReducer,
});

const rootReducer = (state: any, action: any) => {
    if (action.type === RESET_STATE) {
        return appReducer(
            {
                ...state,
                mapStyles: state.mapStyles,
                map: undefined,
                route: state.route,
                router: undefined,
                location: undefined,
                createProductJourney: undefined,
                productOptions: undefined,
                customisation: undefined,
                cover: undefined,
                imageCrop: undefined,
                mediaQuery: undefined,
                userInfoModal: undefined,
                productPreview: undefined,
                tourGuide: undefined,
                checkoutOptions: undefined,
            },
            action
        );
    }

    return appReducer(state, action);
};

export default rootReducer;
