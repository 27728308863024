import * as React from 'react';
import { connect } from 'react-redux';
import Tour from 'reactour';

import * as Types from 'Types';
import { tourGuideActions } from 'features/tourGuide';
import { createProductJourneyActions, createProductJourneySelectors } from 'features/createProductJourney';
import {
    CreateProductJourneyAction,
    CreateProductJourneyStep,
    tourGuideStepFromJourney,
} from 'root/model/createProductJourney/CreateProductJourney';

import styles from './index.module.scss';
import { mapSelectors } from 'features/map';

interface ITourGuide {
    isTourGuideVisible: boolean;
    isSingleTooltipMode: boolean;
    tourGuideStep: number;
    productJourneyStep: CreateProductJourneyStep;
    showGuideTooltip: typeof tourGuideActions.showGuideTooltip;
    switchSingleTooltipMode: typeof tourGuideActions.switchSingleTooltipMode;
    dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
    mapType: number;
}

class TourGuide extends React.PureComponent<ITourGuide> {
    public AREA_1 = {
        selector: '[tour-guide="area_1"]',
        content: () => (
            <div>
                <div className={styles['tour-guide__header']}>Choose location</div>
                <div className={styles['tour-guide__content']}>
                    Create your own unique map using the instantly recognisable style of the OS Explorer and OS
                    Landranger leisure maps, or try our OS Explorer Enlarged 1:10 000 available on selected items,
                    perfect for planning smaller adventures or for display.
                </div>
                <div className={styles['tour-guide__content']}>
                    Start by searching for your favourite area using a town, city, postcode, or grid reference to centre
                    the map on a special place.
                </div>
                <div>
                    Alternatively, why not upload a route GPX file or pick from our curated list, perfect for
                    commemorating your favourite challenges!
                </div>
            </div>
        ),
    };

    public OPTIONS_1 = {
        selector: '[tour-guide="options_1"]',
        content: () => {
            const { productJourneyStep, mapType } = this.props;
            if (productJourneyStep === CreateProductJourneyStep.PRODUCT_TYPE && mapType === 1) {
                return (
                    <div>
                        <div className={styles['tour-guide__header']}>Select your product</div>
                        <div className={styles['tour-guide__content']}>
                            Choose from a traditional folded map to fit in your rucksack, a flat rolled map for planning
                            at home or our small folded map, ideal for beginners with an A3 map on one side and a guide
                            to map reading on the other.
                        </div>
                    </div>
                );
            } else if (productJourneyStep === CreateProductJourneyStep.PRODUCT_TYPE && mapType === 2) {
                return (
                    <div>
                        <div className={styles['tour-guide__header']}>Select your product</div>
                        <div className={styles['tour-guide__content']}>
                            Great for display and planning choose from framed or unframed canvas or three sizes of
                            framed map. All our maps are framed and finished by hand in your choice of black or oak
                            coloured wood.
                        </div>
                    </div>
                );
            }

            return (
                <div>
                    <div className={styles['tour-guide__header']}>Choose your product</div>
                    <div className={styles['tour-guide__content']}>
                        Choose from a traditional folded map to fit in your rucksack, a flat rolled map for planning at
                        home or one of our framed and canvas options, ideal for display.
                    </div>
                </div>
            );
        },
        action: () => {
            if (this.props.productJourneyStep === CreateProductJourneyStep.AREA_AND_STYLE) {
                this.props.dispatchJourneyAction(CreateProductJourneyAction.NEXT);
            } else if (this.props.productJourneyStep === CreateProductJourneyStep.CUSTOMISE) {
                this.props.dispatchJourneyAction(CreateProductJourneyAction.BACK);
            }
        },
    };

    public CUSTOMIZE_1 = {
        selector: '[tour-guide="customize_1"]',
        content: () => {
            const { mapType } = this.props;
            if (mapType === 1) {
                return (
                    <div>
                        <div className={styles['tour-guide__header']}>Customise your map</div>
                        <div className={styles['tour-guide__content']}>
                            Enter your own title and add a cover image to our folded maps by uploading your own or
                            choosing one of ours.
                            <br />
                            <br />
                            Please note: Folded map image sizes must be more than 200kb and less than 10MB. Image height
                            must be between 500px and 8000px.
                        </div>
                        <div>When you are ready just click on confirm order to check your order summary.</div>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className={styles['tour-guide__header']}>Customise your map</div>
                        <div className={styles['tour-guide__content']}>
                            Enter your own title and subtitle to complete your personalisation of your map. The text
                            space is different for each product so please follow the instructions next to the text box
                            if your text is too long.
                        </div>
                        <div>When you are ready just click on confirm order to check your order summary.</div>
                    </div>
                );
            }
        },
    };

    public TOUR_GUIDE_STEPS_FOR_AREA = [this.OPTIONS_1, this.AREA_1, this.CUSTOMIZE_1];

    public render() {
        return (
            <React.Fragment>
                <Tour
                    steps={this.TOUR_GUIDE_STEPS_FOR_AREA}
                    isOpen={this.props.isTourGuideVisible && !this.props.isSingleTooltipMode}
                    disableInteraction={true}
                    className={styles['tour-guide__close']}
                    disableDotsNavigation={true}
                    onRequestClose={() => {
                        this.props.showGuideTooltip(false);
                        this.props.switchSingleTooltipMode(true);

                        if (this.props.productJourneyStep === CreateProductJourneyStep.CUSTOMISE) {
                            this.props.dispatchJourneyAction(CreateProductJourneyAction.BACK);
                            this.props.dispatchJourneyAction(CreateProductJourneyAction.BACK);
                        }
                    }}
                />

                <Tour
                    steps={[this.TOUR_GUIDE_STEPS_FOR_AREA[this.props.tourGuideStep]]}
                    isOpen={this.props.isTourGuideVisible && this.props.isSingleTooltipMode}
                    disableInteraction={true}
                    className="helper"
                    disableDotsNavigation={true}
                    onRequestClose={() => {
                        this.props.showGuideTooltip(false);
                    }}
                />
            </React.Fragment>
        );
    }
}

export default connect(
    (state: Types.RootState) => ({
        isTourGuideVisible: state.tourGuide.isTourGuideVisible,
        productJourneyStep: createProductJourneySelectors.getCreateProductJourneyStep(state),
        isSingleTooltipMode: state.tourGuide.isSingleTooltipMode,
        tourGuideStep: tourGuideStepFromJourney[state.createProductJourney.journeyStep],
        mapType: mapSelectors.getMapType(state),
    }),
    {
        showGuideTooltip: tourGuideActions.showGuideTooltip,
        switchSingleTooltipMode: tourGuideActions.switchSingleTooltipMode,
        dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
    }
)(TourGuide);
