import * as React from 'react';

import LockMapSection from './components/LockMapSection/';

const AreaAndStyleBottomBarContent: React.FunctionComponent = () => (
  <React.Fragment>
    <LockMapSection />
  </React.Fragment>
);

export default AreaAndStyleBottomBarContent;
