import React, { forwardRef, HTMLAttributes, useCallback, useMemo, useState } from 'react';

import { MAP_FORMAT } from 'root/modules/types';
import { MapFormatType, WidthType } from 'root/modules/styles';
import { AlternativeLayoutType } from '../../types';
import { MapFrame } from '../MapFrame';
import { MapPreviewProps, MagnifierZoom } from '../MapPreview';
import { PreviewControlLayout } from '../PreviewControlPanel';
import * as P from './parts';

type Props = WidthType &
  MapFormatType &
  AlternativeLayoutType &
  Pick<MapPreviewProps, 'onLoad' | 'dispatchZoomRequest'> & {
    coverSrc: string;
    descriptionFrameSrc?: string;
    wrapperProps?: HTMLAttributes<HTMLDivElement>;
  };

export const FoldedMapPreview = forwardRef<HTMLDivElement, Props>(
  (
    { coverSrc, width, mapFormat, descriptionFrameSrc, alternativeLayout, onLoad, dispatchZoomRequest, wrapperProps },
    ref
  ) => {
    const [isMapMagnifierOpen, setIsMapMagnifierOpen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState<MagnifierZoom>(MagnifierZoom.small);

    const toggleMapMagnifier = useCallback(() => setIsMapMagnifierOpen((prev) => !prev), []);

    const Frame = useMemo(() => (mapFormat === MAP_FORMAT.SQUARE ? MapFrame : P.OffsetMapFrame), [mapFormat]);

    return (
      <P.FoldedMapPreview {...wrapperProps}>
        {alternativeLayout && <P.VerticalPlaceholder mapFormat={mapFormat} />}
        <P.MapPreviewWrapper ref={ref}>
          <Frame
            mapFormat={mapFormat}
            descriptionFrameSrc={descriptionFrameSrc}
            width={width}
            alternativeLayout={alternativeLayout}
          >
            <P.MapPreview
              mapFormat={mapFormat}
              width={width}
              onLoad={onLoad}
              isMagnifierOpen={isMapMagnifierOpen}
              showMapMagnifier={alternativeLayout}
              dispatchZoomRequest={dispatchZoomRequest}
              dispatchRequestOnError={alternativeLayout}
              zoom={zoomLevel}
              onZoomChange={setZoomLevel}
              disableZoomControl
            />
          </Frame>
        </P.MapPreviewWrapper>

        {alternativeLayout && (
          <P.CoverSpace mapFormat={mapFormat}>
            <P.PreviewControlPanel
              mapFormat={mapFormat}
              isMapMagnifierOpen={isMapMagnifierOpen}
              toggleMapMagnifier={toggleMapMagnifier}
              zoomLevel={zoomLevel}
              changeZoomLevel={setZoomLevel}
              controlLayout={PreviewControlLayout.Fold}
              disableZoomControls
            />
          </P.CoverSpace>
        )}

        <P.Cover coverSrc={coverSrc} width={width} mapFormat={mapFormat} alternativeLayout={alternativeLayout} />
      </P.FoldedMapPreview>
    );
  }
);
