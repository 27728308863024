export enum MAP_TYPES {
  FRAMED = 'FRAMED',
  CANVAS = 'CANVAS',
  PAPER = 'PAPER',
}

export enum FRAMED_MAP {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export enum CANVAS_MAP {
  UNFRAMED = 'UNFRAMED',
  FRAMED = 'FRAMED',
}

export enum PAPER_MAP {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
  LARGE_FLAT = 'LARGE_FLAT',
}

export enum MAP_FRAME_COLOR {
  BLACK = 'BLACK',
  OAK = 'OAK',
}

export enum MAP_LANGUAGE {
  EN = 'EN',
  CY = 'CY',
}

export enum MAP_FORMAT {
  RECTANGLE = 'RECTANGLE',
  SQUARE = 'SQUARE',
}

export enum MAP_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
