import Types from 'Types';

export const getIsCroppingImage = (state: Types.RootState) => state.imageCrop.isCroppingImage;

export const getCrop = (state: Types.RootState) => state.imageCrop.crop;

export const getPixelCrop = (state: Types.RootState) => state.imageCrop.pixelCrop;

export const getIsSavingImage = (state: Types.RootState) => state.imageCrop.isSavingImage;

export const getIsSavingImageError = (state: Types.RootState) => state.imageCrop.isSavingImageError;
