import { RouteStyleType } from 'root/components/Map/utils/types';
import { Scale, SheetSize } from '../map/MapEnums';
import { IMapLocationCoordinates } from '../map/MapParams';

type OriginProviderType = {
    id: string;
    logoUrl: string;
    name: string;
    website: string;
};

type OriginSourceApplicationType = {
    appVersion: string;
    applicationName: string;
    platform: string;
};

type MetadataRatingType = {
    average: number;
    count: number;
};

type MetadataOriginType = {
    createdBy: string;
    owned: boolean;
    provider: OriginProviderType;
    sourceApplication: OriginSourceApplicationType;
};

type MetadataType = {
    createdAt: string;
    description: string;
    lastModifiedBy: string;
    moderationStatus: 'OK';
    modifiedAt: string;
    name: string;
    origin: MetadataOriginType;
    premium: boolean;
    rating: MetadataRatingType;
    slugName: string;
    visibility: 'PUBLIC';
};

type TimePointType = {
    coordinates: [number, number];
    type: 'POINT';
};

export enum RecomendationStatus {
    Warning1 = 'WARNING_1',
    Warning2 = 'WARNING_2',
    Default = 'DEFAULT',
    None = 'NONE',
    Blank = 'Blank',
}

export type ConfigType = {
    sheetSize: SheetSize;
    scale: Scale;
    type: 'FRAMED' | 'FOLDED';
    recommendationStatus: RecomendationStatus;
};

type PathType = {
    start: number;
    end: number;
    type: string;
};

type Characteristics = {
    activity: string;
    difficulty: string;
    distance: number;
    elevationAscent: number;
    equivalentDistance: number;
    fitness: string;
    looped: boolean;
    path: PathType[];
    surfaceTypes: string[];
};

export type FeatureType = {
    geometry: {
        coordinates: [number, number];
        type: 'Point';
    };
    properties: {
        kind: 'WAYPOINT';
        positionInSegment: number;
    };
    type: 'Feature';
};

export type RouteType = {
    bbox: [number, number, number, number];
    characteristics: Characteristics;
    config: ConfigType[];
    endPoint: TimePointType;
    height: number;
    id: string;
    metadata: MetadataType;
    startPoint: TimePointType;
    type: string;
    version: number;
    width: number;
    features: FeatureType[];
};

export type RouteListType = RouteType[];

export type RouteVM = RouteType & {
    bngCoordinates: IMapLocationCoordinates;
    routeType: 'gpx' | 'list';
};

export type RouteListVM = RouteVM[];

export type LineOpacityType = 1 | 0 | 0.1 | 0.2 | 0.3 | 0.4 | 0.5 | 0.6 | 0.7 | 0.8 | 0.9;

export enum LineColorType {
    Black = '#000000',
    Purple = '#453C90',
    Red = '#D30258',
    Green = '#33691F',
    Yellow = '#FFC00A',
    Blue = '#0498CE',
}

export enum LineThicknessType {
    ExtraThin = 1,
    VeryThin = 2,
    Thin = 3,
    Regular = 5,
    Thick = 7,
}

export type RouteForBasketType = Pick<MetadataType, 'name'> & {
    routeId: Pick<RouteType, 'id'>;
    routeType: Pick<RouteVM, 'routeType'>;
    settings: RouteStyleType;
};
