import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeRouteSetting } from 'root/features/route/actions';
import { getRouteSettingOpacity } from 'root/features/route/selectors';
import { LineOpacityType } from 'root/model/route/Route';
import { convertTransparencyToOpacity } from 'root/services/route';

export const useTransparencySlider = () => {
    const activeValue = useSelector(getRouteSettingOpacity);
    const dispatch = useDispatch();

    const valuetext = useCallback(
        (value: number) => {
            return `${value * 100}%`;
        },
        [activeValue]
    );

    const handleChangeValue = (e: React.ChangeEvent<{}>, value: LineOpacityType) => {
        const newValue = convertTransparencyToOpacity(value);

        dispatch(changeRouteSetting({ lineOpacity: newValue }));
    };

    return {
        valuetext,
        handleChangeValue,
        activeValue,
    };
};
