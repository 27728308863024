import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Map as MapBox, Point as MapBoxPoint, GeoJSONSource, RasterSource } from 'mapbox-gl';

import { Scale } from 'root/model/map/MapEnums';
import { CMFA_APP_OS_MAPBOX_TOKEN } from 'root/constants';

import { useForceUpdate, useRefState } from 'root/modules/utils/hooks';
import { calcRotationAngle, calculateBoundingBox, PointConverter } from './helpers';
import { Boundaries, EventsType, MapBoxEventObject, Point, SourceType, ViewType } from './types';
import { createMapStyles } from './styles';
import { getBounding } from 'root/components/Map/utils/utils';

export const POINT_0: Point = { x: 0, y: 0 };

type MapProps = {
    view: ViewType;
    source?: SourceType;
    events?: EventsType;
    bounding?: number;
    proportion?: Point;
    interactive?: boolean;
};

export const useMap = ({
    view: { center, mapApi, zoom },
    source,
    events,
    bounding,
    proportion,
    interactive = true,
}: MapProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useRefState<MapBox | undefined>(void 0);
    const forceUpdate = useForceUpdate();

    const calcRotation = useCallback(
        (mapBox?: MapBox) => void mapBox?.setBearing(360 - calcRotationAngle(mapBox.getCenter())),
        []
    );

    const setBounding = useCallback(
        (mapBox?: MapBox) => {
            if (!center || !bounding || !proportion) return;

            const { leftBottom, rightTop } = calculateBoundingBox(bounding, proportion, center);

            mapBox?.fitBounds(
                [PointConverter.forward(leftBottom).toArray(), PointConverter.forward(rightTop).toArray()],
                {
                    duration: 0,
                }
            );
        },
        [center, bounding, proportion]
    );

    const isCenter = useMemo(() => !!center, [center]);

    useEffect(() => {
        if (!isCenter || !center) return;

        const mapBox = new MapBox({
            container: ref.current as HTMLElement,
            accessToken: CMFA_APP_OS_MAPBOX_TOKEN,
            center: PointConverter.forward(center).toArray(),
            zoom,
            bearingSnap: 0,
            dragRotate: false,
            doubleClickZoom: false,
            touchZoomRotate: false,
            scrollZoom: false,
            style: createMapStyles([mapApi]),
            dragPan: interactive,
            keyboard: false,
        });

        if (source)
            mapBox.on('load', () => {
                mapBox.addSource(source.id, source.source);
                mapBox.addLayer(source.layer);
            });

        setMap(mapBox);
        setBounding(mapBox);
        calcRotation(mapBox);
        forceUpdate();
    }, [isCenter]);

    const setEvents = useCallback(
        (events?: EventsType) => {
            if (!events || !map) return;

            const { onDragEnd, onMoveEnd } = events;

            const eventArray: MapBoxEventObject[] = [
                { type: 'dragend', callback: onDragEnd },
                { type: 'moveend', callback: onMoveEnd },
            ];
            eventArray.forEach(({ type, callback }) => callback && map.on(type, callback));
        },
        [map]
    );

    useEffect(() => setEvents(events), [events]);

    const jumpTo = useCallback(
        (center: Point) => {
            map?.jumpTo({ center: PointConverter.forward(center).toArray() });
            calcRotation(map);
        },
        [map, calcRotation]
    );

    useEffect(() => center && jumpTo(center), [center]);
    useEffect(() => void (!bounding && map?.jumpTo({ zoom })), [zoom]);
    useEffect(() => {
        setBounding(map);
        calcRotation(map);
    }, [bounding, proportion]);
    useEffect(
        () => void (source && (map?.getSource(source.id) as GeoJSONSource)?.setData((source.source as any)?.data)),
        [source?.source]
    );

    useEffect(() => {
        if (!map) return;
        const src = map.getSource('raster-tiles') as RasterSource;
        src.tiles = [mapApi];
        map.triggerRepaint();
    }, [mapApi]);

    useEffect(() => {
        if (!map) return;
        const { dragPan, keyboard } = map;

        if (interactive) [dragPan, keyboard].forEach((action) => action.enable());
        else [dragPan, keyboard].forEach((action) => action.disable());
    }, [interactive]);

    const getCenter = useCallback<() => Point>(() => (map ? PointConverter.backward(map.getCenter()) : POINT_0), [map]);

    const calculateBoundingNarrowPoints = useCallback(
        (mapScale: Scale): Boundaries => {
            if (!map || !proportion) return { p1: POINT_0, p2: POINT_0 };

            const { leftBottom, rightTop } = calculateBoundingBox(getBounding(mapScale), proportion, getCenter());
            return { p1: leftBottom, p2: rightTop };
        },
        [getCenter]
    );

    const resize = useCallback(() => map?.resize(), [map]);

    const getPointFromPixel = useCallback<(pixel: Point) => Point>(
        ({ x, y }) => (map ? PointConverter.backward(map.unproject(new MapBoxPoint(x, y))).point : POINT_0),
        [map]
    );

    return {
        ref,
        map,
        calculateBoundingNarrowPoints,
        getCenter,
        resize,
        setEvents,
        getPointFromPixel,
    };
};
