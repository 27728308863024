import React from 'react';
import { useSelector } from 'react-redux';
import * as classNames from 'classnames';

import { getCreateProductJourneyStep } from 'root/features/createProductJourney/selectors';
import { getProductId } from 'root/features/map/selectors';

import styles from './index.module.scss';
import { PreviewComponent } from './PreviewComponent';

type ProductPreviewProps = {
    isHidden: boolean;
};

export const ProductPreview = ({ isHidden }: ProductPreviewProps) => {
    const createProductJourneyStep = useSelector(getCreateProductJourneyStep);
    const productId = useSelector(getProductId);

    const productPreviewCssClasses = classNames(
        styles['product-preview'],
        {
            [styles['product-preview--hidden']]: isHidden,
        },
        styles['product-preview--product-' + (productId ? productId.replace('_', '-').toLowerCase() : 'none')],
        styles['product-preview--journey-step-' + createProductJourneyStep.replace('/', '').toLowerCase()]
    );

    return (
        <div tour-guide="area_2" className={productPreviewCssClasses}>
            <PreviewComponent createProductJourneyStep={createProductJourneyStep} productId={productId} />
        </div>
    );
};
