import styled, { css } from 'styled-components';

import {
    backgroundImage,
    breakpoint,
    getBoxShadow,
    offset,
    OSFontFamily,
    Rotate3d,
    SourceType,
    WidthType,
} from 'root/modules/styles';
import { AlternativeLayoutType } from '../../types';
import { PreviewControlPanel as PreviewControlPanelComponent } from '../PreviewControlPanel';
import { MapTitles } from '../MapTitles';

export const FlatMapPreviewWrapper = styled.div`
    display: flex;

    ${breakpoint.medium(css`
        flex-direction: column;
    `)}
`;

export const FlatMapPreview = styled.div<Rotate3d>`
    flex-grow: 1;

    height: fit-content;
    position: relative;
    ${({ rotate3d }) =>
        rotate3d &&
        css`
            perspective: 800px;
            left: -20px;
        `}
`;

export const LeftPreviewSide = styled.div<WidthType & SourceType>`
    position: absolute;
    height: 98.65%;
    width: 3.5%;

    ${backgroundImage}
    background-repeat: round;
    background-color: #eaeaea;

    ${({ width }) =>
        css`
            transform: translateX(365%) translateY(0.87%) rotateY(${0.0555 * width - 68.3333}deg)
                translateZ(${0.0233 * width - 12.6667}px);
        `}
`;

export const ProductPreviewWrapper = styled.div<Rotate3d & WidthType>`
    position: relative;
    overflow: hidden;
    ${({ rotate3d, width }) =>
        rotate3d &&
        css`
            transform: rotateY(20deg) translateZ(-${width * 0.09375}px) translateX(16.25%);
        `}
`;

export const TitlesOverlay = styled.div<SourceType>`
    position: absolute;
    top: -10%;
    right: -10%;
    z-index: 1;
    width: 55%;
    height: 61.8%;
    display: flex;

    ${backgroundImage}
    background-color: #ffffff;
    background-position-y: -3.75%;
    background-position-x: calc(100% - 10px);
    background-repeat: no-repeat;
    background-size: 685%;

    border: 1px solid #666;
    border-radius: 50%;
    box-shadow: 0 2px 5px 3px #0000004d;
`;

export const TitleWrapper = styled(MapTitles)<WidthType>`
    position: initial;

    margin-left: calc(0.228125 * 100% - 12.375px);
    margin-top: 43.7%;

    ${({ width }) => css`
        & > h3 {
            font-size: ${width * 0.03125}px;
            line-height: ${width * 0.03625}px;
            height: ${width * 0.03925}px;
        }

        & > h5 {
            font-size: ${width * 0.0175}px;
            line-height: ${width * 0.01875}px;
            height: ${width * 0.02275}px;
        }
    `}
`;

export const FrameLessBackground = styled.div<AlternativeLayoutType>`
    position: absolute;
    top: 1.5%;
    margin-left: 1.25%;
    z-index: -1;

    width: 98%;
    height: 97.5%;

    background-color: #ffffff;
    ${({ alternativeLayout }) => alternativeLayout && getBoxShadow()}
`;

export const TitleWrapperBottom = styled.div<WidthType>`
    position: absolute;
    width: 91%;
    height: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${OSFontFamily}

    ${({ width }) => css`
        & > h3 {
            font-size: ${width * 0.014}px;
            line-height: ${width * 0.018}px;
            height: ${width * 0.018}px;
        }

        & > h5 {
            font-size: ${width * 0.011}px;
            line-height: ${width * 0.016}px;
            height: ${width * 0.016}px;
        }
    `}
`;

export const PreviewControlPanel = styled(PreviewControlPanelComponent)`
    justify-content: flex-start;
    margin-top: 6%;
    margin-left: ${offset.small};

    ${breakpoint.medium(css`
        margin-top: 5%;
        margin-left: 3%;
    `)}

    ${breakpoint.smallMedium(css`
        align-items: flex-start;
    `)}
`;
