import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';

import { ProductPreviewActions, ProductPreviewActionsType, productPreviewActions } from './actions';
import {
  SET_PRODUCT_PREVIEW_IMAGE_URL,
  RESET_PRODUCT_PREVIEW_IMAGE_URL,
  SET_ERROR_LOADING_PRODUCT_PREVIEW_IMAGE_URL,
  GET_PRODUCT_PREVIEW,
} from './constants';

export const productPreviewImageUrlReducer = (state = '', action: ProductPreviewActions) => {
  switch (action.type) {
    case SET_PRODUCT_PREVIEW_IMAGE_URL:
      return action.payload;
    case RESET_PRODUCT_PREVIEW_IMAGE_URL:
      return '';
    default:
      return state;
  }
};

export const productPreviewErrorReducer = (state = false, action: ProductPreviewActions) => {
  switch (action.type) {
    case SET_ERROR_LOADING_PRODUCT_PREVIEW_IMAGE_URL:
      return action.payload;
    case RESET_PRODUCT_PREVIEW_IMAGE_URL:
      return false;
    case GET_PRODUCT_PREVIEW:
      return false;
    default:
      return state;
  }
};

type CenterPointState = {
  url?: string;
  error?: any;
  isPending?: boolean;
};

const INITIAL_STATE: CenterPointState = {
  url: undefined,
  error: undefined,
  isPending: undefined,
};

export const productPreviewCenterPoint = createReducer<CenterPointState, ProductPreviewActionsType>(INITIAL_STATE)
  .handleAction(productPreviewActions.request, (state) => ({
    ...state,
    isPending: true,
  }))
  .handleAction(productPreviewActions.success, (state, { payload }) => ({
    ...state,
    ...payload,
    isPending: false,
    error: undefined,
  }))
  .handleAction(productPreviewActions.error, (state, { payload }) => ({
    ...state,
    ...payload,
    isPending: false,
  }))
  .handleAction(productPreviewActions.clear, () => INITIAL_STATE);

interface IProductPreviewState {
  productPreviewImageUrl: string;
  productPreviewError: boolean;
  productPreviewCenterPoint: CenterPointState;
}

export default combineReducers<IProductPreviewState, ProductPreviewActions | ProductPreviewActionsType>({
  productPreviewImageUrl: productPreviewImageUrlReducer,
  productPreviewError: productPreviewErrorReducer,
  productPreviewCenterPoint,
});
