import * as React from 'react';
import { Slider as MuiSlider } from '@material-ui/core';

import styles from './index.module.scss';

type Props = {
    valueLabelDisplay: 'on' | 'off' | 'auto' | undefined;
    ariaLabel: string;
    ariaValueText: string;
    valueLabelFormat: (value: number) => string;
    min: number;
    max: number;
    step: number;
    onChange: ((event: React.ChangeEvent<{}>, value: number | number[]) => void) | undefined;
    value: number | number[] | undefined;
    startLabel: string;
    endLabel: string;
};

export const Slider = ({
    valueLabelDisplay,
    ariaLabel,
    ariaValueText,
    valueLabelFormat,
    min,
    max,
    step,
    onChange,
    value,
    startLabel,
    endLabel,
}: Props) => {
    return (
        <div className={styles['slider-component']}>
            <MuiSlider
                valueLabelDisplay={valueLabelDisplay}
                aria-label={ariaLabel}
                aria-valuetext={ariaValueText}
                valueLabelFormat={valueLabelFormat}
                min={min}
                max={max}
                step={step}
                onChange={onChange}
                value={value}
            />

            <div className={styles['slider-label']}>
                <div>{startLabel}</div>
                <div>{endLabel}</div>
            </div>
        </div>
    );
};
