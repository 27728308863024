import * as React from 'react';

import { MapType } from 'root/model/map/MapEnums';
import VerticalLine from 'root/components/VerticalLine';
import { UKmap } from 'root/modules/AreaAndStyle/components/UKmap';

import { MapControlPanel } from './StyledMapControlPanel';

import { useMapSelectionContainer, useMapSelectionContainerClassNames } from './hooks';

import { ProductPreviewContainer } from './components/ProductPreview';
import { DetailedViewMap } from './components/DetailedViewMap';

export const MapSelectionContainer = () => {
    const {
        map,
        mapRef,
        zoomRef,
        bounds,
        glassLocation,
        mapVisibleBounds,
        showMagnifyingGlass,
        showResetMap,
        setShowResetMap,
        mapSize,
        mapType,
        mapDimension,
        frameUrl,
        toggleUkMap,
        showUkOverview,
        isGlassDragged,
        isGlassCentered,
        zoomIsHovered,
        onMapDrag,
        onGlassDrag,
        onGlassDragStart,
        zoomHoverStarts,
        zoomHoverEnds,
        onMagnifierOpen,
        onCenterGlassClicked,
        onCenterMapClicked,
        isMoreToolActive,
        isSwitchMapStyleActive,
        onMoreToolsChange,
        onSwitchMapStyle,
    } = useMapSelectionContainer();

    const { containerClassName, verticalLineClassName } = useMapSelectionContainerClassNames(
        showMagnifyingGlass || showResetMap
    );

    return (
        <>
            <div className={containerClassName}>
                <VerticalLine className={verticalLineClassName}>{mapDimension}</VerticalLine>

                <ProductPreviewContainer
                    showMagnifyingGlass={showMagnifyingGlass}
                    showResetMap={showResetMap}
                    isGlassCentered={isGlassCentered}
                    showUkOverview={showUkOverview}
                    map={map}
                    mapRef={mapRef}
                    frameUrl={frameUrl}
                    zoomIsHovered={zoomIsHovered}
                    onMapDrag={onMapDrag}
                >
                    <DetailedViewMap
                        mapSize={mapSize}
                        mapType={mapType as MapType}
                        zoomRef={zoomRef}
                        bounds={bounds}
                        mapVisibleBounds={mapVisibleBounds}
                        glassLocation={glassLocation}
                        isGlassDragged={isGlassDragged}
                        onGlassDrag={onGlassDrag}
                        onGlassDragStart={onGlassDragStart}
                        zoomHoverStarts={zoomHoverStarts}
                        zoomHoverEnds={zoomHoverEnds}
                    />
                </ProductPreviewContainer>

                <MapControlPanel
                    isMagnifierOpen={showMagnifyingGlass}
                    showResetMap={showResetMap}
                    setShowResetMap={setShowResetMap}
                    onMagnifierOpen={onMagnifierOpen}
                    onReturnToCentreClick={onCenterGlassClicked}
                    onSetNewCentreClick={onCenterMapClicked}
                    disableCentreButtons={isGlassCentered}
                    isUkMapOpen={showUkOverview}
                    onUkMapClick={toggleUkMap}
                    isMoreToolActive={isMoreToolActive}
                    isSwitchMapStyleActive={isSwitchMapStyleActive}
                    onMoreToolsChange={onMoreToolsChange}
                    onSwitchMapStyle={onSwitchMapStyle}
                />
            </div>

            <UKmap isOpen={showUkOverview} setIsOpen={toggleUkMap} />
        </>
    );
};
