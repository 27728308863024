import * as React from 'react';
import * as classNames from 'classnames';
import { isEmpty } from 'lodash';

import LoadingIndicator from '../LoadingIndicator';

import styles from './index.module.scss';

export enum ButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  ERROR = 'error',
  LINK = 'link',
}

interface IButtonProps extends IClassNameProp {
  htmlType?: 'button' | 'reset' | 'submit';
  text: string;
  type?: ButtonType;
  isLoading?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  icon?: string;
  Icon?: React.ReactNode;
  title?: string;
}

const Button: React.FunctionComponent<IButtonProps> = ({
  htmlType,
  className,
  text,
  type,
  onClick,
  isLoading,
  isDisabled,
  icon,
  title,
  Icon,
}) => {
  const baseClassName = 'button';
  const buttonCssClasses = classNames(
    styles['button'],
    {
      [styles[`${baseClassName}--primary`]]: type === ButtonType.PRIMARY && !isDisabled,
      [styles[`${baseClassName}--secondary`]]: type === ButtonType.SECONDARY && !isDisabled,
      [styles[`${baseClassName}--error`]]: type === ButtonType.ERROR && !isDisabled,
      [styles[`${baseClassName}--link`]]: type === ButtonType.LINK && !isDisabled,
      [styles[`${baseClassName}--disabled`]]: isDisabled,
      [styles[`${baseClassName}--loading`]]: isLoading,
    },
    className
  );
  const textCssClasses = classNames(styles['button__text'], {
    [styles['button__text--loading']]: isLoading,
  });

  return (
    <button type={htmlType} onClick={onClick} className={buttonCssClasses} title={title}>
      {Icon}
      {!isEmpty(icon) && <img src={icon} alt="" />}
      <p className={textCssClasses}>{text}</p>
      {isLoading && <LoadingIndicator />}
    </button>
  );
};

Button.defaultProps = {
  htmlType: 'button',
  type: ButtonType.PRIMARY,
  isLoading: false,
  isDisabled: false,
  icon: '',
};

export default Button;
