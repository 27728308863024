import { combineReducers } from 'redux';
import { Size } from 'root/model/map/MapEnums';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import {
    SET_COVER_URL,
    SET_PREDEFINED_IMAGE,
    SET_IMAGE_SIZE,
    SET_IS_PREDEFINED_IMAGE,
    SET_NEW_COVER_URL,
} from './constants';

export type CoverActions = ActionType<typeof actions>;

export const coverUrlReducer = (state = '', action: CoverActions) => {
    switch (action.type) {
        case SET_COVER_URL:
            return action.payload;
        default:
            return state;
    }
};

export const newCoverUrlReducer = (state = '', action: CoverActions) => {
    switch (action.type) {
        case SET_NEW_COVER_URL:
            return action.payload;
        default:
            return state;
    }
};

export const setPredefinedImageIndexReducer = (state = 0, action: CoverActions) => {
    switch (action.type) {
        case SET_PREDEFINED_IMAGE:
            return action.payload;
        default:
            return state;
    }
};

export const setImageSize = (state = Size.NONE, action: CoverActions) => {
    switch (action.type) {
        case SET_IMAGE_SIZE:
            return action.payload;
        default:
            return state;
    }
};

export const setIsPredefinedImage = (state = true, action: CoverActions) => {
    switch (action.type) {
        case SET_IS_PREDEFINED_IMAGE:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers({
    coverUrl: coverUrlReducer,
    newCoverUrl: newCoverUrlReducer,
    predefinedImageIndex: setPredefinedImageIndexReducer,
    imageSize: setImageSize,
    isPredefinedImage: setIsPredefinedImage,
});
