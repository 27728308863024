import { ILocation } from 'root/model/location/Location';

export const getLocationNames = (locations: ILocation[]) => {
    return locations.map((location, index) => {
        const label = getLocationLabel(location);

        return {
            label,
            value: label,
        };
    });
};

export const getLocationLabel = (location: ILocation) => {
    let result = location.name ? `${location.name}` : '';
    result = location.districtBorough ? `${result}, ${location.districtBorough}` : result;
    result = location.postcodeDistrict ? `${result}, ${location.postcodeDistrict}` : result;

    return result;
};
