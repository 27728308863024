import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MapStyle, MapType, ProductId, SelectedMapStyle, Size } from 'src/model/map/MapEnums';

import { stylesOptions } from './constants';
import StyleItem from './components/StyleItem';
import styles from './switchstylecontent.module.scss';
import { getMapSize, getMapStyle, getMapType, getProductId, getSelectedMapStyle } from 'root/features/map/selectors';
import { setMapStyleParams } from 'root/features/map/actions';
import { AutoZoomItem } from './components/AutoZoomItem';

export const SwitchStyleContent = () => {
    const selectedMapStyle = useSelector(getSelectedMapStyle) as SelectedMapStyle;
    const mapStyle = useSelector(getMapStyle) as MapStyle;
    const mapSize = useSelector(getMapSize) as Size;
    const mapType = useSelector(getMapType);
    const productId = useSelector(getProductId);
    const dispatch = useDispatch();

    const availableStyles = stylesOptions.filter(
        () =>
            !(
                mapType === MapType.PAPER &&
                [Size.SMALL, Size.LARGE].includes(mapSize) &&
                productId === ProductId.FOLDED_MAP
            )
    );
    if (!availableStyles.map((style) => style.mapScale).includes(mapStyle)) {
        dispatch(setMapStyleParams({ mapStyle: availableStyles[0].mapScale }));
    }

    const handleSetMapStyle = (selectedMapStyle: SelectedMapStyle) => {
        dispatch(setMapStyleParams({ selectedMapStyle }));
    };

    return (
        <div className={styles['map-style-list']}>
            <AutoZoomItem active={selectedMapStyle === SelectedMapStyle.AUTO} setActive={handleSetMapStyle} />

            {availableStyles.map((style) => (
                <StyleItem
                    {...style}
                    mapStyle={style.mapScale}
                    key={style.mapScale}
                    active={style.active === selectedMapStyle}
                    isDisabled={false}
                    setActive={() => handleSetMapStyle(style.active)}
                />
            ))}
        </div>
    );
};
