import { Framing, MapType, ProductId, Size } from 'root/model/map/MapEnums';
import { IMapOptionsParams, IMapStyleParams } from 'root/model/map/MapParams';

export const isMapTypeSelected = (mapStyleParams: IMapStyleParams) =>
  mapStyleParams.mapType !== undefined && mapStyleParams.mapType !== MapType.NONE;

export const isProductSeleted = (optionsParams: IMapOptionsParams) =>
  optionsParams.productId !== undefined &&
  optionsParams.productId !== ProductId.NONE &&
  optionsParams.size !== undefined &&
  optionsParams.size !== Size.NONE;

export const isFramingSelected = (optionsParams: IMapOptionsParams) =>
  (optionsParams.framings && optionsParams.framings.includes(Framing.NONE)) ||
  (optionsParams.framing && optionsParams.framing !== Framing.NONE);
