import * as React from 'react';
import { useSelector } from 'react-redux';

import JourneyButton from 'root/components/JourneyButton';
import { canProceedToNextStep as canProceedToNextStepSelector } from 'root/features/createProductJourney/selectors';

import styles from './index.module.scss';

export interface INavigationBottomBarContentProps {
  confirmButtonText: string;
  setDisplayValidation: React.Dispatch<React.SetStateAction<boolean>>;
  canGoBack?: boolean;
}

const NavigationBottomBarContent: React.FunctionComponent<INavigationBottomBarContentProps> = ({
  confirmButtonText,
  canGoBack,
  setDisplayValidation,
}: INavigationBottomBarContentProps) => {
  const canProceedToNextStep = useSelector(canProceedToNextStepSelector);

  return (
    <>
      <JourneyButton
        text={confirmButtonText}
        next={true}
        className={styles['navigation-bottom-bar-content__button-forth']}
        isDisabled={!canProceedToNextStep}
        setDisplayValidation={setDisplayValidation}
      />
      {canGoBack && (
        <JourneyButton text="BACK" next={false} className={styles['navigation-bottom-bar-content__button-back']} />
      )}
    </>
  );
};

export default NavigationBottomBarContent;
