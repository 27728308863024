import { action } from 'typesafe-actions';
import { push } from 'connected-react-router';

import {
    CreateProductJourneyStep,
    CreateProductJourneyAction,
} from 'root/model/createProductJourney/CreateProductJourney';

import {
    SET_CREATE_PRODUCT_JOURNEY_STEP,
    DISPATCH_JOURNEY_ACTION,
    SEND_ORDER,
    INIT_SEND_ORDER,
    FINISH_SEND_ORDER,
    SET_IS_PRODUCT_PREVIEW_OVERLAY_OPENED,
    SET_IS_ENTRY_HISTORY_POINT_MOUNTED,
} from './constants';

export const setCreateProductJourneyStep = (state: CreateProductJourneyStep) =>
    action(SET_CREATE_PRODUCT_JOURNEY_STEP, state);

export const dispatchJourneyAction = (journeyAction: CreateProductJourneyAction) =>
    action(DISPATCH_JOURNEY_ACTION, journeyAction);

export const sendOrder = () => action(SEND_ORDER);

export const initSendOrder = () => action(INIT_SEND_ORDER);

export const finishSendOrder = () => action(FINISH_SEND_ORDER);

export const setProductPreviewOverlayOpened = (isOpened: boolean) =>
    action(SET_IS_PRODUCT_PREVIEW_OVERLAY_OPENED, isOpened);

export const redirectPage = (path: string) => push(path);

export const setEntryHistoryPointMounted = (flag: boolean) => action(SET_IS_ENTRY_HISTORY_POINT_MOUNTED, flag);
