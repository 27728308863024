import { useSelector } from 'react-redux';
import { getFraming, getMapSize, getMapType, getMaterial, getName, getProductId } from 'root/features/map/selectors';
import { Framing, MapType, Material, ProductId, Size } from 'root/model/map/MapEnums';

import canvasAndFramed from 'root/assets/img/productPreviews/canvasAndFramed.png';
import paper from 'root/assets/img/productPreviews/paper.png';

import canvasAndFramedA2Black from 'root/assets/img/productPreviews/canvasAndFramedA2Black.jpg';
import all from 'root/assets/img/productPreviews/all.png';
import canvasAndFramedA2Wood from 'root/assets/img/productPreviews/canvasAndFramedA2Wood.jpg';
import canvasAndFramedA3Black from 'root/assets/img/productPreviews/canvasAndFramedA3Black.jpg';
import canvasAndFramedA3Wood from 'root/assets/img/productPreviews/canvasAndFramedA3Wood.jpg';
import canvasAndFramedLargeFramedBlack from 'root/assets/img/productPreviews/canvasAndFramedLargeFramedBlack.jpg';
import canvasAndFramedLargeFramedWood from 'root/assets/img/productPreviews/canvasAndFramedLargeFramedWood.jpg';
import canvasAndFramedLargeCanvasFramedBlack from 'root/assets/img/productPreviews/canvasAndFramedLargeCanvasFramedBlack.jpg';
import canvasAndFramedLargeCanvasFramedOak from 'root/assets/img/productPreviews/canvasAndFramedLargeCanvasFramedOak.jpg';
import paperFoldedLarge from 'root/assets/img/productPreviews/paperFoldedLarge.jpg';
import paperFlat from 'root/assets/img/productPreviews/paperFlat.jpg';
import paperFoldedSmall from 'root/assets/img/productPreviews/paperFoldedSmall.jpg';
import canvasAndFramedLargeCanvas from 'root/assets/img/productPreviews/canvasAndFramedLargeCanvas.jpg';

export const usePreviewFileName = () => {
  const type = useSelector(getMapType);
  const size = useSelector(getMapSize);
  const framing = useSelector(getFraming);
  const material = useSelector(getMaterial);
  const name = useSelector(getName);
  const productId = useSelector(getProductId);

  const checkIfNoProductSelected = (!framing || framing === Framing.NONE) && (!size || size === Size.NONE);
  const checkIfProductWasSelectedWithoutFrame = (!framing || framing === Framing.NONE) && size && size !== Size.NONE;
  const checkIfProductWasSelectedWithFrame = framing && framing !== Framing.NONE && size && size !== Size.NONE;
  let previewFileName = all;

  if (checkIfNoProductSelected) {
    // no product selected
    if (type === MapType.CANVAS_AND_FRAMED) {
      previewFileName = canvasAndFramed;
    } else if (type === MapType.PAPER) {
      previewFileName = paper;
    }
  }

  if (checkIfProductWasSelectedWithoutFrame) {
    // the product was selected without frame
    if (type === MapType.PAPER) {
      if (size === Size.SMALL) {
        previewFileName = paperFoldedSmall;
      } else if (size === Size.LARGE && productId === ProductId.FLAT_MAP) {
        previewFileName = paperFlat;
      } else if (size === Size.LARGE && productId === ProductId.FOLDED_MAP) {
        previewFileName = paperFoldedLarge;
      }
    } else {
      if (size === Size.SMALL) {
        previewFileName = canvasAndFramedA3Wood;
      } else if (size === Size.MEDIUM) {
        previewFileName = canvasAndFramedA2Wood;
      } else if (size === Size.LARGE) {
        if (material === Material.PAPER) {
          previewFileName = canvasAndFramedLargeFramedWood;
        } else if (material === Material.CANVAS) {
          if (name === 'Large Canvas Framed') {
            previewFileName = canvasAndFramedLargeCanvasFramedOak;
          } else if (name === 'Large Canvas') {
            previewFileName = canvasAndFramedLargeCanvas;
          }
        }
      }
    }
  }

  if (checkIfProductWasSelectedWithFrame) {
    // the product was selected with frame
    if (type === MapType.CANVAS_AND_FRAMED) {
      if (material === Material.PAPER) {
        if (size === Size.SMALL) {
          previewFileName = framing === Framing.LIGHT_OAK ? canvasAndFramedA3Wood : canvasAndFramedA3Black;
        } else if (size === Size.MEDIUM) {
          previewFileName = framing === Framing.LIGHT_OAK ? canvasAndFramedA2Wood : canvasAndFramedA2Black;
        } else if (size === Size.LARGE) {
          previewFileName =
            framing === Framing.LIGHT_OAK ? canvasAndFramedLargeFramedWood : canvasAndFramedLargeFramedBlack;
        }
      } else if (material === Material.CANVAS) {
        if (name === 'Large Canvas Framed') {
          previewFileName =
            framing === Framing.LIGHT_OAK ? canvasAndFramedLargeCanvasFramedOak : canvasAndFramedLargeCanvasFramedBlack;
        } else if (name === 'Large Canvas') {
          previewFileName = canvasAndFramedLargeCanvas;
        }
      }
    }
  }

  return previewFileName;
};
