import React from 'react';

import { ReactComponent as RouteIcon } from './assets/route-icon.svg';
import styles from './index.module.scss';

const CoverDropzoneRouteContent = () => {
    const baseClassName = 'cover-dropzone-route-content';
    const backgroundCss = styles[`${baseClassName}__background`];
    const coverDropzoneContentCss = styles[baseClassName];
    const containerCss = styles[`${baseClassName}__container`];
    const titleCss = styles[`${baseClassName}__title`];
    const descriptionCss = styles[`${baseClassName}__description`];

    return (
        <div className={backgroundCss}>
            <div className={coverDropzoneContentCss}>
                <div className={containerCss}>
                    <RouteIcon />

                    <p className={titleCss}>Drop your GPX file here or click to browse</p>

                    <p className={descriptionCss}>Alternatively, why not explore our list of curated route overlays.</p>
                </div>
            </div>
        </div>
    );
};

export default CoverDropzoneRouteContent;
