import * as React from 'react';
import * as classNames from 'classnames';

import { IMapStyleParams } from 'root/model/map/MapParams';
import styles from './index.module.scss';

interface IMapFrameProps extends IMapStyleParams, IClassNameProp {
  isCustomiseStep: boolean;
  frameUrl: string;
}

const MapFrame: React.FunctionComponent<IMapFrameProps> = ({
  children,
  isCustomiseStep,
  frameUrl,
}) => {
  const mapFrameCssClasses = classNames(styles['map-frame'], {
    [styles['map-frame--customise']]: isCustomiseStep,
  });

  return (
    <div
      className={mapFrameCssClasses}
      style={{ backgroundImage: `url(${frameUrl})` }}
    >
      {children}
    </div>
  );
};

export default MapFrame;
