import styled, { css } from 'styled-components';

import { IconButton } from 'root/modules/components';
import {
  offset,
  AnimationControls,
  breakpoint,
  CssReturnType,
  horizontalAnimation,
  verticalAnimation,
} from 'root/modules/styles';

export enum PreviewControlLayout {
  Flat = 'Flat',
  Compact = 'Compact',
  Fold = 'Fold',
}

export type PreviewControlLayoutType = {
  controlLayout: PreviewControlLayout;
};

const layout: Record<PreviewControlLayout, CssReturnType> = {
  Fold: css<AnimationControls>`
    ${horizontalAnimation}

    ${breakpoint.medium(css<AnimationControls>`
      flex-direction: row;
    `)}

    ${breakpoint.smallMedium(css`
      flex-direction: column;
    `)}
    
    ${breakpoint.small(css<AnimationControls>`
      ${verticalAnimation}
    `)}
  `,

  Flat: css<AnimationControls>`
    ${verticalAnimation}

    ${breakpoint.medium(css<AnimationControls>`
      flex-direction: row;
      ${horizontalAnimation}
    `)}

    ${breakpoint.small(css`
      flex-direction: column;
    `)}
  `,

  Compact: css<AnimationControls>`
    ${verticalAnimation}

    ${breakpoint.medium(css<AnimationControls>`
      flex-direction: row;
      ${horizontalAnimation}
    `)}
  `,
};

export const PreviewControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${breakpoint.medium(css`
    flex-direction: row;
    align-items: flex-start;

    margin-right: ${offset.xSmall};
  `)}
`;

export const Button = styled(IconButton)`
  width: 75px;
  white-space: normal;

  margin: ${offset.xSmall} 0;

  ${breakpoint.medium(css`
    margin: 0 ${offset.xSmall};
  `)}

  ${breakpoint.smallMedium(css`
    margin: ${offset.xSmall};
  `)}
`;

export const AnimationWrapper = styled.div`
  overflow: hidden;
`;

export const ButtonsWrapper = styled.div<AnimationControls & PreviewControlLayoutType>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;

  ${({ controlLayout }) => layout[controlLayout]}
`;
