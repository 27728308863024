import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';

import {
    SET_CREATE_PRODUCT_JOURNEY_STEP,
    INIT_SEND_ORDER,
    FINISH_SEND_ORDER,
    SET_IS_PRODUCT_PREVIEW_OVERLAY_OPENED,
    SET_IS_ENTRY_HISTORY_POINT_MOUNTED,
} from './constants';
import * as actions from './actions';

export interface ICreateProductJourneyState {
    readonly journeyStep: CreateProductJourneyStep;
    readonly isSendOrderInProgress: boolean;
    readonly isProductPreviewOverlayOpened: boolean;
    readonly isEntryHistoryPointMounted: boolean;
}

export type CreateProductJourneyActionType = ActionType<typeof actions>;

export const journeyStepReducer = (
    state = CreateProductJourneyStep.PRODUCT_TYPE,
    action: CreateProductJourneyActionType
) => {
    switch (action.type) {
        case SET_CREATE_PRODUCT_JOURNEY_STEP:
            return action.payload;
        default:
            return state;
    }
};

export const isSendOrderInProgressReducer = (state = false, action: CreateProductJourneyActionType) => {
    switch (action.type) {
        case INIT_SEND_ORDER:
            return true;
        case FINISH_SEND_ORDER:
            return false;
        default:
            return state;
    }
};

export const isProductPreviewOverlayOpenedReducer = (state = false, action: CreateProductJourneyActionType) => {
    switch (action.type) {
        case SET_IS_PRODUCT_PREVIEW_OVERLAY_OPENED:
            return action.payload;
        default:
            return state;
    }
};

export const isEntryHistoryPointMountedReducer = (state = false, action: CreateProductJourneyActionType) => {
    switch (action.type) {
        case SET_IS_ENTRY_HISTORY_POINT_MOUNTED:
            return action.payload;
        default:
            return state;
    }
};

export default combineReducers<ICreateProductJourneyState, CreateProductJourneyActionType>({
    journeyStep: journeyStepReducer,
    isSendOrderInProgress: isSendOrderInProgressReducer,
    isProductPreviewOverlayOpened: isProductPreviewOverlayOpenedReducer,
    isEntryHistoryPointMounted: isEntryHistoryPointMountedReducer,
});
