import { LineColorType, LineOpacityType, LineThicknessType } from 'root/model/route/Route';

export enum SOURCE {
    singleRouteLine = 'SINGLE_ROUTE_LINE',
    singleRouteEndIcons = 'SINGLE_ROUTE_END_ICONS',
    clusters = 'CLUSTERS',
    selectedWaypoints = 'SELECTED_WAYPOINTS',
    keyWaypoints = 'KEY_WAYPOINTS',
    activity = 'ACTIVITY',
    createRouteStartIcon = 'CREATE_ROUTE_START_ICON',
}

export enum LAYER {
    routePins = 'ROUTE_PINS',
    clusters = 'CLUSTERS',
    clustersCount = 'CLUSTER_COUNT',
    singleRouteLine = 'SINGLE_ROUTE_LINE',
    singleRouteEndIcons = 'SINGLE_ROUTE_END_ICONS',
    selectedWaypoints = 'SELECTED_WAYPOINTS',
    keyWaypoints = 'KEY_WAYPOINTS',
    keyWaypointsIndex = 'KEY_WAYPOINTS_INDEX',
    routeDirections = 'ROUTE_DIRECTIONS',
    activity = 'ACTIVITY',
    createRouteStartIcon = 'CREATE_ROUTE_START_ICON',
}

export enum DRAWER_LAYER {
    vertexCold = 'VERTEX_VOLD',
    vertexHot = 'VERTEX_HOT',
}

export type RouteStyleType = {
    lineColourHex: LineColorType;
    lineThickness: LineThicknessType;
    lineOpacity: LineOpacityType;
};
