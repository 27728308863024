import { Epic } from 'redux-observable';
import { filter, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { isOfType } from 'typesafe-actions';

import * as tourGuideActions from './actions';
import Types from 'Types';
import { RESET_STATE } from 'root/appConfig/store/constants';

const hideTourGuide: Epic<
  Types.RootAction,
  Types.RootAction,
  Types.RootState,
  Types.Services
> = action$ =>
  action$.pipe(
    filter(isOfType(RESET_STATE)),
    switchMap(action => {
      return of(tourGuideActions.showGuideTooltip(false));
    })
  );

export default hideTourGuide;
