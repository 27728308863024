import * as React from 'react';
import { useDispatch } from 'react-redux';

import { createProductJourneyActions } from 'root/features/createProductJourney';
import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';

import styles from './index.module.scss';
import { hideUserInfoModal } from 'root/features/userInfoModal/actions';
import { RoutePath } from 'root/appConfig/routes/enums';
import { setCreateProductJourneyStep } from 'root/features/createProductJourney/actions';
import { createBrowserHistory } from 'history';

const DefaultLocationWarning = () => {
    const dispatch = useDispatch();
    const history = createBrowserHistory();

    const goToAreaAndStyle = (event: React.MouseEvent) => {
        dispatch(hideUserInfoModal());
        dispatch(createProductJourneyActions.redirectPage(RoutePath.ROOT));
        dispatch(setCreateProductJourneyStep(CreateProductJourneyStep.AREA_AND_STYLE));
        history.push('/areaAndStyle/');
    };

    return (
        <div className={styles['default-location-warning']}>
            Your map has been created using our default location of Central London. If this is as intended please
            continue, if not please <a onClick={goToAreaAndStyle}>return to the area and style tab</a> to reset your
            location.
        </div>
    );
};

export default DefaultLocationWarning;
