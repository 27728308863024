export const INITIALIZE_MAP_PARAMS_FROM_SEARCH_PARAMS = 'map/INITIALIZE_MAP_PARAMS_FROM_SEARCH_PARAMS';

export const INITIALIZE_MAP_PARAMS_FROM_PRODUCT_OPTIONS = 'map/INITIALIZE_MAP_PARAMS_FROM_PRODUCT_OPTIONS';

export const SET_MAP_LOCATION_PARAMS = 'map/SET_MAP_LOCATION_PARAMS';

export const RESET_MAP_LOCATION_PARAMS = 'map/RESET_MAP_LOCATION_PARAMS';

export const SET_MAP_STYLE_PARAMS = 'map/SET_MAP_STYLE_PARAMS';

export const SET_MAP_STYLES = 'map/SET_MAP_STYLES';

export const GET_MAP_STYLES = 'map/GET_MAP_STYLES';

export const RESET_MAP_STYLES = 'map/RESET_MAP_STYLES';

export const SET_MAP_OPTIONS_PARAMS = 'map/SET_MAP_OPTIONS_PARAMS';

export const SET_PRODUCT = 'map/SET_PRODUCT';

export const SET_PRODUCT_PARAMS = 'map/SET_PRODUCT_PARAMS';

export const SET_AREA_AND_STYLE_TAB = 'map/SET_AREA_AND_STYLE_SELECTED_TAB';

export const TOGGLE_MINI_MAP = 'map/TOGGLE_MINI_MAP';

export const SET_AREA_AND_STYLE_COORDINATES = 'map/SET_AREA_AND_STYLE_COORDINATES';

export const SET_CENTRE_LOCATION_COORDINATES = 'map/SET_CENTRE_LOCATION_COORDINATES';

export const SET_ROUTE_COORDINATES = 'map/SET_ROUTE_COORDINATES';

export const INCREMENT_ZOOM = 'map/INCREMENT_ZOOM';

export const DECREMENT_ZOOM = 'map/DECREMENT_ZOOM';

export const CHANGE_SELECTED_MAP_STYLE = 'map/CHANGE_SELECTED_MAP_STYLE';
