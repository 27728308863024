import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { SET_QUANTITY } from './constants';

export type CheckoutOptionsActionType = ActionType<typeof actions>;

export const quantityReducer = (
  state = 1,
  action: CheckoutOptionsActionType
) => {
  switch (action.type) {
    case SET_QUANTITY:
      return action.payload > 0 ? action.payload : 1;
    default:
      return state;
  }
};

export default combineReducers({
  quantity: quantityReducer,
});
