import React, { Dispatch, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getMediaQuery } from 'root/features/mediaQuery/selectors';
import { useTranslation } from 'root/modules/utils/locales';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { MobilePopper, Popper } from 'root/components/Popper';

import { ReactComponent as DotsIcon } from '../../../../../../assets/dots.svg';
import { MapIconButton } from '../../MapIconButton';
import { MoreToolsContent } from './components/MoreToolsContent';
import { thinnerThan, widerThanOrEqual } from 'root/services/mediaQuery';
import { MapControlPanelProps } from '../../../MapControlPanel';

export type SwitchStyleModalProps = Pick<
    MapControlPanelProps,
    'onMoreToolsChange' | 'onUkMapClick' | 'isUkMapOpen' | 'isMoreToolActive' | 'isMagnifierOpen' | 'onMagnifierOpen'
> & {
    setTemporaryHideMinimap: Dispatch<React.SetStateAction<boolean>>;
};

export const MoreToolsModal = ({
    isMoreToolActive,
    onMoreToolsChange,
    isMagnifierOpen,
    onMagnifierOpen,
    isUkMapOpen,
    onUkMapClick,
    setTemporaryHideMinimap,
}: SwitchStyleModalProps) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [t] = useTranslation();
    const mediaQuery = useSelector(getMediaQuery);

    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

    const handleMoreToolClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        onMoreToolsChange();
    };

    return (
        <>
            <MapIconButton
                ref={buttonRef}
                Icon={DotsIcon}
                title="More tools"
                onClick={handleMoreToolClick}
                isActive={isMoreToolActive}
                isDisabled={false}
            />

            {widerThanOrEqual(mediaQuery, MediaQuery.MEDIUM) && (
                <Popper
                    anchorEl={buttonRef.current}
                    placement="left-start"
                    id={isMoreToolActive ? 'more tools modal' : undefined}
                    open={isMoreToolActive}
                    onClose={handleMoreToolClick}
                    ariaLabel={t(({ ariaLabel }) => ariaLabel.moreToolsModal)}
                    title={t(({ modal }) => modal.moreToolsTitle)}
                >
                    <MoreToolsContent
                        isUkMapOpen={isUkMapOpen}
                        onMagnifierOpen={onMagnifierOpen}
                        onUkMapClick={onUkMapClick}
                        setTemporaryHideMinimap={setTemporaryHideMinimap}
                    />
                </Popper>
            )}

            {thinnerThan(mediaQuery, MediaQuery.MEDIUM) && (
                <MobilePopper
                    ariaLabel={t(({ ariaLabel }) => ariaLabel.moreToolsModal)}
                    title={t(({ modal }) => modal.moreToolsTitle)}
                    open={isMoreToolActive}
                    onClose={handleMoreToolClick}
                    id={isMoreToolActive ? 'more tools modal' : undefined}
                >
                    <MoreToolsContent
                        isUkMapOpen={isUkMapOpen}
                        onMagnifierOpen={onMagnifierOpen}
                        onUkMapClick={onUkMapClick}
                        setTemporaryHideMinimap={setTemporaryHideMinimap}
                    />
                </MobilePopper>
            )}
        </>
    );
};
