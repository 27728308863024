import { BBox } from '@turf/turf';
import proj4 from 'proj4';
import { IMapLocationCoordinates } from 'root/model/map/MapParams';

export enum PROJECTIONS {
    EPSG_3857 = 'EPSG_3857',
    EPSG_4326 = 'EPSG_4326',
    EPSG_7405 = 'EPSG_7405',
    EPSG_27700 = 'EPSG_27700',
}

const projectionsDefinitions = {
    [PROJECTIONS.EPSG_3857]:
        '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs',
    [PROJECTIONS.EPSG_4326]: '+proj=longlat +datum=WGS84 +no_defs',
    [PROJECTIONS.EPSG_7405]:
        '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +vunits=m +no_defs',
    [PROJECTIONS.EPSG_27700]:
        '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
};

export const convertProjection = (
    currentProjection: PROJECTIONS,
    destinationProjection: PROJECTIONS,
    dataToTransform: IMapLocationCoordinates
): IMapLocationCoordinates => {
    const newProjection = proj4(
        projectionsDefinitions[currentProjection],
        projectionsDefinitions[destinationProjection],
        [dataToTransform.x, dataToTransform.y]
    );
    return {
        x: newProjection[0],
        y: newProjection[1],
    } as IMapLocationCoordinates;
};

const padNumber = (numberVal: number, padLength: number) => {
    let n = numberVal.toString();
    while (n.length < padLength) {
        n = `0${n}`;
    }
    return n;
};

export const calculateNationalGridLetters = (easting: number, northing: number, precision?: number) => {
    const digits = precision || 6;

    let e = easting;
    let n = northing;

    const e100k = Math.floor(e / 100000.0);
    const n100k = Math.floor(n / 100000.0);

    if (e100k < 0 || e100k > 6 || n100k < 0 || n100k > 12) {
        return `${easting.toFixed(0)} ${northing.toFixed(0)}`;
    }

    let l1 = 19 - n100k - ((19 - n100k) % 5) + Math.floor((e100k + 10) / 5);
    let l2 = (((19 - n100k) * 5) % 25) + (e100k % 5);

    if (l1 > 7) {
        l1 = l1 + 1;
    }

    if (l2 > 7) {
        l2 = l2 + 1;
    }

    const letters = String.fromCharCode(l1 + 'A'.charCodeAt(0), l2 + 'A'.charCodeAt(0));

    e = Math.floor((e % 100000) / Math.pow(10, 5 - digits / 2));
    n = Math.floor((n % 100000) / Math.pow(10, 5 - digits / 2));

    return `${letters} ${padNumber(e, digits / 2)} ${padNumber(n, digits / 2)}`;
};

export const convertLatLongToBritishNationGrid = (bbox: BBox) => {
    const minLongitude = bbox[0];
    const maxLongitude = bbox[2];
    const minLatitude = bbox[1];
    const maxLatitude = bbox[3];

    // calculate center
    const centerLatitude = (minLatitude + maxLatitude) / 2;
    const centerLongitude = (minLongitude + maxLongitude) / 2;

    return convertProjection(PROJECTIONS.EPSG_4326, PROJECTIONS.EPSG_27700, {
        x: centerLongitude,
        y: centerLatitude,
    });
};
