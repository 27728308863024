import * as React from 'react';
import { connect } from 'react-redux';

import * as Types from 'Types';
import { MapStyle, Size } from 'root/model/map/MapEnums';
import { mapSelectors } from 'features/map';
import { MAP_SCALE_TO_BACKGROUND } from './constants';
import styles from './index.module.scss';

interface IFoldedCoverTopBackgroundProps {
    mapScale: MapStyle;
    mapSize: Size;
}

const FoldedCoverTopBackground: React.FunctionComponent<IFoldedCoverTopBackgroundProps> = ({ mapScale, mapSize }) => {
    const topBackground = MAP_SCALE_TO_BACKGROUND[mapSize][mapScale];

    return <img className={styles['folded-cover-top']} src={topBackground} alt="map cover top image" />;
};

export default connect((state: Types.RootState) => ({
    mapScale: mapSelectors.getMapStyle(state) as MapStyle,
    mapSize: mapSelectors.getMapSize(state) as Size,
}))(FoldedCoverTopBackground);
