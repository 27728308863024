import * as React from 'react';
import styles from './index.module.scss';


const BreakHoldingPage: React.FunctionComponent = () => {
  return (
    <div className={styles['break-holding-page']}>
      <span className={styles['break-holding-page__text']}>
        Our custom made maps are currently unavailable due to planned maintenance.
        We are making essential changes to our store so we can offer you a wider range of custom made products.
        Please check back shortly.
      </span>
    </div>
  )
}

export default BreakHoldingPage;