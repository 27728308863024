import classNames from 'classnames';
import * as React from 'react';
import { useCallback } from 'react';
import ReactSelect from 'react-select';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import { formatGroupLabel } from './constants';

import styles from './index.module.scss';
import { OptionType, SelectProps } from './model';

export const SearchSelect = ({
    options,
    placeholder,
    isClearable,
    isLoading,
    name,
    onChange,
    value,
    defaultValue,
    emptyMessage,
    changeInputFunc,
    onLocalizeMeClick,
    isLocalizeMeButtonDisabled,
}: SelectProps) => {
    const [showMenu, setShowMenu] = React.useState<boolean>(false);

    const filterOption = useCallback((option: FilterOptionOption<OptionType>) => {
        if (option.data.className === 'hidden') {
            return false;
        }

        return true;
    }, []);

    const disableOnFocusParent = useCallback((event: React.FocusEvent<HTMLElement>) => event.stopPropagation(), []);

    return (
        <div className={styles['select-component']}>
            <ReactSelect
                className="select-search"
                classNamePrefix="select"
                value={value}
                classNames={{
                    control: (state) => (state.isFocused ? 'control-focused' : ''),
                    option: (state) => {
                        const className = [];

                        if (state.isSelected) {
                            className.push('option-selected');
                        }

                        if (state.isFocused) {
                            className.push('option-focused');
                        }

                        return className.join(' ');
                    },
                }}
                options={options}
                placeholder={placeholder}
                isClearable={isClearable}
                name={name}
                onChange={onChange}
                noOptionsMessage={() => emptyMessage}
                formatGroupLabel={formatGroupLabel}
                components={{
                    DropdownIndicator: () => null,
                    LoadingIndicator: () => null,
                }}
                filterOption={filterOption}
                menuIsOpen={showMenu}
                isLoading={isLoading}
                loadingMessage={() => 'loading'}
                onFocus={() => {
                    if (defaultValue && defaultValue.label.length > 2) {
                        setShowMenu(true);
                    }
                }}
                onInputChange={(newValue: string) => {
                    if (newValue.length > 2 && changeInputFunc) {
                        setShowMenu(true);
                        changeInputFunc(newValue);
                    } else {
                        setShowMenu(false);
                    }
                }}
            />

            <div className={styles['search-input']}>
                <button
                    type="button"
                    className={classNames(styles['search-input__button'], styles['search-input__button--localize-me'])}
                    onClick={onLocalizeMeClick}
                    disabled={isLocalizeMeButtonDisabled}
                    onFocus={disableOnFocusParent}
                >
                    <i
                        className={classNames(styles['search-input__icon'], styles['search-input__icon--localize-me'])}
                    />
                </button>
            </div>
        </div>
    );
};
