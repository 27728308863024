import { ajax } from 'rxjs/ajax';
import { throwError, Observable, Observer } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { MAP_EXTENT } from 'root/constants';
import { ILocation } from 'root/model/location/Location';
import { IMapLocationCoordinates } from 'root/model/map/MapParams';
import {
  API_BASE_URL,
  API_KEY,
  MAX_RESULTS,
  OUTSIDE_EXTENT_ERROR_MESSAGE,
  GET_CURRENT_POSITION_ERROR_MESSAGE,
  UNSUPPORTED_BROWSER_ERROR_MESSAGE,
  SUPPORTED_COUNTRIES,
} from './constants';
import mapboxgl from 'mapbox-gl';
import { convertProjection, PROJECTIONS } from '../projection/projection';

export const findLocationsFromAPI = (
  query: string,
  isNearest: boolean
): Observable<ILocation[]> => {
  const supportedCountresFilter = SUPPORTED_COUNTRIES.map(
    (country) => `countries=${country}`
  ).join('&');

  let apiQuery = '';

  if (isNearest) {
    query = query.replace(', ', ',').replace(' ,', ',');
    apiQuery =
      `${API_BASE_URL}?` +
      `point=${query}&` +
      `crs=epsg:27700&` +
      `radius=1000&` +
      `${supportedCountresFilter}`;
  } else {
    apiQuery =
      `${API_BASE_URL}?` +
      `searchPhrase=${query}&` +
      `top=${MAX_RESULTS}&` +
      `${supportedCountresFilter}`;
  }

  return ajax
    .getJSON<any>(apiQuery, { apikey: API_KEY })
    .pipe(
      map(transformFindLocationsResult),
      catchError((error) => {
        return throwError('Error getting data!');
      })
    );
};

export const getUserGeoLocationCoordinates = (): Observable<
  IMapLocationCoordinates
> => {
  return Observable.create((observer: Observer<IMapLocationCoordinates>) => {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          const projectionCoordinates = getProjectionCoordinates(
            position.coords.longitude,
            position.coords.latitude
          );

          if (!extentContainsCoordinate(projectionCoordinates)) {
            observer.error(OUTSIDE_EXTENT_ERROR_MESSAGE);
          }

          observer.next({
            x: projectionCoordinates.x,
            y: projectionCoordinates.y,
          });
          observer.complete();
        },
        (error) => {
          observer.error(GET_CURRENT_POSITION_ERROR_MESSAGE);
        }
      );
    } else {
      observer.error(UNSUPPORTED_BROWSER_ERROR_MESSAGE);
    }
  });
};

const transformFindLocationsResult = (data: any): ILocation[] => {
  if (!data.value) {
    return [];
  }

  return data.value.map((entry: any) => {
    const eastingNorthing = getProjectionCoordinates(
      entry.location.coordinates[1],
      entry.location.coordinates[0]
    );

    return {
      name: entry.name,
      districtBorough: entry.address?.localAuthority,
      postcodeDistrict: entry.address?.postCode,
      geometryX: eastingNorthing.x,
      geometryY: eastingNorthing.y,
    };
  });
};

export const getProjectionCoordinates = (
  longitude: number,
  latitude: number
) => {
  return convertProjection(PROJECTIONS.EPSG_4326, PROJECTIONS.EPSG_27700, {
    x: longitude,
    y: latitude,
  });
};

export const extentContainsCoordinate = (
  coordinates: IMapLocationCoordinates
) => {
  const southWest = convertProjection(
    PROJECTIONS.EPSG_27700,
    PROJECTIONS.EPSG_4326,
    { x: MAP_EXTENT[0], y: MAP_EXTENT[1] }
  );
  const northEast = convertProjection(
    PROJECTIONS.EPSG_27700,
    PROJECTIONS.EPSG_4326,
    { x: MAP_EXTENT[2], y: MAP_EXTENT[3] }
  );

  const bounds = new mapboxgl.LngLatBounds(
    [southWest.x, southWest.y],
    [northEast.x, northEast.y]
  );

  const longLatCoords = convertProjection(
    PROJECTIONS.EPSG_27700,
    PROJECTIONS.EPSG_4326,
    coordinates
  );

  return bounds.contains([longLatCoords.x, longLatCoords.y]);
};
