import styled from 'styled-components';

export const WarningMessage = styled.p`
  color: #f36721;
  font-weight: bold;
  margin: 10px 0;
`;

export const MessageLink = styled.a`
  cursor: pointer;
`;
