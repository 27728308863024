import * as React from 'react';

import { SliderArrowDirection } from 'root/model/customisation/SliderArrowEnums';
import SliderArrow from './components/SliderArrow';

export const SLIDER_SETTINGS = {
  dots: false,
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 3,
  variableWidth: true,
  nextArrow: <SliderArrow direction={SliderArrowDirection.NEXT} />,
  prevArrow: <SliderArrow direction={SliderArrowDirection.PREV} />,
};
