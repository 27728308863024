export const FIND_LOCATIONS = 'location/FIND_LOCATIONS';

export const SET_LOCATIONS = 'location/SET_LOCATIONS';

export const SUBMIT_LOCATION_FORM = 'location/SUBMIT_LOCATION_FORM';

export const LOCALIZE_USER = 'location/LOCALIZE_USER';

export const SET_GEO_LOCALIZATION_ENABLED =
  'location/SET_GEO_LOCALIZATION_ENABLED';
