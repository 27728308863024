import styled, { css } from 'styled-components';

import { borderRadius, boxShadow, CssReturnType, fontSize, offset, px } from 'root/modules/styles';
import { Button as ButtonComponent, ButtonSize, ButtonSizeType, ButtonStyle, ButtonStyleType } from '../Button';

export enum BorderStyle {
    Square = 'Square',
    Circle = 'Circle',
}

export type BorderStyleType = {
    borderStyle: BorderStyle;
};

type DisabledType = { disabled?: boolean };

const getLabelTextStyles = (isClickable: boolean): Record<ButtonStyle, CssReturnType> => ({
    [ButtonStyle.Primary]: css`
        font-weight: 600;

        color: #453c90;

        ${isClickable &&
        css`
            &:hover:enabled,
            &:active {
                color: #292074;
            }
        `}
    `,
    [ButtonStyle.Secondary]: css``,
    [ButtonStyle.Third]: css`
        font-weight: 600;

        color: #453c90;

        ${isClickable &&
        css`
            &:hover:enabled,
            &:active {
                color: #292074;
            }
        `}
    `,
    [ButtonStyle.Accept]: css``,
    [ButtonStyle.Ghost]: css``,
    [ButtonStyle.Error]: css``,
});

const labelFontSize: Record<ButtonSize, CssReturnType> = {
    [ButtonSize.small]: fontSize.xSmall,
    [ButtonSize.medium]: fontSize.small,
    [ButtonSize.large]: fontSize.medium,
};

type LabelStylesProps = ButtonStyleType & ButtonSizeType & DisabledType;

const getLabelStyles = (isClickable: boolean) => css<LabelStylesProps>`
    ${({ buttonSize }) => labelFontSize[buttonSize]}
    text-align: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: fit-content;

    ${({ buttonStyle }) => getLabelTextStyles(isClickable)[buttonStyle]}
    ${({ disabled }) =>
        disabled &&
        css`
            color: #666666;
            ${isClickable && `cursor: not-allowed;`}
        `}
    
  & > button {
        margin-bottom: ${offset.xSmall};
    }
`;

const getWrapperStyles = css<LabelStylesProps>`
    ${getLabelStyles(false)}
`;

type HasLabelType = {
    hasLabel?: boolean;
};

export const Wrapper = styled.div<ButtonStyleType & DisabledType & HasLabelType>`
    ${({ hasLabel }) => hasLabel && getWrapperStyles}
`;

export const Label = styled.label<ButtonStyleType & DisabledType>`
    ${getLabelStyles(true)}
`;

const buttonPadding = (size: ButtonSize) =>
    px(() => {
        const BASE_PADDING = parseInt(offset.small);
        switch (size) {
            case ButtonSize.small:
                return BASE_PADDING - 2;
            case ButtonSize.medium:
                return BASE_PADDING;
            case ButtonSize.large:
                return BASE_PADDING + 2;
        }
    });

export const Button = styled(ButtonComponent)<BorderStyleType & ButtonSizeType>`
    ${({ borderStyle, buttonSize }) =>
        borderStyle === BorderStyle.Circle &&
        css`
            ${borderRadius.round}

            padding: ${buttonPadding(buttonSize)};

            ${boxShadow.light}
            &:hover:enabled,
      &:active {
                ${boxShadow.medium}
            }
        `}
`;

export const Text = styled.span<ButtonSizeType>`
    margin-left: ${({ buttonSize }) => {
        switch (buttonSize) {
            case ButtonSize.small:
                return '2px';
            case ButtonSize.medium:
                return '3px';
            case ButtonSize.large:
                return offset.xSmall;
        }
    }};
`;
