import * as React from 'react';
import { TabPanel } from 'react-tabs';
import * as classNames from 'classnames';

import BottomBar from 'root/components/BottomBar';
import { AreaAndStyleTab } from '../AreaAndStyleTab/AreaAndStyleTab';
// import AreaAndStyleTab from '../AreaAndStyleTab';
import ProductTypeTab from '../ProductTypeTab';
import CustomiseTab from '../CustomiseTab';

import styles from './index.module.scss';
import { SIDEBAR_TAB_CONTENT_ID } from 'root/constants';
import { useContext } from 'react';
import { ValidationContext, ValidationContextType } from '../../../../providers/validation';

type TabContentProps = {
    isCustomiseStep: boolean;
};

export const TabContent = ({ isCustomiseStep }: TabContentProps) => {
    const { displayValidation, setDisplayValidation } = useContext(ValidationContext) as ValidationContextType;

    const tabPanelCssClasses = styles['sidebar-tabs__tab-panel'];
    const tabPanelContentCssClasses = styles['sidebar-tabs__tab-panel-content'];
    const tabPanelSelectedCssClasses = styles['sidebar-tabs__tab-panel--selected'];
    const tabPanelWrapperClasses = classNames(styles['sidebar-tabs__tab-panel-wrapper'], {
        [styles['sidebar-tabs__tab-panel-wrapper--customize-tab']]: isCustomiseStep,
    });

    return (
        <div className={tabPanelWrapperClasses}>
            <TabPanel className={tabPanelCssClasses} selectedClassName={tabPanelSelectedCssClasses}>
                <div className={tabPanelContentCssClasses} id={SIDEBAR_TAB_CONTENT_ID}>
                    <ProductTypeTab key="product-type-content" />
                    <BottomBar setDisplayValidation={setDisplayValidation} />
                </div>
            </TabPanel>

            <TabPanel className={tabPanelCssClasses} selectedClassName={tabPanelSelectedCssClasses}>
                <div className={tabPanelContentCssClasses} id={SIDEBAR_TAB_CONTENT_ID}>
                    <AreaAndStyleTab key="location-content" />
                    <BottomBar setDisplayValidation={setDisplayValidation} />
                </div>
            </TabPanel>

            <TabPanel className={tabPanelCssClasses} selectedClassName={tabPanelSelectedCssClasses}>
                <div className={tabPanelContentCssClasses} id={SIDEBAR_TAB_CONTENT_ID}>
                    <CustomiseTab key="customise-content" />
                    <BottomBar setDisplayValidation={setDisplayValidation} />
                </div>
            </TabPanel>
        </div>
    );
};
