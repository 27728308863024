import { FileRejection } from 'root/model/fileRejection/FIleRejection';
import { Size } from 'root/model/map/MapEnums';
import { action } from 'typesafe-actions';

import {
    SET_COVER_URL,
    HANDLE_COVER_UPLOAD,
    GET_PREDEFINED_IMAGE,
    SET_PREDEFINED_IMAGE,
    SET_IMAGE_SIZE,
    GET_IS_PREDEFINED_IMAGE,
    SET_IS_PREDEFINED_IMAGE,
    SET_NEW_COVER_URL,
} from './constants';

export const setCoverUrl = (coverUrl: string) => action(SET_COVER_URL, coverUrl);

export const setNewCoverUrl = (coverUrl: string) => action(SET_NEW_COVER_URL, coverUrl);

export const handleCoverUpload = (accepted: File[], rejected: FileRejection[], meta: IPromiseExecutorArguments) =>
    action(HANDLE_COVER_UPLOAD, { accepted, rejected }, meta);

export const getFullPredefinedImage = (url: string) => action(GET_PREDEFINED_IMAGE, url);

export const setPredefinedImageIndex = (index: number) => action(SET_PREDEFINED_IMAGE, index);

export const setImageSize = (size: Size) => action(SET_IMAGE_SIZE, size);

export const getIsPredefinedImage = () => action(GET_IS_PREDEFINED_IMAGE);

export const setIsPredefinedImage = (isPredefinedImage: boolean) => action(SET_IS_PREDEFINED_IMAGE, isPredefinedImage);
