import React from 'react';
import classNames from 'classnames';

import styles from './autozoomitem.module.scss';
import { SelectedMapStyle } from 'root/model/map/MapEnums';

type AutoZoomItemType = {
    active: boolean;
    setActive: (selectedMapStyle: SelectedMapStyle) => void;
};

export const AutoZoomItem = ({ active, setActive }: AutoZoomItemType) => {
    const containerClassName = classNames(styles['auto-zoom__container'], {
        [styles['auto-zoom__container--active']]: active,
    });

    return (
        <div className={containerClassName} onClick={() => setActive(SelectedMapStyle.AUTO)}>
            <div className={styles['auto-zoom__title']}>Auto Zoom</div>
            <div className={styles['auto-zoom__description']}>Map style changes with zoom level.</div>
        </div>
    );
};
