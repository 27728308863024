import React, { RefObject, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import { AreaAndStyleSelectedTab, IMapLocationCoordinates } from 'root/model/map/MapParams';
import { MapType, Scale, Size } from 'root/model/map/MapEnums';
import { Map } from 'root/components/Map';
import { getMainMapBounding, getProportion } from 'root/components/Map/utils/utils';
import { createMapStyles } from 'root/modules/utils/functions';
import {
    getAreaAndStyleSelectedTab,
    getMapLocationCoordinates,
    getMapScale,
    getMapSize,
    getMapType,
} from 'root/features/map/selectors';
import { getRouteSetting, getSelectedRoute } from 'root/features/route/selectors';
import { getMapStyles, getMapStylesApi } from 'root/features/mapStyles/selectors';

import styles from './index.module.scss';
import { getMapViewOptions } from '../../utils';

type PreviewMapProps = {
    onDrag: (event: any) => void;
    mapRef: RefObject<Map>;
};

// without connect, the map doesn't move
export const PreviewMap = connect()(({ mapRef, onDrag }: PreviewMapProps) => {
    const mapLocationCoordinates = useSelector(getMapLocationCoordinates) as IMapLocationCoordinates;
    const areaAndStyleSelectedTab = useSelector(getAreaAndStyleSelectedTab);
    const mapSize = useSelector(getMapSize) as Size;
    const mapType = useSelector(getMapType) as MapType;
    const mapScale = useSelector(getMapScale) as Scale;
    const mapStyles = useSelector(getMapStyles);
    const mapStylesApi = useSelector(getMapStylesApi(mapScale));
    const route = useSelector(getSelectedRoute);
    const routeSetting = useSelector(getRouteSetting);

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.resize();
            mapRef.current.forceUpdate();
        }
    }, []);

    return (
        <Map
            className={styles['preview-map']}
            view={getMapViewOptions(mapLocationCoordinates, 14, mapStylesApi)}
            mapStyles={createMapStyles(mapStyles, mapScale)}
            events={{
                onPointerDrag: onDrag,
            }}
            ref={mapRef}
            bounding={getMainMapBounding(mapScale)}
            proportion={getProportion(mapSize, mapType)}
            interactive={true}
            mapScale={mapScale}
            route={route ? route : undefined}
            routeSetting={routeSetting}
            isRouteActive={areaAndStyleSelectedTab === AreaAndStyleSelectedTab.Route}
        />
    );
});
