import React, { FC, HTMLAttributes, useCallback, SetStateAction, Dispatch } from 'react';
import { ZoomIn } from '@styled-icons/material/ZoomIn';
import { Close } from '@styled-icons/material/Close';
import { Add } from '@styled-icons/material/Add';
import { Remove } from '@styled-icons/material/Remove';
import { Title } from '@styled-icons/material/Title';

import { useTranslation } from 'root/modules/utils/locales';
import { ButtonSize } from 'root/modules/components';
import { useAnimationProps } from 'root/modules/styles';
import { MagnifierZoom } from '../MapPreview';
import * as P from './parts';

type PreviewControlPanelProps = HTMLAttributes<HTMLDivElement> &
  P.PreviewControlLayoutType & {
    isMapMagnifierOpen: boolean;
    toggleMapMagnifier: () => void;
    showToggleTitle?: boolean;
    isTitleMagnifierOpen?: boolean;
    toggleTitleMagnifier?: () => void;
    zoomLevel: MagnifierZoom;
    changeZoomLevel: Dispatch<SetStateAction<MagnifierZoom>>;
    disableZoomControls?: boolean;
  };

export const PreviewControlPanel: FC<PreviewControlPanelProps> = ({
  toggleMapMagnifier,
  isMapMagnifierOpen,
  showToggleTitle,
  isTitleMagnifierOpen,
  toggleTitleMagnifier,
  zoomLevel,
  changeZoomLevel,
  disableZoomControls,
  controlLayout,
  ...props
}) => {
  const [t] = useTranslation();

  const animationProps = useAnimationProps(isMapMagnifierOpen);

  const incrementZoom = useCallback(() => changeZoomLevel((prev) => prev + 1), [changeZoomLevel]);
  const decrementZoom = useCallback(() => changeZoomLevel((prev) => prev - 1), [changeZoomLevel]);

  return (
    <P.PreviewControlPanel {...props}>
      {showToggleTitle && (
        <P.Button
          Icon={isTitleMagnifierOpen ? Close : Title}
          onClick={toggleTitleMagnifier}
          label={t(({ button }) => (isTitleMagnifierOpen ? button.hideTitle : button.showTitle))}
          buttonSize={ButtonSize.large}
        />
      )}

      <P.Button
        Icon={isMapMagnifierOpen ? Close : ZoomIn}
        onClick={toggleMapMagnifier}
        label={t(({ button }) => (isMapMagnifierOpen ? button.hideZoom : button.zoomMap))}
        buttonSize={ButtonSize.large}
      />

      {!disableZoomControls && (
        <P.AnimationWrapper style={{ visibility: disableZoomControls ? 'hidden' : undefined }}>
          <P.ButtonsWrapper controlLayout={controlLayout} {...animationProps}>
            <P.Button
              Icon={Add}
              label={t(({ button }) => button.zoomIn)}
              onClick={incrementZoom}
              disabled={zoomLevel === MagnifierZoom.large}
              buttonSize={ButtonSize.large}
            />
            <P.Button
              Icon={Remove}
              label={t(({ button }) => button.zoomOut)}
              onClick={decrementZoom}
              disabled={zoomLevel === MagnifierZoom.small}
              buttonSize={ButtonSize.large}
            />
          </P.ButtonsWrapper>
        </P.AnimationWrapper>
      )}
    </P.PreviewControlPanel>
  );
};
