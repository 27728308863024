import { action } from 'typesafe-actions';
import { Crop, PixelCrop } from 'react-image-crop';

import {
    SET_IMAGE_CROP,
    RESET_IMAGE_CROP,
    SET_CROPPED_COVER,
    HANDLE_SIZE_CHANGE,
    SEND_IMAGE_TO_SERVER,
    SET_CROP_FOR_NEW_IMAGE,
    ROTATE_IMAGE,
    SET_CROP_RATIO,
    SET_SAVING_IMAGE_ERROR,
} from './constants';

export const setImageCrop = (crop: Crop, pixelCrop: PixelCrop) => action(SET_IMAGE_CROP, { crop, pixelCrop });

export const resetImageCrop = () => action(RESET_IMAGE_CROP);

export const setCropForNewImage = () => action(SET_CROP_FOR_NEW_IMAGE);

export const handleCropSelection = (coverUrl: string, pixelCrop: PixelCrop) =>
    action(SET_CROPPED_COVER, { coverUrl, pixelCrop });

export const handleSizeChange = (crop: Crop, pixelCrop: PixelCrop) => action(HANDLE_SIZE_CHANGE, { crop, pixelCrop });

export const sendImageToServer = (data: string) => action(SEND_IMAGE_TO_SERVER, data);

export const rotateImage = () => action(ROTATE_IMAGE);

export const setCropRatio = (cropRatio: number) => action(SET_CROP_RATIO, { cropRatio });

export const setSavingImageError = (isError: boolean) => action(SET_SAVING_IMAGE_ERROR, { isError });
