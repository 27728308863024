import { of, throwError } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { find, isEqual } from 'lodash';

import {
    IProductOption,
    IProductCache,
    IProductCacheOptions,
    IProductCacheDataType,
    IProductCacheCollectionGroup,
    IProductCacheOption,
    IProductCacheComponentGroup,
} from 'root/model/product/ProductOption';
import { ProductId, Material, IProduct, Size } from 'root/model/map/MapEnums';
import {
    INTERFACE_FEED,
    PRODUCT_FEED,
    CACHE_GROUP,
    GET_PRODUCT_OPTIONS_ERROR_MESSAGE,
    GET_PRODUCT_SKU_ERROR_MESSAGE,
    GET_CACHE_DATA_ERROR_MESSAGE,
} from './constants';

// tslint:disable-next-line:no-var-requires
const data = require('./productCache.json');

export const getProductOptionsFromAPI = () =>
    of(data).pipe(
        switchMap((productCache: IProductCache) => {
            return getCacheData(productCache.data, {
                type: INTERFACE_FEED,
            });
        }),
        switchMap((cacheData: IProductCacheDataType) => {
            return getCacheData(cacheData.components, {
                group: CACHE_GROUP,
            });
        }),
        map((cacheGroup: IProductCacheComponentGroup) => {
            return {
                options: cacheGroup.products.map(
                    (option: any) =>
                        ({
                            dimension: option.dimension,
                            activeIconSrc: option.activeIconSrc,
                            size: option.size,
                            iconSrc: option.iconSrc,
                            name: option.name,
                            price: Number(option.price),
                            // @ts-ignore
                            productId: ProductId[option.productId],
                            // @ts-ignore
                            material: Material[option.material],
                            framings: option.framings,
                            shortDescription: option.name,
                            currency: option.currency,
                            delivery: option.delivery,
                            type: option.type,
                        } as IProductOption)
                ),
                predefinedCoverImages: cacheGroup.predefinedCoverImages,
            };
        }),
        catchError((error) => throwError(GET_PRODUCT_OPTIONS_ERROR_MESSAGE))
    );

export const getProductSKU = (productCacheOptions: Partial<IProductCacheOptions>) =>
    of(data).pipe(
        switchMap((productCache: IProductCache) => {
            return getCacheData(productCache.data, {
                type: PRODUCT_FEED,
            });
        }),
        switchMap((cacheData: IProductCacheDataType) => {
            return getCacheData(cacheData.collection, {
                group: CACHE_GROUP,
            });
        }),
        switchMap((cacheGroup: IProductCacheCollectionGroup) => {
            return getCacheData(cacheGroup.products, {
                options: productCacheOptions,
            });
        }),
        map((product: IProductCacheOption) => ({
            sku: product.sku,
            price: product.price,
        })),
        catchError((error) => {
            if (error) {
                return throwError(error);
            }
            return throwError(GET_PRODUCT_SKU_ERROR_MESSAGE);
        })
    );

const getCacheData = (cacheComponent: any, predicate: any) => {
    const cacheData = find(cacheComponent, predicate);

    if (!cacheData) {
        return throwError(GET_CACHE_DATA_ERROR_MESSAGE);
    }

    return of(cacheData);
};

export const isActiveOption = (toCheck: IProduct, activePattern: IProduct) => {
    return isEqual(toCheck, activePattern);
};

export const isAnyActive = (toChecks: IProduct[], activePattern: IProduct) => {
    return toChecks.some((toCheck) => isActiveOption(toCheck, activePattern));
};

export const isCurrentOptionInLoaded = (currentOption: IProduct, loadedOptions: IProductOption[]) => {
    return (
        currentOption !==
            ({} as {
                productId: ProductId | undefined;
                material: Material | undefined;
                size: Size | undefined;
            }) &&
        loadedOptions.find(
            (productOption) =>
                currentOption.productId === productOption.productId &&
                currentOption.material === productOption.material &&
                currentOption.size === productOption.size
        ) !== undefined
    );
};
