import mapFrameLargeOak from 'src/modules/assets/outerFrames/mapFrame.png';
import mapFrameLargeBlack from 'src/modules/assets/outerFrames/mapFrameBlack.png';
import mapFrameMediumOak from 'src/modules/assets/outerFrames/mapFrameMedium.png';
import mapFrameMediumBlack from 'src/modules/assets/outerFrames/mapFrameMediumBlack.png';
import mapFrameSmallOak from 'src/modules/assets/outerFrames/mapFrameSmall.png';
import mapFrameSmallBlack from 'src/modules/assets/outerFrames/mapFrameSmallBlack.png';
import mapLeftWood from 'src/modules/assets/outerFrames/mapLeftWood.png';
import mapLeftWoodBlack from 'src/modules/assets/outerFrames/mapLeftWoodBlack.png';
import { MAP_FRAME_COLOR, MAP_SIZE } from '../../types';

export const WOOD_MAP_FRAME = {
  [MAP_SIZE.SMALL]: {
    [MAP_FRAME_COLOR.OAK]: mapFrameSmallOak,
    [MAP_FRAME_COLOR.BLACK]: mapFrameSmallBlack,
  },
  [MAP_SIZE.MEDIUM]: {
    [MAP_FRAME_COLOR.OAK]: mapFrameMediumOak,
    [MAP_FRAME_COLOR.BLACK]: mapFrameMediumBlack,
  },
  [MAP_SIZE.LARGE]: {
    [MAP_FRAME_COLOR.OAK]: mapFrameLargeOak,
    [MAP_FRAME_COLOR.BLACK]: mapFrameLargeBlack,
  },
} as const;

export const LEFT_SIDE_FRAME = {
  [MAP_FRAME_COLOR.OAK]: mapLeftWood,
  [MAP_FRAME_COLOR.BLACK]: mapLeftWoodBlack,
};
