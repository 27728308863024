export const NationalGridToEastingNorthing = (nationalGrid: string) => {
  let e;
  let n;

  nationalGrid = nationalGrid.toUpperCase();

  const bits = nationalGrid.split(' ');
  nationalGrid = '';
  for (const val of bits) {
    nationalGrid += val;
  }

  let c = nationalGrid.charAt(0);
  if (c === 'S') {
    e = 0;
    n = 0;
  } else if (c === 'T') {
    e = 500000;
    n = 0;
  } else if (c === 'N') {
    n = 500000;
    e = 0;
  } else if (c === 'O') {
    n = 500000;
    e = 500000;
  } else if (c === 'H') {
    n = 1000000;
    e = 0;
  } else {
    return null;
  }

  c = nationalGrid.charAt(1);
  if (c === 'I') {
    return null;
  }

  let c2 = null;
  c2 = nationalGrid.charCodeAt(1) - 65;
  if (c2 > 8) {
    c2 -= 1;
  }
  e += (c2 % 5) * 100000;
  n += (4 - Math.floor(c2 / 5)) * 100000;

  c = nationalGrid.substr(2);
  if (c.length % 2 === 1) {
    return null;
  }
  if (c.length > 10) {
    return null;
  }

  let s = c.substr(0, c.length / 2);
  while (s.length < 5) {
    s += '0';
  }
  e += parseInt(s, 10);
  if (isNaN(e)) {
    return null;
  }

  s = c.substr(c.length / 2);
  while (s.length < 5) {
    s += '0';
  }
  n += parseInt(s, 10);
  if (isNaN(n)) {
    return null;
  }

  return e.toString() + ',' + n.toString();
};
