import { VectorSource } from 'mapbox-gl';
import { Scale } from 'root/model/map/MapEnums';
import { getMapStylePrefix } from 'root/modules/utils/functions';
import * as Types from 'Types';

export const getMapStyles = (state: Types.RootState) => state.mapStyles;

export const getMapStylesApi = (scale: Scale) => (state: Types.RootState) => {
    const prefix = getMapStylePrefix(scale, 'source');

    const sources = state.mapStyles?.sources?.[prefix] as VectorSource;

    if (!sources || !sources.tiles) {
        console.error('map styles does not exist');
        return '';
    }

    return sources.tiles![0];
};
