import { throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, map } from 'rxjs/operators';
import { round } from 'lodash';

import { MapSizeToApiSize, ProductIdToApiType, PRODUCT_PREVIEW_API_URL } from 'root/services/productPreview/constants';
import { IMapLocationCoordinates } from 'root/model/map/MapParams';
import { Scale, Size, ProductId } from 'root/model/map/MapEnums';
import { RouteForBasketType } from 'root/model/route/Route';

export const getProductPreview = (
    coordinates: IMapLocationCoordinates,
    mapScale: Scale,
    resolution: number,
    mapSize: Size,
    productId: ProductId,
    zoom?: number,
    route?: RouteForBasketType
) => {
    const x = round(coordinates.x);
    const y = round(coordinates.y);

    // @ts-ignore
    const size = MapSizeToApiSize[mapSize];
    // @ts-ignore
    const type = ProductIdToApiType[productId];

    const routeSpecification =
        route &&
        route.settings &&
        `&routeId=${route.routeId}&lineThickness=${route.settings.lineThickness}&lineOpacity=${
            route.settings.lineOpacity
        }&lineColourHex=${route.settings.lineColourHex.replace('#', '')}`;

    return ajax({
        url: `${PRODUCT_PREVIEW_API_URL}?easting=${x}&northing=${y}&scale=${mapScale}&resolution=${resolution}&size=${size}&type=${type}&zoom=${
            zoom ?? 0
        }${routeSpecification ?? ''}`,
        method: 'GET',
        crossDomain: true,
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
        responseType: 'blob',
    }).pipe(
        map(transformProductPreviewResult),
        catchError((error) => throwError('Error getting preview!'))
    );
};

const transformProductPreviewResult = (data: any) => {
    if (!data.response) {
        return '';
    }

    return data.response;
};
