import { ActionType } from 'typesafe-actions';
import * as actions from './actions';
import {
  SHOW_TOUR_GUIDE_TOOLTIP,
  SWITCH_SINGLE_TOOLTIP_MODE,
} from 'features/tourGuide/constants';

export type TourGuideActions = ActionType<typeof actions>;

interface ITourGuideState {
  isTourGuideVisible: boolean;
  isSingleTooltipMode: boolean;
}

const initialState: ITourGuideState = {
  isTourGuideVisible: false,
  isSingleTooltipMode: true,
};

export const tourGuideReducer = (
  state = initialState,
  action: TourGuideActions
) => {
  switch (action.type) {
    case SHOW_TOUR_GUIDE_TOOLTIP:
      return { ...state, isTourGuideVisible: action.payload };
    case SWITCH_SINGLE_TOOLTIP_MODE:
      return { ...state, isSingleTooltipMode: action.payload };
    default:
      return state;
  }
};

export default tourGuideReducer;
