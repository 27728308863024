export const SET_CREATE_PRODUCT_JOURNEY_STEP = 'createProductJourney/SET_CREATE_PRODUCT_JOURNEY_STEP';

export const DISPATCH_JOURNEY_ACTION = 'createProductJourney/DISPATCH_JOURNEY_ACTION';

export const SEND_ORDER = 'createProductJourney/SEND_ORDER';

export const INIT_SEND_ORDER = 'createProductJourney/INIT_SEND_ORDER';

export const FINISH_SEND_ORDER = 'createProductJourney/FINISH_SEND_ORDER';

export const SET_IS_PRODUCT_PREVIEW_OVERLAY_OPENED = 'createProductJourney/SET_IS_PRODUCT_PREVIEW_OVERLAY_OPENED';

export const SET_IS_ENTRY_HISTORY_POINT_MOUNTED = 'createProductJourney/SET_IS_ENTRY_HISTORY_POINT_MOUNTED';
