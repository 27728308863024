import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { timer } from 'rxjs';
import { useSelector } from 'react-redux';

import { MilisTime } from 'root/modules/utils/constants';
import { usePrevious } from 'root/modules/utils/hooks';
import { useTranslation } from 'root/modules/utils/locales';
import { useTooltip, PopupOn } from 'root/modules/components';
import * as P from './parts';
import {
    LARGE_ZOOM,
    MAGNIFIER_MAX_OPTIMAL_HEIGHT,
    MAGNIFIER_MIN_HEIGHT,
    MEDIUM_ZOOM,
    SMALL_FOLDED_ZOOM,
    SMALL_FRAMED_ZOOM,
} from './constants';
import { getMapSize, getMapType } from 'root/features/map/selectors';
import { MapType, Size } from 'root/model/map/MapEnums';

export type MapMagnifierProps = P.DisableZoomControl & {
    zoom: P.MagnifierZoom;
    onZoomChange: Dispatch<SetStateAction<P.MagnifierZoom>>;
};

type MapMagnifierOwnProps = MapMagnifierProps & P.ZoomBaseType & P.EnlargeType;

export const MapMagnifier: FC<MapMagnifierOwnProps> = ({
    children,
    zoom,
    onZoomChange,
    zoomBase,
    enlarge,
    disableZoomControl,
}) => {
    const [touchInProgress, setTouchInProgress] = useState(false);
    const [zoomDirection, setZoomDirection] = useState<P.ZoomDirection>(
        zoom === P.MagnifierZoom.large ? P.ZoomDirection.Out : P.ZoomDirection.In
    );
    const prevZoom = usePrevious(zoom);
    const ref = useRef<HTMLDivElement>(null);
    const [t] = useTranslation();

    const mapSize = useSelector(getMapSize);
    const mapType = useSelector(getMapType);

    const { setIsOpen: setIsTooltipOpen, triggerProps, Tooltip } = useTooltip<HTMLDivElement>({
        onEvent: PopupOn.Hover,
        closeOnDocumentClick: true,
    });

    const getZoomDirection = useCallback((prev: P.MagnifierZoom, current: P.MagnifierZoom) => {
        if (current === P.MagnifierZoom.large) return P.ZoomDirection.Out;
        if (current === P.MagnifierZoom.small) return P.ZoomDirection.In;

        if (prev === P.MagnifierZoom.small) return P.ZoomDirection.In;
        if (prev === P.MagnifierZoom.large) return P.ZoomDirection.Out;

        return P.ZoomDirection.In;
    }, []);

    useEffect(() => {
        setZoomDirection(getZoomDirection(prevZoom, zoom));
    }, [zoom]);

    useEffect(() => {
        const subscription = timer(MilisTime.xShort).subscribe(() => setIsTooltipOpen(true));

        return () => subscription.unsubscribe();
    }, []);

    const handleClick = useCallback(() => {
        if (touchInProgress || disableZoomControl) return setTouchInProgress(false);
        onZoomChange((prev) => (zoomDirection === P.ZoomDirection.In ? prev + 1 : prev - 1));
    }, [onZoomChange, zoomDirection, touchInProgress]);

    const handleTouchStart = useCallback(() => {
        setTouchInProgress(true);
        setIsTooltipOpen((prev) => !prev);
    }, []);

    const tooltipContent = useMemo(
        () => (
            <>
                {t(({ productPreview }) => productPreview.magnifier.text1)}
                <b>{t(({ productPreview }) => productPreview.magnifier.textBold)}</b>
                {t(({ productPreview }) => productPreview.magnifier.text2)}
            </>
        ),
        [t]
    );

    const calculateZoomForMagnifier = () => {
        let zoom = LARGE_ZOOM;

        switch (mapSize) {
            case Size.SMALL:
                if (mapType === MapType.CANVAS_AND_FRAMED) {
                    zoom = SMALL_FRAMED_ZOOM;
                } else if (mapType === MapType.PAPER) {
                    zoom = SMALL_FOLDED_ZOOM;
                }
                break;

            case Size.MEDIUM:
                zoom = MEDIUM_ZOOM;
                break;

            case Size.LARGE:
                zoom = LARGE_ZOOM;
                break;

            default:
                zoom = LARGE_ZOOM;
                break;
        }

        return zoom;
    };

    const refHeight = ref.current?.getBoundingClientRect().height as number;

    return (
        <P.MagnifierWrapper>
            <P.MagnifierContainer
                ref={ref}
                width={refHeight > MAGNIFIER_MIN_HEIGHT ? MAGNIFIER_MAX_OPTIMAL_HEIGHT : refHeight}
            >
                <P.AspectRatioHolder />
                <Tooltip content={tooltipContent}>
                    <P.Magnifier
                        zoom={calculateZoomForMagnifier()}
                        onClick={handleClick}
                        onTouchStart={handleTouchStart}
                        direction={zoomDirection}
                        zoomBase={zoomBase}
                        enlarge={enlarge}
                        disableZoomControl={disableZoomControl}
                        {...triggerProps}
                    >
                        {children}
                    </P.Magnifier>
                </Tooltip>
            </P.MagnifierContainer>
        </P.MagnifierWrapper>
    );
};
