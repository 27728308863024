import { Dispatch, RefObject, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import uniqueId from 'lodash/uniqueId';
import isEqual from 'lodash/isEqual';

import { getReturnValue } from './functions';
import { DEFAULT_RECT, MilisTime } from './constants';

export const useId = (prefix?: string, id?: string) => {
  const [ID] = useState(id ?? uniqueId(prefix));

  return ID;
};

export const useForceUpdate = () => {
  const [, setValue] = useState(0);
  return () => setValue((value) => value + 1);
};

export const usePrevious = <T>(value: T) => {
  const prev = useRef<T>(value);

  useEffect(() => void (prev.current = value), [value]);

  return prev.current;
};

type StateHistory<T> = {
  initial: T;
  current: T;
  previous?: T;
  history: (T | undefined)[];
};
export const useStateHistory = <T>(state: T, historyMode: 'full' | 'last' | 'none' = 'none'): StateHistory<T> => {
  const history = useRef<StateHistory<T>>({
    initial: state,
    current: state,
    previous: undefined,
    history: historyMode === 'none' ? [] : [state],
  });

  useEffect(() => {
    if (state === history.current.current) return;

    if (historyMode === 'last') history.current.history = [history.current.previous, history.current.current];

    history.current.previous = history.current.current;
    history.current.current = state;

    if (historyMode === 'none') return;
    history.current.history.push(state);
  }, [state]);

  return history.current;
};

type UseStateChangedOptions = {
  deepEqual?: boolean;
};

export const useStateChanged = <T>(state: T, options?: UseStateChangedOptions) => {
  const previous = usePrevious(state);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    const next = options?.deepEqual ? isEqual(previous, state) : previous !== state;
    setIsChanged(next);
  }, [state]);

  return isChanged;
};

export const useRefState = <T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] => {
  const ref = useRef(getReturnValue(initialState));
  const setState = (value: T | ((prev: T) => T)) => {
    ref.current = getReturnValue(value, ref.current);
  };
  return [ref.current, setState];
};

export const useDerivedState = <T>(
  value: T | (() => T),
  effect?: (state: T, setState: Dispatch<SetStateAction<T>>) => void
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState(value);

  useEffect(() => {
    if (effect) return effect(state, setState);
    if (state !== getReturnValue(value)) {
      setState(value);
    }
  }, [value]);

  return [state, setState];
};

type UseElementSizeSetState = (prev: DOMRect, current?: DOMRect) => DOMRect;
export const useElementSize = <T extends HTMLElement>(element: T | null, setState?: UseElementSizeSetState) => {
  const [boundingBox, setBoundingBox] = useState(
    () => setState?.(DEFAULT_RECT, element?.getBoundingClientRect()) ?? element?.getBoundingClientRect()
  );

  useLayoutEffect(() => {
    if (!element) return;

    const subscription = fromEvent<UIEvent>(window, 'resize')
      .pipe(debounceTime(MilisTime.xShort))
      .subscribe(() =>
        setBoundingBox(
          (prev = DEFAULT_RECT) => setState?.(prev, element.getBoundingClientRect()) ?? element.getBoundingClientRect()
        )
      );

    return () => subscription.unsubscribe();
  }, [!!element]);

  const bBox = element?.getBoundingClientRect();

  useLayoutEffect(() => {
    if (boundingBox?.width === bBox?.width && boundingBox?.height === bBox?.height) return;

    setBoundingBox((prev = DEFAULT_RECT) => setState?.(prev, bBox) ?? bBox);
  }, [bBox?.width, bBox?.height]);

  return boundingBox ?? DEFAULT_RECT;
};

export const useRefElementSize = <T extends HTMLElement>(
  setState?: UseElementSizeSetState
): [RefObject<T>, DOMRect] => {
  const ref = useRef<T>(null);
  const rect = useElementSize(ref.current, setState);
  return [ref, rect];
};
