import * as React from 'react';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';

import { createProductJourneyActions } from 'features/createProductJourney';
import { CreateProductJourneyAction } from 'root/model/createProductJourney/CreateProductJourney';
import Button from 'root/components/Button';

import styles from './index.module.scss';
import { FC, useCallback } from 'react';
import classNames from 'classnames';

interface IJourneyButtonProps extends IClassNameProp {
  text: string;
  dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
  next: boolean;
  setDisplayValidation?: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}

const JourneyButton: FC<IJourneyButtonProps> = ({
  text,
  next,
  dispatchJourneyAction,
  className,
  setDisplayValidation,
  isDisabled = false,
}) => {
  const onJourneyButtonClick = useCallback(() => {
    animateScroll.scrollToTop({
      duration: 800,
      smooth: 'easeInOutQuart',
    });

    if (isDisabled) {
      if (setDisplayValidation) {
        setDisplayValidation(true);
      }

      return;
    }

    dispatchJourneyAction(next ? CreateProductJourneyAction.NEXT : CreateProductJourneyAction.BACK);
  }, [isDisabled]);

  return (
    <div className={classNames(className, styles['journey-button'])}>
      <Button
        text={text}
        onClick={onJourneyButtonClick}
        className={styles['journey-button__button']}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default connect(null, {
  dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
})(JourneyButton);
