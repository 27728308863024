import { MapVisibleBoundsType } from 'root/components/Map/map';
import { convertProjection, PROJECTIONS } from 'root/services/projection/projection';

export const createHideOutsidePolygon = (
    mapVisibleBounds: MapVisibleBoundsType
): { source: any; sourceName: string; layer: any } => {
    const outsideBoundingPolygonThickness = 2000;
    const feature: { source: any; sourceName: string; layer: any } = {
        source: null,
        sourceName: '',
        layer: null,
    };

    if (!mapVisibleBounds) {
        return feature;
    }

    const c = (nortingEasting: number[]) => {
        const latLong = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, {
            x: nortingEasting[0],
            y: nortingEasting[1],
        });

        return [latLong.x, latLong.y];
    };

    feature.sourceName = 'source';

    feature.source = {
        type: 'geojson',
        data: {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'Polygon',
                coordinates: [
                    [
                        c([mapVisibleBounds.p1.x, mapVisibleBounds.p1.y]),
                        c([mapVisibleBounds.p2.x, mapVisibleBounds.p1.y]),
                        c([mapVisibleBounds.p2.x, mapVisibleBounds.p2.y]),
                        c([mapVisibleBounds.p1.x, mapVisibleBounds.p2.y]),
                        c([mapVisibleBounds.p1.x, mapVisibleBounds.p1.y - outsideBoundingPolygonThickness]),
                        c([
                            mapVisibleBounds.p1.x - outsideBoundingPolygonThickness,
                            mapVisibleBounds.p1.y - outsideBoundingPolygonThickness,
                        ]),
                        c([
                            mapVisibleBounds.p1.x - outsideBoundingPolygonThickness,
                            mapVisibleBounds.p2.y + outsideBoundingPolygonThickness,
                        ]),
                        c([
                            mapVisibleBounds.p2.x + outsideBoundingPolygonThickness,
                            mapVisibleBounds.p2.y + outsideBoundingPolygonThickness,
                        ]),
                        c([
                            mapVisibleBounds.p2.x + outsideBoundingPolygonThickness,
                            mapVisibleBounds.p1.y - outsideBoundingPolygonThickness,
                        ]),
                        c([mapVisibleBounds.p1.x, mapVisibleBounds.p1.y - outsideBoundingPolygonThickness]),
                    ],
                ],
            },
        },
    };

    feature.layer = {
        id: 'pattern-layer',
        type: 'fill',
        source: feature.sourceName,
        paint: {
            'fill-color': '#FFFFFF',
        },
    };

    return feature;
};
