import * as React from 'react';
import { useSelector } from 'react-redux';
import { getMapType } from 'root/features/map/selectors';
import { MapType } from 'root/model/map/MapEnums';

import { Type } from './Type';
import styles from './index.module.scss';

export const ProductTypeList = () => {
    const mapType = useSelector(getMapType);

    const types = [
        { name: 'Paper Maps', id: MapType.PAPER },
        { name: 'Canvas & Framed', id: MapType.CANVAS_AND_FRAMED },
    ];

    return (
        <div className={styles['options-list']}>
            {types.map((type) => (
                <Type {...type} key={type.id} active={type.id === mapType} />
            ))}
        </div>
    );
};
