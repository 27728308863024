import React, { FormEvent } from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { withFormik, FormikProps } from 'formik';
import { scroller } from 'react-scroll';

import Types from 'Types';
import { customistaionSelectors } from 'root/features/customisation';
import { mapSelectors, mapActions } from 'root/features/map';
import { coverSelectors } from 'root/features/cover';
import { imageCropSelectors } from 'root/features/imageCrop';
import { ProductId } from 'root/model/map/MapEnums';
import {
  ITitleFormValues,
  TITLE_FORM_FIELD_NAMES,
} from 'root/model/customisation/TitleFormValues';
import { createProductJourneyActions } from 'root/features/createProductJourney';
import { SIDEBAR_TAB_CONTENT_ID } from 'root/constants';

import { validateForm, handleFormSubmit } from './utils';
import styles from './index.module.scss';

export interface IOrderFormProps {
  children: React.ReactNode;
  titleFirstLine: string;
  titleSecondLine: string;
  titleThirdLine: string;
  subtitleFirstLine: string;
  subtitleSecondLine: string;
  spine: string;
  productId: ProductId;
  coverUrl: string;
  flatMapCoverUrl: string;
  croppingImage: boolean;
  dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
  setProduct: typeof mapActions.setProduct;
}

interface IOrderFormState {
  scrollOffset: number;
}

export class OrderForm extends React.Component<
  IOrderFormProps & FormikProps<ITitleFormValues>,
  IOrderFormState
> {
  constructor(props: IOrderFormProps & FormikProps<ITitleFormValues>) {
    super(props);
    this.state = { scrollOffset: 0 };
  }

  public render() {
    return (
      <form
        className={styles['order-form']}
        onSubmit={this.onSubmit}
        ref={this.setScrollOffset}
      >
        {this.props.children}
      </form>
    );
  }

  private onSubmit = async (event: FormEvent) => {
    event.preventDefault();
    const fieldsWithErrors = Object.keys(this.props.errors);
    if (fieldsWithErrors) {
      scroller.scrollTo(fieldsWithErrors[0], {
        duration: 800,
        containerId: SIDEBAR_TAB_CONTENT_ID,
        smooth: 'easeInOutQuart',
        offset: this.state.scrollOffset,
      });
    }
    this.props.handleSubmit();
  };

  private setScrollOffset = (element: HTMLFormElement) => {
    if (element) {
      this.setState({ scrollOffset: -element.getBoundingClientRect().top });
    }
  };
}

export default flow([
  withFormik<IOrderFormProps, ITitleFormValues>({
    mapPropsToValues: (props) => {
      return {
        [TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE]: props.titleFirstLine,
        [TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE]: props.titleSecondLine,
        [TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE]: props.titleThirdLine,
        [TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE]: props.subtitleFirstLine,
        [TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE]: props.subtitleSecondLine,
        [TITLE_FORM_FIELD_NAMES.SPINE]: props.spine,
        [TITLE_FORM_FIELD_NAMES.COVER_IMAGE]: props.coverUrl,
      };
    },
    validate: validateForm,
    handleSubmit: handleFormSubmit,
    validateOnChange: true,
    validateOnBlur: true,
    isInitialValid: false,
  }),
  connect(
    (state: Types.RootState) => ({
      titleFirstLine: customistaionSelectors.getTitleFirstLine(state),
      titleSecondLine: customistaionSelectors.getTitleSecondLine(state),
      titleThirdLine: customistaionSelectors.getTitleThirdLine(state),
      subtitleFirstLine: customistaionSelectors.getSubtitleFirstLine(state),
      subtitleSecondLine: customistaionSelectors.getSubtitleSecondLine(state),
      spine: customistaionSelectors.getSpine(state),
      productId: mapSelectors.getProductId(state),
      coverUrl: coverSelectors.getCoverUrl(state),
      flatMapCoverUrl: customistaionSelectors.getLinkToImage(state),
      croppingImage: imageCropSelectors.getIsCroppingImage(state),
    }),
    {
      dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
      setProduct: mapActions.setProduct,
    }
  ),
])(OrderForm);
