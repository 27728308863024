import * as React from 'react';

import { AlertMessage } from 'root/components/AlertMessage';
import { Select } from 'root/components/Select';

import mainStyles from '../../index.module.scss';
import styles from './RouteTab.module.scss';
import { RouteMapValidator } from '../RouteMapValidator';
import { RouteUploadFilename } from './components/RouteUploadFilename';
import { RouteUploadDropzone } from './components/RouteUploadDropzone';
import { RouteStyleToolkit } from './components/RouteStyleToolkit';
import { useRouteTab } from './hooks';

export const RouteTab = () => {
    const {
        filename,
        resetRoute,
        onUploadRouteFile,
        onRouteStylesRestore,
        options,
        onChangeRoute,
        selectedRouteFromList,
        isRouteActive,
        routeSettingValidator,
    } = useRouteTab();

    return (
        <div className={mainStyles['area-and-style-tab__columns']}>
            <div className={styles['route-container']}>
                <RouteMapValidator />

                {filename && <RouteUploadFilename fileName={filename} resetRoute={resetRoute} />}

                {!filename && (
                    <>
                        <RouteUploadDropzone onDrop={onUploadRouteFile} />

                        <Select
                            options={[
                                { label: 'BEST FOR CHOSEN PRODUCT', options: options.optimal },
                                { label: 'OTHER', options: options.notOptimal },
                            ]}
                            name="routes"
                            placeholder="Select from popular routes"
                            isClearable={true}
                            onChange={onChangeRoute}
                            defaultValue={selectedRouteFromList}
                            emptyMessage="No routes"
                        />
                    </>
                )}

                {isRouteActive && <RouteStyleToolkit />}

                {routeSettingValidator && (
                    <AlertMessage
                        message={routeSettingValidator.message}
                        onRestore={onRouteStylesRestore}
                        actionText={routeSettingValidator.actionText}
                    />
                )}
            </div>
        </div>
    );
};
