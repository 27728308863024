import { ActionType } from 'typesafe-actions';

import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import * as actions from './actions';
import { SET_MEDIA_QUERY } from './constants';

export type MediaQueryActions = ActionType<typeof actions>;

const mediaQueryReducer = (
  state: MediaQuery = MediaQuery.SMALL,
  action: MediaQueryActions
) => {
  switch (action.type) {
    case SET_MEDIA_QUERY:
      return action.payload;
    default:
      return state;
  }
};

export default mediaQueryReducer;
