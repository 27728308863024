import { EMPTY, of } from 'rxjs';
import { Epic, combineEpics, ofType } from 'redux-observable';
import {
  filter,
  distinctUntilChanged,
  map,
  withLatestFrom,
  switchMap,
} from 'rxjs/operators';
import { ActionType, isOfType } from 'typesafe-actions';
import { get, values } from 'lodash';
import Types from 'Types';
import { TITLE_FORM_FIELD_NAMES } from 'root/model/customisation/TitleFormValues';
import {
  customisationConstants,
  customistaionSelectors,
  customisationActions,
} from 'features/customisation';
import { CustomisationActions } from './reducer';
import { SET_TITLE_LINE } from './constants';

const titleActionMap = {
  [TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE]:
    customisationActions.setTitleFirstLine,
  [TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE]:
    customisationActions.setTitleSecondLine,
  [TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE]:
    customisationActions.setTitleThirdLine,
  [TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE]:
    customisationActions.setSubtitleFirstLine,
  [TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE]:
    customisationActions.setSubtitleSecondLine,
  [TITLE_FORM_FIELD_NAMES.SPINE]: customisationActions.setSpine,
};

type SetTitleActionType = ActionType<typeof customisationActions.setTitleLine>;

export const setTitleLineEpic: Epic<
  CustomisationActions,
  CustomisationActions
> = (action$) =>
  action$.pipe(
    ofType<SetTitleActionType>(SET_TITLE_LINE),
    filter(
      (action) =>
        action.payload &&
        values(TITLE_FORM_FIELD_NAMES).indexOf(
          action.payload.fieldName as TITLE_FORM_FIELD_NAMES
        ) >= 0
    ),
    distinctUntilChanged(),
    map((action) => {
      return get(
        titleActionMap,
        action.payload.fieldName
      )(action.payload.titleLine);
    })
  );

export const setSpineFromTitlesEpic: Epic<
  CustomisationActions,
  CustomisationActions,
  Types.RootState
> = (action$, state$) =>
  action$.pipe(
    filter(isOfType(customisationConstants.SET_SPINE_FROM_TITLES)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      if (!action.payload) {
        return EMPTY;
      }

      const spineFromTitles = customistaionSelectors.getSpineFromTitles(state);

      return of(
        customisationActions.setTitleLine(
          spineFromTitles,
          TITLE_FORM_FIELD_NAMES.SPINE
        )
      );
    })
  );

export default combineEpics(setTitleLineEpic, setSpineFromTitlesEpic);
