import * as React from 'react';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import * as Types from 'Types';
import { IMapLocationCoordinates, IMapProduct } from 'root/model/map/MapParams';
import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { Scale, MapLanguage, Material, Proportions, Size, MapType } from 'root/model/map/MapEnums';
import { createProductJourneySelectors } from 'features/createProductJourney';
import { productPreviewSelectors } from 'features/productPreview';
import { mapSelectors } from 'features/map';
import ProductPreviewMap from 'root/components/ProductPreviewMap';
import ProductPreviewErrorMessage from './components/ProductPreviewErrorMessage';
import MapOptionDetails from './components/MapOptionDetails';
import MapFrame from './components/MapFrame';
import MapTitlesPreviewOverlay from './components/MapTitlesPreviewOverlay';
import {
    getMapFrontModificatorClasses,
    getMapFrontLeftSideModificatorClasses,
    getMapSceneModificatorClasses,
    getProductSizeModificatorClasses,
} from './utils';
import { MAP_STYLE_TO_FRAME_URL } from './constants';
import styles from './index.module.scss';
import VerticalLine from 'root/components/VerticalLine';

interface IMapPreviewProps {
    mapLocationCoordinates: IMapLocationCoordinates;
    createProductJourneyStep: CreateProductJourneyStep;
    product: IMapProduct;
    mapScale: Scale;
    mapLanguage: MapLanguage;
    mapSize: Size;
    productPreviewError: boolean;
    mapType?: number;
}

const MapPreview: React.FunctionComponent<IMapPreviewProps> = ({
    createProductJourneyStep,
    product,
    mapScale,
    mapLanguage,
    mapSize,
    productPreviewError,
    mapType,
}) => {
    if (!product.productId) {
        return null;
    }
    const isCustomiseStep = createProductJourneyStep === CreateProductJourneyStep.CUSTOMISE;

    const isRectangleFramed = mapType === MapType.CANVAS_AND_FRAMED && [Size.SMALL, Size.MEDIUM].includes(mapSize);

    let frameUrl =
        mapSize === Size.LARGE
            ? // @ts-ignore
              MAP_STYLE_TO_FRAME_URL[Proportions.SQUARE][mapScale][mapLanguage]
            : // @ts-ignore
              MAP_STYLE_TO_FRAME_URL[Proportions.RECTANGLE][mapScale];
    if (mapSize === Size.SMALL && mapType === MapType.PAPER) {
        frameUrl = '';
    }

    const mapPreviewCssClasses = classNames(styles['map-preview'], {
        [styles['map-preview--customise']]: isCustomiseStep,
        [styles['map-preview--customise--rectangle']]: isCustomiseStep && isRectangleFramed,
    });
    const sceneCssClasses = classNames(
        styles['map-preview__scene'],
        getMapSceneModificatorClasses(styles, product.material),
        {
            [styles['map-preview__scene--rectangle']]: isCustomiseStep && isRectangleFramed,
        }
    );
    const frontCssClasses = classNames(styles['map-preview__front'], getMapFrontModificatorClasses(styles, product));
    const previewImageCssClasses = classNames(styles['map-preview__preview-image'], {
        [styles['map-preview__preview-image--customise']]: isCustomiseStep,
        [styles['map-preview__preview-image--customise--rectangle']]: isCustomiseStep && isRectangleFramed,
    });
    const leftSideCssClasses = classNames(
        styles['map-preview__left-side'],
        getMapFrontLeftSideModificatorClasses(styles, product.framing)
    );
    const productSizeCssClasses = classNames(
        styles['map-preview__product-size'],
        getProductSizeModificatorClasses(styles, product)
    );
    const mapPreviewInnerClasses = classNames(styles['map-preview__inner'], {
        [styles['map-preview__inner--rectangle']]: isCustomiseStep && isRectangleFramed,
    });

    return (
        <div className={mapPreviewCssClasses}>
            {!isCustomiseStep && (
                <>
                    <div className={productSizeCssClasses}>
                        <VerticalLine className={styles['map-preview__vertical-line']}>
                            <MapOptionDetails />
                        </VerticalLine>
                    </div>
                </>
            )}

            <div className={sceneCssClasses}>
                <div className={frontCssClasses}>
                    {isCustomiseStep && <MapTitlesPreviewOverlay frameUrl={frameUrl} />}
                    <div className={mapPreviewInnerClasses}>
                        <MapFrame isCustomiseStep={isCustomiseStep} frameUrl={frameUrl}>
                            {productPreviewError ? (
                                <ProductPreviewErrorMessage />
                            ) : (
                                <ProductPreviewMap previewImageClassName={previewImageCssClasses} />
                            )}
                        </MapFrame>
                    </div>
                </div>
                {product.material === Material.CANVAS && <div className={leftSideCssClasses} />}
            </div>
        </div>
    );
};

export default connect((state: Types.RootState) => ({
    createProductJourneyStep: createProductJourneySelectors.getCreateProductJourneyStep(state),
    product: mapSelectors.getProduct(state),
    productPreviewError: productPreviewSelectors.getProductPreviewError(state),
    ...mapSelectors.getMapStyleParams(state),
    mapSize: mapSelectors.getMapSize(state),
    mapType: mapSelectors.getMapType(state),
}))(MapPreview);
