import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Types from 'Types';
import { ProductId } from 'root/model/map/MapEnums';
import { mapSelectors } from 'src/features/map';
import { checkoutOptionsSelectors } from 'features/checkoutOptions';
import { createProductJourneyActions, createProductJourneySelectors } from 'features/createProductJourney';
import { checkoutOptionsActions } from 'features/checkoutOptions';

import { SummaryPrice } from 'root/components/SummaryPrice';
import ProductDescription from './components/ProductDescription';

import styles from './index.module.scss';
import QuantityChooser from './components/QuantityChooser';
import { IMapLocationCoordinates } from 'root/model/map/MapParams';
import { userInfoModalActions } from 'root/features/userInfoModal';
import { MessageType } from 'root/services/fileHelpers/consts';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { OrderSummaryLeft } from 'root/modules';
import { ActionButtons } from 'root/modules/OrderSummary/OrderSummaryRight/components/ActionButtons';
import { CustomerCareText } from 'root/modules/OrderSummary/OrderSummaryRight/components/CustomerCareText';
import { DefaultLocationWarning } from 'root/modules/OrderSummary/OrderSummaryRight/components/DefaultLocationWarning';
import { useCheckDefaultLocalization } from 'root/modules/OrderSummary/OrderSummaryRight/hooks';
import { getEnvName } from 'root/appConfig/utils';

export interface IOrderSummaryProps {
    price: number;
    quantity: number;
    productId: ProductId;
    framing?: string;
    framings: string[];
    name?: string;
    material?: string;
    isSendOrderInProgress: boolean;
    mapLocationCoordinates: IMapLocationCoordinates;
    initSendOrder: typeof createProductJourneyActions.initSendOrder;
    sendOrder: typeof createProductJourneyActions.sendOrder;
    setQuantity: typeof checkoutOptionsActions.setQuantity;
    dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
    showUserInfoModal: typeof userInfoModalActions.showUserInfoModal;
}

const OrderSummary: FC<IOrderSummaryProps> = ({
    framings,
    price,
    productId,
    showUserInfoModal,
    framing,
    material,
    name,
}) => {
    const isDefaultLocalization = useCheckDefaultLocalization();

    const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
    const handlePreviewOpen = useCallback(() => setIsPreviewOpen(true), []);

    const checkDefaultLondon = () => {
        if (isDefaultLocalization) {
            datadogLogs.logger.log(
                'Order confirmation default location warning',
                { env: getEnvName() },
                StatusType.warn
            );
            showUserInfoModal({
                contentType: MessageType.DEFAULT_LOCATION_WARNING,
                headerText: 'Default location of Central London',
            });
        }
    };

    useEffect(() => {
        checkDefaultLondon();
    }, []);

    return (
        <div className={styles['order-summary']}>
            <OrderSummaryLeft isPreviewOpen={isPreviewOpen} setIsPreviewOpen={setIsPreviewOpen} />

            <div className={styles['order-summary__content']}>
                <ProductDescription
                    className={styles['order-summary__product-description']}
                    productId={productId}
                    name={name}
                    framing={framing}
                    framings={framings}
                    material={material}
                />
                <SummaryPrice price={price} className={styles['order-summary__summary-price']} />
                <QuantityChooser className={styles['order-summary__summary-quantity']} />

                <CustomerCareText onPreviewClick={handlePreviewOpen} />

                {isDefaultLocalization && <DefaultLocationWarning />}

                <ActionButtons />
            </div>
        </div>
    );
};

export default connect(
    (state: Types.RootState) => ({
        price: mapSelectors.getPrice(state),
        productId: mapSelectors.getProductId(state),
        framing: mapSelectors.getFraming(state),
        framings: mapSelectors.getFramings(state),
        name: mapSelectors.getName(state),
        material: mapSelectors.getMaterial(state),
        isSendOrderInProgress: createProductJourneySelectors.getIsSendOrderInProgress(state),
        quantity: checkoutOptionsSelectors.getQuantity(state),
        mapLocationCoordinates: mapSelectors.getMapLocationCoordinates(state),
    }),
    {
        dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
        initSendOrder: createProductJourneyActions.initSendOrder,
        sendOrder: createProductJourneyActions.sendOrder,
        setQuantity: checkoutOptionsActions.setQuantity,
        showUserInfoModal: userInfoModalActions.showUserInfoModal,
    }
)(OrderSummary);
