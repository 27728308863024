import * as React from 'react';
import { connect } from 'react-redux';

import Types from 'Types';
import { mapSelectors, mapActions } from 'root/features/map';
import { MapLanguage, Size } from 'root/model/map/MapEnums';

import { Toggle } from './components/Toggle';
import styles from './index.module.scss';

export interface ILanguageSelectProps {
  selectedLanguage: MapLanguage;
  setMapStyleParams: typeof mapActions.setMapStyleParams;
  mapSize?: string;
}

export class LanguageOptions extends React.Component<ILanguageSelectProps> {
  public render() {
    const { selectedLanguage, mapSize } = this.props;

    return (
      <div className={styles['language-select']}>
        <p className={styles['language-select__title']}>Map key language</p>
        <Toggle
          leftText="English"
          leftValue={MapLanguage.ENGLISH}
          rightText="Cymraeg (Welsh)"
          rightValue={MapLanguage.WELSH}
          selectedLeft={selectedLanguage === MapLanguage.ENGLISH}
          toggle={this.onToggle}
          rightDisabled={mapSize === Size.SMALL || mapSize === Size.MEDIUM}
        />
      </div>
    );
  }

  private onToggle = (value: MapLanguage) => {
    const { selectedLanguage, setMapStyleParams } = this.props;
    if (selectedLanguage !== value) {
      setMapStyleParams({
        mapLanguage: value,
      });
    }
  };
}

export default connect(
  (state: Types.RootState) => {
    return {
      selectedLanguage: mapSelectors.getMapLanguage(state),
      mapSize: mapSelectors.getMapSize(state),
    };
  },
  {
    setMapStyleParams: mapActions.setMapStyleParams,
  }
)(LanguageOptions);
