import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CoverTitles from 'root/components/CoverTitles';
import { CoverDropzone } from 'root/components/CoverDropzone';
import UserInfoModal from 'root/components/UserInfoModal';
import FoldedCoverTopBackground from './components/FoldedCoverTopBackground';
import FoldedCoverImageCrop from './components/FoldedCoverImageCrop';
import mapBehindPreview from './assets/mapBehindPreview.png';
import mapBehindPreviewSmall from './assets/mapBehindPreviewSmall.png';
import styles from './index.module.scss';
import { Size } from 'root/model/map/MapEnums';
import classNames from 'classnames';
import { getCoverUrl } from 'root/features/cover/selectors';
import { getLinkToImage } from 'root/features/customisation/selectors';
import { getMapSize } from 'root/features/map/selectors';
import { FileRejection } from 'react-dropzone';
import { handleCoverUpload } from 'root/features/cover/actions';
import { showUserInfoModal } from 'root/features/userInfoModal/actions';
import { ALLOWED_MIME_TYPE_FOR_IMAGE, MessageType } from 'root/services/fileHelpers/consts';
import CoverDropzoneImageContent from 'root/components/CoverDropzone/components/CoverDropzoneImageContent';

export const FoldedCoverPreview = () => {
    const coverUrl = useSelector(getCoverUrl);
    const flatMapCoverUrl = useSelector(getLinkToImage);
    const mapSize = useSelector(getMapSize);

    const dispatch = useDispatch();

    const baseClassName = 'folded-cover-preview';

    let frontCoverClass = styles[`${baseClassName}__front-cover`];

    if (mapSize === Size.SMALL) {
        frontCoverClass = classNames(frontCoverClass, styles[`${baseClassName}__front-cover--small`]);
    }

    const containerClasses = classNames(styles['folded-cover-preview__container'], {
        [styles[`${baseClassName}__container--small`]]: mapSize === Size.SMALL,
    });

    const behindCoverClasses = classNames(styles['folded-cover-preview__behind-cover'], {
        [styles[`${baseClassName}__behind-cover--small`]]: mapSize === Size.SMALL,
    });

    const getImageClassNameSize = () => {
        if (mapSize === Size.SMALL) {
            return classNames(styles[`${baseClassName}__cover-image`], {
                [styles[`${baseClassName}__cover-image--cover-small`]]: true,
            });
        } else {
            return classNames(styles[`${baseClassName}__cover-image`], {
                [styles[`${baseClassName}__cover-image--cover-large`]]: true,
            });
        }
    };

    const imageClassName = getImageClassNameSize();

    const behindPreview = mapSize === Size.SMALL ? mapBehindPreviewSmall : mapBehindPreview;

    const onDrop = async (accepted: File[], rejected: FileRejection[]) => {
        try {
            await new Promise((resolve, reject) => {
                dispatch(handleCoverUpload(accepted, rejected, { resolve, reject }));
            });
        } catch (error) {
            dispatch(
                showUserInfoModal({
                    contentType: MessageType.UPLOAD_IMAGE_ERROR,
                    headerText: 'Error while uploading image',
                })
            );
        }
    };

    return (
        <React.Fragment>
            <div className={styles[baseClassName]}>
                <div className={containerClasses}>
                    <img className={behindCoverClasses} src={behindPreview} alt="" />

                    <div className={frontCoverClass}>
                        <FoldedCoverTopBackground />

                        <CoverTitles />

                        <div className={styles['folded-cover-preview__bottom-background']}>
                            <CoverDropzone
                                size={'auto'}
                                isUploaded={!!coverUrl && !!flatMapCoverUrl}
                                onDrop={onDrop}
                                mimeType={ALLOWED_MIME_TYPE_FOR_IMAGE}
                            >
                                {coverUrl && flatMapCoverUrl ? (
                                    <div>
                                        <img src={coverUrl} alt="cover" className={imageClassName} />
                                    </div>
                                ) : (
                                    <CoverDropzoneImageContent isCover={true} />
                                )}
                            </CoverDropzone>
                        </div>
                    </div>
                </div>
            </div>

            <FoldedCoverImageCrop />

            <UserInfoModal />
        </React.Fragment>
    );
};
