import React from 'react';
import { ColorSelector } from './components/ColorSelector';
import { ThicknessSelector } from './components/ThicknessSelector';
import { TransparencySlider } from './components/TransparencySlider';

import styles from './RouteStyleToolkit.module.scss';

export const RouteStyleToolkit = () => {
    return (
        <>
            <div className={styles['style-section']}>
                <ColorSelector />
            </div>

            <div className={styles['style-section']}>
                <ThicknessSelector />
            </div>

            <div className={styles['style-section']}>
                <TransparencySlider />
            </div>
        </>
    );
};
