import * as React from 'react';
import { ButtonHTMLAttributes, FC } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';

type HeaderButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
};

const VerticalLine: FC<HeaderButtonProps> = ({ className, children }) => {
  return (
    <div className={cn(className, styles['vertical-line'])}>
      <div className={styles['vertical-line__text']}>
        <span>{children}</span>
      </div>
    </div>
  );
};

export default VerticalLine;
