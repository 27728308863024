import { action } from 'typesafe-actions';

import { ILocation } from 'root/model/location/Location';
import { ILocationFormValues } from 'root/model/location/LocationFormValues';

import {
  FIND_LOCATIONS,
  SET_LOCATIONS,
  SUBMIT_LOCATION_FORM,
  LOCALIZE_USER,
  SET_GEO_LOCALIZATION_ENABLED,
} from './constants';

export const findLocations = (query: string, meta: IPromiseExecutorArguments) =>
  action(FIND_LOCATIONS, query, meta);

export const setLocations = (locations: ILocation[]) =>
  action(SET_LOCATIONS, locations);

export const submitLocationForm = (values: ILocationFormValues) =>
  action(SUBMIT_LOCATION_FORM, values);

export const localizeUser = (meta: IPromiseExecutorArguments) =>
  action(LOCALIZE_USER, undefined, meta);

export const setGeoLocalizationEnabled = (isEnabled: boolean) =>
  action(SET_GEO_LOCALIZATION_ENABLED, isEnabled);
