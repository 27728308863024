import * as React from 'react';
import * as classNames from 'classnames';

import styles from './index.module.scss';

interface ISidebarScrollAreaProps {
  isHidden: boolean;
  onScrollAreaClick: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const SidebarScrollArea: React.FunctionComponent<ISidebarScrollAreaProps> = ({
  isHidden,
  onScrollAreaClick,
}) => {
  const sidebarScrollAreaCssClasses = classNames(
    styles['sidebar-scroll-area'],
    { [styles['sidebar-scroll-area--hidden']]: isHidden }
  );

  return (
    <div className={sidebarScrollAreaCssClasses}>
      <div
        className={styles['sidebar-scroll-area__clickable-area']}
        onClick={onScrollAreaClick}
      >
        <i className={styles['sidebar-scroll-area__icon']} />
        <p className={styles['sidebar-scroll-area__text']}>
          Scroll to see the full map
        </p>
      </div>
    </div>
  );
};

export default SidebarScrollArea;
