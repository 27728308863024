import { action } from 'typesafe-actions';
import {
  IProductOption,
  IPredefinedCoverImage,
} from 'root/model/product/ProductOption';
import {
  GET_PRODUCTS_OPTIONS,
  SET_PRODUCTS_OPTIONS,
  SET_PREDEFINED_COVER_IMAGES,
} from './constants';

export const getProductsOptions = () => action(GET_PRODUCTS_OPTIONS);

export const setProductOptions = (productsOptions: IProductOption[]) =>
  action(SET_PRODUCTS_OPTIONS, productsOptions);

export const setPredefinedCoverImages = (predefinedProductImages: {
  SMALL: IPredefinedCoverImage[];
  LARGE: IPredefinedCoverImage[];
}) => action(SET_PREDEFINED_COVER_IMAGES, predefinedProductImages);
