import React from 'react';

import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { ProductId } from 'root/model/map/MapEnums';

import { MapSelectionContainer } from './components/MapSelectionContainer';
import MapPreviewContainer from './components/MapPreviewContainer';
import ProductPreviewContainer from './components/ProductPreviewContainer/ProductPreviewContainer';
import { FoldedCoverPreview } from './components/FoldedCoverPreview';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { useSelector } from 'react-redux';
import { getSelectedRoute, getUploadedRoute } from 'root/features/route/selectors';
import { getAreaAndStyleSelectedTab } from 'root/features/map/selectors';
import { AreaAndStyleNonSelectedPreview } from './components/AreaAndStyleNonSelectedPreview';
import { AreaAndStyleRouteSelectedPreview } from './components/AreaAndStyleRouteSelectedPreview/AreaAndStyleRouteSelectedPreview';

export type ComponentType =
    | typeof MapPreviewContainer
    | typeof ProductPreviewContainer
    | typeof MapSelectionContainer
    | typeof FoldedCoverPreview;

type GetPreviewComponentProps = {
    createProductJourneyStep: CreateProductJourneyStep;
    productId?: ProductId;
};

export const PreviewComponent = ({ createProductJourneyStep, productId }: GetPreviewComponentProps) => {
    const selectedRoute = useSelector(getSelectedRoute);
    const uploadedRoute = useSelector(getUploadedRoute);
    const areaAndStyleSelectedTab = useSelector(getAreaAndStyleSelectedTab);

    switch (createProductJourneyStep) {
        case CreateProductJourneyStep.PRODUCT_TYPE:
            return <ProductPreviewContainer />;

        case CreateProductJourneyStep.AREA_AND_STYLE:
            if (areaAndStyleSelectedTab === AreaAndStyleSelectedTab.None) {
                return <AreaAndStyleNonSelectedPreview />;
            } else if (
                areaAndStyleSelectedTab === AreaAndStyleSelectedTab.Route &&
                selectedRoute === null &&
                !uploadedRoute.filename
            ) {
                return <AreaAndStyleRouteSelectedPreview />;
            }

            return <MapSelectionContainer />;

        case CreateProductJourneyStep.CUSTOMISE:
            if (productId === ProductId.FOLDED_MAP) {
                return <FoldedCoverPreview />;
            }

            return <MapPreviewContainer />;

        default:
            return <MapSelectionContainer />;
    }
};
