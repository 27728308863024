import { action } from 'typesafe-actions';
import {
  SHOW_TOUR_GUIDE_TOOLTIP,
  SWITCH_SINGLE_TOOLTIP_MODE,
} from 'features/tourGuide/constants';

export const showGuideTooltip = (isVisible: any) =>
  action(SHOW_TOUR_GUIDE_TOOLTIP, isVisible);

export const switchSingleTooltipMode = (isSingle: boolean) =>
  action(SWITCH_SINGLE_TOOLTIP_MODE, isSingle);
