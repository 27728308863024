import React, { FC, HTMLAttributes, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { MAP_TYPES } from 'root/modules/types';
import { FlatMapPreview } from './components/FlatMapPreview';
import { FoldedMapPreview } from './components/FoldedMapPreview';
import { useProductPreviewProps } from './hooks';
import { AlternativeLayoutType } from './types';

const WIDTH_CHANGE_DELAY = 100;

type ProductPreviewProps = AlternativeLayoutType & {
  wrapperProps?: HTMLAttributes<HTMLDivElement>;
  className?: string;
  dispatchZoomRequest?: boolean;
};

export const ProductPreview: FC<ProductPreviewProps> = ({
  alternativeLayout,
  wrapperProps,
  className,
  dispatchZoomRequest,
}) => {
  const { format, type, coverSrc, descriptionFrameSrc, leftSideFrameSrc, woodFrameSrc } = useProductPreviewProps();

  const [width, setWidth] = useState<number>(0);
  const width$ = useRef(new Subject<number>());
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listener = () => {
      if (!ref.current) return;
      width$.current.next(ref.current.getBoundingClientRect().width);
    };

    window.addEventListener('resize', listener);

    return () => window.removeEventListener('resize', listener);
  }, []);

  useEffect(() => {
    const subscription = width$.current.pipe(debounceTime(WIDTH_CHANGE_DELAY)).subscribe((value) => setWidth(value));

    return () => subscription.unsubscribe();
  }, []);

  const handleMapLoad = useCallback(() => {
    if (!ref.current) return;
    setWidth(ref.current.getBoundingClientRect().width);
  }, [ref.current?.getBoundingClientRect().width]);

  useLayoutEffect(() => {
    if (!ref.current) return;
    setWidth(ref.current.getBoundingClientRect().width);
  }, []);

  return coverSrc ? (
    <FoldedMapPreview
      ref={ref}
      coverSrc={coverSrc}
      width={width}
      mapFormat={format!}
      descriptionFrameSrc={descriptionFrameSrc}
      alternativeLayout={alternativeLayout}
      onLoad={handleMapLoad}
      dispatchZoomRequest={dispatchZoomRequest}
      wrapperProps={{ ...wrapperProps, className }}
    />
  ) : (
    <FlatMapPreview
      ref={ref}
      width={width}
      mapFormat={format!}
      descriptionFrameSrc={descriptionFrameSrc}
      woodFrameSrc={woodFrameSrc}
      rotate3d={type === MAP_TYPES.CANVAS}
      leftSideFrameSrc={leftSideFrameSrc}
      alternativeLayout={alternativeLayout}
      onLoad={handleMapLoad}
      dispatchZoomRequest={dispatchZoomRequest}
      wrapperProps={{ ...wrapperProps, className }}
    />
  );
};
