import { PixelCrop } from 'react-image-crop';
import { Observable, Observer } from 'rxjs';

export const imageCropToDataUrl = (
  imageUrl: string,
  pixelCrop: PixelCrop
): Observable<string> =>
  Observable.create((observer: Observer<string>) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    const ctx = canvas.getContext('2d');

    const image = new Image();

    image.onload = () => {
      let { x, y, width, height } = pixelCrop;
      x = Math.floor(x);
      y = Math.floor(y);
      width = Math.floor(width);
      height = Math.floor(height);

      if (ctx) {
        ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

        const coverUrl = canvas.toDataURL('image/jpeg');
        observer.next(coverUrl);
        observer.complete();
      }
    };

    image.src = imageUrl;
  });
