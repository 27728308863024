import Types from 'Types';

export const getProductPreviewImageUrl = (state: Types.RootState) => state.productPreview.productPreviewImageUrl;

export const getProductPreviewError = (state: Types.RootState) => state.productPreview.productPreviewError;

export const getProductPreviewCenterImage = (state: Types.RootState) =>
  state.productPreview.productPreviewCenterPoint.url;

export const getProductPreviewCenterImageError = (state: Types.RootState) =>
  state.productPreview.productPreviewCenterPoint.error;

export const getProductPreviewCenterImageIsLoading = (state: Types.RootState) =>
  state.productPreview.productPreviewCenterPoint.isPending;
