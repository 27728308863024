import React, { forwardRef } from 'react';
import { StyledIconProps } from '@styled-icons/styled-icon';
import styled from 'styled-components';

import crosshair, { ReactComponent } from './assets/crosshairs-thin.svg';

const Icon = styled(ReactComponent)`
  & * {
    fill: currentColor;
  }
`;

export const ThinCrosshair = forwardRef<SVGSVGElement, StyledIconProps>(({ size, ...props }, ref) => (
  <Icon ref={ref} width={size} height={size} viewBox="0 0 24 24" {...props} />
));

export const ThinCrosshairDiv = styled.div`
  background-image: url(${crosshair});
  background-size: contain;
`;
