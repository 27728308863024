import React, { FC, HTMLAttributes } from 'react';
import { fontFamily } from 'root/modules/styles';
import styled from 'styled-components';

export const H5 = styled.h5`
  ${fontFamily.sourcesanspro}
  font-weight: 500;
`;

type SubTitleProps = HTMLAttributes<HTMLHeadingElement> & {
  text?: string;
};

export const SubTitle: FC<SubTitleProps> = ({ text, children, ...props }) => {
  return <H5 {...props}>{text ?? children}</H5>;
};
