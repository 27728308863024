import * as React from 'react';
import { useSelector } from 'react-redux';

import Sidebar from './components/Sidebar';
import ProductPreviewOverlay from 'root/scenes/Home/components/ProductPreviewOverlay';
import styles from './index.module.scss';

import TourGuide from 'root/components/TourGuide';
import classNames from 'classnames';
import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { ProductPreview } from './components/ProductPreview';
import {
    getCreateProductJourneyStep,
    getIsProductPreviewOverlayOpened,
} from 'root/features/createProductJourney/selectors';

export const Home = () => {
    const isProductPreviewOverlayOpened = useSelector(getIsProductPreviewOverlayOpened);
    const productJourneyStep = useSelector(getCreateProductJourneyStep);

    return (
        <div
            className={classNames(
                styles['home'],
                productJourneyStep === CreateProductJourneyStep.PRODUCT_TYPE ? styles['home--product'] : null
            )}
        >
            <ProductPreview isHidden={isProductPreviewOverlayOpened} />
            <Sidebar />
            {isProductPreviewOverlayOpened && <ProductPreviewOverlay />}
            <TourGuide />
        </div>
    );
};
