import React, { useContext, useEffect } from 'react';
import { FileRejection } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { OptionType } from 'root/components/Select/model';
import {
    changeUploadedRouteError,
    handleRouteUpload,
    resetUploadedRoute,
    selectRoute,
    setSelectedRoute,
} from 'root/features/route/actions';

import { getRouteListSelector, getSelectedRouteForOptions, getUploadedRoute } from 'root/features/route/selectors';
import { RouteValidatorContext } from '../../../providers';

export const useRouteTab = () => {
    const { routeSettingValidator } = useContext(RouteValidatorContext);
    const { filename } = useSelector(getUploadedRoute);
    const options = useSelector(getRouteListSelector);
    const selectedRouteFromList = useSelector(getSelectedRouteForOptions);
    const dispatch = useDispatch();

    const isRouteActive = selectedRouteFromList !== null || filename;

    const onRouteStylesRestore = () => {
        if (routeSettingValidator) {
            dispatch(routeSettingValidator.restoreDefault());
        }
    };

    const resetRoute = () => {
        dispatch(resetUploadedRoute());
        dispatch(setSelectedRoute(null));
    };

    const onUploadRouteFile = (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        dispatch(handleRouteUpload(acceptedFiles, rejectedFiles));
    };

    const onChangeRoute = (route: OptionType) => {
        dispatch(changeUploadedRouteError(null));
        dispatch(selectRoute(route));
    };

    useEffect(() => {
        dispatch(changeUploadedRouteError(null));
    }, []);

    return {
        filename,
        resetRoute,
        onUploadRouteFile,
        onRouteStylesRestore,
        options,
        onChangeRoute,
        selectedRouteFromList,
        isRouteActive,
        routeSettingValidator,
    };
};
