import * as React from 'react';
import { useSelector } from 'react-redux';
import Draggable, { ControlPosition, DraggableEventHandler } from 'react-draggable';

import { AreaAndStyleSelectedTab, IMapLocationCoordinates } from 'root/model/map/MapParams';
import { MapStyle, MapType, Scale, Size } from 'root/model/map/MapEnums';
import { Map } from 'root/components/Map';
import { getProportion } from 'root/components/Map/utils/utils';
import { createMapStyles } from 'root/modules/utils/functions';
import {
    getAreaAndStyleSelectedTab,
    getDetailedViewCoordinates,
    getMapScale,
    getMapStyle,
} from 'root/features/map/selectors';
import { getRouteSetting, getSelectedRoute } from 'root/features/route/selectors';
import { getMapStyles, getMapStylesApi } from 'root/features/mapStyles/selectors';
import { MapVisibleBoundsType } from 'root/components/Map/map';

import { DETAILED_VIEW_MAP_SCALE_TO_ZOOM } from './constants';
import { createHideOutsidePolygon } from './utils';
import styles from './index.module.scss';
import { Bounds } from '../../types';
import classNames from 'classnames';
import { getMapViewOptions } from '../../utils';
import { MAP_FORMAT, useMapHooks } from 'root/modules/types';
import { CENTER_IMAGE_ZOOM } from 'root/modules/ProductPreview/components/MapPreview';

type DetailedViewMapProps = {
    mapSize: Size;
    mapType: MapType;
    zoomRef: React.RefObject<HTMLDivElement>;
    bounds: Bounds;
    mapVisibleBounds: MapVisibleBoundsType;
    glassLocation: ControlPosition;
    isGlassDragged: boolean;
    onGlassDrag: DraggableEventHandler;
    onGlassDragStart: () => void;
    zoomHoverStarts: () => void;
    zoomHoverEnds: () => void;
};

export const DetailedViewMap = ({
    mapSize,
    mapType,
    zoomRef,
    bounds,
    glassLocation,
    mapVisibleBounds,
    isGlassDragged,
    onGlassDrag,
    onGlassDragStart,
    zoomHoverStarts,
    zoomHoverEnds,
}: DetailedViewMapProps) => {
    const { mapFormat } = useMapHooks();

    const mapScale = useSelector(getMapScale) as Scale;
    const mapStyles = useSelector(getMapStyles);
    const mapStyle = useSelector(getMapStyle) as MapStyle;
    const detailedViewCoordinates = useSelector(getDetailedViewCoordinates) as IMapLocationCoordinates;
    const mapStylesApi = useSelector(getMapStylesApi(mapScale));
    const route = useSelector(getSelectedRoute);
    const routeSetting = useSelector(getRouteSetting);
    const areaAndStyleSelectedTab = useSelector(getAreaAndStyleSelectedTab);

    const mapZoom = DETAILED_VIEW_MAP_SCALE_TO_ZOOM[mapStyle];
    const feature = createHideOutsidePolygon(mapVisibleBounds);

    const baseClassName = 'detailed-view-map';
    const glassDraggedClassName = classNames(styles[`${baseClassName}__zoom__map`], {
        [styles[`${baseClassName}__zoom__map--dragged`]]: isGlassDragged,
    });
    const zoomCursorClassName = classNames(styles[`${baseClassName}__zoom__cursor`], {
        [styles[`${baseClassName}__zoom__cursor--dragged`]]: isGlassDragged,
    });

    const format = mapFormat(mapSize) as MAP_FORMAT;

    const resolution = {
        fullScaleMap: {
            width: 20800,
            height: 20800,
        },
        a2FullScaleMap: {
            width: 20800,
            height: 12800,
        },
        a3FramedFullScaleMap: {
            width: 20800,
            height: 12696,
        },
        a3FoldedFullScaleMap: {
            width: 20800,
            height: 14750,
        },
        miniScaleMap: {
            width: 2000,
            height: 2000,
        },
    };

    const mapResolution = 1500;

    const newThickness =
        (mapResolution / resolution.miniScaleMap.height) *
        Math.pow(2, CENTER_IMAGE_ZOOM[format]) *
        routeSetting.lineThickness;

    return (
        <Draggable bounds={bounds} onDrag={onGlassDrag} position={glassLocation} onStart={onGlassDragStart}>
            <div
                className={styles[`${baseClassName}__zoom`]}
                ref={zoomRef}
                onMouseEnter={() => zoomHoverStarts()}
                onMouseLeave={() => zoomHoverEnds()}
            >
                <div className={glassDraggedClassName}>
                    <Map
                        className={styles[baseClassName]}
                        view={getMapViewOptions(detailedViewCoordinates, mapZoom, mapStylesApi)}
                        mapStyles={createMapStyles(mapStyles, mapScale)}
                        feature={feature}
                        proportion={getProportion(mapSize, mapType)}
                        interactive={true}
                        mapScale={mapScale}
                        route={route ? route : undefined}
                        routeSetting={{ ...routeSetting, lineThickness: newThickness }}
                        isRouteActive={areaAndStyleSelectedTab === AreaAndStyleSelectedTab.Route}
                    />
                </div>
                {isGlassDragged && <div className={styles[`${baseClassName}__zoom__crosshair`]} />}
                <svg className={zoomCursorClassName}>
                    <circle cx="50%" cy="50%" r="50%" />
                </svg>
            </div>
        </Draggable>
    );
};
