import { convertSizeToSheetSize, ProductId, SheetSize } from 'root/model/map/MapEnums';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { RecomendationStatus, RouteForBasketType } from 'root/model/route/Route';
import * as Types from 'Types';

export const getRouteListSelector = (state: Types.RootState) => {
    const sheetSize = convertSizeToSheetSize(state.map.mapOptionsParams.size);
    const productId = state.map.mapOptionsParams.productId;

    return state.route.routeList.reduce(
        (acc, el) => {
            const isOptimal = (el.config || []).filter((config) => {
                const allowedRecommendation =
                    config.recommendationStatus === RecomendationStatus.Warning1 ||
                    config.recommendationStatus === RecomendationStatus.Default ||
                    config.recommendationStatus === RecomendationStatus.Blank;

                if (sheetSize === SheetSize.LARGE && productId === ProductId.FOLDED_MAP) {
                    return config.type === 'FOLDED' && config.sheetSize === sheetSize && allowedRecommendation;
                }

                return config.sheetSize === sheetSize && allowedRecommendation;
            });
            const route = {
                value: el.id,
                label: el.metadata.name,
            };

            if (isOptimal.length > 0) {
                return { ...acc, optimal: [...acc.optimal, route] };
            } else {
                return { ...acc, notOptimal: [...acc.notOptimal, route] };
            }
        },
        { optimal: [], notOptimal: [] }
    );
};

export const getSelectedRoute = (state: Types.RootState) => {
    return state.route.selectedRoute;
};

export const getSelectedRouteForOptions = (state: Types.RootState) => {
    if (state.route.selectedRoute === null) {
        return null;
    }

    return {
        value: state.route.selectedRoute.id,
        label: state.route.selectedRoute.metadata.name,
    };
};

export const getRouteSetting = (state: Types.RootState) => {
    return state.route.routeSetting;
};

export const getRouteSettingColor = (state: Types.RootState) => {
    return state.route.routeSetting.lineColourHex;
};

export const getRouteSettingThickness = (state: Types.RootState) => {
    return state.route.routeSetting.lineThickness;
};

export const getRouteSettingOpacity = (state: Types.RootState) => {
    return state.route.routeSetting.lineOpacity;
};

export const getRouteForBasket = (state: Types.RootState) => {
    if (
        state.route.selectedRoute === null ||
        state.map.areaAndStyleTab.selectedTab === AreaAndStyleSelectedTab.CentreLocation
    ) {
        return undefined;
    }

    return ({
        routeId: state.route.selectedRoute.id,
        routeType: state.route.selectedRoute.routeType,
        name: state.route.selectedRoute.metadata.name,
        settings: state.route.routeSetting,
    } as unknown) as RouteForBasketType;
};

export const getUploadedRoute = (state: Types.RootState) => state.route.uploadedRoute;

export const getIsGpxUploaded = (state: Types.RootState) => {
    const route = state.route.selectedRoute;

    if (route && route.routeType === 'gpx') {
        return true;
    }

    return false;
};
