import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { GpsFixed } from '@styled-icons/material/GpsFixed';
import { Close } from '@styled-icons/material/Close';
import isEqual from 'lodash/isEqual';

import { useTranslation } from 'root/modules/utils/locales';
import { useAnimationProps } from 'root/modules/styles';
import { CenterMapIcon, ButtonStyle } from 'root/modules/components';
import * as P from './parts';
import { ReactComponent as RestoreIcon } from '../../../assets/restore.svg';

import { restoreRouteCoordinates } from 'root/features/route/actions';
import { getMapLocationCoordinates, getAreaAndStyleSelectedTab } from 'root/features/map/selectors';
import { getSelectedRoute } from 'root/features/route/selectors';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { ZoomControl } from './components';
import { SwitchStyleModal, MoreToolsModal } from './components/Modals';
import { thinnerThan, widerThanOrEqual } from 'root/services/mediaQuery';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { getMediaQuery } from 'root/features/mediaQuery/selectors';
import { toggleMiniMap } from 'root/features/map/actions';

export type MapControlPanelProps = {
    isMagnifierOpen: boolean;
    showResetMap: boolean;
    setShowResetMap: Dispatch<React.SetStateAction<boolean>>;
    onMagnifierOpen: (flag: boolean) => void;
    onReturnToCentreClick: () => void;
    onSetNewCentreClick: () => void;
    disableCentreButtons: boolean;
    isUkMapOpen: boolean;
    onUkMapClick: (value: boolean) => void;
    isMoreToolActive: boolean;
    isSwitchMapStyleActive: boolean;
    onMoreToolsChange: () => void;
    onSwitchMapStyle: () => void;
    className?: string;
};

export const MapControlPanel = ({
    isMagnifierOpen,
    showResetMap,
    setShowResetMap,
    onMagnifierOpen,
    onReturnToCentreClick,
    onSetNewCentreClick,
    disableCentreButtons,
    isUkMapOpen,
    onUkMapClick,
    isMoreToolActive,
    isSwitchMapStyleActive,
    onMoreToolsChange,
    onSwitchMapStyle,
    className,
}: MapControlPanelProps) => {
    const [t] = useTranslation();
    const dispatch = useDispatch();
    const selectedRoute = useSelector(getSelectedRoute);
    const currentCoordinates = useSelector(getMapLocationCoordinates);
    const selectedTab = useSelector(getAreaAndStyleSelectedTab);
    const mediaQuery = useSelector(getMediaQuery);

    const [temporaryHideMinimap, setTemporaryHideMinimap] = useState<boolean>(false);

    const isMobile = thinnerThan(mediaQuery, MediaQuery.MEDIUM);
    const isMobileMagnifierOpen = (isMagnifierOpen && isMobile) || (showResetMap && isMobile);

    const animationProps = useAnimationProps(isMagnifierOpen || showResetMap);

    const handleCloseMagnifier = () => {
        onMagnifierOpen(false);

        if (temporaryHideMinimap) {
            dispatch(toggleMiniMap(true));
            setTemporaryHideMinimap(false);
        }
    };

    useEffect(() => {
        if (
            selectedTab === AreaAndStyleSelectedTab.Route &&
            currentCoordinates &&
            selectedRoute &&
            !isEqual(selectedRoute.bngCoordinates, currentCoordinates)
        ) {
            setShowResetMap(true);
        } else {
            setShowResetMap(false);
        }
    }, [currentCoordinates, selectedTab, selectedRoute]);

    return (
        <P.ControlPanel className={className} isMobileMagnifierOpen={isMobileMagnifierOpen}>
            <P.MagnifierControls isMobileMagnifierOpen={isMobileMagnifierOpen}>
                <P.ZoomControlContainer>
                    {widerThanOrEqual(mediaQuery, MediaQuery.MEDIUM) && <ZoomControl />}

                    <SwitchStyleModal
                        isSwitchMapStyleActive={isSwitchMapStyleActive}
                        setSwitchMapStyleActive={onSwitchMapStyle}
                    />

                    {thinnerThan(mediaQuery, MediaQuery.MEDIUM) && <ZoomControl />}

                    <MoreToolsModal
                        isMoreToolActive={isMoreToolActive}
                        onMoreToolsChange={onMoreToolsChange}
                        isMagnifierOpen={isMagnifierOpen}
                        onMagnifierOpen={onMagnifierOpen}
                        isUkMapOpen={isUkMapOpen}
                        onUkMapClick={onUkMapClick}
                        setTemporaryHideMinimap={setTemporaryHideMinimap}
                    />
                </P.ZoomControlContainer>

                <P.AnimationWrapper {...animationProps}>
                    <P.MagnifierActions {...animationProps}>
                        {isMagnifierOpen && (
                            <>
                                <P.Button
                                    Icon={Close}
                                    label={t(({ button }) => button.zoom)}
                                    onClick={handleCloseMagnifier}
                                />

                                <P.Button
                                    Icon={GpsFixed}
                                    buttonStyle={ButtonStyle.Third}
                                    label={t(({ button }) => button.returnToCentre)}
                                    onClick={onReturnToCentreClick}
                                    disabled={disableCentreButtons}
                                />

                                <P.Button
                                    Icon={CenterMapIcon}
                                    buttonStyle={ButtonStyle.Third}
                                    label={t(({ button }) => button.setNewCentre)}
                                    onClick={onSetNewCentreClick}
                                    disabled={disableCentreButtons}
                                />
                            </>
                        )}

                        {showResetMap && (
                            <P.Button
                                Icon={RestoreIcon}
                                label={t(({ button }) => button.resetMap)}
                                onClick={() => dispatch(restoreRouteCoordinates())}
                                buttonStyle={ButtonStyle.Third}
                            />
                        )}
                    </P.MagnifierActions>
                </P.AnimationWrapper>
            </P.MagnifierControls>
        </P.ControlPanel>
    );
};
