import { MapStyle, MapType, Size } from 'root/model/map/MapEnums';

import topExplorerBackground from './assets/topExplorerBackground.jpg';
import topLandrangerBackground from './assets/topLandrangerBackground.jpg';
import topSmallExplorerBackground from './assets/topSmallExplorerBackground.jpg';
import topSmallLandrangerBackground from './assets/topSmallLandrangerBackground.jpg';

type MapScaleToBackgroundType = Record<Size, Record<MapStyle, string | undefined>>;

export const MAP_SCALE_TO_BACKGROUND: MapScaleToBackgroundType = {
    [Size.SMALL]: {
        [MapStyle.OS_EXPLORER]: topSmallExplorerBackground,
        [MapStyle.OS_LANDRANGER]: topSmallLandrangerBackground,
    },
    [Size.LARGE]: {
        [MapStyle.OS_EXPLORER]: topExplorerBackground,
        [MapStyle.OS_LANDRANGER]: topLandrangerBackground,
    },
    [Size.MEDIUM]: {
        [MapStyle.OS_EXPLORER]: undefined,
        [MapStyle.OS_LANDRANGER]: undefined,
    },
    [Size.NONE]: {
        [MapStyle.OS_EXPLORER]: undefined,
        [MapStyle.OS_LANDRANGER]: undefined,
    },
};
