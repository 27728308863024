export const INTERFACE_FEED = 'interfaceFeed';

export const PRODUCT_FEED = 'productFeed';

export const CACHE_GROUP = 'custommade_configurable';

export const GET_PRODUCT_OPTIONS_ERROR_MESSAGE =
  'Error getting products option data!';

export const GET_PRODUCT_SKU_ERROR_MESSAGE = 'Error getting product SKU!';

export const GET_CACHE_DATA_ERROR_MESSAGE = 'Error getting cache data!';
