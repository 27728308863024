import { combineEpics, Epic } from 'redux-observable';
import { concat, EMPTY, of } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';
import Types from 'Types';
import {
    createProductJourney,
    CreateProductJourneyAction,
    CreateProductJourneyStep,
} from 'root/model/createProductJourney/CreateProductJourney';
import { Framing, MapType, ProductId, Size } from 'root/model/map/MapEnums';
import {
    createProductJourneyActions,
    createProductJourneyConstants,
    createProductJourneySelectors,
} from 'features/createProductJourney';
import { userInfoModalActions } from 'features/userInfoModal';
import { mapActions, mapSelectors } from 'features/map';
import { customistaionSelectors } from 'features/customisation';
import { MessageType } from 'root/services/fileHelpers/consts';
import { mediaQueryActions } from 'features/mediaQuery';
import { resetApplicationState } from 'root/appConfig/store/rootActions';
import { checkoutOptionsSelectors } from '../checkoutOptions';
import { RoutePath } from 'root/appConfig/routes/enums';
import { getFramings } from '../map/selectors';
import { getFraming } from '../map/selectors';
import { setMapOptionsParams } from 'features/map/actions';
import { createBrowserHistory } from 'history';
import { datadogLogs } from '@datadog/browser-logs';
import { getEnvName } from 'root/appConfig/utils';
import { routeActions, routeSelectors } from '../route';

export const dispatchCreateProductJourneyAction: Epic<
    Types.RootAction,
    Types.RootAction,
    Types.RootState,
    Types.Services
> = (action$, state$, { createProductJourney: createProductJourneyService }) =>
    action$.pipe(
        filter(isOfType(createProductJourneyConstants.DISPATCH_JOURNEY_ACTION)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const history = createBrowserHistory();

            const currentStep = createProductJourneySelectors.getCreateProductJourneyStep(state);
            const isContinueShopping = action.payload === CreateProductJourneyAction.CONTINUE_SHOPPING;
            const isRedirect = action.payload === CreateProductJourneyAction.REDIRECT;
            let next = createProductJourneyService.getNextJourneyStep(
                createProductJourney,
                currentStep,
                action.payload,
                isRedirect
            );

            if (isRedirect) {
                if (next['redirect'] && next['to']) {
                    return concat(
                        of(createProductJourneyActions.redirectPage(next['redirect'])),
                        of(createProductJourneyActions.setCreateProductJourneyStep(next['to']))
                    );
                } else {
                    return concat(
                        of(createProductJourneyActions.redirectPage(RoutePath.ROOT)),
                        of(
                            createProductJourneyActions.setCreateProductJourneyStep(
                                CreateProductJourneyStep.PRODUCT_TYPE
                            )
                        )
                    );
                }
            } else if (isContinueShopping) {
                history.push(`/`);

                return concat(of(resetApplicationState()), of(mediaQueryActions.updateMediaQuery(window.innerWidth)));
            } else {
                if (typeof next === 'object') {
                    const conditionalTo = next as {
                        framed: CreateProductJourneyStep;
                        nonframed: CreateProductJourneyStep;
                    };
                    const framings = getFramings(state);
                    next = framings && framings[0] !== Framing.NONE ? conditionalTo.framed : conditionalTo.nonframed;

                    if (getFraming(state)) {
                        history.push(`${next.replace('CreateProductJourneyStep', '')}/`);

                        return concat(of(createProductJourneyActions.setCreateProductJourneyStep(next)));
                    }

                    return concat(
                        of(createProductJourneyActions.setCreateProductJourneyStep(next)),
                        of(setMapOptionsParams({ framing: Framing.NONE }))
                    );
                }

                history.push(`${next.replace('CreateProductJourneyStep', '')}/`);

                return of(createProductJourneyActions.setCreateProductJourneyStep(next));
            }
        })
    );

export const sendOrder: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { basket: basketService }
) =>
    action$.pipe(
        filter(isOfType(createProductJourneyConstants.SEND_ORDER)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const coordinates = mapSelectors.getMapLocationCoordinates(state);

            if (!coordinates) {
                return EMPTY;
            }

            const productId = mapSelectors.getProductId(state);
            const titles = customistaionSelectors.getTitles(state);
            const linkToImage = customistaionSelectors.getLinkToImage(state);
            const productParams = mapSelectors.getProductParams(state);
            const quantity = checkoutOptionsSelectors.getQuantity(state);

            const mapSize = mapSelectors.getMapSize(state) as Size;
            const mapType = mapSelectors.getMapType(state);

            const routeSpecification = routeSelectors.getRouteForBasket(state);

            const isRectangleFramed =
                mapType === MapType.CANVAS_AND_FRAMED && [Size.SMALL, Size.MEDIUM].includes(mapSize);

            const customisationTitle = {
                title: [titles.titleFirstLine],
                subtitle: [titles.subtitleFirstLine],
                spine:
                    productId === ProductId.FOLDED_MAP && mapSize !== Size.SMALL && !isRectangleFramed
                        ? titles.spine
                        : null,
            };

            if (!isRectangleFramed) {
                customisationTitle.title.push(titles.titleSecondLine);

                if (mapSize !== Size.SMALL) {
                    customisationTitle.title.push(titles.titleThirdLine);
                    customisationTitle.subtitle.push(titles.subtitleSecondLine);
                }
            }

            datadogLogs.logger.log(
                JSON.stringify({
                    productInfo: {
                        sku: productParams.sku,
                        geometry: {
                            type: 'Point',
                            coordinates: [coordinates.x, coordinates.y],
                        },
                        routeSpecification,
                        ...customisationTitle,
                        cover: productId === ProductId.FOLDED_MAP ? linkToImage : null,
                        quantity,
                    },
                }),
                { env: getEnvName() }
            );

            return basketService
                .addToBasket(
                    productParams.sku,
                    coordinates,
                    customisationTitle,
                    productId === ProductId.FOLDED_MAP ? linkToImage : null,
                    quantity,
                    routeSpecification
                )
                .pipe(
                    switchMap(() => {
                        return concat(
                            of(createProductJourneyActions.finishSendOrder()),
                            of(
                                userInfoModalActions.showUserInfoModal({
                                    contentType: MessageType.SEND_ORDER_SUCCESS,
                                    headerText: 'Added to your basket.',
                                })
                            ),
                            of(routeActions.resetSelectedRoute()),
                            of(mapActions.resetMapLocationParams())
                        );
                    }),
                    catchError(() => {
                        return concat(
                            of(createProductJourneyActions.finishSendOrder()),
                            of(
                                userInfoModalActions.showUserInfoModal({
                                    contentType: MessageType.SEND_ORDER_ERROR,
                                    headerText: 'Sorry, there has been a problem adding your map to the basket.',
                                })
                            )
                        );
                    })
                );
        })
    );

export default combineEpics(dispatchCreateProductJourneyAction, sendOrder);
