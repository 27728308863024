import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action, createBrowserHistory } from 'history';

import { PRODUCT_PREVIEW_RESOLUTION } from 'root/constants';
import { getProductPreview, resetProductPreviewImageUrl } from 'root/features/productPreview/actions';
import { setCreateProductJourneyStep, setEntryHistoryPointMounted } from 'root/features/createProductJourney/actions';
import { hideUserInfoModal } from 'root/features/userInfoModal/actions';
import { ValidationContext, ValidationContextType } from '../../../providers/validation';
import { getAreaAndStyleSelectedTab } from 'root/features/map/selectors';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { getSelectedRoute } from 'root/features/route/selectors';
import {
    canProceedToNextStep as canProceedToNextStepSelector,
    getCreateProductJourneyStep,
    getIsCustomiseStep,
    getIsEntryHistoryPointMounted,
} from 'root/features/createProductJourney/selectors';

import { TAB_INDEX_TO_JOURNEY_STEP } from '../constants';

export const useSideBarTabs = (onTabSelect: () => void) => {
    const { setDisplayValidation } = useContext(ValidationContext) as ValidationContextType;
    const createProductJourneyStep = useSelector(getCreateProductJourneyStep);
    const canProceedToNextStep = useSelector(canProceedToNextStepSelector);
    const isCustomiseStep = useSelector(getIsCustomiseStep);
    const isEntryHistoryPointMounted = useSelector(getIsEntryHistoryPointMounted);
    const areaAndStyleSelectedTab = useSelector(getAreaAndStyleSelectedTab);
    const selectedRoute = useSelector(getSelectedRoute);

    const history = createBrowserHistory();
    const dispatch = useDispatch();

    let listensToHistory = false;

    const getDefaultTabsIndex = useCallback((): number => {
        const index = TAB_INDEX_TO_JOURNEY_STEP.filter((item) => item.step === createProductJourneyStep).map(
            (item) => item.index
        );
        return index.length > 0 ? index[0] : 0;
    }, [createProductJourneyStep, TAB_INDEX_TO_JOURNEY_STEP]);

    const onSelect = useCallback(
        (index: number, lastIndex: number) => {
            const goNext = index > lastIndex;
            if (index === lastIndex || (!canProceedToNextStep && goNext)) {
                setDisplayValidation(true);
                return;
            }

            setDisplayValidation(false);
            onTabSelect();

            const nextStepItems = TAB_INDEX_TO_JOURNEY_STEP.filter((item) => item.index === index).map(
                (item) => item.step
            );
            let nextStep = nextStepItems.length > 0 ? nextStepItems[0] : CreateProductJourneyStep.PRODUCT_TYPE;

            if (
                lastIndex === 0 &&
                nextStep === CreateProductJourneyStep.CUSTOMISE &&
                (areaAndStyleSelectedTab === AreaAndStyleSelectedTab.None ||
                    (areaAndStyleSelectedTab === AreaAndStyleSelectedTab.Route && selectedRoute === null))
            ) {
                // this step means that the user should choose one of two options in the area and style tab
                nextStep = CreateProductJourneyStep.AREA_AND_STYLE;
            }

            if (nextStep === CreateProductJourneyStep.CUSTOMISE) {
                dispatch(resetProductPreviewImageUrl());
                dispatch(getProductPreview(PRODUCT_PREVIEW_RESOLUTION));
            }

            history.push(`/${nextStep.replace('CreateProductJourneyStep/', '')}/`);

            dispatch(setCreateProductJourneyStep(nextStep as CreateProductJourneyStep));
        },
        [canProceedToNextStep, TAB_INDEX_TO_JOURNEY_STEP, areaAndStyleSelectedTab]
    );

    useEffect(() => {
        const pathNameToStep = {
            '/productType/': CreateProductJourneyStep.PRODUCT_TYPE,
            '/areaAndStyle/': CreateProductJourneyStep.AREA_AND_STYLE,
            '/customise/': CreateProductJourneyStep.CUSTOMISE,
        };

        if (!isEntryHistoryPointMounted) {
            history.push(`/productType/`);
            dispatch(setEntryHistoryPointMounted(true));
        }

        if (!listensToHistory) {
            history.listen((location, action: Action) => {
                const shouldRedirect = true;
                const pathname = location.pathname as keyof typeof pathNameToStep;

                if (shouldRedirect && pathNameToStep[pathname]) {
                    dispatch(hideUserInfoModal());
                    dispatch(setCreateProductJourneyStep(pathNameToStep[pathname]));
                }
                listensToHistory = true;
            });
        }
    }, []);

    return {
        getDefaultTabsIndex,
        onSelect,
        isCustomiseStep,
    };
};
