import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { mapSelectors } from 'root/features/map';

import { WOOD_MAP_FRAME, LEFT_SIDE_FRAME, MAP_INNER_FRAME, FOLDED_COVER } from 'root/modules/utils/assets';
import { CANVAS_MAP, MAP_SIZE, MAP_TYPES, PAPER_MAP, useMapHooks } from 'root/modules/types';

const useMapSelector = () => {
  const {
    mapCanvas,
    mapFormat,
    mapFrameColor,
    mapFramed,
    mapLanguage,
    mapPaper,
    mapStyle: mapStyleFunc,
    mapSize,
    mapType,
  } = useMapHooks();

  const storeLanguage = useSelector(mapSelectors.getMapLanguage);
  const storeMaterial = useSelector(mapSelectors.getMaterial);
  const storeSize = useSelector(mapSelectors.getMapSize);
  const storeMapStyle = useSelector(mapSelectors.getMapStyle);
  const storeFrame = useSelector(mapSelectors.getFraming);
  const storeProductId = useSelector(mapSelectors.getProductId);

  const mapped = useMemo(() => {
    const format = mapFormat(storeSize);
    const type = mapType(storeProductId, storeMaterial, storeFrame);
    const paperMap = mapPaper(type, storeProductId, storeSize);
    const framedMap = mapFramed(type, storeSize);
    const canvasMap = mapCanvas(type, storeFrame);
    const language = mapLanguage(storeLanguage);
    const frameColor = mapFrameColor(storeFrame);
    const mapStyle = mapStyleFunc(storeMapStyle);
    const size = mapSize(storeSize);

    return {
      format,
      type,
      paperMap,
      framedMap,
      canvasMap,
      language,
      frameColor,
      mapStyle,
      size,
    };
  }, [storeLanguage, storeMaterial, storeSize, storeMapStyle, storeFrame, storeProductId]);

  return mapped;
};

export const useProductPreviewProps = () => {
  const { format, type, size, frameColor, canvasMap, paperMap, framedMap, language, mapStyle } = useMapSelector();

  const woodFrameSrc = useMemo<string | undefined>(
    () =>
      type && [MAP_TYPES.FRAMED, MAP_TYPES.CANVAS].includes(type) && size && frameColor
        ? WOOD_MAP_FRAME[size][frameColor]
        : undefined,
    [type, size, frameColor, canvasMap]
  );

  const descriptionFrameSrc = useMemo<string | undefined>(() => {
    if (!type || !language || !mapStyle) return undefined;

    if (type === MAP_TYPES.PAPER) {
      if (!paperMap) return undefined;
      return MAP_INNER_FRAME[type][paperMap]?.[language][mapStyle];
    }

    if (type === MAP_TYPES.FRAMED) {
      if (!framedMap) return undefined;
      return MAP_INNER_FRAME[type][framedMap][language][mapStyle];
    }

    if (type === MAP_TYPES.CANVAS) {
      if (!canvasMap) return undefined;
      return MAP_INNER_FRAME[type][canvasMap][language][mapStyle];
    }

    return undefined;
  }, [type, size, frameColor, canvasMap, paperMap, framedMap, language, mapStyle]);

  const coverSrc = useMemo<string | undefined>(
    () =>
      !type ||
      type !== MAP_TYPES.PAPER ||
      !size ||
      size === MAP_SIZE.MEDIUM ||
      !mapStyle ||
      paperMap === PAPER_MAP.LARGE_FLAT
        ? undefined
        : FOLDED_COVER[size][mapStyle],
    [type, size, mapStyle]
  );

  const leftSideFrameSrc = useMemo<string | undefined>(
    () =>
      !type || type !== MAP_TYPES.CANVAS || !frameColor || canvasMap !== CANVAS_MAP.FRAMED
        ? undefined
        : LEFT_SIDE_FRAME[frameColor],
    [type, frameColor, canvasMap]
  );

  return {
    format,
    type,
    woodFrameSrc,
    descriptionFrameSrc,
    leftSideFrameSrc,
    coverSrc,
  };
};
