import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Switch } from 'root/components/Switch';
import { useTranslation } from 'root/modules/utils/locales';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { widerThanOrEqual } from 'root/services/mediaQuery';
import { getMediaQuery } from 'root/features/mediaQuery/selectors';
import { getIsMiniMapOpen } from 'root/features/map/selectors';
import { toggleMiniMap } from 'root/features/map/actions';

import { ReactComponent as MagnifierGlassIcon } from '../../assets/magnifierGlass.svg';
import { ReactComponent as UKMapIcon } from '../../assets/ukMap.svg';
import { SwitchStyleModalProps } from '../../MoreToolsModal';

import styles from './moretools.module.scss';

type MoreToolsContentProps = Pick<
    SwitchStyleModalProps,
    'onMagnifierOpen' | 'onUkMapClick' | 'isUkMapOpen' | 'setTemporaryHideMinimap'
>;

export const MoreToolsContent = ({
    onMagnifierOpen,
    onUkMapClick,
    isUkMapOpen,
    setTemporaryHideMinimap,
}: MoreToolsContentProps) => {
    const [t] = useTranslation();
    const mediaQuery = useSelector(getMediaQuery);
    const isMiniMapOpen = useSelector(getIsMiniMapOpen);
    const dispatch = useDispatch();

    const handleSwitchMiniMap = useCallback(() => {
        dispatch(toggleMiniMap());
    }, []);

    const handleMagnifierOpen = useCallback(() => {
        onMagnifierOpen(true);

        if (isMiniMapOpen) {
            dispatch(toggleMiniMap(false));
            setTemporaryHideMinimap(true);
        }
    }, [isMiniMapOpen]);

    return (
        <>
            <hr className={styles['tool__separator']} />

            <div className={styles['tool__container']} onClick={handleMagnifierOpen}>
                <MagnifierGlassIcon className={styles['tool__icon']} />
                {t(({ button }) => button.zoom)}
            </div>

            <hr className={styles['tool__separator']} />

            <div className={styles['tool__container']} onClick={() => onUkMapClick(!isUkMapOpen)}>
                <UKMapIcon className={styles['tool__icon']} />
                <span>{t(({ button }) => button.ukMapPreview)}</span>
            </div>

            <hr className={styles['tool__separator']} />

            {widerThanOrEqual(mediaQuery, MediaQuery.MEDIUM) && (
                <div className={styles['tool__container']} onClick={handleSwitchMiniMap}>
                    <span className={styles['tool__mb20']}>{t(({ button }) => button.showMiniMap)}</span>
                    <Switch
                        checked={isMiniMapOpen}
                        id={'switch-mini-map'}
                        ariaLabel={t(({ ariaLabel }) => ariaLabel.switchMiniMap)}
                    />
                </div>
            )}
        </>
    );
};
