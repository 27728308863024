import styled, { css } from 'styled-components';

import { breakpoint, offset } from 'root/modules/styles';
import { Button, IconButton } from 'root/modules/components';

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: ${offset.medium};
`;

export const EditButton = styled(IconButton)`
  padding-left: ${offset.medium};
  padding-right: ${offset.medium};

  align-items: flex-start;
`;

const ADD_TO_BAG_BUTTON_WIDTH = '255px';

export const AddToBagButton = styled(Button)`
  width: ${ADD_TO_BAG_BUTTON_WIDTH};

  ${breakpoint.medium(css`
    width: 100%;
  `)}
`;
