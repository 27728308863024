import { switchMap, catchError, filter, withLatestFrom, map } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { isActionOf, isOfType } from 'typesafe-actions';

import Types from 'Types';
import {
    ProductPreviewActions,
    setErrorLoadingProductPreviewUrl,
    setProductPreviewImageUrl,
    ProductPreviewActionsType,
    productPreviewActions,
} from './actions';
import { GET_PRODUCT_PREVIEW } from './constants';
import { mapSelectors } from 'features/map';
import { EMPTY, of } from 'rxjs';
import { Size, ProductId } from 'root/model/map/MapEnums';
import { routeSelectors } from '../route';

export const getProductPreviewAction: Epic<
    ProductPreviewActions,
    ProductPreviewActions,
    Types.RootState,
    Types.Services
> = (action$, state$, { file: fileService, productPreview }) =>
    action$.pipe(
        filter(isOfType(GET_PRODUCT_PREVIEW)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const resolution: number = action.payload;
            const coordinates = mapSelectors.getMapLocationCoordinates(state);
            const scale = mapSelectors.getMapScale(state);
            const mapSize = mapSelectors.getMapSize(state) as Size;
            const productId = mapSelectors.getProductId(state) as ProductId;
            const route = routeSelectors.getRouteForBasket(state);

            if (!coordinates || !scale) {
                return EMPTY;
            }

            return productPreview
                .getProductPreview(coordinates, scale, resolution, mapSize, productId, undefined, route)
                .pipe(
                    switchMap((result) => {
                        return fileService.getBlobDataUrl(new Blob([result], { type: 'image/png' }));
                    }),
                    map((url) => {
                        return setProductPreviewImageUrl(url);
                    }),
                    catchError(() => {
                        return of(setErrorLoadingProductPreviewUrl(true));
                    })
                );
        }),
        catchError(() => {
            return of(setErrorLoadingProductPreviewUrl(true));
        })
    );

const getProductPreviewCenterPoint: Epic<
    ProductPreviewActionsType,
    ProductPreviewActionsType,
    Types.RootState,
    Types.Services
> = (action$, state$, { file: fileService, productPreview }) =>
    action$.pipe(
        filter(isActionOf(productPreviewActions.request)),
        switchMap((action) => {
            const { resolution, zoom } = action.payload;
            const coordinates = mapSelectors.getMapLocationCoordinates(state$.value);
            const scale = mapSelectors.getMapScale(state$.value);
            const mapSize = mapSelectors.getMapSize(state$.value) as Size;
            const productId = mapSelectors.getProductId(state$.value) as ProductId;
            const route = routeSelectors.getRouteForBasket(state$.value);

            if (!coordinates || !scale) {
                return EMPTY;
            }

            return productPreview
                .getProductPreview(coordinates, scale, resolution, mapSize, productId, zoom, route)
                .pipe(
                    switchMap((result) => fileService.getBlobDataUrl(new Blob([result], { type: 'image/png' }))),
                    map((url) => productPreviewActions.success({ url })),
                    catchError((error) => of(productPreviewActions.error(error)))
                );
        }),
        catchError((error) => of(productPreviewActions.error(error)))
    );

export default combineEpics(getProductPreviewAction, getProductPreviewCenterPoint);
