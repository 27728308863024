import { Epic, ofType, combineEpics } from 'redux-observable';
import { of, concat, EMPTY } from 'rxjs';
import { catchError, switchMap, filter, withLatestFrom } from 'rxjs/operators';
import { ActionType, isOfType } from 'typesafe-actions';

import Types from 'Types';
import { coverActions } from 'features/cover';
import { customisationActions } from 'features/customisation';
import { userInfoModalActions } from 'features/userInfoModal';
import { FILE_CONSTS, MessageType } from 'root/services/fileHelpers/consts';

import { ImageCropActions } from './reducer';
import { HANDLE_SIZE_CHANGE, SEND_IMAGE_TO_SERVER, SET_CROPPED_COVER, ROTATE_IMAGE } from './constants';
import { imageCropActions } from '.';

type SetCroppedCoverAction = ActionType<typeof imageCropActions.handleCropSelection>;

type HandleSizeChange = ActionType<typeof imageCropActions.handleSizeChange>;
type SendImageToServer = ActionType<typeof imageCropActions.sendImageToServer>;

export const setCroppedCover: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { imageCrop }
) =>
    action$.pipe(
        ofType<SetCroppedCoverAction>(SET_CROPPED_COVER),
        switchMap((action) => {
            const { coverUrl, pixelCrop } = action.payload;

            return imageCrop
                .imageCropToDataUrl(coverUrl, pixelCrop)
                .pipe(
                    switchMap((croppedCoverUrl) =>
                        concat(
                            of(coverActions.setCoverUrl(croppedCoverUrl)),
                            of(imageCropActions.sendImageToServer(croppedCoverUrl)),
                            of(coverActions.setIsPredefinedImage(false))
                        )
                    )
                );
        })
    );

export const handleSizeChange: Epic<ImageCropActions, ImageCropActions, Types.RootState, Types.Services> = (
    action$,
    state$
) =>
    action$.pipe(
        ofType<HandleSizeChange>(HANDLE_SIZE_CHANGE),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { crop, pixelCrop } = action.payload;

            if (
                pixelCrop.width >= FILE_CONSTS.MIN_WIDTH &&
                pixelCrop.width <= FILE_CONSTS.MAX_WIDTH &&
                pixelCrop.height >= FILE_CONSTS.MIN_HEIGHT &&
                pixelCrop.height <= FILE_CONSTS.MAX_HEIGHT
            ) {
                return of(imageCropActions.setImageCrop(crop, pixelCrop));
            }

            return of(
                imageCropActions.setImageCrop(state.imageCrop.crop, {
                    ...state.imageCrop.pixelCrop,
                    height: FILE_CONSTS.MIN_HEIGHT,
                    width: FILE_CONSTS.MIN_WIDTH,
                })
            );
        })
    );

export const sendImageToServer: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { cover }
) =>
    action$.pipe(
        ofType<SendImageToServer>(SEND_IMAGE_TO_SERVER),
        switchMap((action) => {
            const image = action.payload;

            return cover.sendImage(image).pipe(
                switchMap((response) => {
                    return concat(
                        of(customisationActions.setLinkToImage(response.response.url)),
                        of(imageCropActions.resetImageCrop())
                    );
                }),
                catchError((error) => {
                    return of(imageCropActions.setSavingImageError(true));
                })
            );
        })
    );

export const rotateImage: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { rotateImage: rotateImageService }
) =>
    action$.pipe(
        filter(isOfType(ROTATE_IMAGE)),
        withLatestFrom(state$),
        switchMap(([, state]) => {
            const { newCoverUrl } = state.cover;
            return rotateImageService.rotate(newCoverUrl).pipe(
                switchMap((rotatedCoverUrl) =>
                    concat(of(coverActions.setNewCoverUrl(rotatedCoverUrl)), of(imageCropActions.setCropForNewImage()))
                ),
                catchError((error: string) =>
                    of(
                        userInfoModalActions.showUserInfoModal({
                            contentType: MessageType.ROTATE_IMAGE_ERROR,
                            headerText: error,
                        })
                    )
                )
            );
        })
    );

export default combineEpics(setCroppedCover, handleSizeChange, sendImageToServer, rotateImage);
