import { PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const persistConfig: PersistConfig = {
  key: 'root',
  storage,
  blacklist: [
    'router',
    'map.mapOptionsParams',
    'createProductJourney',
    'imageCrop',
    'mediaQuery',
    'location',
    'userInfoModal',
  ],
};
