import * as React from 'react';

import styles from './index.module.scss';
import {
  IMPERIAL_SCALE,
  IMPERIAL_ZOOM_SCALE,
  METRIC_SCALE,
  METRIC_ZOOM_SCALE,
} from 'root/scenes/Home/components/ProductPreviewOverlay/components/CustomScaleLine/constans';

interface ICustomScaleLineProps {
  zoomMap: boolean;
}

export const CustomScaleLine: React.FunctionComponent<
  ICustomScaleLineProps
> = ({ zoomMap }) => {
  return (
    <div>
      <div
        className={
          styles[
            zoomMap ? 'custom-scale-line-inner-zoom' : 'custom-scale-line-inner'
          ]
        }
      >
        {zoomMap ? METRIC_ZOOM_SCALE : METRIC_SCALE}
      </div>
      <div
        className={
          styles[
            zoomMap
              ? 'custom-scale-line-imperial-inner-zoom'
              : 'custom-scale-line-imperial-inner'
          ]
        }
      >
        {zoomMap ? IMPERIAL_ZOOM_SCALE : IMPERIAL_SCALE}
      </div>
    </div>
  );
};
