import styled, { css } from 'styled-components';

import { offset, borderRadius, fontSize } from 'root/modules/styles';

type RootProps = {
  triggerRect?: DOMRect;
};

export const Root = styled.div<RootProps>`
  position: absolute;
  display: flex;
  justify-content: center;
`;

export const Wrapper = styled.div`
  position: relative;
  height: 0;
`;

type ContainerProps = {
  containerRect?: DOMRect;
};

export const Container = styled.div<ContainerProps>`
  position: absolute;
  bottom: 0;
  ${({ containerRect }) =>
    css`
      left: ${-(containerRect?.width || 0) / 2}px;
    `};
  width: max-content;

  display: flex;
  flex-direction: column;
  align-items: center;

  z-index: 10000;
`;

export const Content = styled.div`
  flex-shrink: 1;
  background-color: #3c3c3c;
  color: #ffffff;
  padding: ${offset.small} ${offset.medium};
  ${borderRadius.medium}
  ${fontSize.small}
`;

const ARROW_SIZE = 8;

export const Arrow = styled.div`
  border: ${ARROW_SIZE}px solid #00000000;
  border-top-color: #3c3c3c;
`;
