import { Epic, combineEpics } from 'redux-observable';
import { isOfType } from 'typesafe-actions';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { of, EMPTY } from 'rxjs';

import * as Types from 'Types';
import { UPDATE_MEDIA_QUERY } from './constants';
import { MediaQueryActions } from './reducer';
import { mediaQueryActions, mediaQuerySelectors } from '../mediaQuery';

export const updateMediaQuery: Epic<
  MediaQueryActions,
  MediaQueryActions,
  Types.RootState,
  Types.Services
> = (action$, state$, { mediaQuery: mediaQueryService }) =>
  action$.pipe(
    filter(isOfType(UPDATE_MEDIA_QUERY)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const mediaQuery = mediaQueryService.getMediaQuery(action.payload);

      if (mediaQuery !== mediaQuerySelectors.getMediaQuery(state)) {
        return of(mediaQueryActions.setMediaQuery(mediaQuery));
      }

      return EMPTY;
    })
  );

export default combineEpics(updateMediaQuery);
