import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getMapSize, getMapType } from 'root/features/map/selectors';
import { getMediaQuery } from 'root/features/mediaQuery/selectors';

import { MapType, Size } from 'root/model/map/MapEnums';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { thinnerThan } from 'root/services/mediaQuery';

import styles from '../index.module.scss';

export const useProductPreview = (showMagnifyingGlass: boolean, showResetMap: boolean) => {
    const mapSize = useSelector(getMapSize) as Size;
    const mapType = useSelector(getMapType);
    const mediaQuery = useSelector(getMediaQuery);

    const isMobile = thinnerThan(mediaQuery, MediaQuery.MEDIUM);
    const isSmallPaper = mapSize === Size.SMALL && mapType === MapType.PAPER;
    const isSmallOrMedium = mapSize === Size.SMALL || mapSize === Size.MEDIUM;
    const isSmallAndCanvas = mapSize === Size.SMALL && mapType === MapType.CANVAS_AND_FRAMED;
    const isMedium = mapSize === Size.MEDIUM;

    const baseClassName = 'product-preview';

    const containerClassName = classNames(styles[baseClassName], [
        { [styles[`${baseClassName}--small`]]: isSmallPaper },
        {
            [styles[`${baseClassName}--mobile-magnifier-open`]]:
                (isMobile && showMagnifyingGlass) || (isMobile && showResetMap),
        },
    ]);

    const spacerClassName = classNames(
        styles[`${baseClassName}__spacer`],
        isSmallOrMedium ? styles[`${baseClassName}__spacer--rectangle`] : null,
        isSmallPaper || false ? styles[`${baseClassName}__spacer--no-frame`] : null
    );
    const mapClassName = classNames(styles[`${baseClassName}__map`]);

    const tooltipClassName = classNames(
        styles[`${baseClassName}__tooltip`],
        isMedium ? styles[`${baseClassName}__tooltip--rectangle`] : null,
        isSmallAndCanvas ? styles[`${baseClassName}__tooltip--rectangle`] : null
    );

    return {
        baseClassName,
        containerClassName,
        spacerClassName,
        mapClassName,
        tooltipClassName,
    };
};
