import React from 'react';
import { useDispatch } from 'react-redux';

import { setAreaAndStyleTab } from 'root/features/map/actions';
import { NON_SELECTED_PREVIEW_TAB_LIST } from './constants';

import styles from './index.module.scss';

export const AreaAndStyleNonSelectedPreview = () => {
    const dispatch = useDispatch();

    return (
        <div className={styles['non-selected']}>
            <div className={styles['non-selected__main-title']}>
                <span className={styles['non-selected__main-title__bold']}>New!&nbsp;</span>

                <span>There are now two ways to customise your map area with OS Custom Made</span>
            </div>

            {NON_SELECTED_PREVIEW_TAB_LIST.map((tab) => (
                <div className={styles['non-selected__options-wrapper']} key={`tab-list-${tab.id}`}>
                    <div className={styles['non-selected__option-container']} onClick={() => dispatch(setAreaAndStyleTab(tab.id))} >
                        <img src={tab.image} className={styles['non-selected__option-container__image']} />

                        <div className={styles['non-selected__option-container__content']}>
                            <div className={styles['non-selected__option-container__content__title']}>
                                {tab.title}
                            </div>

                            <div className={styles['non-selected__option-container__content__description']}>
                                {tab.description}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};
