import styled, { css } from 'styled-components';

import Modal from 'root/components/Modal';

import { breakpoint, offset, WithClassName } from 'root/modules/styles';
import { ProductPreview as ProductPreviewComponent } from 'root/modules/ProductPreview';
import { IconButton, Header1 } from 'root/modules/components';

export const OrderSummaryLeft = styled.div`
  flex-basis: 35%;
  margin-right: ${offset.xxxLarge};
  margin-bottom: ${offset.small};

  ${breakpoint.small(css`
    margin-right: 0;
  `)}
`;

export const Header = styled(Header1)`
  color: #453c90;
  white-space: nowrap;
  margin-bottom: ${offset.xxLarge};

  ${breakpoint.small(css`
    white-space: normal;
  `)}
`;

export const ProductPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ProductPreview = styled(ProductPreviewComponent)`
  cursor: pointer;
`;

export const PreviewButton = styled(IconButton)`
  margin-top: ${offset.medium};
  text-transform: uppercase;
  font-weight: bold;
`;

export const ModalOverlayStyles = styled(WithClassName)`
  position: fixed;
`;

export const ModalContentStyles = styled(WithClassName)`
  height: fit-content;
  max-height: none;
  overflow: initial;
`;

const PREVIEW_MODAL_MAX_WIDTH = '1092px';

export const PreviewModal = styled(Modal)`
  position: fixed;
  overflow: auto;
  max-width: ${PREVIEW_MODAL_MAX_WIDTH};

  background-color: #f3f3f4;
`;
