export const en = {
  areaAndStyle: {
    ukMap: {
      tooltip: {
        part1: 'Approximate location ',
        part2: 'of your selected map',
      },
    },
  },

  productPreview: {
    foldedCover: {
      osCustomMade: 'OS Custom Made',
    },
    mapPreviewErrorMessage: {
      text1: 'Your map preview could not be loaded at this moment. Please ',
      link: 'try again',
      text2: ' or continue to next step.',
    },
    magnifier: {
      text1: 'This is ',
      textBold: 'centre point ',
      text2: 'of your map',
    },
  },

  orderSummary: {
    previewHeader: 'Your order summary',

    checkSummary: {
      header1: 'Please check this summary carefully and use the ',
      preview: 'preview',
      header2: ' button to ensure you are happy with your map.',
      text:
        'OS Custom Made maps are made to your specification and therefore cannot be returned or refunded unless they are delivered damaged.',
    },

    warning: {
      defaultLocation: {
        part1:
          'Your map has been created using our default location of Central London. If this is as intended please continue, if not please ',
        link: 'return to the area and style tab',
        part2: ' to reset your location.',
      },
    },
  },

  button: {
    addToBag: 'add to basket',
    edit: 'edit',
    preview: 'preview',
    zoomMap: 'Zoom map',
    hideZoom: 'Hide zoom',
    showTitle: 'Show title',
    hideTitle: 'Hide title',
    zoomIn: 'Zoom in',
    zoomOut: 'Zoom out',
    zoom: 'Magnifying glass',
    zoomBar: "Zoom bar",
    returnToCentre: 'Return to centre',
    setNewCentre: 'Set new centre',
    ukMapPreview: 'UK map preview',
    showMiniMap: 'Show mini map',
    resetMap: 'Re-centre route',
  },

  ariaLabel: {
    switchStyleModal: 'Modal open - Switch map style',
    moreToolsModal: 'Modal open - more tools',
    switchMiniMap: 'Switch mini map'
  },

  modal: {
    switchStyleTitle: 'OS Map styles',
    switchStyleSubtitle: 'Move automatically between our iconic Explorer and Landranger styles, or select to lock your preferred mapping.',
    moreToolsTitle: 'More tools'
  }
};
