import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { JOURNEY_STEP_TO_BOTTOM_BAR_SETTINGS } from './constants';
import styles from './index.module.scss';
import { INavigationBottomBarContentProps } from './components/NavigationBottomBarContent';
import { Framing } from 'root/model/map/MapEnums';
import { getCreateProductJourneyStep } from 'root/features/createProductJourney/selectors';
import { getFramings } from 'root/features/map/selectors';

export interface ISidebarBottomBarProps {
  setDisplayValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const BottomBar: React.FunctionComponent<ISidebarBottomBarProps> = ({ setDisplayValidation }) => {
  const productJourneyStep = useSelector(getCreateProductJourneyStep);
  const framings = useSelector(getFramings);

  const componentClassName = 'bottom-bar';
  const className = classNames(styles[componentClassName], {
    [styles[`${componentClassName}--options-tab`]]: productJourneyStep === CreateProductJourneyStep.PRODUCT_TYPE,
    [styles[`${componentClassName}--location-and-style-tab`]]:
      productJourneyStep === CreateProductJourneyStep.AREA_AND_STYLE,
    [styles[`${componentClassName}--customisation-tab`]]: productJourneyStep === CreateProductJourneyStep.CUSTOMISE,
  });

  const renderContent = () => {
    if (productJourneyStep) {
      const bottomBarSetting = JOURNEY_STEP_TO_BOTTOM_BAR_SETTINGS[productJourneyStep];
      const Component = bottomBarSetting.component;
      if (bottomBarSetting.confirmButtonText) {
        const StandardComponent = Component as React.FunctionComponent<INavigationBottomBarContentProps>;

        let buttonText = bottomBarSetting.confirmButtonText;
        if (typeof buttonText === 'object') {
          const buttonTextSettings = buttonText as {
            frameable: string;
            nonframeable: string;
          };
          buttonText =
            framings && framings[0] !== Framing.NONE ? buttonTextSettings.frameable : buttonTextSettings.nonframeable;
        }

        return (
          <StandardComponent
            confirmButtonText={buttonText}
            canGoBack={bottomBarSetting.canGoBack}
            setDisplayValidation={setDisplayValidation}
          />
        );
      } else {
        return <Component />;
      }
    } else {
      return null;
    }
  };

  return <div className={className}>{renderContent()}</div>;
};

export default BottomBar;
