import { Framing } from 'root/model/map/MapEnums';
import frameBlack from 'root/assets/img/frame-black.png';
import framekOak from 'root/assets/img/frame-oak.png';

export const FRAME_TYPES = [
  {
    name: 'Oak effect',
    id: Framing.LIGHT_OAK,
    icon: framekOak,
    title: 'oak',
  },
  {
    name: 'Black',
    id: Framing.BLACK,
    icon: frameBlack,
    title: 'black',
  },
];
