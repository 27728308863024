import { IMapLocationCoordinates } from 'root/model/map/MapParams';
import { ViewOptions } from 'root/model/map/ViewOptions';

export const getMapViewOptions = (center: IMapLocationCoordinates, zoom: number, mapApi: string): ViewOptions => {
    return {
        center,
        zoom,
        mapApi,
    };
};
