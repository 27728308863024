import * as React from 'react';
import { ErrorMessage, FormikContext, getIn } from 'formik';
import { Element } from 'react-scroll';
import { connect } from 'formik';

import CoverDropButton from 'root/components/CoverDropButton';
import FormErrorMessage from 'root/components/FormErrorMessage';
import PredefinedImagesSlider from './components/PredefinedImagesSlider';
import styles from './index.module.scss';
import { ITitleFormValues } from 'root/model/customisation/TitleFormValues';
import classNames from 'classnames';

interface ICoverSelectProps {
  fieldName: string;
  onChangeCover: () => void;
}

interface IFormikConnectPartProps {
  formik: FormikContext<ITitleFormValues>;
}

const CoverSelect: React.FunctionComponent<
  ICoverSelectProps & IFormikConnectPartProps
> = ({ fieldName, onChangeCover, formik }) => {
  const renderErrorMessage = (error: string) => {
    return <FormErrorMessage error={error} />;
  };

  const isValid =
    !getIn(formik.errors, fieldName) || !getIn(formik.touched, fieldName);

  return (
    <Element className={styles['cover-select']} name={fieldName}>
      <div className={styles['cover-select__container']}>
        <p
          className={classNames(
            styles['cover-select__header-top'],
            isValid ? null : styles['cover-select__header-top--invalid']
          )}
        >
          Cover image
        </p>
        <ErrorMessage name={fieldName} render={renderErrorMessage} />
      </div>
      <CoverDropButton onChangeCover={onChangeCover} isValid={isValid} />
      <p className={styles['cover-select__header']}>Ready to use images</p>
      <PredefinedImagesSlider />
    </Element>
  );
};

export default connect<ICoverSelectProps, ITitleFormValues>(CoverSelect);
