import { action } from 'typesafe-actions';

import {
  SET_TITLE_FIRST_LINE,
  SET_TITLE_SECOND_LINE,
  SET_TITLE_THIRD_LINE,
  SET_SUBTITLE_FIRST_LINE,
  SET_SUBTITLE_SECOND_LINE,
  SET_SPINE,
  SET_SPINE_FROM_TITLES,
  SET_TITLE_LINE,
  SET_LINK_TO_IMAGE,
  SET_IS_LOADING_IMAGE,
} from './constants';
import { IIsLoadingImage } from 'root/model/customisation/IIsLoadingImage';

export const setTitleLine = (titleLine: string, fieldName: string) =>
  action(SET_TITLE_LINE, { titleLine, fieldName });

export const setTitleFirstLine = (titleLine: string) =>
  action(SET_TITLE_FIRST_LINE, titleLine);

export const setTitleSecondLine = (titleLine: string) =>
  action(SET_TITLE_SECOND_LINE, titleLine);

export const setTitleThirdLine = (titleLine: string) =>
  action(SET_TITLE_THIRD_LINE, titleLine);

export const setSubtitleFirstLine = (subtitleLine: string) =>
  action(SET_SUBTITLE_FIRST_LINE, subtitleLine);

export const setSubtitleSecondLine = (subtitleLine: string) =>
  action(SET_SUBTITLE_SECOND_LINE, subtitleLine);

export const setSpineFromTitles = (shouldChangeSpine: boolean) =>
  action(SET_SPINE_FROM_TITLES, shouldChangeSpine);

export const setSpine = (spine: string) => action(SET_SPINE, spine);

export const setLinkToImage = (linkToImage: string) => {
  return action(SET_LINK_TO_IMAGE, linkToImage);
};

export const setIsLoadingImage = (isLoadingImage: IIsLoadingImage) =>
  action(SET_IS_LOADING_IMAGE, isLoadingImage);
