import * as React from 'react';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as Types from 'Types';
import { IPredefinedCoverImage } from 'root/model/product/ProductOption';
import { productOptionsSelectors } from 'features/productOptions';
import NoItemsMessage from 'root/components/NoItemsMessage';
import { SLIDER_SETTINGS } from './constants';
import { coverActions, coverSelectors } from 'features/cover';
import LoadingIndicator from 'root/components/LoadingIndicator';
import { getIsLoadingImage } from 'features/customisation/selectors';
import { customisationActions } from 'features/customisation';
import { IIsLoadingImage } from 'root/model/customisation/IIsLoadingImage';

import styles from './index.module.scss';
import { mapSelectors } from 'root/features/map';
import { Size } from 'root/model/map/MapEnums';

interface IPredefinedImagesSliderProps {
    predefinedCoverImages: {
        SMALL: IPredefinedCoverImage[];
        LARGE: IPredefinedCoverImage[];
    };
    setPredefinedImage: typeof coverActions.setPredefinedImageIndex;
    predefinedImage: number;
    setImageSize: typeof coverActions.setImageSize;
    imageSize: Size;
    isLoadingImage: IIsLoadingImage;
    setIsLoadingImage: typeof customisationActions.setIsLoadingImage;
    getFullPredefinedImage: typeof coverActions.getFullPredefinedImage;
    mapSize: Size;
    isPredefinedImage: boolean;
    setIsPredefinedImage: typeof coverActions.setIsPredefinedImage;
}

const PredefinedImagesSlider: React.FunctionComponent<IPredefinedImagesSliderProps> = ({
    predefinedCoverImages,
    predefinedImage,
    setPredefinedImage,
    setImageSize,
    imageSize,
    isLoadingImage,
    getFullPredefinedImage,
    setIsLoadingImage,
    mapSize,
    isPredefinedImage,
    setIsPredefinedImage,
}) => {
    // @ts-ignore
    let predefinedCoverImagesForSize = predefinedCoverImages[mapSize];

    if (imageSize !== Size.NONE && mapSize !== imageSize && isPredefinedImage) {
        setImageSize(mapSize);
        // @ts-ignore
        predefinedCoverImagesForSize = predefinedCoverImages[mapSize];
        if (predefinedImage) getFullPredefinedImage(predefinedCoverImagesForSize[predefinedImage]?.url);
    }

    const renderSlide = (image: IPredefinedCoverImage, index: number) => {
        const imageCssClasses = classNames(styles['predefined-images-slider__image'], {
            [styles['predefined-images-slider__image--last']]: index === predefinedCoverImagesForSize.length - 1,
        });

        const onImageClick = () => {
            setIsPredefinedImage(true);
            setIsLoadingImage({ isLoading: true, index });
            setImageSize(mapSize);
            setPredefinedImage(index);
            getFullPredefinedImage(image.url);
        };

        const slideClasses = classNames(styles['predefined-images-slider__slide'], {
            [styles['predefined-images-slider__slide--small']]: mapSize === Size.SMALL,
        });

        return (
            <div onClick={onImageClick} className={slideClasses} key={index}>
                <img className={imageCssClasses} src={image.preview} alt="" />
                {isLoadingImage.isLoading && isLoadingImage.index === index && (
                    <LoadingIndicator
                        className={styles['predefined-images-loading']}
                        elementClassName={styles['predefined-images-loading__loading-element']}
                    />
                )}
            </div>
        );
    };

    return (
        <div className={styles['predefined-images-slider']}>
            {predefinedCoverImagesForSize.length !== 0 ? (
                <Slider {...SLIDER_SETTINGS}>{predefinedCoverImagesForSize.map(renderSlide)}</Slider>
            ) : (
                <NoItemsMessage text="There are no available images" />
            )}
        </div>
    );
};

export default connect(
    (state: Types.RootState) => ({
        predefinedCoverImages: productOptionsSelectors.getPredefinedCoverImages(state),
        isLoadingImage: getIsLoadingImage(state),
        mapSize: mapSelectors.getMapSize(state),
        predefinedImage: coverSelectors.getPredefinedImageIndex(state),
        imageSize: coverSelectors.getImageSize(state),
        isPredefinedImage: coverSelectors.getIsPredefinedImage(state),
    }),
    {
        setPredefinedImage: coverActions.setPredefinedImageIndex,
        setIsLoadingImage: customisationActions.setIsLoadingImage,
        getFullPredefinedImage: coverActions.getFullPredefinedImage,
        setImageSize: coverActions.setImageSize,
        setIsPredefinedImage: coverActions.setIsPredefinedImage,
    }
)(PredefinedImagesSlider);
