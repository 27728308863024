import { combineEpics, Epic, ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { ActionType } from 'typesafe-actions';

import Types from 'Types';
import { mapStylesActions } from 'features/mapStyles';
import { GET_MAP_STYLES } from './constants';
import { getMapStyles as getMapStylesAction } from './actions';
import { getMapStyles as getMapStylesService } from '../../services/map';

type SetMapStylesAction = ActionType<typeof getMapStylesAction>;

export const getMapStyles: Epic<Types.RootAction, Types.RootAction, Types.RootState> = ($action, $state) =>
  $action.pipe(
    ofType<SetMapStylesAction>(GET_MAP_STYLES),
    withLatestFrom($state),
    switchMap(([action, state]) => {
      return getMapStylesService().pipe(
        switchMap((ajaxResponse) => {
          return of(ajaxResponse);
        }),
        catchError((e) => {
          console.error(e);
          return of(e);
        })
      );
    }),
    switchMap((data) => {
      if (data.response === null) {
        return of(mapStylesActions.resetMapStyles());
      }

      return of(mapStylesActions.setMapStyles(data.response));
    })
  );

export default combineEpics(getMapStyles);
