import styled, { css } from 'styled-components';

import { backgroundImage, MapFormatType, SourceType, WidthType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import { MapTitles } from '../MapTitles';

type AspectRatioType = { aspectRatio: MAP_FORMAT };

const ASPECT_RATIO: Record<MAP_FORMAT, number> = {
  [MAP_FORMAT.SQUARE]: 1.64,
  [MAP_FORMAT.RECTANGLE]: 1.4088,
};

export const FoldedCover = styled.div`
  display: flex;
  position: absolute;
  width: 43.59%;

  box-shadow: 5px 5px 3px 0px #0000004d;
`;

export const AspectRatioHolder = styled.div<AspectRatioType>`
  ${({ aspectRatio }) => css`
    padding-top: ${ASPECT_RATIO[aspectRatio] * 100}%;
  `}
  width: 0;
`;

export const CustomMadeText = styled.h6<WidthType>`
  position: absolute;

  padding-top: 5.295%;
  padding-left: 21.475%;

  color: #ffffff;
  text-transform: uppercase;
  ${({ width }) => css`
    font-size: ${width * 0.01154}px;
  `}
`;

export const CoverWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TitleWrapper = styled(MapTitles)<WidthType & MapFormatType>`
  ${({ mapFormat }) => css`
    padding-top: ${mapFormat === MAP_FORMAT.SQUARE ? 13.35 : 6.181}%;
    padding-left: 6.475%;
  `}

  color: #ffffff;

  ${({ width }) => css`
    & > h3 {
      font-size: ${width * 0.03205}px;
      line-height: ${width * 0.03718}px;
      height: ${width * 0.03718}px;
    }

    & > h5 {
      font-size: ${width * 0.01795}px;
      line-height: ${width * 0.01925}px;
      height: ${width * 0.01925}px;
    }
  `}
`;

export const TopCover = styled.img`
  width: 100%;
`;

export const ImageCover = styled.img`
  width: 100%;
`;

export const ImageCoverPlaceholder = styled.div<SourceType>`
  ${backgroundImage}
  width: 100%;
  height: 100%;
`;
