import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { mapSelectors } from 'root/features/map';
import { initialMapLocationParams } from 'root/features/map/reducer';

const { getMapLocationCoordinates } = mapSelectors;

export const useCheckDefaultLocalization = () => {
  const mapLocationCoordinates = useSelector(getMapLocationCoordinates);

  return useMemo(
    () =>
      mapLocationCoordinates?.x === initialMapLocationParams.coordinates?.x &&
      mapLocationCoordinates?.y === initialMapLocationParams.coordinates?.y,
    [mapLocationCoordinates]
  );
};
