import * as React from 'react';
import * as classNames from 'classnames';

import { SliderArrowDirection } from 'root/model/customisation/SliderArrowEnums';
import styles from './index.module.scss';

interface ISliderArrowProps extends IClassNameProp, IOnClickProp {
  direction: SliderArrowDirection;
}

const SliderArrow: React.FunctionComponent<ISliderArrowProps> = ({
  className,
  onClick,
  direction,
}) => {
  return (
    <i
      className={classNames(
        className,
        styles['slider-arrow'],
        styles[`slider-arrow--${direction}`]
      )}
      onClick={onClick}
    />
  );
};

export default SliderArrow;
