import { Size } from 'root/model/map/MapEnums';

/*
SMALL: width:105.5 height:86.5
LARGE: width:135 height:147.5
*/

type FOLDED_MAP_SIZE_TO_CROP_RATIO_TYPE = {
    [key in Size.LARGE | Size.SMALL]: number;
};
export const FOLDED_MAP_SIZE_TO_CROP_RATIO: FOLDED_MAP_SIZE_TO_CROP_RATIO_TYPE = {
    [Size.SMALL]: 105.5 / 86.5,
    [Size.LARGE]: 135 / 147.5,
};
