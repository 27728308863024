import { getValidator } from 'root/services/formValidators';
import { ProductId, Size } from 'root/model/map/MapEnums';
import { useSelector } from 'react-redux';
import { getMapSize, getProductId } from 'root/features/map/selectors';
import { TEXT_TYPE } from 'root/services/formValidators/consts';

export const useCharacterValidation = () => {
    const mapSize = useSelector(getMapSize) as Size;
    const productId = useSelector(getProductId) as ProductId;

    const titleValidator = getValidator(mapSize, productId, TEXT_TYPE.TITLE);

    const subtitleValidator = getValidator(mapSize, productId, TEXT_TYPE.SUBTITLE);

    const parseSpineEnv = () => {
        try {
            return JSON.parse(SPINE_FIELD_ENABLED as string);
        } catch (e) {
            return false;
        }
    };

    const spineValidator = getValidator(mapSize, productId, TEXT_TYPE.SPINE);
    const showSpineText = parseSpineEnv();

    const SPINE_FIELD_ENABLED = process.env.CMFA_APP_SPINE_FIELD;

    return {
        titleValidator,
        subtitleValidator,
        spineValidator,
        showSpineText,
    };
};
