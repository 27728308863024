import * as React from 'react';
import * as classNames from 'classnames';

import TabHeader from 'root/components/TabHeader';
import TitlesForm from './components/TitlesForm';
import styles from './index.module.scss';

const CustomiseTab: React.FunctionComponent<IClassNameProp> = ({
  className,
}) => (
  <div
    tour-guide="customize_1"
    className={classNames(styles['customise-tab'], className)}
  >
    <TabHeader
      tabDescription="Add an image and/or personalised text to your map."
      tabTitle="Customise your map"
    />
    <TitlesForm />
  </div>
);

export default CustomiseTab;
