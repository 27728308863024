import * as React from 'react';

import styles from './index.module.scss';

export interface IFormErrorMessageProps {
  error: string;
}

const FormErrorMessage: React.FunctionComponent<IFormErrorMessageProps> = ({
  error,
}) => {
  return (
    <div className={styles['form-error-message']}>
      <span className={styles['form-error-message__inner']}>{error}</span>
    </div>
  );
};

export default FormErrorMessage;
