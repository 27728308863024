import React from 'react';

import styles from './RouteFeeInformation.module.scss';

export const RouteFeeInformation = () => {
    return (
        <div className={styles['upload-description']}>
            A small fee of £2 applies for us to maintain your GPX data seamlessly. Your adventure, your map!
        </div>
    );
};
