import { get } from 'lodash';

import {
    CreateProductJourney,
    CreateProductJourneyAction,
    CreateProductJourneyStep,
} from 'root/model/createProductJourney/CreateProductJourney';

export const getNextJourneyStep = (
    journey: CreateProductJourney,
    currentJourneyStep: CreateProductJourneyStep,
    journeyAction: CreateProductJourneyAction,
    redirect: boolean
) => {
    const selectedJourney = journey
        .filter(({ from }) => from === currentJourneyStep)
        .find(({ on }) => on === journeyAction) as object;

    return redirect
        ? {
              redirect: get(selectedJourney, 'redirect'),
              to: get(selectedJourney, 'to'),
          }
        : get(selectedJourney, 'to');
};
