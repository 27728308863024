import React, { forwardRef, useCallback, useState, useMemo, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';

import { customistaionSelectors } from 'root/features/customisation';

import { Rotate3d, WidthType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import { SubTitle, Title } from 'root/modules/components/Title';
import { AlternativeLayoutType } from '../../types';
import { MapFrame, MapFrameProps } from '../MapFrame';
import { MapPreview, MapPreviewProps, MagnifierZoom } from '../MapPreview';
import { PreviewControlLayout } from '../PreviewControlPanel';
import * as P from './parts';

const { getTitles } = customistaionSelectors;

type FlatMapPreviewProps = MapFrameProps &
  Rotate3d &
  WidthType &
  AlternativeLayoutType &
  Pick<MapPreviewProps, 'onLoad' | 'dispatchZoomRequest'> & {
    leftSideFrameSrc?: string;
    wrapperProps?: HTMLAttributes<HTMLDivElement>;
  };

export const FlatMapPreview = forwardRef<HTMLDivElement, FlatMapPreviewProps>(
  (
    {
      width,
      mapFormat,
      descriptionFrameSrc,
      woodFrameSrc,
      rotate3d,
      leftSideFrameSrc,
      alternativeLayout,
      onLoad,
      wrapperProps,
      dispatchZoomRequest,
    },
    ref
  ) => {
    const { titleFirstLine, subtitleFirstLine } = useSelector(getTitles);

    const [isMapMagnifierOpen, setIsMapMagnifierOpen] = useState(false);
    const [isTitleMagnifierOpen, setIsTitleMagnifierOpen] = useState(false);
    const [zoomLevel, setZoomLevel] = useState<MagnifierZoom>(MagnifierZoom.small);

    const toggleMapMagnifier = useCallback(() => setIsMapMagnifierOpen((prev) => !prev), []);
    const toggleTitleMagnifier = useCallback(() => setIsTitleMagnifierOpen((prev) => !prev), []);

    const rotation = useMemo(() => rotate3d && !alternativeLayout, [rotate3d, alternativeLayout]);

    return (
      <P.FlatMapPreviewWrapper {...wrapperProps}>
        <P.FlatMapPreview ref={ref} rotate3d={rotation}>
          {rotation && <P.LeftPreviewSide width={width} src={leftSideFrameSrc} />}

          <P.ProductPreviewWrapper width={width} rotate3d={rotation}>
            {mapFormat === MAP_FORMAT.SQUARE && isTitleMagnifierOpen && (
              <P.TitlesOverlay src={descriptionFrameSrc}>
                <P.TitleWrapper width={width} mapFormat={mapFormat} />
              </P.TitlesOverlay>
            )}

            {!woodFrameSrc && <P.FrameLessBackground alternativeLayout={alternativeLayout} />}

            <MapFrame
              width={width}
              mapFormat={mapFormat}
              descriptionFrameSrc={descriptionFrameSrc}
              woodFrameSrc={woodFrameSrc}
            >
              <MapPreview
                mapFormat={mapFormat}
                width={width}
                onLoad={onLoad}
                isMagnifierOpen={isMapMagnifierOpen}
                showMapMagnifier={alternativeLayout}
                dispatchZoomRequest={dispatchZoomRequest}
                dispatchRequestOnError={alternativeLayout}
                zoom={zoomLevel}
                onZoomChange={setZoomLevel}
                disableZoomControl
              />

              {mapFormat === MAP_FORMAT.RECTANGLE && (
                <P.TitleWrapperBottom width={width}>
                  <Title text={titleFirstLine} />
                  <SubTitle text={subtitleFirstLine} />
                </P.TitleWrapperBottom>
              )}
            </MapFrame>
          </P.ProductPreviewWrapper>
        </P.FlatMapPreview>

        {alternativeLayout && (
          <P.PreviewControlPanel
            isMapMagnifierOpen={isMapMagnifierOpen}
            toggleMapMagnifier={toggleMapMagnifier}
            showToggleTitle={mapFormat === MAP_FORMAT.SQUARE}
            isTitleMagnifierOpen={isTitleMagnifierOpen}
            toggleTitleMagnifier={toggleTitleMagnifier}
            zoomLevel={zoomLevel}
            changeZoomLevel={setZoomLevel}
            controlLayout={mapFormat === MAP_FORMAT.SQUARE ? PreviewControlLayout.Flat : PreviewControlLayout.Compact}
            disableZoomControls
          />
        )}
      </P.FlatMapPreviewWrapper>
    );
  }
);
