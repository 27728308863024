import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import {
    MapControlPanel as MapControlPanelComponent,
    MapControlPanelProps,
} from 'root/modules/AreaAndStyle/components/MapControlPanel';
import { breakpoint } from 'root/modules/styles';

const StyledMapControlPanel = styled(MapControlPanelComponent)``;

const MapControlPanelWrapper = styled.div`
    ${breakpoint.medium(css`
        position: absolute;
        bottom: 0;
        width: 100%;
    `)}
`;

export const MapControlPanel: FC<MapControlPanelProps> = (props) => (
    <MapControlPanelWrapper>
        <StyledMapControlPanel {...props} />
    </MapControlPanelWrapper>
);
