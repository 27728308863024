import { action } from 'typesafe-actions';

import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { UPDATE_MEDIA_QUERY, SET_MEDIA_QUERY } from './constants';

export const updateMediaQuery = (windowWidth: number) =>
  action(UPDATE_MEDIA_QUERY, windowWidth);

export const setMediaQuery = (mediaQuery: MediaQuery) =>
  action(SET_MEDIA_QUERY, mediaQuery);
