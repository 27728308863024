import * as React from 'react';

const UploadImageError: React.FunctionComponent = () => {
    return (
        <div>
            <div>Tip:</div>
            Please note: Image size must be more than 200kb and less than 10Mb. Image height must be between 500px and
            8000px
        </div>
    );
};

export default UploadImageError;
