import { Observable, Observer } from 'rxjs';
import * as loadImage from 'blueimp-load-image';

import { BAD_FILE_MESSAGES, FILE_CONSTS } from 'root/services/fileHelpers/consts';

export const getImageFileDataUrl = (file: File): Observable<string> =>
    Observable.create((observer: Observer<string>) => {
        loadImage(file, (canvas: HTMLCanvasElement) => validateAndConvertToBase64(canvas, file, observer), {
            orientation: true,
            canvas: true,
        });
    });

export const getBase64FromBlob = (blob: Blob): Observable<string> =>
    Observable.create((observer: Observer<string>) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            observer.next(reader.result as string);
            observer.complete();
        };
        reader.readAsDataURL(blob);
    });

export const getBlobDataUrl = (blob: Blob): Observable<string> =>
    Observable.create((observer: Observer<string>) => {
        const url = window.URL.createObjectURL(blob);
        observer.next(url);
        observer.complete();
    });

const validateAndConvertToBase64 = (canvas: HTMLCanvasElement, file: File, observer: Observer<string>) => {
    const validation = isImageValid(canvas.width, canvas.height, file.size);
    if (validation.isValid) {
        observer.next(canvas.toDataURL('image/jpeg'));
    } else {
        observer.error(validation.errors);
    }
    observer.complete();
};

export const isImageValid = (width: number, height: number, size: number): { isValid: boolean; errors?: string } => {
    const errorList = [];
    if (width < FILE_CONSTS.MIN_INITIAL_WIDTH || height < FILE_CONSTS.MIN_INITIAL_HEIGHT) {
        errorList.push(BAD_FILE_MESSAGES.FILE_RESOLUTION_SMALL);
    }
    if (width > FILE_CONSTS.MAX_WIDTH || height > FILE_CONSTS.MAX_HEIGHT) {
        errorList.push(BAD_FILE_MESSAGES.FILE_RESOLUTION_LARGE);
    }
    if (size < FILE_CONSTS.MIN_FILE_SIZE) {
        errorList.push(BAD_FILE_MESSAGES.FILE_SIZE_SMALL);
    }
    if (size > FILE_CONSTS.MAX_FILE_SIZE) {
        errorList.push(BAD_FILE_MESSAGES.FILE_SIZE_LARGE);
    }

    return errorList.length > 0 ? { isValid: false, errors: errorList.join(' ').concat() } : { isValid: true };
};
