import * as React from 'react';
import { connect } from 'react-redux';
import * as classNames from 'classnames';
import * as Types from 'Types';
import { customistaionSelectors } from 'features/customisation';
import { mapSelectors } from 'features/map';
import { productOptionsSelectors } from 'features/productOptions';
import { Framing, MapStyle, MapType, ProductId, Size } from 'root/model/map/MapEnums';
import { Scale } from 'src/model/map/MapEnums';
import { IProductOption } from 'root/model/product/ProductOption';
import styles from './index.module.scss';
import { scaleOptions } from './constants';
import { createProductJourneyActions } from 'features/createProductJourney';

interface IProductDescriptionProps {
    titleFirstLine: string;
    titleSecondLine: string;
    titleThirdLine: string;
    subtitleFirstLine: string;
    subtitleSecondLine: string;
    productId: ProductId;
    framing?: string;
    framings: string[];
    material?: string;
    name?: string;
    mapStyle: MapStyle;
    mapOptions: IProductOption[];
    className?: string;
    mapSize: Size;
    mapType?: number;
    dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
}

const ProductDescription: React.FunctionComponent<IProductDescriptionProps> = ({
    titleFirstLine,
    titleSecondLine,
    titleThirdLine,
    subtitleFirstLine,
    subtitleSecondLine,
    productId,
    framing,
    framings,
    material,
    name,
    mapStyle,
    mapOptions,
    className,
    mapSize,
    mapType,
    dispatchJourneyAction,
}) => {
    const mapScale = scaleOptions[mapStyle];
    const searchedFraming = framing ? framing : framings && framings.length > 0 ? framings[0] : Framing.NONE;

    const mapDetails = mapOptions.find(
        (element) =>
            element.framings.find((el) => el === searchedFraming) &&
            element.material === material &&
            element.productId === productId &&
            element.name === name
    );

    const mapDescription = mapDetails && mapDetails.shortDescription;

    const productDescriptionClassNames = classNames(styles['product-description'], className);

    const isRectangleFramed = mapType === MapType.CANVAS_AND_FRAMED && [Size.SMALL, Size.MEDIUM].includes(mapSize);

    const isCanvasFramed = mapType === MapType.CANVAS_AND_FRAMED && framing !== Framing.NONE;

    return (
        <div className={productDescriptionClassNames}>
            <p>
                <span className={styles['product-description__description']}>{mapScale}</span>
            </p>
            {isCanvasFramed ? (
                <p className={styles['product-description__description']}>
                    {mapDescription}, {framing === Framing.LIGHT_OAK ? 'Light Oak' : 'Black'}
                </p>
            ) : (
                <p className={styles['product-description__description']}>{mapDescription}</p>
            )}
            <p className={styles['product-description__title-line']}>{titleFirstLine}</p>
            {!isRectangleFramed && <p className={styles['product-description__title-line']}>{titleSecondLine}</p>}
            {mapSize !== Size.SMALL && !isRectangleFramed && (
                <p className={styles['product-description__title-line']}>{titleThirdLine}</p>
            )}
            <p className={styles['product-description__subtitle-line']}>{subtitleFirstLine}</p>
            {mapSize !== Size.SMALL && !isRectangleFramed && (
                <p className={styles['product-description__subtitle-line']}>{subtitleSecondLine}</p>
            )}
        </div>
    );
};

export default connect(
    (state: Types.RootState) => ({
        ...customistaionSelectors.getTitles(state),
        mapStyle: mapSelectors.getMapStyle(state),
        mapOptions: productOptionsSelectors.getOptions(state),
        mapSize: mapSelectors.getMapSize(state),
        mapType: mapSelectors.getMapType(state),
    }),
    {
        dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
    }
)(ProductDescription);
