import React from 'react';

import CoverDropzoneRouteContent from 'root/components/CoverDropzone/components/CoverDropzoneRouteContent';
import { CoverDropzone } from 'root/components/CoverDropzone';
import { ALLOWED_MIME_TYPE_FOR_ROUTE_UPLOAD } from 'root/services/fileHelpers/consts';

import styles from './index.module.scss';
import { useRouteTab } from '../../../Sidebar/components/SidebarTabs/components/AreaAndStyleTab/components/RouteTab/hooks';

export const AreaAndStyleRouteSelectedPreview = () => {
    const { onUploadRouteFile } = useRouteTab();

    return (
        <div className={styles['route-selected']}>
            <CoverDropzone
                onDrop={onUploadRouteFile}
                size={'large'}
                isUploaded={false}
                mimeType={ALLOWED_MIME_TYPE_FOR_ROUTE_UPLOAD}
            >
                <CoverDropzoneRouteContent />
            </CoverDropzone>
        </div>
    );
};
