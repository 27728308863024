import { action, ActionType, createAction } from 'typesafe-actions';

import {
  GET_PRODUCT_PREVIEW,
  SET_PRODUCT_PREVIEW_IMAGE_URL,
  RESET_PRODUCT_PREVIEW_IMAGE_URL,
  SET_ERROR_LOADING_PRODUCT_PREVIEW_IMAGE_URL,
  PRODUCT_PREVIEW,
} from 'features/productPreview/constants';

export const getProductPreview = (resolution: number) => action(GET_PRODUCT_PREVIEW, resolution);

export const setProductPreviewImageUrl = (url: string) => action(SET_PRODUCT_PREVIEW_IMAGE_URL, url);

export const setErrorLoadingProductPreviewUrl = (isError: boolean) =>
  action(SET_ERROR_LOADING_PRODUCT_PREVIEW_IMAGE_URL, isError);

export const resetProductPreviewImageUrl = () => action(RESET_PRODUCT_PREVIEW_IMAGE_URL);

const actions = {
  getProductPreview,
  setProductPreviewImageUrl,
  setErrorLoadingProductPreviewUrl,
  resetProductPreviewImageUrl,
};

export type ProductPreviewActions = ActionType<typeof actions>;

export const productPreviewActions = {
  request: createAction(PRODUCT_PREVIEW.REQUEST)<{ resolution: number; zoom?: number }>(),
  success: createAction(PRODUCT_PREVIEW.SUCCESS)<{ url: string }>(),
  error: createAction(PRODUCT_PREVIEW.ERROR)<{ error: any }>(),
  clear: createAction(PRODUCT_PREVIEW.CLEAR)(),
};

export type ProductPreviewActionsType = ActionType<typeof productPreviewActions>;
