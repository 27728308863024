import * as React from 'react';

import styles from './index.module.scss';

interface INoItemsMessageProps {
  text: string;
}

const NoItemsMessage: React.FunctionComponent<INoItemsMessageProps> = ({
  text,
}) => {
  return <span className={styles['no-products-message']}>{text}</span>;
};

export default NoItemsMessage;
