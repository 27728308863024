import React, { createContext, FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    getMapLocationCoordinates,
    getMapScale,
    getMapSize,
    getMapType,
    getProductId,
} from 'root/features/map/selectors';
import { resetRouteSetting, restoreRouteCoordinates, restoreRouteScale } from 'root/features/route/actions';
import { getRouteSettingOpacity, getRouteSettingThickness, getSelectedRoute } from 'root/features/route/selectors';
import { convertSizeToSheetSize, ProductId, Scale, SheetSize } from 'root/model/map/MapEnums';
import { LineOpacityType, LineThicknessType, RecomendationStatus } from 'root/model/route/Route';
import { checkRoutePositionToAnotherLocation } from 'root/modules/components/Map/helpers';
import { convertTransparencyToOpacity } from 'root/services/route';
import {
    ROUTE_SETTING_MAX_OPACITY,
    ROUTE_SETTING_MIN_OPACITY,
} from '../components/RouteTab/components/RouteStyleToolkit/components/TransparencySlider';

import { ROUTE_WARNINGS, ROUTE_WARNING_ACTION_TEXT } from '../constants';

type ValidRouteSetting = typeof ROUTE_WARNINGS['TOO_THICK_AND_OPAQUE' | 'TOO_THIN_AND_TRANSPARENT'];
type ValidRouteMap = typeof ROUTE_WARNINGS[
    | 'ENTIRELY_OUTSIDE_MAP'
    | 'NOT_OPTIMAL_WARNING1'
    | 'NOT_OPTIMAL_WARNING2'
    | 'PARTIALLY_OUTSIDE_MAP'];

type RouteValidatorType<T> = {
    message: T;
    restoreDefault: () => void;
    actionText: string;
};

type RouteSettingValidatorType = RouteValidatorType<ValidRouteSetting> | null;
type RouteMapValidatorType = RouteValidatorType<ValidRouteMap> | null;

export type RouteValidatorContextType = {
    routeSettingValidator: RouteSettingValidatorType;
    routeMapValidator: RouteMapValidatorType;
};

const INITIAL_ROUTE_VALIDATOR_CONTEXT = {
    routeMapValidator: null,
    routeSettingValidator: null,
};

export const RouteValidatorContext = createContext<RouteValidatorContextType>(INITIAL_ROUTE_VALIDATOR_CONTEXT);

export const RouteValidatorProvider: FC = ({ children }) => {
    const [routeSettingValidator, setRouteSettingValidator] = useState<RouteSettingValidatorType>(null);
    const [routeMapValidator, setRouteMapValidator] = useState<RouteMapValidatorType>(null);

    const routeSettingThickness = useSelector(getRouteSettingThickness);
    const routeSettingOpacity = useSelector(getRouteSettingOpacity);
    const selectedRoute = useSelector(getSelectedRoute);
    const mapScale = useSelector(getMapScale);
    const mapSize = useSelector(getMapSize);
    const mapType = useSelector(getMapType);
    const currentCoordinates = useSelector(getMapLocationCoordinates);
    const productId = useSelector(getProductId);

    useEffect(() => {
        const isToThinAndTransparent =
            routeSettingThickness === LineThicknessType.ExtraThin &&
            routeSettingOpacity === convertTransparencyToOpacity(ROUTE_SETTING_MAX_OPACITY as LineOpacityType) &&
            mapScale === Scale.OS_LANDRANGER_50K;

        const isToThickAndOpaque =
            routeSettingThickness === LineThicknessType.Thick &&
            routeSettingOpacity === convertTransparencyToOpacity(ROUTE_SETTING_MIN_OPACITY as LineOpacityType) &&
            mapScale === Scale.OS_EXPLORER_10K;

        if (selectedRoute === null) {
            setRouteSettingValidator(null);
        } else if (isToThinAndTransparent) {
            setRouteSettingValidator({
                message: ROUTE_WARNINGS.TOO_THIN_AND_TRANSPARENT,
                restoreDefault: resetRouteSetting,
                actionText: ROUTE_WARNING_ACTION_TEXT.TOO_THIN_AND_TRANSPARENT,
            });
        } else if (isToThickAndOpaque) {
            setRouteSettingValidator({
                message: ROUTE_WARNINGS.TOO_THICK_AND_OPAQUE,
                restoreDefault: resetRouteSetting,
                actionText: ROUTE_WARNING_ACTION_TEXT.TOO_THICK_AND_OPAQUE,
            });
        } else {
            setRouteSettingValidator(null);
        }
    }, [routeSettingThickness, routeSettingOpacity, selectedRoute, mapScale]);

    useEffect(() => {
        if (mapScale && mapSize && mapType && currentCoordinates && selectedRoute) {
            const sheetSize = convertSizeToSheetSize(mapSize);
            const recommendationStatus = (selectedRoute.config || []).find((config) => {
                if (productId === ProductId.FOLDED_MAP) {
                    return config.scale === mapScale && config.sheetSize === sheetSize && config.type === 'FOLDED';
                }

                return config.scale === mapScale && config.sheetSize === sheetSize && config.type === 'FRAMED';
            })?.recommendationStatus;

            const isNotOptimal =
                !recommendationStatus ||
                recommendationStatus === RecomendationStatus.Warning1 ||
                recommendationStatus === RecomendationStatus.Warning2;
            const { isRouteEntirelyVisible, isRoutePartiallyVisible } = checkRoutePositionToAnotherLocation({
                mapScale,
                mapSize,
                mapType,
                currentCoordinates,
                routeBbox: selectedRoute.bbox,
            });

            if (isNotOptimal) {
                setRouteMapValidator({
                    message:
                        recommendationStatus === RecomendationStatus.Warning1
                            ? ROUTE_WARNINGS.NOT_OPTIMAL_WARNING1
                            : ROUTE_WARNINGS.NOT_OPTIMAL_WARNING2,
                    restoreDefault: restoreRouteScale,
                    actionText:
                        recommendationStatus === RecomendationStatus.Warning1
                            ? ROUTE_WARNING_ACTION_TEXT.MAP_SCALE_AND_PRODUCT_SIZE_NOT_OPTIMAL
                            : '',
                });
            } else if (!isRouteEntirelyVisible) {
                setRouteMapValidator({
                    message: ROUTE_WARNINGS.ENTIRELY_OUTSIDE_MAP,
                    restoreDefault: restoreRouteCoordinates,
                    actionText: ROUTE_WARNING_ACTION_TEXT.ENTIRELY_OUTSIDE_MAP,
                });
            } else if (!isRoutePartiallyVisible) {
                setRouteMapValidator({
                    message: ROUTE_WARNINGS.PARTIALLY_OUTSIDE_MAP,
                    restoreDefault: restoreRouteCoordinates,
                    actionText: ROUTE_WARNING_ACTION_TEXT.PARTIALLY_OUTSIDE_MAP,
                });
            } else {
                setRouteMapValidator(null);
            }
        } else {
            setRouteMapValidator(null);
        }
    }, [selectedRoute, mapScale, currentCoordinates, productId, mapSize, mapType]);

    return (
        <RouteValidatorContext.Provider value={{ routeSettingValidator, routeMapValidator }}>
            {children}
        </RouteValidatorContext.Provider>
    );
};
