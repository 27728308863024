import * as React from 'react';
import { connect } from 'react-redux';

import * as Types from 'Types';
import { createProductJourneyActions } from 'features/createProductJourney';
import PrintAreaView, { AreaViewType } from 'root/components/PrintAreaView';
import ProductPreviewMap from 'root/components/ProductPreviewMap';
import { productPreviewSelectors } from 'features/productPreview';
import {
  calculateCoordinatesX,
  calculateCoordinatesY,
} from 'root/scenes/Home/components/ProductPreviewOverlay/utils';
import { mediaQuerySelectors } from 'features/mediaQuery';
import { CustomScaleLine } from 'root/scenes/Home/components/ProductPreviewOverlay/components/CustomScaleLine';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';

import styles from './index.module.scss';

interface IProductPreviewOverlayProps {
  productPreviewImageUrl: string;
  setProductPreviewOverlayOpened: typeof createProductJourneyActions.setProductPreviewOverlayOpened;
  mediaQuery: MediaQuery;
}

interface IProductPreviewOverlayState {
  backgroundSizeCss: string;
  zoomCoordinates: { x: number; y: number };
}

class ProductPreviewOverlay extends React.PureComponent<
  IProductPreviewOverlayProps,
  IProductPreviewOverlayState
> {
  constructor(props: IProductPreviewOverlayProps) {
    super(props);
    this.state = {
      backgroundSizeCss: '',
      zoomCoordinates: { x: 0, y: 0 },
    };
  }

  public handleImageLoad = (width: number, height: number) => {
    const backgroundSizeCss = width + 'px ' + height + 'px';
    this.setState({ backgroundSizeCss });
  };

  public onCloseButtonClick = () => {
    this.props.setProductPreviewOverlayOpened(false);
  };

  public setCoordinateX = (x: number) => {
    this.setState({
      zoomCoordinates: {
        x: calculateCoordinatesX(x, this.props.mediaQuery),
        y: this.state.zoomCoordinates.y,
      },
    });
  };

  public setCoordinateY = (y: number) => {
    this.setState({
      zoomCoordinates: {
        x: this.state.zoomCoordinates.x,
        y: calculateCoordinatesY(y, this.props.mediaQuery),
      },
    });
  };

  public renderArea = () => {
    return (
      <div className={styles['product-preview-overlay__preview']}>
        <ProductPreviewMap
          previewImageClassName={
            styles['product-preview-overlay__preview-image']
          }
          onImageLoad={this.handleImageLoad}
        />
      </div>
    );
  };

  public renderScaleLine = () => {
    return <CustomScaleLine zoomMap={false} />;
  };

  public render() {
    return (
      <div className={styles['product-preview-overlay']}>
        <button
          className={styles['product-preview-overlay__close-button']}
          onClick={this.onCloseButtonClick}
        >
          Close preview
          <i className={styles['product-preview-overlay__close-icon']} />
        </button>
        <PrintAreaView
          renderScaleLine={this.renderScaleLine}
          renderArea={this.renderArea}
          areaViewType={AreaViewType.MAP}
        />
      </div>
    );
  }
}

export default connect(
  (state: Types.RootState) => ({
    productPreviewImageUrl: productPreviewSelectors.getProductPreviewImageUrl(
      state
    ),
    mediaQuery: mediaQuerySelectors.getMediaQuery(state),
  }),
  {
    setProductPreviewOverlayOpened:
      createProductJourneyActions.setProductPreviewOverlayOpened,
  }
)(ProductPreviewOverlay);
