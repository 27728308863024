import * as React from 'react';
import { FieldProps } from 'formik';

import { default as Input, IInputProps } from '../Input';

const FormInput: React.FunctionComponent<FieldProps & IInputProps> = ({
  field,
  form: { touched, errors },
  className,
  ...props
}) => {
  return (
    <Input
      {...field}
      {...{ touched: !!touched, invalid: !!errors[field.name] }}
      {...props}
    />
  );
};

export default FormInput;
