import React, { FC, HTMLAttributes } from 'react';
import { fontFamily } from 'root/modules/styles';
import styled from 'styled-components';

export const H3 = styled.h3`
  text-transform: uppercase;
  ${fontFamily.osgill}
  font-weight: normal;
`;

type TitleProps = HTMLAttributes<HTMLHeadingElement> & {
  text?: string;
};

export const Title: FC<TitleProps> = ({ text, children, ...props }) => {
  return <H3 {...props}>{text ?? children}</H3>;
};
