import { MapStyle, Scale } from 'root/model/map/MapEnums';

type ScaleParams = {
    mapScale: Scale;
    mapStyle: MapStyle;
    bounding: number;
    order: number;
    mainMapBounding: number;
};

type ScaleLogicMap = {
    [key: string]: ScaleParams[];
};

export const scaleLogicMapMinOrder = 0;
export const scaleLogicMapMaxOrder = 7;

export const scaleLogicMap: ScaleLogicMap = {
    [MapStyle.OS_EXPLORER]: [
        {
            mapScale: Scale.OS_EXPLORER_10K,
            mapStyle: MapStyle.OS_EXPLORER,
            bounding: 10000,
            order: 7,
            mainMapBounding: 500,
        },
        {
            mapScale: Scale.OS_EXPLORER_12_5K,
            mapStyle: MapStyle.OS_EXPLORER,
            bounding: 12500,
            order: 6,
            mainMapBounding: 500,
        },
        {
            mapScale: Scale.OS_EXPLORER_25K,
            mapStyle: MapStyle.OS_EXPLORER,
            bounding: 25000,
            order: 5,
            mainMapBounding: 500,
        },
        {
            mapScale: Scale.OS_EXPLORER_40K,
            mapStyle: MapStyle.OS_EXPLORER,
            bounding: 40000,
            order: 4,
            mainMapBounding: 500,
        },
        {
            mapScale: Scale.OS_EXPLORER_50K,
            mapStyle: MapStyle.OS_EXPLORER,
            bounding: 50000,
            order: 3,
            mainMapBounding: 500,
        },
    ],
    [MapStyle.OS_LANDRANGER]: [
        {
            mapScale: Scale.OS_LANDRANGER_25K,
            mapStyle: MapStyle.OS_LANDRANGER,
            bounding: 25000,
            order: 2,
            mainMapBounding: 1000,
        },
        {
            mapScale: Scale.OS_LANDRANGER_40K,
            mapStyle: MapStyle.OS_LANDRANGER,
            bounding: 40000,
            order: 1,
            mainMapBounding: 1000,
        },
        {
            mapScale: Scale.OS_LANDRANGER_50K,
            mapStyle: MapStyle.OS_LANDRANGER,
            bounding: 50000,
            order: 0,
            mainMapBounding: 1000,
        },
    ],
};

export const defaultScaleParams = scaleLogicMap[MapStyle.OS_EXPLORER][2];
