import { IMapProduct } from 'root/model/map/MapParams';
import { Framing, Material } from 'root/model/map/MapEnums';

const SCENE_MODIFICATOR_CLASSES = {
    CANVAS: 'map-preview__scene--canvas',
};

const FRONT_MODIFICATOR_CLASSES = {
    FOLDED_MAP: {
        PAPER: {
            SMALL: {
                NONE: ['map-preview__front--folded'],
                LIGHT_OAK: ['map-preview__front--folded'],
            },
            LARGE: {
                NONE: ['map-preview__front--folded'],
                LIGHT_OAK: ['map-preview__front--folded'],
            },
        },
    },
    FLAT_MAP: {
        PAPER: {
            SMALL: {
                LIGHT_OAK: ['map-preview__front--flat-framed', 'map-preview__front--flat-framed--small'],
                BLACK: ['map-preview__front--flat-framed', 'map-preview__front--flat-framed--small--black'],
            },
            MEDIUM: {
                LIGHT_OAK: ['map-preview__front--flat-framed', 'map-preview__front--flat-framed--medium'],
                BLACK: ['map-preview__front--flat-framed', 'map-preview__front--flat-framed--medium--black'],
            },
            LARGE: {
                NONE: ['map-preview__front--flat'],
                LIGHT_OAK: ['map-preview__front--flat-framed'],
                BLACK: ['map-preview__front--flat-framed', 'map-preview__front--flat-framed--black'],
            },
        },
        CANVAS: {
            LARGE: {
                NONE: ['map-preview__front--canvas'],
                LIGHT_OAK: ['map-preview__front--canvas-framed'],
                BLACK: ['map-preview__front--canvas-framed', 'map-preview__front--canvas-framed--black'],
            },
        },
    },
};

const LEFT_SIDE_MODIFICATOR_CLASSES = {
    LIGHT_OAK: ['map-preview__left-side--frame'],
    BLACK: ['map-preview__left-side--frame', 'map-preview__left-side--frame--black'],
};

export const getMapSceneModificatorClasses = (styles: any, material: Material) => {
    // @ts-ignore
    const sceneModificatorCssClasses = SCENE_MODIFICATOR_CLASSES[material];

    return styles[sceneModificatorCssClasses];
};

const extractModificators = (product: IMapProduct) => {
    // @ts-ignore
    const productIdPart = FRONT_MODIFICATOR_CLASSES[product.productId];
    const materialPart = productIdPart[product.material];
    const sizePart = materialPart[product.size];
    const modificators = product.framing ? sizePart[product.framing] : sizePart[Framing.NONE];

    return modificators;
};

export const getMapFrontModificatorClasses = (styles: any, product: IMapProduct) => {
    return extractModificators(product).map((item: string) => styles[item]);
};

export const getProductSizeModificatorClasses = (styles: any, product: IMapProduct) => {
    return extractModificators(product).map(
        (item: string) => styles[item.replace('map-preview__front', 'map-preview__product-size')]
    );
};

export const getMapFrontLeftSideModificatorClasses = (styles: any, framing: Framing) => {
    // @ts-ignore
    return (LEFT_SIDE_MODIFICATOR_CLASSES[framing]
        ? // @ts-ignore
          LEFT_SIDE_MODIFICATOR_CLASSES[framing]
        : []
    ).map((item: string) => styles[item]);
};
