import styled, { css } from 'styled-components';

import { AnimationControls, breakpoint, horizontalAnimation, offset, verticalAnimation } from 'root/modules/styles';
import { IconButton } from 'root/modules/components';

const commonLayout = css`
    display: flex;

    flex-direction: column;
    ${breakpoint.medium(css({ flexDirection: 'row' }))}
`;

export const ControlPanel = styled.div<{ isMobileMagnifierOpen: boolean }>`
    ${commonLayout}

    /* FIXME: remove if possible. EXISTENCE REASON: verticalAnimation */
  height: 415px;

    ${breakpoint.medium(
        css<{ isMobileMagnifierOpen: boolean }>`
            height: ${(props) => (props.isMobileMagnifierOpen ? 165 : 115)}px;
            width: 100%;
        `
    )}
`;

export const Button = styled(IconButton)`
    width: 75px;
    padding-bottom: ${offset.small};
    font-size: 14px;
    line-height: 14px;

    ${breakpoint.medium(css`
        padding: 0;
        line-height: 15px;
    `)}
`;

export const MagnifierControls = styled.div<{ isMobileMagnifierOpen: boolean }>`
    ${commonLayout}
    align-items: center;
    padding: 0 ${offset.xxLarge};
    max-width: 100px;

    ${breakpoint.medium(css`
        padding: ${offset.small};
        width: 100%;
        max-width: 100%;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: center;
    `)}
`;

export const AnimationWrapper = styled.div<AnimationControls>`
    overflow: hidden;
`;

export const MagnifierActions = styled.div<AnimationControls>`
    ${commonLayout}

    position: relative;

    ${verticalAnimation}

    ${breakpoint.medium(css<AnimationControls>(horizontalAnimation))}
`;

export const ZoomControlContainer = styled.div`
    height: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;

    ${breakpoint.medium(css`
        height: auto;
        margin-bottom: 0;
        flex-direction: row;
        width: 100%;
    `)}
`;
