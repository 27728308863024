import * as React from 'react';
import { connect, Field, FormikContext } from 'formik';
import { Element } from 'react-scroll';

import { ITitleFormValues, TITLE_FORM_FIELD_NAMES } from 'root/model/customisation/TitleFormValues';
import { ProductId, Size } from 'root/model/map/MapEnums';
import FormField from 'root/components/FormField';
import FormInput from 'root/components/FormInput';
import CoverSelect from './components/CoverSelect';

import styles from './index.module.scss';
import { useTitlesForm } from './hooks';
import LanguageOptions from '../LanguageOptions';

export interface IFormikConnectPartProps {
    formik: FormikContext<ITitleFormValues>;
}

const TitlesForm = ({ formik }: IFormikConnectPartProps) => {
    const {
        productId,
        mapSize,
        onChangeCover,
        isRectangleFramed,
        showSpineText,
        titleValidator,
        subtitleValidator,
        spineValidator,
        getStyles,
        onChange,
    } = useTitlesForm({ formik });

    return (
        <div className={styles['titles-form']}>
            {productId === ProductId.FOLDED_MAP && (
                <CoverSelect onChangeCover={onChangeCover} fieldName={TITLE_FORM_FIELD_NAMES.COVER_IMAGE} />
            )}
            <div>
                <div className={styles['titles-form__header']}>
                    <div>Main title</div>
                    <div>Text will print in CAPITALS</div>
                </div>
                <Element name={TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE}>
                    <Field
                        name={TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE}
                        component={FormField}
                        control={FormInput}
                        onChange={onChange}
                        validate={(value: string) => titleValidator(value, true)}
                        placeholder="Line 1"
                        style={getStyles(formik.errors, TITLE_FORM_FIELD_NAMES.TITLE_FIRST_LINE)}
                    />
                </Element>
                {!isRectangleFramed && (
                    <Element name={TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE}>
                        <Field
                            name={TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE}
                            component={FormField}
                            control={FormInput}
                            onChange={onChange}
                            validate={(value: string) => titleValidator(value, true)}
                            placeholder="Line 2"
                            style={getStyles(formik.errors, TITLE_FORM_FIELD_NAMES.TITLE_SECOND_LINE)}
                        />
                    </Element>
                )}
                {mapSize !== Size.SMALL && !isRectangleFramed && (
                    <Element name={TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE}>
                        <Field
                            name={TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE}
                            component={FormField}
                            control={FormInput}
                            onChange={onChange}
                            validate={(value: string) => titleValidator(value, true)}
                            placeholder="Line 3"
                            style={getStyles(formik.errors, TITLE_FORM_FIELD_NAMES.TITLE_THIRD_LINE)}
                        />
                    </Element>
                )}
                <div className={styles['titles-form__header']}>
                    <div>Sub-title</div>
                    <div>Text will print exactly as entered</div>
                </div>
                <Element name={TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE}>
                    <Field
                        name={TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE}
                        component={FormField}
                        control={FormInput}
                        onChange={onChange}
                        validate={(value: string) => subtitleValidator(value, true)}
                        placeholder="Line 1"
                        style={getStyles(formik.errors, TITLE_FORM_FIELD_NAMES.SUBTITLE_FIRST_LINE)}
                    />
                </Element>
                {mapSize !== Size.SMALL && !isRectangleFramed && (
                    <Element name={TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE}>
                        <Field
                            name={TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE}
                            component={FormField}
                            control={FormInput}
                            onChange={onChange}
                            validate={(value: string) => subtitleValidator(value, true)}
                            placeholder="Line 2"
                            style={getStyles(formik.errors, TITLE_FORM_FIELD_NAMES.SUBTITLE_SECOND_LINE)}
                        />
                    </Element>
                )}
                {productId === ProductId.FOLDED_MAP && mapSize !== Size.SMALL && mapSize !== Size.MEDIUM && (
                    <>
                        {showSpineText && (
                            <>
                                <div className={styles['titles-form__header']}>
                                    <div>Spine</div>
                                    <div>Text will print in CAPITALS</div>
                                </div>
                                <Field
                                    name={TITLE_FORM_FIELD_NAMES.SPINE}
                                    component={FormField}
                                    control={FormInput}
                                    onChange={onChange}
                                    validate={spineValidator}
                                    placeholder="Spine text"
                                    style={getStyles(formik.errors, TITLE_FORM_FIELD_NAMES.SPINE)}
                                />
                            </>
                        )}
                    </>
                )}

                <LanguageOptions />
            </div>
        </div>
    );
};

export default connect<{}, ITitleFormValues>(TitlesForm);
