import { createStore, applyMiddleware } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';

import * as Types from 'Types';
import services from 'root/services';
import history from 'root/services/history';
import { composeEnhancers } from './utils';
import rootEpic from './rootEpic';
import rootReducer from './rootReducer';
import { persistConfig } from './reduxPersistConfig';

const epicMiddleware = createEpicMiddleware<
  Types.RootAction,
  Types.RootAction,
  Types.RootState,
  Types.Services
>({
  dependencies: services,
});

function configureStore() {
  const middlewares = [routerMiddleware(history), epicMiddleware];

  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  const persistedRootReducer = persistReducer(persistConfig, rootReducer);

  return createStore(persistedRootReducer, enhancers);
}

const store = configureStore();
export const persistor = persistStore(store);

epicMiddleware.run(rootEpic);

export default store;
