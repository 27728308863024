import React from 'react';

import styles from './RouteUploadFilename.module.scss';
import trash from '../../../../assets/trash.svg';
import { RouteFeeInformation } from '../RouteFeeInformation';

type Props = {
    fileName: string;
    resetRoute: () => void;
};

export const RouteUploadFilename = ({ fileName, resetRoute }: Props) => {
    return (
        <>
            <div className={styles['route-upload']}>
                <div className={styles['route-upload__filename']}>{fileName}</div>

                <img onClick={resetRoute} className={styles['route-upload__icon']} src={trash} />
            </div>

            <RouteFeeInformation />
        </>
    );
};
