import React, { FC } from 'react';
import styled from 'styled-components';

import { breakpoint, headerFontSize, offset } from 'root/modules/styles';
import { HeaderProps } from './types';

const H1 = styled.h1`
  ${headerFontSize.h1}
  ${breakpoint.small(headerFontSize.h1s)}
  
  margin-bottom: ${offset.xxxLarge};
`;

export const Header1: FC<HeaderProps> = ({ text, children, ...props }) => {
  return <H1 {...props}>{text ?? children}</H1>;
};
