export const FILE_CONSTS = {
    MIN_INITIAL_WIDTH: 500,
    MIN_INITIAL_HEIGHT: 500,
    MIN_WIDTH: 400,
    MIN_HEIGHT: 400,
    MAX_WIDTH: 8000,
    MAX_HEIGHT: 8000,
    MIN_FILE_SIZE: 200000,
    MAX_FILE_SIZE: 10000000,
    MAX_ROUTE_FILE_SIZE: 5000000,
};

export const ENDING = 'Please upload another image';

export enum MessageType {
    SEND_ORDER_SUCCESS = 'SEND_ORDER_SUCCESS',
    SEND_ORDER_ERROR = 'SEND_ORDER_ERROR',
    UPLOAD_IMAGE_ERROR = 'UPLOAD_IMAGE_ERROR',
    ROTATE_IMAGE_ERROR = 'ROTATE_IMAGE_ERROR',
    DEFAULT_LOCATION_WARNING = 'DEFAULT_LOCATION_WARNING',
    NO_MESSAGE = 'NO_MESSAGE',
}

export const BAD_FILE_MESSAGES = {
    FILE_SIZE_LARGE: {
        header: 'Your image file size is too large – please upload a smaller one',
        messageType: MessageType.UPLOAD_IMAGE_ERROR,
    },
    FILE_SIZE_SMALL: {
        header: 'Your image file size is too small – please upload a larger one',
        messageType: MessageType.UPLOAD_IMAGE_ERROR,
    },
    FILE_RESOLUTION_SMALL: {
        header: 'Your image file resolution is too small – please upload a larger one',
        messageType: MessageType.UPLOAD_IMAGE_ERROR,
    },
    FILE_RESOLUTION_LARGE: {
        header: 'Your image file resolution is too large – please upload a smaller one',
        messageType: MessageType.UPLOAD_IMAGE_ERROR,
    },
};

export const ALLOWED_MIME_TYPE_FOR_ROUTE_UPLOAD = '.gpx';

export const ALLOWED_MIME_TYPE_FOR_IMAGE = 'image/jpeg, image/png';
