import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './index.module.scss';
import { MapStyle, MapType, Material } from 'root/model/map/MapEnums';
import { setMapOptionsParams, setMapStyleParams } from 'root/features/map/actions';
import { initialMapOptionsParams } from 'root/features/map/reducer';
import { ValidationContext, ValidationContextType } from 'root/scenes/Home/components/Sidebar/providers/validation';
import { useContext } from 'react';
import { getMapType, getMemorizedFraming } from 'root/features/map/selectors';
import { Tile } from 'root/components/Tile';

export interface ITypeProps {
    id: number;
    name: string;
    active: boolean;
}

export const Type = ({ active, id, name }: ITypeProps) => {
    const { displayValidation, setDisplayValidation } = useContext(ValidationContext) as ValidationContextType;
    const mapType = useSelector(getMapType);
    const memorizedFraming = useSelector(getMemorizedFraming);
    const dispatch = useDispatch();

    const onClick = () => {
        dispatch(setMapStyleParams({ mapType: id }));

        if (id === MapType.PAPER) {
            dispatch(setMapStyleParams({ mapStyle: MapStyle.OS_EXPLORER }));
        }

        setDisplayValidation(false);

        dispatch(
            setMapOptionsParams({
                ...initialMapOptionsParams,
                memorizedFraming,
                material: id === MapType.PAPER ? Material.PAPER : Material.CANVAS,
            })
        );
    };

    return (
        <Tile
            variant="secondary"
            className={styles['type']}
            isActive={active}
            isError={mapType === MapType.NONE && displayValidation}
            action={onClick}
        >
            <div className={styles['type__description']}>
                <span className={styles['type__description-first-line']}>
                    <strong>{name}</strong>
                </span>
            </div>
        </Tile>
    );
};
