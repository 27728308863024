import * as React from 'react';
import { InputHTMLAttributes } from 'react';
import * as classNames from 'classnames';

import styles from './index.module.scss';

export interface IInputProps
  extends InputHTMLAttributes<HTMLInputElement>,
    IClassNameProp,
    IIdProp,
    IInvalidProp,
    ITouchedProp {
  min?: number | string;
  max?: number | string;
  autoComplete?: string;
  type?:
    | 'text'
    | 'number'
    | 'checkbox'
    | 'email'
    | 'password'
    | 'radio'
    | 'hidden';
}

const Input: React.FunctionComponent<IInputProps> = ({
  className,
  invalid,
  touched,
  ...props
}) => {
  const cssClass = classNames([styles['input'], className], {
    [styles['input--disabled']]: props.disabled,
  });

  const blockEventPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <input {...props} className={cssClass} onClick={blockEventPropagation} />
  );
};

Input.defaultProps = {
  type: 'text',
};

export default Input;
