import * as React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import styles from './index.module.scss';
import { OnCloseOptions, OnModalOpen, OnOpenOptions } from './OnModalOpen';

export interface IModalProps extends IClassNameProp {
  buttons?: () => JSX.Element;
  showModal: boolean;
  danger: boolean;
  closeModal: () => void;
  children: React.ReactNode;
  hideCloseMark?: boolean;
  overlayClassName?: string;
  contentClassName?: string;
  onOpenOptions?: OnOpenOptions;
  onCloseOptions?: OnCloseOptions;
}

class Modal extends React.Component<IModalProps> {
  public static defaultProps = {
    danger: false,
    performingConfirm: false,
    hideCloseMark: false,
  };

  public render() {
    const {
      buttons,
      showModal,
      closeModal,
      children,
      danger,
      className,
      overlayClassName,
      contentClassName,
      onOpenOptions,
      onCloseOptions,
    } = this.props;

    const modalClassNames = classNames([styles['modal'], { [styles['modal--danger']]: danger }, className]);

    const closeButtonStyles = classNames([
      styles['modal__close-button'],
      { [styles['modal__hide']]: this.props.hideCloseMark },
    ]);

    return (
      <ReactModal
        isOpen={showModal}
        className={modalClassNames}
        overlayClassName={classNames(styles['modal__overlay'], overlayClassName)}
        onRequestClose={closeModal}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
      >
        <OnModalOpen {...onOpenOptions} {...onCloseOptions}>
          <i className={closeButtonStyles} onClick={closeModal} />
          <div className={classNames(styles['modal__content'], contentClassName)}>{children}</div>
          {buttons && <div className={styles['modal__buttons']}>{buttons()}</div>}
        </OnModalOpen>
      </ReactModal>
    );
  }
}

export default Modal;
