import React, { ButtonHTMLAttributes, forwardRef, ReactNode, useCallback } from 'react';

import { IsLoadingType } from 'root/modules/types';
import * as P from './parts';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  IsLoadingType &
  Partial<P.ButtonStyleType & P.ButtonSizeType> & {
    text?: ReactNode;
  };

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      text,
      children,
      buttonStyle = P.ButtonStyle.Primary,
      buttonSize = P.ButtonSize.medium,
      isLoading,
      onClick,
      ...props
    },
    ref
  ) => {
    const handleClick = useCallback((event) => void (!isLoading && onClick?.(event)), [onClick, isLoading]);

    return (
      <P.Button ref={ref} buttonStyle={buttonStyle} buttonSize={buttonSize} onClick={handleClick} {...props}>
        {isLoading ? <P.Loading /> : text ?? children}
      </P.Button>
    );
  }
);
