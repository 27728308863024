import styled, { css } from 'styled-components';
import { Close } from '@styled-icons/material/Close';
import { LocationSearching } from '@styled-icons/material/LocationSearching';

import { breakpoint, HeightType, important, leftPanelWidth, offset, px, WidthType } from 'root/modules/styles';
import { ButtonSize, ButtonStyle, IconButton, IconButtonProps } from 'root/modules/components';

type IsOpenType = { isOpen: boolean };
type ShowMiniMap = { showMiniMap: boolean };
type CalcFunction = (width: number, height: number, percentWidth?: number, percentHeight?: number) => number;

const MAX_SIZE = 800;
const SCALE = 0.25;
const WINDOW_WIDTH_PERCENT = { DESKTOP: 0.6, MOBILE: 0.9 };
const WINDOW_HEIGHT_PERCENT = 0.9;
const BORDER_SIZE = 10;

const getSize: CalcFunction = (
    width,
    height,
    percentWidth = WINDOW_WIDTH_PERCENT.DESKTOP,
    percentHeight = WINDOW_HEIGHT_PERCENT
) => Math.min(width * percentWidth, height * percentHeight, MAX_SIZE);

const calcOffset: CalcFunction = (...argv) => {
    const size = getSize(...argv);

    return (size - size * SCALE) / -2;
};

const calcIndicatorOffset: CalcFunction = (...argv) => -(getSize(...argv) * SCALE) - 1;

export const Overlay = styled.div<IsOpenType & ShowMiniMap>`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    ${({ isOpen, showMiniMap }) =>
        isOpen
            ? css`
                  display: ${isOpen ? 'flex' : 'none'};
                  position: fixed;
                  background-color: #00000066;

                  top: 0;
                  left: ${leftPanelWidth};
                  right: 0;
                  bottom: 0;
                  ${breakpoint.large(css`
                      left: 0;
                      justify-content: center;
                      align-items: center;
                      z-index: 2;
                  `)}
              `
            : css`
                  ${breakpoint.large(css({ display: 'none' }))}
                  display: ${showMiniMap ? 'flex' : 'none'};
                  position: absolute;
                  right: 0;
                  bottom: 0;
              `}
`;

export const ModalLike = styled.div`
    display: flex;
    align-items: flex-start;

    ${breakpoint.medium(css`
        flex-direction: column;
        align-items: flex-end;
    `)}

    width: fit-content;
    height: fit-content;
`;

const closeButtonAttrs: IconButtonProps = {
    Icon: Close,
    buttonSize: ButtonSize.medium,
    buttonStyle: ButtonStyle.Third,
};
export const CloseButton = styled(IconButton).attrs(closeButtonAttrs)`
    margin-right: ${offset.small};

    ${breakpoint.medium(css`
        margin-right: 0;
        margin-bottom: ${offset.small};
    `)}
`;

export const OpenIndicator = styled.div<WidthType & HeightType>`
    position: absolute;

    ${({ width, height }) => css`
        top: ${px(calcIndicatorOffset(width, height))};
        left: ${px(calcIndicatorOffset(width, height))};
    `}

    border: ${BORDER_SIZE}px solid #ffffff;
    border-top-color: #f3f3f4;
    border-left-color: #f3f3f4;
`;

export const MapWrapper = styled.div<IsOpenType & WidthType & HeightType>`
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: content-box;

    ${({ isOpen, width, height }) => css`
        ${isOpen
            ? css`
                  transform: scale(1);
                  position: relative;
              `
            : css`
                  transform: scale(${SCALE});
                  bottom: ${px(calcOffset(width, height))};
                  right: ${px(calcOffset(width, height))};
                  position: absolute;
              `}

        width: ${px(getSize(width, height))};
        height: ${px(getSize(width, height))};

        ${breakpoint.medium(css`
            width: ${px(getSize(width, height, WINDOW_WIDTH_PERCENT.MOBILE))};
            height: ${px(getSize(width, height, WINDOW_WIDTH_PERCENT.MOBILE))};
        `)}
    `}

    transition: all 0.2s ease-out;
    background-color: #ffffff;
`;

const crosshairAttrs: IconButtonProps = {
    Icon: LocationSearching,
    buttonSize: ButtonSize.large,
    buttonStyle: ButtonStyle.Ghost,
};
export const Crosshair = styled(IconButton).attrs(crosshairAttrs)<IsOpenType>`
    position: absolute;

    z-index: 1;

    & > button {
        padding: 0;
        box-shadow: none !important;
        font-size: 80px;
        ${({ isOpen }) => css({ cursor: isOpen ? 'default' : 'pointer' })}

        &:hover:enabled,
    &:active {
            color: #453c90;
        }
    }
`;

export const Map = styled.div<IsOpenType>`
    width: 100%;
    height: 100%;

    & > * {
        ${({ isOpen }) => important('cursor', isOpen ? 'default' : 'pointer')}
    }
`;
