import mapFrameRectangleExplorer from 'src/modules/assets/innerFrames/mapFrameRectangleExplorer.jpg';
import mapFrameRectangleLandranger from 'src/modules/assets/innerFrames/mapFrameRectangleLandranger.jpg';
import mapFrameExplorerEN from 'src/modules/assets/innerFrames/mapFrameExplorerEN.jpg';
import mapFrameExplorerCY from 'src/modules/assets/innerFrames/mapFrameExplorerCY.jpg';
import mapFrameLandrangerEN from 'src/modules/assets/innerFrames/mapFrameLandrangerEN.jpg';
import mapFrameLandrangerCY from 'src/modules/assets/innerFrames/mapFrameLandrangerCY.jpg';

import { MAP_LANGUAGE, FRAMED_MAP, MAP_TYPES, PAPER_MAP, CANVAS_MAP } from '../../types';
import { MapStyle, Scale } from 'root/model/map/MapEnums';

type ProductsType<T1, T2, T3> = {
    [MAP_TYPES.PAPER]: Record<PAPER_MAP, T1>;
    [MAP_TYPES.CANVAS]: Record<CANVAS_MAP, T2>;
    [MAP_TYPES.FRAMED]: Record<FRAMED_MAP, T3>;
};

type InnerFrameType<T extends string = MapStyle> = Record<MAP_LANGUAGE, Record<T, string>>;

const FRAMED_MAP_INNER_FRAME_STYLE: Pick<InnerFrameType, MAP_LANGUAGE.EN | MAP_LANGUAGE.CY> = {
    [MAP_LANGUAGE.EN]: {
        [MapStyle.OS_EXPLORER]: mapFrameRectangleExplorer,
        [MapStyle.OS_LANDRANGER]: mapFrameRectangleLandranger,
    },
    [MAP_LANGUAGE.CY]: {
        [MapStyle.OS_EXPLORER]: mapFrameRectangleExplorer,
        [MapStyle.OS_LANDRANGER]: mapFrameRectangleLandranger,
    },
};

const FLAT_MAP_INNER_FRAME_STYLE: InnerFrameType = {
    [MAP_LANGUAGE.EN]: {
        [MapStyle.OS_EXPLORER]: mapFrameExplorerEN,
        [MapStyle.OS_LANDRANGER]: mapFrameLandrangerEN,
    },
    [MAP_LANGUAGE.CY]: {
        [MapStyle.OS_EXPLORER]: mapFrameExplorerCY,
        [MapStyle.OS_LANDRANGER]: mapFrameLandrangerCY,
    },
};

const PAPER_MAP_INNER_FRAME_STYLE: InnerFrameType<MapStyle> = {
    [MAP_LANGUAGE.EN]: {
        [MapStyle.OS_EXPLORER]: mapFrameExplorerEN,
        [MapStyle.OS_LANDRANGER]: mapFrameLandrangerEN,
    },
    [MAP_LANGUAGE.CY]: {
        [MapStyle.OS_EXPLORER]: mapFrameExplorerCY,
        [MapStyle.OS_LANDRANGER]: mapFrameLandrangerCY,
    },
};

export const MAP_INNER_FRAME: ProductsType<
    null | typeof PAPER_MAP_INNER_FRAME_STYLE | typeof FLAT_MAP_INNER_FRAME_STYLE,
    typeof FLAT_MAP_INNER_FRAME_STYLE,
    typeof FRAMED_MAP_INNER_FRAME_STYLE | typeof FLAT_MAP_INNER_FRAME_STYLE
> = {
    [MAP_TYPES.PAPER]: {
        [PAPER_MAP.SMALL]: null,
        [PAPER_MAP.LARGE]: PAPER_MAP_INNER_FRAME_STYLE,
        [PAPER_MAP.LARGE_FLAT]: FLAT_MAP_INNER_FRAME_STYLE,
    },

    [MAP_TYPES.CANVAS]: {
        [CANVAS_MAP.UNFRAMED]: FLAT_MAP_INNER_FRAME_STYLE,
        [CANVAS_MAP.FRAMED]: FLAT_MAP_INNER_FRAME_STYLE,
    },

    [MAP_TYPES.FRAMED]: {
        [FRAMED_MAP.SMALL]: FRAMED_MAP_INNER_FRAME_STYLE,
        [FRAMED_MAP.MEDIUM]: FRAMED_MAP_INNER_FRAME_STYLE,
        [FRAMED_MAP.LARGE]: FLAT_MAP_INNER_FRAME_STYLE,
    },
} as const;
