import React, { PropsWithChildren } from 'react';
import * as classNames from 'classnames';

import styles from './index.module.scss';

export type TileProps = {
    action: () => void;
    isActive: boolean;
    isError: boolean;
    variant: 'primary' | 'secondary' | 'third';
    className?: string;
};

export const Tile = ({ action, isActive, isError, variant, className, children }: PropsWithChildren<TileProps>) => {
    const tileClassName = classNames(
        styles['tile'],
        {
            [styles[`tile--${variant}--active`]]: isActive,
            [styles['tile--primary']]: variant === 'primary',
            [styles['tile--secondary']]: variant === 'secondary',
            [styles['tile--third']]: variant === 'third',
            [styles['tile--error']]: isError,
        },
        className
    );

    return (
        <div className={tileClassName} onClick={action}>
            {children}
        </div>
    );
};
