export const API_BASE_URL = process.env.CMFA_APP_OS_NAMES_API_URL;

export const API_KEY = process.env.CMFA_APP_OS_NAMES_API_KEY;

export const MAX_RESULTS = 10;

export const SUPPORTED_COUNTRIES = ['England', 'Scotland', 'Wales'];

export const OUTSIDE_EXTENT_ERROR_MESSAGE = 'Restricted to GB only';

export const GET_CURRENT_POSITION_ERROR_MESSAGE = 'Error getting position';

export const UNSUPPORTED_BROWSER_ERROR_MESSAGE = 'Unsupported browser';

export interface IApiQueryType {
  type: string;
  query: string;
}

export const API_QUERY_TYPE = {
  byGrid: { type: 'nearest', query: 'point' },
  byName: { type: 'find', query: 'query' },
};
