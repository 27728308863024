import { Style } from 'mapbox-gl';

export const createMapStyles = (tiles: string[]): Style => ({
  version: 8,
  sources: {
    'raster-tiles': {
      type: 'raster',
      tiles: [...tiles],
      tileSize: 256,
      attribution: '',
    },
  },
  layers: [
    {
      id: 'background',
      type: 'background',
      paint: { 'background-color': 'white' },
    },
    {
      id: 'simple-tiles',
      type: 'raster',
      source: 'raster-tiles',
      minzoom: 0,
      maxzoom: 22,
    },
  ],
});
