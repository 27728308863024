import * as React from 'react';
import Modal from 'root/components/Modal';
import styles from './MapSizeGuidePopup.module.scss';
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { MAP_SIZE_GUIDE_POPUP_CONTENT } from 'root/components/MapSizeGuidePopup/constants';
import { connect } from 'react-redux';
import Types from 'Types';
import { mapSelectors } from 'features/map';
import * as arrowLeft from 'root/assets/img/arrowLeft.gif';
const leftArrow = arrowLeft.default;
import * as arrowRight from 'root/assets/img/arrowRight.gif';
import classNames from 'classnames';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useWindowSize } from 'root/hooks';
const rightArrow = arrowRight.default;

interface IMapSizeGuidePopup {
  mapType: number;
  isVisible: number;
}

interface ICarouselItem {
  backgroundImageSrc: string;
  foregroundImageSrc: string;
  heightSize: string;
  widthSize: string;
  name: string;
  imageDescription: string;
  isRectangle: boolean;
  foldedDimensions?: string;
}

const MapSizeItem: React.FunctionComponent<ICarouselItem> = ({
  backgroundImageSrc,
  foregroundImageSrc,
  heightSize,
  widthSize,
  name,
  imageDescription,
  isRectangle,
  foldedDimensions,
}) => {
  return (
    <div>
      <div
        className={styles['map-size-popup__image-wrapper']}
        style={{ backgroundImage: `url(${backgroundImageSrc})` }}
      >
        <div
          className={
            isRectangle
              ? classNames(
                  styles['map-size-popup__dimension-height'],
                  styles['map-size-popup__dimension-height__rectangle']
                )
              : styles['map-size-popup__dimension-height']
          }
        >
          <span>{heightSize}</span>
        </div>
        <img className={styles['map-size-popup__image-wrapper__image']} src={foregroundImageSrc} />
        <div className={styles['map-size-popup__dimension-width']}>
          <span>{widthSize}</span>
        </div>
      </div>
      <div className={styles['map-size-popup__description']}>
        <p className={styles['map-size-popup__description__map-name']}>{name}</p>
        <p className={styles['map-size-popup__description__map-size']}>{imageDescription}</p>
        {foldedDimensions && <p className={styles['map-size-popup__description__map-size']}>{foldedDimensions}</p>}
        {!foldedDimensions && <span>&nbsp;</span>}
      </div>
    </div>
  );
};

const MapSizeGuidePopup: FunctionComponent<IMapSizeGuidePopup> = ({ mapType, isVisible }) => {
  const [prevIsVisible, setPrevIsVisible] = useState<number>(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [pictureAmountDisplay, setPictureAmountDisplay] = useState<number>(3);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const width = useWindowSize();

  useEffect(() => {
    if (isVisible !== prevIsVisible) {
      setSlideIndex(0);
    }
    setPrevIsVisible(isVisible);
  }, [isVisible, prevIsVisible]);

  useEffect(() => {
    if (isVisible !== 0) {
      setVisible(true);
    }

    return () => {
      setVisible(true);
    };
  }, [isVisible]);

  useEffect(() => {
    setSlideIndex(0);
    if (width && width > 1200) {
      setPictureAmountDisplay(3);
    } else if (width && width < 1200 && width > 750) {
      setPictureAmountDisplay(2);
    } else if (width && width < 750) {
      setPictureAmountDisplay(1);
    }
  }, [width]);

  const leftArrowClickHandler = useCallback(() => {
    setSlideIndex(slideIndex - pictureAmountDisplay);
    const domRightArrow = document.getElementsByClassName(styles['map-size-popup__arrow'])[1] as HTMLElement;
    domRightArrow.style.visibility = 'visible';
  }, [slideIndex]);

  const rightArrowClickHandler = useCallback(
    (typeMap: number) => {
      const allItems =
        typeMap === 1 ? MAP_SIZE_GUIDE_POPUP_CONTENT.PAPER_MAP.length : MAP_SIZE_GUIDE_POPUP_CONTENT.CANVAS_MAP.length;

      const domRightArrow = document.getElementsByClassName(styles['map-size-popup__arrow'])[1] as HTMLElement;
      domRightArrow.style.visibility = slideIndex < allItems - 2 * pictureAmountDisplay ? 'visible' : 'hidden';

      if (slideIndex < allItems - pictureAmountDisplay) {
        setSlideIndex(slideIndex + pictureAmountDisplay);
      }
    },
    [pictureAmountDisplay, slideIndex]
  );

  const closeModal = useCallback(() => {
    setVisible(false);
    const productTypes = document.querySelector('div[tour-guide="options_1"]')?.parentNode;
    if (productTypes) {
      setTimeout(() => {
        (productTypes as HTMLElement).scrollTop = 0;
      }, 0);
    }
  }, []);

  return (
    <Modal className={styles['map-size-popup']} showModal={visible} closeModal={closeModal}>
      <div className={styles['map-size-popup__header']}>
        <p>Custom Made Map Size Guide</p>
      </div>
      <Carousel
        draggable={false}
        value={slideIndex}
        plugins={
          mapType === 1
            ? MAP_SIZE_GUIDE_POPUP_CONTENT.PAPER_MAP.length === pictureAmountDisplay
              ? [
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: pictureAmountDisplay,
                    },
                  },
                ]
              : [
                  {
                    resolve: slidesToShowPlugin,
                    options: {
                      numberOfSlides: pictureAmountDisplay,
                    },
                  },
                  {
                    resolve: arrowsPlugin,
                    options: {
                      arrowLeft: (
                        <img
                          onClick={leftArrowClickHandler}
                          className={styles['map-size-popup__arrow']}
                          src={leftArrow}
                        />
                      ),
                      arrowRight: (
                        <img
                          onClick={() => rightArrowClickHandler(mapType)}
                          className={styles['map-size-popup__arrow']}
                          src={rightArrow}
                        />
                      ),
                    },
                  },
                ]
            : [
                {
                  resolve: slidesToShowPlugin,
                  options: {
                    numberOfSlides: pictureAmountDisplay,
                  },
                },
                {
                  resolve: arrowsPlugin,
                  options: {
                    arrowLeft: (
                      <img
                        onClick={() => leftArrowClickHandler()}
                        className={styles['map-size-popup__arrow']}
                        src={leftArrow}
                      />
                    ),
                    arrowRight: (
                      <img
                        onClick={() => rightArrowClickHandler(mapType)}
                        className={styles['map-size-popup__arrow']}
                        src={rightArrow}
                      />
                    ),
                  },
                },
              ]
        }
      >
        {mapType === 1
          ? MAP_SIZE_GUIDE_POPUP_CONTENT.PAPER_MAP.map((value, index) => (
              <MapSizeItem
                key={index}
                name={value.name}
                widthSize={value.widthSize}
                heightSize={value.heightSize}
                foregroundImageSrc={value.foregroundImageSrc}
                backgroundImageSrc={value.backgroundImageSrc}
                imageDescription={value.imageDescription}
                isRectangle={value.isRectangle}
                foldedDimensions={value.foldedDimensions}
              />
            ))
          : MAP_SIZE_GUIDE_POPUP_CONTENT.CANVAS_MAP.map((value, index) => (
              <MapSizeItem
                key={index}
                name={value.name}
                widthSize={value.widthSize}
                heightSize={value.heightSize}
                foregroundImageSrc={value.foregroundImageSrc}
                backgroundImageSrc={value.backgroundImageSrc}
                imageDescription={value.imageDescription}
                isRectangle={value.isRectangle}
              />
            ))}
      </Carousel>
    </Modal>
  );
};

export default connect(
  (state: Types.RootState) => ({
    mapType: mapSelectors.getMapType(state),
    mapSize: mapSelectors.getMapSize(state),
  }),
  {}
)(MapSizeGuidePopup);
