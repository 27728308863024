import styled, { css } from 'styled-components';

import { coverDropzoneBackground } from 'root/modules/utils/assets';
import { fontSize, WidthType, withMaxInnerWidth } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import { MapIcon } from 'root/modules/components';

type AspectRatioType = { aspectRatio: MAP_FORMAT };

const ASPECT_RATIO: Record<MAP_FORMAT, number> = {
  [MAP_FORMAT.SQUARE]: 1,
  [MAP_FORMAT.RECTANGLE]: 0.62,
};

enum innerBreakpoint {
  small = 320,
  medium = 420,
  large = 650,
}

export const MapPreview = styled.div`
  display: flex;
  position: relative;
`;

export const AspectRatioHolder = styled.div<AspectRatioType>`
  ${({ aspectRatio }) => css`
    padding-top: ${ASPECT_RATIO[aspectRatio] * 100}%;
  `}
  width: 0;
`;

export const MapImage = styled.img`
  width: 100%;
`;

export const Placeholder = styled.div`
  background-image: url(${coverDropzoneBackground});
  width: 100%;

  background-size: 105.3% 108.8%;
  background-position: 47% 55%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 260px;
`;

export const StyledMapIcon = styled(MapIcon)<WidthType>`
  font-size: 78px;
  color: #bcbcbc;

  ${withMaxInnerWidth(innerBreakpoint.large)(css`
    font-size: 62px;
  `)}

  ${withMaxInnerWidth(innerBreakpoint.medium)(css`
    font-size: 46px;
  `)}
`;

const backgroundGradient = (threshold: 25 | 50 | 75) => css`
  background: radial-gradient(circle, rgba(255, 255, 255, 1) ${threshold}%, rgba(255, 255, 255, 0) 100%);
`;

export const ErrorMessage = styled.div<WidthType>`
  text-align: center;
  background: #ffffff;
  ${backgroundGradient(25)}

  ${withMaxInnerWidth(innerBreakpoint.medium)(
    css`
      ${fontSize.small}
      ${backgroundGradient(50)}
    `
  )}

  ${withMaxInnerWidth(innerBreakpoint.small)(css`
    margin-top: -15px;
    ${fontSize.xSmall}
    ${backgroundGradient(75)}
  `)}
`;

export const ErrorMessageText = styled.span``;

export const ErrorMessageButton = styled.button`
  display: inline-block;
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: #453c90;
`;
