import styled, { css } from 'styled-components';

import { breakpoint, CssReturnType, MapFormatType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import { AlternativeLayoutType } from '../../types';
import { PreviewControlPanel as PreviewControlPanelComponent } from '../PreviewControlPanel';
import { FoldedCover } from '../FoldedCover';
import { MapPreview as MapPreviewComponent } from '../MapPreview';

const forRectangular = (content: CssReturnType) => ({ mapFormat }: MapFormatType) =>
  mapFormat === MAP_FORMAT.RECTANGLE && content;

export const FoldedMapPreview = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const MapPreviewWrapper = styled.div`
  height: fit-content;
  width: 100%;
`;

export const OffsetMapFrame = styled.div<AlternativeLayoutType>`
  margin-top: 5%;
  margin-left: 5%;

  ${({ alternativeLayout }) => alternativeLayout && css({ margin: 0 })}
`;

export const MapPreview = styled(MapPreviewComponent)`
  background-color: #ffffff;
`;

export const CoverSpace = styled.div<MapFormatType>`
  display: flex;
  position: relative;
  width: 30%;

  ${forRectangular(css`
    width: 35%;
  `)}

  ${breakpoint.medium(css<MapFormatType>`
    padding-top: 88%;
    left: -55%;
    width: 0;

    ${forRectangular(css`
      padding-top: 75%;
      left: -63%;
    `)}
  `)}

  ${breakpoint.small(css`
    left: -30%;
  `)}
`;

export const VerticalPlaceholder = styled.div<MapFormatType>`
  width: 0;

  ${breakpoint.medium(css<MapFormatType>`
    padding-top: 150%;

    ${forRectangular(css`
      padding-top: 125%;
    `)}
  `)}
`;

export const PreviewControlPanel = styled(PreviewControlPanelComponent)<MapFormatType>`
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;

  margin-top: 20%;
  ${forRectangular(css`
    margin-top: 0%;
    margin-left: 5%;
  `)}

  ${breakpoint.medium(css`
    margin-top: 5%;
    margin-left: 3%;
    top: 58%;
  `)}

  ${breakpoint.small(css`
    flex-direction: column;

    margin-top: 5%;
    margin-left: 3%;
    top: 58%;
  `)}
`;

export const Cover = styled(FoldedCover)<AlternativeLayoutType & MapFormatType>`
  ${({ alternativeLayout, mapFormat }) =>
    alternativeLayout &&
    css`
      left: 65%;
      top: ${mapFormat === MAP_FORMAT.RECTANGLE ? 10 : 11.5}%;

      transform: scale(0.5);

      ${breakpoint.medium(css`
        left: ${mapFormat === MAP_FORMAT.RECTANGLE ? -5.5 : 0}%;
        top: 52.5%;
        transform: scale(0.75);
      `)}

      ${breakpoint.small(css`
        left: ${mapFormat === MAP_FORMAT.RECTANGLE ? 0 : 6.5}%;
        top: ${mapFormat === MAP_FORMAT.RECTANGLE ? 61 : 59}%;

        transform: scale(1);
      `)}
    `}
`;
