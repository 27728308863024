export enum LOCATION_FORM_FIELD_NAMES {
    LOCATION_QUERY = 'locationQuery',
    LOCATION_VALUE = 'locationValue',
    LOCATION_SEARCH = 'locationSearch',
}

export interface ILocationFormValues {
    [LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY]: string;
    [LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE]: string;
    [LOCATION_FORM_FIELD_NAMES.LOCATION_SEARCH]: string;
}
