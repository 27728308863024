import { MapLanguage, Proportions, MapStyle } from 'root/model/map/MapEnums';
import mapExplorerENFrame from 'root/assets/img/mapFrameExplorerEN.jpg';
import mapExplorerCYFrame from 'root/assets/img/mapFrameExplorerCY.jpg';
import mapLandrangerENFrame from 'root/assets/img/mapFrameLandrangerEN.jpg';
import mapLandrangerCYFrame from 'root/assets/img/mapFrameLandrangerCY.jpg';
import mapFrameRectangleExplorer from 'root/assets/img/mapFrameRectangleExplorer.jpg';
import mapFrameRectangleLandranger from 'root/assets/img/mapFrameRectangleLandranger.jpg';

export const MAP_STYLE_TO_FRAME_URL = {
    [Proportions.SQUARE]: {
        [MapStyle.OS_EXPLORER]: {
            [MapLanguage.ENGLISH]: mapExplorerENFrame,
            [MapLanguage.WELSH]: mapExplorerCYFrame,
        },
        [MapStyle.OS_LANDRANGER]: {
            [MapLanguage.ENGLISH]: mapLandrangerENFrame,
            [MapLanguage.WELSH]: mapLandrangerCYFrame,
        },
    },
    [Proportions.RECTANGLE]: {
        [MapStyle.OS_EXPLORER]: mapFrameRectangleExplorer,
        [MapStyle.OS_LANDRANGER]: mapFrameRectangleLandranger,
    },
};
