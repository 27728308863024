import * as React from 'react';
import { Framing } from 'root/model/map/MapEnums';

import FrameType from './FrameType';
import styles from './index.module.scss';

import { FRAME_TYPES } from './constants';

export type IFrameTypesProps = {
    framings: string[];
    framing: Framing;
};

export const FrameTypesList = ({ framings, framing }: IFrameTypesProps) => {
    const frameTypes = FRAME_TYPES.filter((frameType) => framings.includes(frameType.id));

    return (
        <div className={styles['frame-type-list']}>
            {frameTypes.map((frameType) => (
                <FrameType
                    {...frameType}
                    key={frameType.id}
                    active={frameType.id === framing}
                    icon={frameType.icon}
                    title={frameType.title}
                />
            ))}
        </div>
    );
};
