import React from 'react';
import cn from 'classnames';

import mainStyles from '../../../../RouteTab.module.scss';

import styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { changeRouteSetting } from 'root/features/route/actions';
import { THICKNESS_LIST } from './constants';
import { getRouteSettingThickness } from 'root/features/route/selectors';

export const ThicknessSelector = () => {
    const active = useSelector(getRouteSettingThickness);
    const dispatch = useDispatch();

    return (
        <>
            <span className={mainStyles['style-section__title']}>Line Thickness</span>

            <div className={styles['container']}>
                {THICKNESS_LIST.map(({ name, classname, label, value }, index) => {
                    return (
                        <div className={styles['thickness-wrapper']} key={`${name}-${index}`}>
                            <button
                                className={cn(styles['thickness-button'], {
                                    [styles['thickness-button__selected']]: active === value,
                                    [styles[`thickness-button--${classname}`]]: true,
                                })}
                                aria-label={`list item - Line thickness - ${label}`}
                                onClick={() => dispatch(changeRouteSetting({ lineThickness: value }))}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
};
