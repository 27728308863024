import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import AreaAndStyleBottomBarContent from './components/AreaAndStyleBottomBarContent';
import CustomisationBottomBarContent from './components/CustomisationBottomBarContent';
import { ComponentClass } from 'react';
import NavigationBottomBarContent from './components/NavigationBottomBarContent';

export interface IStringAssocArray {
  [key: string]: string;
}

export interface IJourneyStepToBottomBarSetting {
  component: React.FunctionComponent | ComponentClass;
  confirmButtonText?: string | IStringAssocArray;
  canGoBack?: boolean;
}

export interface IJourneyStepToBottomBarSettings {
  [key: string]: IJourneyStepToBottomBarSetting;
}

export const JOURNEY_STEP_TO_BOTTOM_BAR_SETTINGS: IJourneyStepToBottomBarSettings = {
  [CreateProductJourneyStep.AREA_AND_STYLE]: {
    component: AreaAndStyleBottomBarContent,
  },
  [CreateProductJourneyStep.CUSTOMISE]: {
    component: CustomisationBottomBarContent,
  },
  [CreateProductJourneyStep.PRODUCT_TYPE]: {
    component: NavigationBottomBarContent,
    confirmButtonText: 'CONTINUE & CHOOSE LOCATION',
    canGoBack: false,
  },
};
