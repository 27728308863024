import { combineEpics, Epic } from 'redux-observable';
import { of, EMPTY } from 'rxjs';
import { catchError, filter, switchMap, withLatestFrom } from 'rxjs/operators';
import { isOfType } from 'typesafe-actions';

import Types from 'Types';
import { productOptionsSelectors } from 'features/productOptions';
import { mapConstants, mapActions, mapSelectors } from 'features/map';
import { MapAction } from './reducer';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { setCentreLocationCoordinates, setMapLocationParams, setMapStyleParams, setRouteCoordinates } from './actions';
import { MapStyle, Scale, SelectedMapStyle } from 'root/model/map/MapEnums';
import { getNewScale } from 'root/components/Map/utils/utils';
import { getMapStyleFromScale } from 'root/modules/utils/functions';

export const setMapParamsFromSearchParamsAction: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (
    action$,
    state$
) =>
    action$.pipe(
        filter(isOfType(mapConstants.INITIALIZE_MAP_PARAMS_FROM_SEARCH_PARAMS)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { location, style, options } = mapSelectors.getMapParamsFromSearchParams(state);

            return [
                ...(location ? [mapActions.setMapLocationParams(location)] : []),
                ...(style ? [mapActions.setMapStyleParams(style)] : []),
                ...(options ? [mapActions.setMapOptionsParams(options)] : []),
            ];
        })
    );

export const setMapParamsFromProductOptionsAction: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (
    action$,
    state$
) =>
    action$.pipe(
        filter(isOfType(mapConstants.INITIALIZE_MAP_PARAMS_FROM_PRODUCT_OPTIONS)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const productOptions = productOptionsSelectors.getOptions(state);

            if (productOptions.length === 0) {
                return EMPTY;
            }

            const productId = mapSelectors.getProductId(state);
            const option = productOptions.find((item) => {
                return item.productId === productId;
            });

            if (!option) {
                return EMPTY;
            }

            return of(mapActions.setMapOptionsParams({ dimension: option.dimension }));
        })
    );

export const setProduct: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { productOptions }
) =>
    action$.pipe(
        filter(isOfType(mapConstants.SET_PRODUCT)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const productId = mapSelectors.getProductId(state);
            const productParams = {
                scale: mapSelectors.getMapScale(state),
                language: mapSelectors.getMapLanguage(state),
                product: productId,
                framing: mapSelectors.getFraming(state),
                material: mapSelectors.getMaterial(state),
                size: mapSelectors.getMapSize(state),
            };
            const getProductSKU$ = productOptions.getProductSKU(productParams);

            return getProductSKU$.pipe(
                switchMap((product) => {
                    return of(mapActions.setProductParams(product));
                }),
                catchError(() => {
                    console.warn('Error finding product');
                    console.warn(productParams);

                    return EMPTY;
                })
            );
        })
    );

export const setAreaAndStyleCoordinates: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (
    action$,
    state$
) =>
    action$.pipe(
        filter(isOfType(mapConstants.SET_AREA_AND_STYLE_COORDINATES)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const selectedTab = state.map.areaAndStyleTab.selectedTab;
            const coordinates = action.payload;

            if (selectedTab === AreaAndStyleSelectedTab.CentreLocation) {
                return of(setCentreLocationCoordinates(coordinates));
            } else if (selectedTab === AreaAndStyleSelectedTab.Route) {
                return of(setRouteCoordinates(coordinates));
            }

            return EMPTY;
        })
    );

export const setMapLocationParamsFromAreaAndStyleSelectedTab: Epic<
    MapAction,
    MapAction,
    Types.RootState,
    Types.Services
> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(mapConstants.SET_AREA_AND_STYLE_TAB)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            if (action.payload === AreaAndStyleSelectedTab.CentreLocation) {
                const coordinates = state.map.areaAndStyleTab.centreLocationCoordinates;

                return of(setMapLocationParams({ coordinates }));
            } else if (action.payload === AreaAndStyleSelectedTab.Route) {
                const coordinates = state.map.areaAndStyleTab.routeCoordinates;

                return of(setMapLocationParams({ coordinates }));
            }

            return EMPTY;
        })
    );

export const onChangeSelectedMapStyle: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (
    action$,
    state$
) =>
    action$.pipe(
        filter(isOfType(mapConstants.CHANGE_SELECTED_MAP_STYLE)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const currentMapScale = state.map.mapStyleParams.mapScale as Scale;
            const selectedMapStyle = action.payload;
            const mapStyle = getMapStyleFromScale(currentMapScale) as MapStyle;

            const autoSelectedMapStyle = selectedMapStyle === SelectedMapStyle.AUTO;
            const isExplorer = selectedMapStyle === SelectedMapStyle.OS_EXPLORER && mapStyle === MapStyle.OS_EXPLORER;
            const isLandranger =
                selectedMapStyle === SelectedMapStyle.OS_LANDRANGER && mapStyle === MapStyle.OS_LANDRANGER;
            const noChange = autoSelectedMapStyle || isExplorer || isLandranger;

            if (noChange) {
                return of(setMapStyleParams({ selectedMapStyle }));
            } else if (selectedMapStyle === SelectedMapStyle.OS_LANDRANGER) {
                return of(
                    setMapStyleParams({
                        mapStyle: MapStyle.OS_LANDRANGER,
                        mapScale: Scale.OS_LANDRANGER_25K,
                        selectedMapStyle,
                    })
                );
            } else {
                return of(
                    setMapStyleParams({
                        mapStyle: MapStyle.OS_EXPLORER,
                        mapScale: Scale.OS_EXPLORER_25K,
                        selectedMapStyle,
                    })
                );
            }
        })
    );

export const incrementZoom: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(mapConstants.INCREMENT_ZOOM)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const currentMapScale = state.map.mapStyleParams.mapScale;
            const selectedMapStyle = state.map.mapStyleParams.selectedMapStyle;

            const mapScale = getNewScale(currentMapScale, '+', selectedMapStyle as SelectedMapStyle);
            const mapStyle = getMapStyleFromScale(mapScale) as MapStyle;

            return of(setMapStyleParams({ mapScale, mapStyle }));
        })
    );

export const decrementZoom: Epic<MapAction, MapAction, Types.RootState, Types.Services> = (action$, state$) =>
    action$.pipe(
        filter(isOfType(mapConstants.DECREMENT_ZOOM)),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const currentMapScale = state.map.mapStyleParams.mapScale;
            const selectedMapStyle = state.map.mapStyleParams.selectedMapStyle as SelectedMapStyle;

            const mapScale = getNewScale(currentMapScale, '-', selectedMapStyle);
            const mapStyle = getMapStyleFromScale(mapScale) as MapStyle;

            return of(setMapStyleParams({ mapScale, mapStyle }));
        })
    );

export default combineEpics(
    setMapParamsFromSearchParamsAction,
    setMapParamsFromProductOptionsAction,
    setProduct,
    setAreaAndStyleCoordinates,
    setMapLocationParamsFromAreaAndStyleSelectedTab,
    incrementZoom,
    decrementZoom
);
