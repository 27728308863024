import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from 'react-router-dom';
import 'reset-css';
import 'normalize.css';

import history from './services/history';
import store, { persistor } from './appConfig/store';
import routes from './appConfig/routes';

import Layout from 'root/components/Layout';
import AppInitializer from './components/AppInitializer';

import './App.scss';
import BreakHoldingPage from 'root/components/BreakHoldingPage';

type AppState = {
    technicalBreak: string | null;
    technicalBreakEnabled: boolean;
};

class App extends React.Component {
    state: AppState = {
        // TODO this is a temporary solution, we need to verify that it is still in use
        technicalBreak: '',
        technicalBreakEnabled: false,
    };

    public render() {
        return (
            <Provider store={store}>
                <AppInitializer />
                <PersistGate loading={null} persistor={persistor}>
                    <ConnectedRouter history={history}>
                        {this.state.technicalBreakEnabled && this.state.technicalBreak !== 'disabled' ? (
                            <BreakHoldingPage />
                        ) : (
                            <Layout>
                                <Switch>
                                    {Object.keys(routes).map((key, index) => (
                                        <Route key={index} {...routes[key]} />
                                    ))}
                                </Switch>
                            </Layout>
                        )}
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        );
    }
}

export default App;
