import * as React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import Types from 'Types';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { mediaQuerySelectors } from 'root/features/mediaQuery';
import { widerThanOrEqual, thinnerThan } from 'root/services/mediaQuery';
import styles from './index.module.scss';

export enum AreaViewType {
  PRODUCT_PREVIEW = 'productPreview',
  MAP = 'map',
}

interface IPrintAreaViewProps {
  renderScaleLine: () => JSX.Element;
  renderArea: () => JSX.Element;
  areaViewType: AreaViewType;
  mediaQuery: MediaQuery;
}

class PrintAreaView extends React.Component<IPrintAreaViewProps> {
  public render() {
    const { renderArea, areaViewType, mediaQuery } = this.props;
    const viewAreaStyles = classNames(styles['print-area-view'], {
      [styles['print-area-view--product-preview']]:
        areaViewType === AreaViewType.PRODUCT_PREVIEW,
      [styles['print-area-view--map']]: areaViewType === AreaViewType.MAP,
    });

    const spacerClassName = classNames(styles['print-area-view__spacer'], {
      [styles['print-area-view__spacer--map']]:
        areaViewType === AreaViewType.MAP,
    });

    const controlsClassName = classNames(styles['print-area-view__controls'], {
      [styles['print-area-view__controls--map']]:
        areaViewType === AreaViewType.MAP,
    });

    return (
      <div className={viewAreaStyles}>
        {thinnerThan(mediaQuery, MediaQuery.XLARGE) && (
          <div className={spacerClassName} />
        )}
        <div className={styles['print-area-view__outer']}>
          <div className={styles['print-area-view__frame']}>
            <div className={styles['print-area-view__controls']}>
              {widerThanOrEqual(mediaQuery, MediaQuery.XLARGE) &&
                this.renderMapControls()}
            </div>
            {renderArea()}
            <div className={controlsClassName}>
              <div className={styles['print-area-view__info']}>
                {thinnerThan(mediaQuery, MediaQuery.XLARGE) &&
                  this.renderMapControls()}
                <i className={styles['print-area-view__info-icon']} />
                <p className={styles['print-area-view__info-text']}>
                  {areaViewType === AreaViewType.PRODUCT_PREVIEW &&
                    'This style of map is for you to select your area only, not the final product'}
                  {areaViewType === AreaViewType.MAP &&
                    'This is the correct style of map and actual map area represented on your final product'}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderMapControls = () => {
    const { renderScaleLine } = this.props;
    return (
      <React.Fragment>
        <div className={styles['print-area-view__scale-line']}>
          {renderScaleLine()}
        </div>
      </React.Fragment>
    );
  };
}
export default connect((state: Types.RootState) => ({
  mediaQuery: mediaQuerySelectors.getMediaQuery(state),
}))(PrintAreaView);
