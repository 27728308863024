import * as React from 'react';

import styles from './index.module.scss';
import { usePreviewFileName } from './hooks';

const ProductPreviewContainer = () => {
  const previewFileName = usePreviewFileName();

  return (
    <div className={styles['product-preview-container']}>
      {previewFileName && (
        <div className={styles['product-preview-container__wrapper']}>
          <img src={previewFileName} className={styles['product-preview-container__wrapper__image']} />
        </div>
      )}
    </div>
  );
};

export default ProductPreviewContainer;
