import { connect } from 'react-redux';
import * as React from 'react';

import { tourGuideActions } from 'features/tourGuide';
import QuestionIcon from 'root/components/QuestionIcon/question-icon';

interface ITabHeaderQuestionIcon {
  showGuideTooltip: typeof tourGuideActions.showGuideTooltip;
}

const TabHeaderQuestionIcon: React.FunctionComponent<ITabHeaderQuestionIcon> = ({
  showGuideTooltip,
}) => {
  const handleOnClick = () => showGuideTooltip(true);

  return <QuestionIcon onClick={handleOnClick} />;
};

export default connect(null, {
  showGuideTooltip: tourGuideActions.showGuideTooltip,
})(TabHeaderQuestionIcon);
