import * as React from 'react';

import Types from 'Types';
import { useDispatch, useSelector } from 'react-redux';
import { FC } from 'react';

import * as classNames from 'classnames';
import styles from './index.module.scss';
import minus from './assets/minus.svg';
import plus from './assets/plus.svg';

import { checkoutOptionsSelectors } from 'root/features/checkoutOptions';
import { setQuantity } from 'root/features/checkoutOptions/actions';
import CircleButton from 'root/components/CircleButton';

type QuantityChooserProps = {
  className: string;
};

const QuantityChooser: FC<QuantityChooserProps> = ({ className }) => {
  const quantity = useSelector((state: Types.RootState) =>
    checkoutOptionsSelectors.getQuantity(state)
  );
  const dispatch = useDispatch();

  const quantityChooser = classNames(styles['quantity-chooser'], className);

  const onLeftButtonClicked = () => {
    if (quantity > 1) {
      dispatch(setQuantity(quantity - 1));
    }
  };

  const onRightButtonClicked = () => {
    dispatch(setQuantity(quantity + 1));
  };

  const onQuantityChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuantity(parseInt(event.target.value, 10)));
  };

  return (
    <div className={quantityChooser}>
      <span className={styles['quantity-chooser__title']}>Qty:</span>
      <CircleButton icon={minus} onClick={onLeftButtonClicked} />
      <input
        value={quantity}
        onChange={onQuantityChanged}
        className={styles['quantity-chooser__input']}
      />
      <CircleButton icon={plus} onClick={onRightButtonClicked} />
    </div>
  );
};

export default QuantityChooser;
