export const GET_PRODUCT_PREVIEW = 'productPreview/GET_PRODUCT_PREVIEW';

export const SET_PRODUCT_PREVIEW_IMAGE_URL = 'productPreview/SET_PRODUCT_PREVIEW_IMAGE_URL';

export const SET_ERROR_LOADING_PRODUCT_PREVIEW_IMAGE_URL = 'productPreview/SET_ERROR_LOADING_PRODUCT_PREVIEW_IMAGE_URL';

export const RESET_PRODUCT_PREVIEW_IMAGE_URL = 'productPreview/RESET_PRODUCT_PREVIEW_IMAGE_URL';

export enum PRODUCT_PREVIEW {
  REQUEST = 'PRODUCT_PREVIEW_REQUEST',
  SUCCESS = 'PRODUCT_PREVIEW_SUCCESS',
  ERROR = 'PRODUCT_PREVIEW_ERROR',
  CLEAR = 'PRODUCT_PREVIEW_CLEAR',
}
