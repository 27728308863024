import classNames from 'classnames';
import * as React from 'react';
import styles from './index.module.scss';

interface IQuestionIcon {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
}

const QuestionIcon: React.FunctionComponent<IQuestionIcon> = ({
  onClick,
  className,
}) => {
  return (
    <i
      onClick={onClick}
      className={classNames(styles['question-icon'], className)}
    />
  );
};

export default QuestionIcon;
