import React, { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { noop } from 'lodash';

import TabHeader from 'root/components/TabHeader';

import { getValidationErrorMessage } from 'root/features/createProductJourney/selectors';
import { AlertMessage } from 'root/components/AlertMessage';
import MapSizeGuidePopup from 'root/components/MapSizeGuidePopup/MapSizeGuidePopup';
import { Framing } from 'root/model/map/MapEnums';
import { getFraming, getFramings, getMapType } from 'root/features/map/selectors';

import { OptionsList } from './components/OptionsList';
import { ValidationContext, ValidationContextType } from '../../../../providers/validation';
import { FrameTypesList } from './components/FrameTypesList';

import styles from './index.module.scss';
import { ProductTypeList } from './components/ProductType';

const ProductTypeTab = () => {
    const mapType = useSelector(getMapType);
    const validationMessage = useSelector(getValidationErrorMessage);

    const { displayValidation } = useContext(ValidationContext) as ValidationContextType;

    const framings = useSelector(getFramings);
    const framing = useSelector(getFraming);

    const [mapSizeGuidePopupVisible, setMapSizeGuidePopupVisible] = useState(0);

    const showMapSizeGuidePopup = useCallback<(event: React.MouseEvent<HTMLElement>) => void>(() => {
        setMapSizeGuidePopupVisible(mapSizeGuidePopupVisible + 1);
    }, [mapSizeGuidePopupVisible]);

    return (
        <div tour-guide="options_1" className={styles['options-tab']}>
            <MapSizeGuidePopup isVisible={mapSizeGuidePopupVisible} />

            <div className={styles['options-tab__error-container']}>
                {displayValidation && validationMessage && (
                    <AlertMessage message={validationMessage} onRestore={noop} />
                )}
            </div>

            <TabHeader tabTitle="Select your product" tabDescription="" />

            <div className={styles['options-tab__columns']}>
                <ProductTypeList />
            </div>

            {(mapType === 1 || mapType === 2) && (
                <>
                    <div className={styles['options-tab__header']}>Size</div>

                    <div className={styles['options-tab__tooltip']}>
                        * Map area dimensions
                        <div onClick={showMapSizeGuidePopup} className={styles['options-tab__tooltip__size-icon']}>
                            Map Size Guide
                        </div>
                    </div>

                    <div className={styles['options-tab__columns']}>
                        <OptionsList />
                    </div>
                </>
            )}

            {framings && framing && !framings.includes(Framing.NONE) && (
                <>
                    <div className={styles['options-tab__header']}>Frame colour</div>
                    <div className={styles['options-tab__columns']}>
                        <FrameTypesList framing={framing} framings={framings} />
                    </div>
                </>
            )}
        </div>
    );
};

export default ProductTypeTab;
