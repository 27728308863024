import posed from 'react-pose';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';

const rectangleSide = 142.4;
const shortRectangleSide = 182.5;
const scaleToImage = 4.21;

const getBoundCorrectionBottom = (mediaQuery: MediaQuery) => {
  if (mediaQuery === MediaQuery.MEDIUM) {
    return 0.71;
  } else if (mediaQuery === MediaQuery.LARGE) {
    return 0.71;
  } else {
    return 1;
  }
};

export const initializeZoomRectangle = (
  coordinates: { x: number; y: number },
  mediaQuery: MediaQuery
) => {
  return posed.div({
    draggable: true,
    dragBounds: {
      top: -coordinates.y,
      right: coordinates.x,
      left: -coordinates.x,
      bottom: coordinates.y * getBoundCorrectionBottom(mediaQuery),
    },
  });
};

export const getStartCoordinatesOfSquare = (mediaQuery: MediaQuery) => {
  switch (mediaQuery) {
    case MediaQuery.MEDIUM:
      return { x: rectangleSide, y: shortRectangleSide };
    case MediaQuery.LARGE:
      return { x: rectangleSide, y: shortRectangleSide };
    case MediaQuery.XLARGE:
      return { x: rectangleSide, y: rectangleSide };
    default:
      return { x: 0, y: 0 };
  }
};

export const calculateCoordinatesY = (
  value: number,
  mediaQuery: MediaQuery
) => {
  switch (mediaQuery) {
    case MediaQuery.MEDIUM:
      return (value + shortRectangleSide) * scaleToImage;
    case MediaQuery.LARGE:
      return (value + shortRectangleSide) * scaleToImage;
    case MediaQuery.XLARGE:
      return (value + rectangleSide) * scaleToImage;
    default:
      return 0;
  }
};
export const calculateCoordinatesX = (
  value: number,
  mediaQuery: MediaQuery
) => {
  switch (mediaQuery) {
    case MediaQuery.MEDIUM:
      return (value + rectangleSide) * scaleToImage;
    case MediaQuery.LARGE:
      return (value + rectangleSide) * scaleToImage;
    case MediaQuery.XLARGE:
      return (value + rectangleSide) * scaleToImage;
    default:
      return 0;
  }
};
