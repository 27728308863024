import styled, { css } from 'styled-components';

import { getBoxShadow, borderRadius, WidthType, breakpoint } from 'root/modules/styles';
import { MAGNIFIER_MAX_OPTIMAL_HEIGHT } from './constants';

export enum MagnifierZoom {
    small,
    medium,
    large,
}

export type MagnifierZoomType = {
    zoom: MagnifierZoom;
};

export enum ZoomDirection {
    In = 'IN',
    Out = 'OUT',
}

type ZoomDirectionType = {
    direction: ZoomDirection;
};

export enum ZoomBase {
    Basic = 0,
    Extra = 1,
}

export type ZoomBaseType = {
    zoomBase: ZoomBase;
};

export type EnlargeType = {
    enlarge: boolean;
};

export type DisableZoomControl = {
    disableZoomControl?: boolean;
};

export const MagnifierWrapper = styled.div`
    position: absolute;
    top: 0;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
`;

export const MagnifierContainer = styled.div<Partial<WidthType>>`
    display: flex;

    height: ${MAGNIFIER_MAX_OPTIMAL_HEIGHT}px;
    ${breakpoint.medium(css({ height: '50%' }))}
    ${breakpoint.smallMedium(css({ height: '60%' }))}
    ${breakpoint.small(css({ height: '70%' }))}

  ${({ width }) => css({ width })}
`;

export const AspectRatioHolder = styled.div`
    width: 0;
    padding-top: 100%;
`;

type MagnifierProps = MagnifierZoomType & ZoomDirectionType & ZoomBaseType & EnlargeType & DisableZoomControl;

export const Magnifier = styled.div<MagnifierProps>`
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    ${borderRadius.round}

    border: solid #ffffff 5px;
    ${breakpoint.smallMedium(css({ borderWidth: 4 }))}
    ${breakpoint.small(css({ borderWidth: 3 }))}
  
  ${getBoxShadow()}

  ${({ direction, disableZoomControl }) =>
        !disableZoomControl && css({ cursor: direction === ZoomDirection.In ? 'zoom-in' : 'zoom-out' })}

  & > img {
        ${({ zoom, zoomBase }) => css`
            transform: scale(${2 ** (zoomBase + zoom)});
            transition: transform ease-out 0.2s;
        `}
        ${({ enlarge }) => (enlarge ? css({ width: '500%' }) : css({ height: '100%', width: 'initial' }))}
    }
`;
