import { css, keyframes } from 'styled-components';

import { animation, CssWithKeyframes } from './animations';

export const slideVertically = {
    show: keyframes`
    from {
      top: -100%;
      height: 0;
    }

    to {
      top: 0;
      height: auto;
    }
`,
    hide: keyframes`
    from {
      top: 0;
      height: auto;
    }

    to {
      top: -100%;
      height: 0;
    }
  `,
};

export const slideHorizontally = {
    show: keyframes`
    from {
      left: -100%;
      width: 0;
      height: 0;
    }

    to {
      left: 0;
      width: auto;
      height: auto;
    }
  `,
    hide: keyframes`
    from {
      left: 0;
      width: auto;
      height: auto;
    }
    
    to {
      left: -100%;
      width: 0;
      height: 0;
    }
  `,
};

export const slideAnimation: CssWithKeyframes = (keyframe) => css`
    animation: ${keyframe} 0.2s ease-out forwards;
`;

export const beforeStart = css({ visibility: 'hidden', display: 'none' });

export const onStart = css({ visibility: 'visible', display: 'flex' });

export const horizontalAnimation = animation(
    { onShow: slideAnimation, onHide: slideAnimation },
    { onShow: slideHorizontally.show, onHide: slideHorizontally.hide },
    beforeStart,
    onStart
);

export const verticalAnimation = animation(
    { onShow: slideAnimation, onHide: slideAnimation },
    { onShow: slideVertically.show, onHide: slideVertically.hide },
    beforeStart,
    onStart
);
