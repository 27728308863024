import React from 'react';

import styles from './index.module.scss';
import mainStyles from '../../../../RouteTab.module.scss';
import { useTransparencySlider } from './hooks';
import { Slider } from 'root/components/Slider';
import { ROUTE_SETTING_MAX_OPACITY, ROUTE_SETTING_MIN_OPACITY, ROUTE_SETTING_OPACITY_STEP } from './constants';
import { convertTransparencyToOpacity } from 'root/services/route';

export const TransparencySlider = () => {
    const { valuetext, handleChangeValue, activeValue } = useTransparencySlider();

    return (
        <>
            <span className={mainStyles['style-section__title']}>Line Transparency</span>

            <div className={styles['container']}>
                <Slider
                    valueLabelDisplay="auto"
                    ariaLabel="line transparency"
                    ariaValueText="line transparency"
                    valueLabelFormat={valuetext}
                    min={ROUTE_SETTING_MIN_OPACITY}
                    max={ROUTE_SETTING_MAX_OPACITY}
                    step={ROUTE_SETTING_OPACITY_STEP}
                    onChange={handleChangeValue}
                    value={convertTransparencyToOpacity(activeValue)}
                    startLabel={`${ROUTE_SETTING_MIN_OPACITY}%`}
                    endLabel={`${ROUTE_SETTING_MAX_OPACITY * 100}%`}
                />
            </div>
        </>
    );
};
