import { FileRejection } from 'react-dropzone';
import { action } from 'typesafe-actions';

import { RouteStyleType } from 'root/components/Map/utils/types';
import { OptionType } from 'root/components/Select/model';
import { RouteListVM, RouteVM } from 'root/model/route/Route';
import {
    CHANGE_ROUTE_SETTING,
    CHANGE_UPLOADED_ROUTE_ERROR,
    CHANGE_UPLOADED_ROUTE_FILE,
    CHANGE_UPLOADED_ROUTE_FILENAME,
    GET_ROUTE_LIST,
    HANDLE_ROUTE_UPLOAD,
    REQUEST_ROUTE_UPLOAD,
    RESET_ROUTE_LIST,
    RESET_ROUTE_SETTING,
    RESET_SELECTED_ROUTE,
    RESET_UPLOADED_ROUTE,
    RESTORE_ROUTE_COORDINATES,
    RESTORE_ROUTE_SCALE,
    SELECT_ROUTE,
    SET_ROUTE_LIST,
    SET_SELECTED_ROUTE,
} from './constants';
import { UploadedRouteErrorType } from './reducer';

export const getRouteList = () => action(GET_ROUTE_LIST);

export const setRouteList = (routeList: RouteListVM) => action(SET_ROUTE_LIST, routeList);

export const resetRouteList = () => action(RESET_ROUTE_LIST);

export const setSelectedRoute = (route: RouteVM | null) => action(SET_SELECTED_ROUTE, route);

export const selectRoute = (route: OptionType) => action(SELECT_ROUTE, route);

export const resetSelectedRoute = () => action(RESET_SELECTED_ROUTE);

export const changeRouteSetting = (setting: Partial<RouteStyleType>) => action(CHANGE_ROUTE_SETTING, setting);

export const resetRouteSetting = () => action(RESET_ROUTE_SETTING);

export const restoreRouteScale = () => action(RESTORE_ROUTE_SCALE);

export const restoreRouteCoordinates = () => action(RESTORE_ROUTE_COORDINATES);

export const handleRouteUpload = (accepted: File[], rejected: FileRejection[]) =>
    action(HANDLE_ROUTE_UPLOAD, { accepted, rejected });

export const changeUploadedRouteFile = (route: RouteVM) => action(CHANGE_UPLOADED_ROUTE_FILE, route);

export const changeUploadedRouteFilename = (filename: string) => action(CHANGE_UPLOADED_ROUTE_FILENAME, filename);

export const changeUploadedRouteError = (error: UploadedRouteErrorType | null) =>
    action(CHANGE_UPLOADED_ROUTE_ERROR, error);

export const requestRouteUpload = (data: FormData, filename: string) =>
    action(REQUEST_ROUTE_UPLOAD, { data, filename });

export const resetUploadedRoute = () => action(RESET_UPLOADED_ROUTE);
