import { useSelector } from 'react-redux';

import { ProductId, MapType, Size } from 'root/model/map/MapEnums';
import { useCharacterValidation } from 'root/hooks';
import {
    getSpine,
    getSubtitleFirstLine,
    getSubtitleSecondLine,
    getTitleFirstLine,
    getTitleSecondLine,
    getTitleThirdLine,
} from 'root/features/customisation/selectors';
import { getMapSize, getMapType, getProductId } from 'root/features/map/selectors';

export const useCustomisationBottomBarContent = () => {
    const titleFirstLine = useSelector(getTitleFirstLine);
    const titleSecondLine = useSelector(getTitleSecondLine);
    const titleThirdLine = useSelector(getTitleThirdLine);
    const subtitleFirstLine = useSelector(getSubtitleFirstLine);
    const subtitleSecondLine = useSelector(getSubtitleSecondLine);
    const spine = useSelector(getSpine);
    const productId = useSelector(getProductId);
    const mapSize = useSelector(getMapSize) as Size;
    const mapType = useSelector(getMapType);

    const { titleValidator, subtitleValidator, showSpineText, spineValidator } = useCharacterValidation();

    const getValuesToValid = () => {
        if (mapType === MapType.PAPER && mapSize === Size.SMALL)
            return (
                (titleFirstLine === '' || titleValidator(titleFirstLine, true) === '') &&
                (titleSecondLine === '' || titleValidator(titleSecondLine, true) === '') &&
                (subtitleFirstLine === '' || subtitleValidator(subtitleFirstLine, true) === '')
            );

        return (
            (titleFirstLine === '' || titleValidator(titleFirstLine, true) === '') &&
            (titleSecondLine === '' || titleValidator(titleSecondLine, true) === '') &&
            (titleThirdLine === '' || titleValidator(titleThirdLine, true) === '') &&
            (subtitleFirstLine === '' || subtitleValidator(subtitleFirstLine, true) === '') &&
            (subtitleSecondLine === '' || subtitleValidator(subtitleSecondLine, true) === '')
        );
    };

    let validData = getValuesToValid();

    if (productId === ProductId.FOLDED_MAP && showSpineText) {
        validData = validData && (spine === '' || spineValidator(spine, false) === '');
    }

    return {
        validData,
    };
};
