import classNames from 'classnames';
import * as React from 'react';

import Button from 'root/components/Button';
import JourneyButton from 'root/components/JourneyButton';
import styles from './index.module.scss';

interface IConfirmOrderButtonProps {
  isDisabled?: boolean;
}

const ConfirmOrderButton: React.FunctionComponent<IConfirmOrderButtonProps> = ({ isDisabled }) => {
  return (
    <>
      <div className={styles['confirm-order-button']}>
        <Button
          className={classNames(
            styles['confirm-order-button__button'],
            isDisabled ? styles['confirm-order-button__button--disabled'] : null
          )}
          htmlType="submit"
          text="GO TO SUMMARY"
          isDisabled={isDisabled}
          title={isDisabled ? 'Please correct form data' : ''}
        />
      </div>
      <JourneyButton text="BACK" next={false} className={styles['confirm-order-button__back']} />
    </>
  );
};

export default ConfirmOrderButton;
