import { FormikBag } from 'formik';

import { ITitleFormValues, TITLE_FORM_FIELD_NAMES } from 'root/model/customisation/TitleFormValues';
import { required } from 'root/services/formValidators';
import { ProductId } from 'root/model/map/MapEnums';
import { CreateProductJourneyAction } from 'root/model/createProductJourney/CreateProductJourney';

import { IOrderFormProps } from '.';

export const validateForm = (values: ITitleFormValues, props: IOrderFormProps) => {
    const errors = {};
    const isCoverImageRequired = props.croppingImage ? '' : required(props.coverUrl) || required(props.flatMapCoverUrl);

    if (props.productId === ProductId.FOLDED_MAP && isCoverImageRequired) {
        // @ts-ignore
        errors[TITLE_FORM_FIELD_NAMES.COVER_IMAGE] = isCoverImageRequired;
    }

    return errors;
};

export const handleFormSubmit = (values: ITitleFormValues, { props }: FormikBag<IOrderFormProps, ITitleFormValues>) => {
    props.setProduct();
    props.dispatchJourneyAction(CreateProductJourneyAction.REDIRECT);
};
