export const ROUTE_ERRORS = {
    INVALID_GPX_FILE: {
        title: 'Oops! Invalid GPX file.',
        message: "The file you uploaded doesn't seem to be a valid GPX file. Please use different file.",
    },
    FILE_TOO_LARGE: {
        title: 'Oops! Your file is a bit too large.',
        message: 'The file you uploaded is over the 5MB limit. Please try a smaller file.',
    },
    GENERIC: {
        title: 'Oops, sorry!',
        message: "We couldn't load your file, please try again.",
    },
};
