import classNames from 'classnames';
import React, { ButtonHTMLAttributes, FC, forwardRef, SVGProps, useMemo } from 'react';

import styles from './index.module.scss';

export type MapIconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
    Icon: FC<SVGProps<SVGSVGElement>>;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    title: string;
    ref?: any;
    isActive?: boolean;
    isDisabled?: boolean;
    text?: string;
    ariaLabel?: string;
};

export const MapIconButton = forwardRef(
    (
        { Icon, onClick, title, isActive, isDisabled = false, text, ariaLabel, ...buttonProps }: MapIconButtonProps,
        ref
    ) => {
        const label = useMemo(() => (text ? text : title), [text, title]);

        const buttonClassName = useMemo(
            () =>
                classNames(styles['map-icon-button'], {
                    [styles['active']]: isActive,
                }),
            [isActive]
        );

        const iconClassName = useMemo(
            () =>
                classNames(styles['map-icon-button__icon'], {
                    [styles['active__icon']]: isActive,
                }),
            [isActive]
        );

        return (
            <button
                className={buttonClassName}
                title={title}
                onClick={onClick}
                ref={ref}
                {...buttonProps}
                disabled={isDisabled}
                aria-label={ariaLabel}
            >
                {Icon ? <Icon className={iconClassName} /> : label}
            </button>
        );
    }
);
