import * as React from 'react';
import { useSelector } from 'react-redux';
import * as classNames from 'classnames';

import { CURRENCY, CurrencyType } from 'root/model/map/Currency';
import { getAreaAndStyleSelectedTab, getMapCurrency } from 'root/features/map/selectors';
import { getIsGpxUploaded } from 'root/features/route/selectors';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';

import styles from './index.module.scss';
import { useEffect, useState } from 'react';

export interface ISummaryPriceProps {
    price: number;
    isBig?: boolean;
    className?: string;
}

export const SummaryPrice: React.FunctionComponent<ISummaryPriceProps> = ({ price, isBig = false, className }) => {
    const currency = useSelector(getMapCurrency) as CurrencyType;
    const isGpxUploaded = useSelector(getIsGpxUploaded);
    const areaAndStyleSelectedTab = useSelector(getAreaAndStyleSelectedTab);

    const [arrayPrice, setArrayPrice] = useState<string[]>([]);

    const selectedCurrency = currency ? CURRENCY[currency] : CURRENCY.GBP;

    const summaryPrice = classNames(styles['summary-price'], className, {
        [styles['summary-price--big']]: isBig,
    });
    const summaryPriceInteger = classNames(styles['summary-price__price--integer'], {
        [styles['summary-price__price--integer-big']]: isBig,
    });
    const summaryPriceDecimal = classNames(styles['summary-price__price--decimal'], {
        [styles['summary-price__price--decimal-big']]: isBig,
    });

    useEffect(() => {
        const shouldDisplayPriceWithRouteGpx =
            isGpxUploaded && areaAndStyleSelectedTab === AreaAndStyleSelectedTab.Route;

        const finalPrice = shouldDisplayPriceWithRouteGpx ? price + 2 : price;

        if (finalPrice) {
            const parsedPrice: string[] = finalPrice.toString().split('.');
            setArrayPrice(parsedPrice);
        }
    }, [price]);

    return (
        <div className={summaryPrice}>
            <div className={styles['summary-price__price']}>
                <div className={summaryPriceInteger}>{selectedCurrency.concat(arrayPrice[0])}</div>
                <div className={summaryPriceDecimal}>.{arrayPrice[1] || '00'}</div>
            </div>
        </div>
    );
};
