import * as React from 'react';
import { ReactComponent as AlertIcon } from './alert-os.svg';

import styles from './index.module.scss';

type AlertMessageProps = {
    message: string;
    title?: string;
    onRestore?: () => void;
    actionText?: string;
};

export const AlertMessage = ({ title, message, onRestore, actionText }: AlertMessageProps) => {
    return (
        <div className={styles['alert-container']}>
            <div className={styles['alert-container__icon']}>
                <AlertIcon />
            </div>
            <span className={styles['alert-container__message']}>
                {title && <span className={styles['alert-container__message__title']}>{title}</span>}
                {message}
                {onRestore && actionText && (
                    <button onClick={onRestore} className={styles['alert-container__restore-button']}>
                        {actionText}
                    </button>
                )}
            </span>
        </div>
    );
};
