import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';

export interface ITabIndexToJourneyStepItems {
  index: number;
  step: CreateProductJourneyStep;
}

export const TAB_INDEX_TO_JOURNEY_STEP: ITabIndexToJourneyStepItems[] = [
  { index: 0, step: CreateProductJourneyStep.PRODUCT_TYPE },
  { index: 1, step: CreateProductJourneyStep.AREA_AND_STYLE },
  { index: 2, step: CreateProductJourneyStep.CUSTOMISE },
];
