export const SET_MAP_STYLES = 'mapStyles/SET_MAP_STYLES';

export const GET_MAP_STYLES = 'mapStyles/GET_MAP_STYLES';

export const RESET_MAP_STYLES = 'mapStyles/RESET_MAP_STYLES';

export const INITIAL_MAP_STYLES = {
    version: 8,
    name: 'Leisure Map Custom Made',
    sources: {
        'os-leisure-source-explorer': {
            type: 'raster',
            tiles: [
                'https://tiles-cm-leisure.oscptiles.com/1_25k/{z}/{x}/{y}.png?token=4bf76c0cacbb37b3a3a36363aac84155ed576b8fe92540e2514a181f1a84fd5b33c93469e531ed14daf04b3916b2100ea6a2cbe9c005128f3ddbbc3e93e33fc7',
            ],
            tileSize: 256,
            bounds: [-22.5, 45.08903556483103, 16.875, 64.16810689799152],
            maxzoom: 16,
            minzoom: 9,
        },
        'os-leisure-source-landranger': {
            type: 'raster',
            tiles: [
                'https://tiles-cm-leisure.oscptiles.com/1_50k/{z}/{x}/{y}.png?token=4bf76c0cacbb37b3a3a36363aac84155ed576b8fe92540e2514a181f1a84fd5b33c93469e531ed14daf04b3916b2100ea6a2cbe9c005128f3ddbbc3e93e33fc7',
            ],
            tileSize: 256,
            bounds: [-22.5, 45.08903556483103, 16.875, 64.16810689799152],
            maxzoom: 16,
            minzoom: 9,
        },
        'os-leisure-source-miniscale': {
            type: 'raster',
            tiles: [
                'https://250k-tiles-web-leisure.oscptiles.com/{z}/{x}/{y}.png?token=5213bc62ddc03f490aea3f6c13fa215c76e06ec21efe55e77d89274560c3770a7e61b460239e71effc87e9889b40f918485abdfc912a51a918c774edadc8766f',
            ],
            tileSize: 256,
            bounds: [-22.5, 45.08903556483103, 16.875, 64.16810689799152],
            maxzoom: 8,
            minzoom: 6,
        },
    },
    layers: [
        {
            id: 'background',
            type: 'background',
            paint: {
                'background-color': 'hsl(0, 0%, 100%)',
            },
        },
        {
            id: 'os-leisure-layer-explorer',
            type: 'raster',
            source: 'os-leisure-source-explorer',
            layout: {
                visibility: 'none',
            },
        },
        {
            id: 'os-leisure-layer-landranger',
            type: 'raster',
            source: 'os-leisure-source-landranger',
            layout: {
                visibility: 'none',
            },
        },
        {
            id: 'os-leisure-layer-miniscale',
            type: 'raster',
            source: 'os-leisure-source-miniscale',
            layout: {
                visibility: 'none',
            },
        },
    ],
} as mapboxgl.Style;
