import * as classNames from 'classnames';
import * as React from 'react';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Tile } from 'root/components/Tile';
import { setMapOptionsParams } from 'root/features/map/actions';
import { Framing } from 'root/model/map/MapEnums';
import { ValidationContext, ValidationContextType } from 'root/scenes/Home/components/Sidebar/providers/validation';

import styles from './index.module.scss';

export interface IFrameTypeProps {
    id: string;
    name: string;
    active: boolean;
    icon: string;
    title: string;
}

export const FrameType = ({ active, id, title, icon, name }: IFrameTypeProps) => {
    const { displayValidation, setDisplayValidation } = useContext(ValidationContext) as ValidationContextType;
    const dispatch = useDispatch();

    const handleOnClick = () => {
        setDisplayValidation(false);
        dispatch(
            setMapOptionsParams({
                // @ts-ignore
                framing: Framing[id],
                // @ts-ignore
                memorizedFraming: Framing[id],
            })
        );
    };

    return (
        <Tile
            variant="primary"
            className={styles['frame-type']}
            isActive={active}
            isError={displayValidation}
            action={handleOnClick}
        >
            <div className={classNames(styles['frame-type__description'])}>
                <span className={styles['frame-type__description-first-line']}>
                    <img src={icon} alt={title} title={title} />
                    <strong>{name}</strong>
                </span>
            </div>
        </Tile>
    );
};

export default FrameType;
