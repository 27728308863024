import { Scale, ProductId, MapLanguage, Material, Framing, MapType, Size, MapStyle, SelectedMapStyle } from './MapEnums';

export interface IMapLocationCoordinates {
    x: number;
    y: number;
}

export interface IMapLocationParams {
    readonly query?: string;
    readonly coordinates?: IMapLocationCoordinates;
    readonly detailedViewCoordinates?: IMapLocationCoordinates;
}

export interface IMapStyleParams {
    readonly selectedMapStyle?: SelectedMapStyle;
    readonly mapStyle?: MapStyle;
    readonly mapScale?: Scale;
    readonly mapLanguage?: MapLanguage;
    readonly mapType?: MapType;
}

export interface IMapOptionsParams {
    readonly productId?: ProductId;
    readonly material?: Material;
    readonly framing?: Framing;
    readonly memorizedFraming?: Framing;
    readonly framings?: string[];
    readonly dimension?: string;
    readonly price?: number;
    readonly shortDescription?: string;
    readonly currency?: string;
    readonly size?: Size;
    readonly name?: string;
}

export interface IMapParams {
    readonly location?: IMapLocationParams;
    readonly style?: IMapStyleParams;
    readonly options?: IMapOptionsParams;
}

export interface IMapProduct {
    readonly productId: ProductId;
    readonly material: Material;
    readonly framing: Framing;
    readonly framings: string[];
    readonly size: Size;
}

export interface IProductParams {
    readonly sku: string;
    readonly price: number;
}

export enum AreaAndStyleSelectedTab {
    None = 0,
    CentreLocation = 1,
    Route = 2,
}

export interface IAreaAndStyleTab {
    isMiniMapOpen: boolean;
    selectedTab: AreaAndStyleSelectedTab;
    centreLocationCoordinates: IMapLocationCoordinates;
    routeCoordinates: IMapLocationCoordinates;
}
