import React, { FC, HTMLAttributes } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { customistaionSelectors } from 'root/features/customisation';

import { SubTitle, Title } from 'root/modules/components';
import { MapFormatType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';

const TitleWrapper = styled.div`
  position: absolute;
`;

const { getTitles } = customistaionSelectors;

type MapTitleProps = HTMLAttributes<HTMLDivElement> & MapFormatType;

export const MapTitles: FC<MapTitleProps> = ({ mapFormat, ...props }) => {
  const { titleFirstLine, titleSecondLine, titleThirdLine, subtitleFirstLine, subtitleSecondLine } = useSelector(
    getTitles
  );

  return (
    <TitleWrapper {...props}>
      <Title text={titleFirstLine} />
      <Title text={titleSecondLine} />
      {mapFormat === MAP_FORMAT.SQUARE && <Title text={titleThirdLine} />}
      <SubTitle text={subtitleFirstLine} />
      {mapFormat === MAP_FORMAT.SQUARE && <SubTitle text={subtitleSecondLine} />}
    </TitleWrapper>
  );
};
