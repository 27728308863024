import * as React from 'react';
import styles from './index.module.scss';

interface ITooltipProps {
  text: string;
}

const Tooltip: React.FunctionComponent<ITooltipProps> = ({
  text,
  children,
}) => {
  return (
    <div className={styles['tooltip']}>
      <div className={styles['tooltip__container']}>
        <div className={styles['tooltip__text']}>{text}</div>
      </div>
      <div className={styles['tooltip__children']}>{children}</div>
    </div>
  );
};

export default Tooltip;
