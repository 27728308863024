import * as Types from 'Types';
import { createSelector } from 'reselect';

import {
    getAreaAndStyleTab,
    getMapLocationCoordinates,
    getMapOptionsParams,
    getMapStyleParams,
} from 'features/map/selectors';
import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { Scale } from 'root/model/map/MapEnums';
import { isFramingSelected, isMapTypeSelected, isProductSeleted } from './helper';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { getSelectedRoute } from '../route/selectors';

export const getCreateProductJourneyStep = (state: Types.RootState) => state.createProductJourney.journeyStep;

export const canProceedToNextStep = createSelector(
    getMapLocationCoordinates,
    getMapStyleParams,
    getMapOptionsParams,
    getCreateProductJourneyStep,
    getAreaAndStyleTab,
    getSelectedRoute,
    (coordinates, mapStyleParams, optionsParams, journeyStep, areaAndStyleTab, selectedRoute) => {
        if (journeyStep === CreateProductJourneyStep.PRODUCT_TYPE) {
            return (
                isMapTypeSelected(mapStyleParams) && isProductSeleted(optionsParams) && isFramingSelected(optionsParams)
            );
        } else if (journeyStep === CreateProductJourneyStep.AREA_AND_STYLE) {
            const isCoordinates = coordinates !== undefined;
            const isMapScale = mapStyleParams.mapScale;
            const isSelectedCentreLocation = areaAndStyleTab.selectedTab === AreaAndStyleSelectedTab.CentreLocation;
            const isSelectedRoute =
                areaAndStyleTab.selectedTab === AreaAndStyleSelectedTab.Route && selectedRoute !== null;

            return isCoordinates && isMapScale && (isSelectedCentreLocation || isSelectedRoute);
        } else {
            return true;
        }
    }
);

export const getValidationErrorMessage = createSelector(
    getMapStyleParams,
    getMapOptionsParams,
    (mapStyleParams, optionsParams) => {
        if (!isMapTypeSelected(mapStyleParams)) {
            return 'Please select product type to continue';
        }

        if (!isProductSeleted(optionsParams)) {
            return 'Please select product size to continue';
        }

        if (!isFramingSelected(optionsParams)) {
            return 'Please select frame colour to continue';
        }

        return '';
    }
);

export const getIsSendOrderInProgress = (state: Types.RootState) => state.createProductJourney.isSendOrderInProgress;

export const getIsProductPreviewOverlayOpened = (state: Types.RootState) =>
    state.createProductJourney.isProductPreviewOverlayOpened;

export const getIsCustomiseStep = (state: Types.RootState) =>
    state.createProductJourney.journeyStep === CreateProductJourneyStep.CUSTOMISE;

export const getIsEntryHistoryPointMounted = (state: Types.RootState) =>
    state.createProductJourney.isEntryHistoryPointMounted;
