import { MapStyle, SelectedMapStyle } from 'src/model/map/MapEnums';

import landranger from './assets/landranger.jpg';
import explorer from './assets/explorer.jpg';

export const stylesOptions = [
    {
        icon: explorer,
        title: 'Explorer',
        description: 'For most outdoor activities, discover new wonders.',
        mapScale: MapStyle.OS_EXPLORER,
        active: SelectedMapStyle.OS_EXPLORER,
    },
    {
        icon: landranger,
        title: 'Landranger',
        description: 'A larger area in detail, to plan longer excursions.',
        mapScale: MapStyle.OS_LANDRANGER,
        active: SelectedMapStyle.OS_LANDRANGER,
    },
];
