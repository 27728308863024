import * as React from 'react';

import styles from './index.module.scss';

export default class NotFound extends React.Component {
  public render() {
    return (
      <div className={styles['not-found']}>
        <h1 className={styles['not-found__header']}>
          Oops, sorry, wrong turn!
        </h1>
        <p>We can't seem to find the page you're looking for.</p>
      </div>
    );
  }
}
