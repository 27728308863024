import * as React from 'react';
import { connect } from 'react-redux';
import { animateScroll } from 'react-scroll';

import Types from 'Types';
import { PRODUCT_PREVIEW_RESOLUTION } from 'root/constants';
import { CreateProductJourneyAction } from 'root/model/createProductJourney/CreateProductJourney';
import { createProductJourneyActions, createProductJourneySelectors } from 'features/createProductJourney';
import { productPreviewActions } from 'features/productPreview';
import Button from 'root/components/Button';
import styles from './index.module.scss';
import JourneyButton from 'root/components/JourneyButton';

export interface ILockMapSection {
    canProceedToNextStep: boolean;
    dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
    getProductPreview: typeof productPreviewActions.getProductPreview;
    resetProductPreviewImageUrl: typeof productPreviewActions.resetProductPreviewImageUrl;
}

const LockMapSection: React.FunctionComponent<ILockMapSection> = ({
    canProceedToNextStep,
    dispatchJourneyAction,
    getProductPreview,
    resetProductPreviewImageUrl,
}) => {
    const onNextButtonClick = () => {
        if (!canProceedToNextStep) {
            return;
        }

        resetProductPreviewImageUrl();
        getProductPreview(PRODUCT_PREVIEW_RESOLUTION);
        animateScroll.scrollToTop({
            duration: 800,
            smooth: 'easeInOutQuart',
        });
        dispatchJourneyAction(CreateProductJourneyAction.NEXT);
    };

    return (
        <div className={styles['lock-map-section']}>
            <Button
                className={styles['lock-map-section__button-forth']}
                onClick={onNextButtonClick}
                text="CONTINUE AND CUSTOMISE"
                isDisabled={!canProceedToNextStep}
            />
            <JourneyButton text="BACK" next={false} className={styles['lock-map-section__button-back']} />
        </div>
    );
};

export default connect(
    (state: Types.RootState) => ({
        canProceedToNextStep: createProductJourneySelectors.canProceedToNextStep(state),
    }),
    {
        dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
        getProductPreview: productPreviewActions.getProductPreview,
        resetProductPreviewImageUrl: productPreviewActions.resetProductPreviewImageUrl,
    }
)(LockMapSection);
