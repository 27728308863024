import * as React from 'react';
import styles from './index.module.scss';

import ConfirmOrderButton from './components/ConfirmOrderButton';
import { useCustomisationBottomBarContent } from './hooks';
import { Price } from 'root/components/Price';

const CustomisationBottomBarContent: React.FunctionComponent = () => {
    const { validData } = useCustomisationBottomBarContent();

    return (
        <div className={styles['customisation-bottom-bar-content']}>
            <Price />
            <ConfirmOrderButton isDisabled={!validData} />
        </div>
    );
};

export default CustomisationBottomBarContent;
