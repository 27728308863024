import * as React from 'react';
import { connect } from 'react-redux';

import * as Types from 'Types';
import CoverTitles from 'root/components/CoverTitles';
import { mapSelectors } from 'root/features/map';
import styles from './index.module.scss';
import { MapType, Size } from 'root/model/map/MapEnums';
import classNames from 'classnames';

interface IMapTitlesPreviewOverlayProps {
  frameUrl: string;
  mapType?: number;
  mapSize: Size;
}

const MapTitlesPreviewOverlay: React.FunctionComponent<IMapTitlesPreviewOverlayProps> = ({
  frameUrl,
  mapType,
  mapSize,
}) => {
  const isRectangleFramed =
    mapType === MapType.CANVAS_AND_FRAMED &&
    [Size.SMALL, Size.MEDIUM].includes(mapSize);

  const style = isRectangleFramed
    ? {}
    : { backgroundImage: `url(${frameUrl})` };
  const mapTitlePreviewOverlayClasses = classNames(
    styles['map-titles-preview-overlay'],
    {
      [styles['map-titles-preview-overlay--rectangle']]: isRectangleFramed,
    }
  );

  const containerClasses = classNames(
    styles['map-titles-preview-overlay__titles-container'],
    {
      [styles[
        'map-titles-preview-overlay__titles-container--rectangle'
      ]]: isRectangleFramed,
    }
  );

  return (
    <div className={mapTitlePreviewOverlayClasses} style={style}>
      <div className={containerClasses}>
        <CoverTitles isWithSpine={false} />
      </div>
    </div>
  );
};

export default connect((state: Types.RootState) => ({
  mapSize: mapSelectors.getMapSize(state),
  mapType: mapSelectors.getMapType(state),
}))(MapTitlesPreviewOverlay);
