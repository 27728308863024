import { switchMap, catchError, filter } from 'rxjs/operators';
import { combineEpics, Epic } from 'redux-observable';
import { isOfType } from 'typesafe-actions';

import Types from 'Types';
import { ProductOptionsActions } from './reducer';
import { GET_PRODUCTS_OPTIONS } from './constants';
import { productOptionsActions } from '.';

export const getProductOptionsAction: Epic<
  ProductOptionsActions,
  ProductOptionsActions,
  Types.RootState,
  Types.Services
> = (action$, state$, { productOptions }) =>
  action$.pipe(
    filter(isOfType(GET_PRODUCTS_OPTIONS)),
    switchMap((action) =>
      productOptions.getProductOptionsFromAPI().pipe(
        switchMap((result) => {
          return [
            productOptionsActions.setProductOptions(result.options),
            productOptionsActions.setPredefinedCoverImages(
              result.predefinedCoverImages
            ),
          ];
        }),
        catchError(() => [
          productOptionsActions.setProductOptions([]),
          productOptionsActions.setPredefinedCoverImages({
            SMALL: [],
            LARGE: [],
          }),
        ])
      )
    )
  );

export default combineEpics(getProductOptionsAction);
