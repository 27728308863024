import React from 'react';
import { useSelector } from 'react-redux';

import { IProductOption } from 'root/model/product/ProductOption';
import { isActiveOption } from 'root/services/productOptions';
import NoItemsMessage from 'root/components/NoItemsMessage';
import { Option } from './Option';
import styles from './index.module.scss';
import { Framing } from 'root/model/map/MapEnums';
import { getOptions } from 'root/features/productOptions/selectors';
import { getFraming, getMapType, getProduct } from 'root/features/map/selectors';

export const OptionsList = () => {
    const options = useSelector(getOptions);
    const product = useSelector(getProduct);
    const mapType = useSelector(getMapType);
    const framing = useSelector(getFraming);

    const filteredOptions = options.filter((option) => option.type === mapType);

    const appendFraming = (option: IProductOption, currentFraming: Framing): IProductOption => {
        if (!option || !option.framings) {
            return option;
        }

        const result = {
            ...option,
            framing:
                option.framings.length > 0 && option.framings[0] !== Framing.NONE
                    ? currentFraming && currentFraming !== Framing.NONE
                        ? currentFraming
                        : Framing.LIGHT_OAK
                    : Framing.NONE,
        };

        return result;
    };

    const optionsWithFramings = (options: IProductOption[], currentFraming: Framing) => {
        return options.map((option) => appendFraming(option, currentFraming));
    };

    return (
        <div className={styles['options-list']}>
            {filteredOptions.length > 0 ? (
                optionsWithFramings(filteredOptions, framing).map((option, index) => (
                    <Option
                        {...option}
                        key={option.name}
                        active={isActiveOption(
                            {
                                productId: option.productId,
                                material: option.material,
                                size: option.size,
                                framings: option.framings,
                                framing,
                            },
                            product
                        )}
                    />
                ))
            ) : (
                <NoItemsMessage text="There are no available options" />
            )}
        </div>
    );
};
