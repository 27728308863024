import { AnyLayer } from 'mapbox-gl';
import { MapStyle, Scale } from 'root/model/map/MapEnums';

export const getReturnValue = <T>(value: ((...args: any[]) => T) | T, ...args: any[]): T =>
    value instanceof Function ? value(...args) : value;

export const toArray = <T>(element: T | T[]): T[] => (Array.isArray(element) ? element : [element]);

export const isPointInRect = ({ x, y }: { x: number; y: number }, { left, right, top, bottom }: DOMRect) =>
    left <= x && x <= right && top <= y && y <= bottom;

type MapStyleTypePrefix = 'layer' | 'source';

export const getMapStyleFromScale = (mapScale: Scale) => {
    const scaleArr = mapScale.split('_');
    const mapStyle = [scaleArr[0], scaleArr[1]].join('_');

    return mapStyle;
};

export const getMapScaleValue = (mapScale: Scale) => {
    const scaleArr = mapScale.split('_');
    const scaleValue = scaleArr[3] ? [scaleArr[2], scaleArr[3]].join('_') : scaleArr[2];

    return scaleValue;
};

export const getMapStylePrefix = (mapScale: Scale, type: MapStyleTypePrefix) => {
    const mapStyle = getMapStyleFromScale(mapScale);

    switch (mapStyle) {
        case MapStyle.OS_EXPLORER: {
            return `os-leisure-${type}-explorer`;
        }

        case MapStyle.OS_LANDRANGER: {
            return `os-leisure-${type}-landranger`;
        }

        case Scale.OS_UK: {
            return `os-leisure-${type}-miniscale`;
        }

        default:
            return `os-leisure-${type}-explorer`;
    }
};

export const createMapStyles = (mapStyles: mapboxgl.Style | null, mapScale: Scale) => {
    if (mapStyles === null) {
        return undefined;
    }

    const layerPrefix = getMapStylePrefix(mapScale, 'layer');

    const updateLayer = mapStyles?.layers?.find((el) => el.id === layerPrefix);
    const newLayer = { ...updateLayer, layout: { visibility: 'visible' } };
    const newLayers = [...mapStyles.layers?.filter((el) => el.id !== layerPrefix)!, newLayer] as AnyLayer[];

    return { ...mapStyles, layers: newLayers };
};
