import posed from 'react-pose';

export const POSES = {
  EXPANDED: 'expanded',
  COLLAPSED: 'collapsed',
};

const POSE_TRANSITION = {
  type: 'spring',
  damping: 18,
};

export const getSidebarContainerComponent = () => {
  return posed.div({
    passive: {
      y: ['y', (v: number) => v, true],
    },
  });
};

export const getSlideComponent = () => {
  return posed.div({
    draggable: 'y',
    dragBounds: ({ bottomBound }) => ({ top: 0, bottom: bottomBound }),
    [POSES.EXPANDED]: {
      y: 0,
      transition: POSE_TRANSITION,
    },
    [POSES.COLLAPSED]: {
      y: ({ bottomBound }: { bottomBound: number }) => bottomBound,
      transition: POSE_TRANSITION,
    },
    props: {
      bottomBound: 0,
    },
  });
};

export const SIDEBAR_MIN_HEIGHT = 58;
