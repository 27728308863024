export const ROUTE_WARNINGS = {
    ENTIRELY_OUTSIDE_MAP:
        "Heads up! Your selected route isn't showing up because it's completely outside the map boundaries.",
    PARTIALLY_OUTSIDE_MAP:
        "Be aware! Part of your selected route falls outside the map boundaries and won't be visible. Consider adjusting your map area.",
    NOT_OPTIMAL_WARNING1:
        "This route may not print optimally at your selected scale, we'd recommend you try a different scale.",
    NOT_OPTIMAL_WARNING2:
        "The route you've chosen may not display well on your selected product, please try a different route or product.",
    TOO_THIN_AND_TRANSPARENT:
        'Your route line seems too thin and transparent. It may not stand out on the map. Try increasing the thickness and opacity for better visibility.',
    TOO_THICK_AND_OPAQUE:
        'Your route line is quite thick and opaque, which may obstruct other map details. For optimal clarity, try reducing the thickness and opacity.',
} as const;

export const ROUTE_WARNING_ACTION_TEXT = {
    ENTIRELY_OUTSIDE_MAP: 'Centre Route on Map',
    PARTIALLY_OUTSIDE_MAP: 'Centre Route on Map',
    MAP_SCALE_AND_PRODUCT_SIZE_NOT_OPTIMAL: 'Restore Optimal Scale',
    TOO_THIN_AND_TRANSPARENT: 'Restore Optimal Styles',
    TOO_THICK_AND_OPAQUE: 'Restore Optimal Styles',
} as const;
