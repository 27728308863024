import * as React from 'react';
import { connect } from 'react-redux';

import * as Types from 'Types';
import { productPreviewSelectors } from 'features/productPreview';
import LoadingIndicator from 'root/components/LoadingIndicator';
import styles from './index.module.scss';

interface IProductPreviewMapProps {
  productPreviewImageUrl: string;
  previewImageClassName: string;
  onImageLoad?: (width: number, height: number) => void;
}

class ProductPreviewMap extends React.PureComponent<IProductPreviewMapProps> {
  public onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    if (this.props.onImageLoad) {
      this.props.onImageLoad(
        e.currentTarget.naturalWidth,
        e.currentTarget.naturalHeight
      );
    }
  };

  public render() {
    return (
      <React.Fragment>
        {this.props.productPreviewImageUrl ? (
          <img
            className={this.props.previewImageClassName}
            src={this.props.productPreviewImageUrl}
            alt="Product Preview image"
            onLoad={this.onImageLoad}
          />
        ) : (
          <LoadingIndicator
            className={styles['product-preview-map__loading']}
            elementClassName={styles['product-preview-map__loading-element']}
          />
        )}
      </React.Fragment>
    );
  }
}

export default connect((state: Types.RootState) => ({
  productPreviewImageUrl: productPreviewSelectors.getProductPreviewImageUrl(
    state
  ),
}))(ProductPreviewMap);
