import React from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';

import Button from 'root/components/Button';
import { ALLOWED_MIME_TYPE_FOR_ROUTE_UPLOAD } from 'root/services/fileHelpers/consts';

import styles from './RouteUploadDropzone.module.scss';

import { RouteFeeInformation } from '../RouteFeeInformation';

type Props = {
    onDrop: (acceptedFiles: File[], rejectedFiles: FileRejection[]) => void;
};

export const RouteUploadDropzone = ({ onDrop }: Props) => {
    return (
        <>
            <Dropzone onDrop={onDrop} accept={ALLOWED_MIME_TYPE_FOR_ROUTE_UPLOAD}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} multiple={false} />

                        <Button text="UPLOAD YOUR GPX" className={styles['upload-button']} />
                    </div>
                )}
            </Dropzone>

            <RouteFeeInformation />
        </>
    );
};
