import { ActionType } from 'typesafe-actions';
import { Epic, ofType, combineEpics } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';
import { concat, of, merge, EMPTY } from 'rxjs';

import Types from 'Types';
import { imageCropActions } from '../imageCrop';
import { coverActions } from '.';
import { customisationActions } from 'features/customisation';
import { GET_PREDEFINED_IMAGE, HANDLE_COVER_UPLOAD } from './constants';

type HandleCoverUpload = ActionType<typeof coverActions.handleCoverUpload>;
type GetPredefinedImage = ActionType<typeof coverActions.getFullPredefinedImage>;

export const handleCoverUpload: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { file }
) =>
    action$.pipe(
        ofType<HandleCoverUpload>(HANDLE_COVER_UPLOAD),
        switchMap((action) => {
            const { accepted } = action.payload;
            const { resolve, reject } = action.meta;

            if (accepted.length) {
                return file.getImageFileDataUrl(accepted[0]).pipe(
                    switchMap((fileUrl) => {
                        if (resolve) {
                            resolve();
                        }

                        return concat(
                            of(coverActions.setNewCoverUrl(fileUrl)),
                            of(imageCropActions.setCropForNewImage())
                        );
                    }),
                    catchError((error) => {
                        if (reject) {
                            reject(error);
                        }

                        return EMPTY;
                    })
                );
            }

            return EMPTY;
        })
    );

export const handleGetPredefinedImage: Epic<Types.RootAction, Types.RootAction, Types.RootState, Types.Services> = (
    action$,
    state$,
    { cover, file: fileHelper }
) =>
    action$.pipe(
        ofType<GetPredefinedImage>(GET_PREDEFINED_IMAGE),
        switchMap((action) => {
            return cover.getPredefinedImage(action.payload).pipe(
                switchMap((product) => {
                    return fileHelper.getBase64FromBlob(product.response);
                }),
                switchMap((url) => {
                    return cover.sendImage(url).pipe(
                        switchMap((ajaxResponse) => {
                            return merge(
                                of(customisationActions.setLinkToImage(ajaxResponse.response.url)),
                                of(
                                    customisationActions.setIsLoadingImage({
                                        isLoading: false,
                                        index: -1,
                                    })
                                ),
                                of(coverActions.setCoverUrl(url))
                            );
                        }),
                        catchError(() => {
                            return merge(
                                of(coverActions.setCoverUrl('')),
                                of(
                                    customisationActions.setIsLoadingImage({
                                        isLoading: false,
                                        index: -1,
                                    })
                                )
                            );
                        })
                    );
                }),
                catchError(() => {
                    return merge(
                        of(coverActions.setCoverUrl('')),
                        of(
                            customisationActions.setIsLoadingImage({
                                isLoading: false,
                                index: -1,
                            })
                        )
                    );
                })
            );
        })
    );

export default combineEpics(handleCoverUpload, handleGetPredefinedImage);
