import { ajax } from 'rxjs/ajax';

export const sendImage = (imageData: string) => {
  return ajax({
    url: `${process.env.CMFA_APP_ORDER_CAPTOR_IMAGE_URL}`,
    method: 'POST',
    body: imageData,
    headers: { 'Content-Type': 'text/plain' },
  });
};

export const getPredefinedImage = (url: string) => {
  return ajax({
    method: 'GET',
    url,
    crossDomain: true,
    responseType: 'blob',
  });
};
