import { ActionType } from 'typesafe-actions';
import { Crop, PixelCrop } from 'react-image-crop';

import * as actions from './actions';
import {
    SET_IMAGE_CROP,
    RESET_IMAGE_CROP,
    SET_CROPPED_COVER,
    SET_CROP_FOR_NEW_IMAGE,
    SEND_IMAGE_TO_SERVER,
    SET_CROP_RATIO,
    SET_SAVING_IMAGE_ERROR,
} from './constants';

export type ImageCropActions = ActionType<typeof actions>;

export interface ICoverCropState {
    readonly isCroppingImage: boolean;
    readonly isSavingImage: boolean;
    readonly isSavingImageError: boolean;
    readonly crop: Crop;
    readonly pixelCrop: PixelCrop;
    readonly initialCropRatio: number;
}

export const initialImageCropState: ICoverCropState = {
    isCroppingImage: false,
    isSavingImage: false,
    isSavingImageError: false,
    crop: { aspect: 1, width: 400, height: 400, x: 0, y: 0 },
    pixelCrop: { height: 0, width: 0, x: 0, y: 0 },
    initialCropRatio: 1,
};

export const imageCropReducer = (state: ICoverCropState = initialImageCropState, action: ImageCropActions) => {
    switch (action.type) {
        case SET_IMAGE_CROP:
            return {
                ...state,
                crop: action.payload.crop,
                pixelCrop: action.payload.pixelCrop,
            };
        case SET_CROPPED_COVER:
            return { ...state, isSavingImage: true };
        case RESET_IMAGE_CROP:
            return {
                ...initialImageCropState,
                crop: {
                    ...initialImageCropState.crop,
                    aspect: state.initialCropRatio,
                },
                initialCropRatio: state.initialCropRatio,
            };
        case SET_CROP_FOR_NEW_IMAGE:
            return {
                ...initialImageCropState,
                crop: {
                    ...initialImageCropState.crop,
                    aspect: state.initialCropRatio,
                },
                initialCropRatio: state.initialCropRatio,
                isCroppingImage: true,
            };
        case SEND_IMAGE_TO_SERVER:
            return { ...state, crop: { width: 0, height: 0, x: 0, y: 0 } };
        case SET_SAVING_IMAGE_ERROR:
            return { ...state, isSavingImageError: action.payload.isError, isSavingImage: false };
        case SET_CROP_RATIO:
            return {
                ...state,
                crop: {
                    ...state.crop,
                    aspect: action.payload.cropRatio,
                },
                initialCropRatio: action.payload.cropRatio,
            };
        default:
            return state;
    }
};

export default imageCropReducer;
