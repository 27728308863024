import * as React from 'react';
import { connect } from 'react-redux';

import * as Types from 'Types';
import { mapSelectors } from 'features/map';

interface IMapOptionDetailsProps {
  dimension: string;
}

const MapOptionDetails: React.FunctionComponent<IMapOptionDetailsProps> = ({
  dimension,
}) => {
  return <>{dimension}</>;
};

export default connect((state: Types.RootState) => ({
  dimension: mapSelectors.getMapDimension(state),
}))(MapOptionDetails);
