import { Observable, Observer } from 'rxjs';

export const rotate = (imageUrl: string): Observable<string> =>
  Observable.create((observer: Observer<string>) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const { width, height } = image;

      canvas.width = height;
      canvas.height = width;

      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.translate(height / 2, height / 2);
        ctx.rotate((90 * Math.PI) / 180);
        ctx.translate(-height / 2, -height / 2);

        ctx.drawImage(image, 0, 0);
        ctx.restore();

        observer.next(canvas.toDataURL('image/jpeg'));
      } else {
        observer.error('Could not rotate image');
      }

      observer.complete();
    };

    image.src = imageUrl;
  });
