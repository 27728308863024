import { LineColorType } from 'root/model/route/Route';

export const COLOR_LIST = [
    {
        name: 'black',
        classname: 'black',
        label: 'Black',
        value: LineColorType.Black,
    },
    {
        name: 'purple',
        classname: 'purple',
        label: 'Purple',
        value: LineColorType.Purple,
    },
    {
        name: 'red',
        classname: 'red',
        label: 'Red',
        value: LineColorType.Red,
    },
    {
        name: 'green',
        classname: 'green',
        label: 'Green',
        value: LineColorType.Green,
    },
    {
        name: 'yellow',
        classname: 'yellow',
        label: 'Yellow',
        value: LineColorType.Yellow,
    },
    {
        name: 'blue',
        classname: 'blue',
        label: 'Blue',
        value: LineColorType.Blue,
    },
];
