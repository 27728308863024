import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import { SET_MAP_STYLES, RESET_MAP_STYLES, INITIAL_MAP_STYLES } from './constants';

export interface IMapStylesState {
    readonly mapStyles: mapboxgl.Style | null;
}

export type MapStylesAction = ActionType<typeof actions>;

export const mapStylesReducer = (state: mapboxgl.Style = INITIAL_MAP_STYLES, action: MapStylesAction) => {
    switch (action.type) {
        case SET_MAP_STYLES:
            return {
                ...action.payload,
            };
        case RESET_MAP_STYLES: {
            return INITIAL_MAP_STYLES;
        }
        default:
            return state;
    }
};

export default combineReducers<IMapStylesState, MapStylesAction>({
    mapStyles: mapStylesReducer,
});
