export enum offset {
  xSmall = '5px',
  small = '10px',
  medium = '15px',
  large = '20px',
  xLarge = '25px',
  xxLarge = '30px',
  xxxLarge = '40px',
}

export enum BorderRadius {
  medium = '3px',
  round = '50%',
}

export enum FontSize {
  xSmall = '12px',
  small = '14px',
  medium = '16px',
  large = '18px',
  xLarge = '22px',
  xxLarge = '24px',
}

export enum HeaderFontSize {
  h1 = '32px',
  h1s = '30px',
  // TODO: h2

  h3 = '18px',
  h3s = '16px',
  // TODO: h4, h5, h6
}

export enum HeaderLineHeight {
  h1 = '40px',
  // TODO: h2

  h3 = '24px',
  // TODO: h4, h5, h6
}

export enum Breakpoint {
  small = 420,
  smallMedium = 540,
  medium = 840,
  large = 1160,
}

export enum BoxShadow {
  light = '0 2px 5px 1px #0000004d',
  medium = '0 2px 5px 3px #0000004d',
}

export const leftPanelWidth = '360px';

export const enum FontFamilyType {
  osgill = 'OSGill',
  sourcesanspro = 'SourceSansPro',
}
