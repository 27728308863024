import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertMessage } from 'root/components/AlertMessage';
import { RouteValidatorContext } from '../../providers/routeValidator';
import styles from './index.module.scss';
import { getAreaAndStyleSelectedTab } from 'root/features/map/selectors';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { getUploadedRoute } from 'root/features/route/selectors';

export const RouteMapValidator = () => {
    const { routeMapValidator } = useContext(RouteValidatorContext);

    const { error } = useSelector(getUploadedRoute);
    const selectedTab = useSelector(getAreaAndStyleSelectedTab);
    const dispatch = useDispatch();
    const isGPXError = true;

    if (selectedTab !== AreaAndStyleSelectedTab.Route || (isGPXError === null && routeMapValidator === null)) {
        return null;
    }

    const onRestoreMapScale = () => {
        if (routeMapValidator) {
            dispatch(routeMapValidator.restoreDefault());
        }
    };

    return (
        <div className={styles['validator-container']}>
            {error && <AlertMessage title={error.title} message={error.message} />}

            {routeMapValidator && (
                <AlertMessage
                    message={routeMapValidator.message}
                    onRestore={onRestoreMapScale}
                    actionText={routeMapValidator.actionText}
                />
            )}
        </div>
    );
};
