export const GET_ROUTE_LIST = 'route/GET_ROUTE_LIST';

export const SET_ROUTE_LIST = 'route/SET_ROUTE_LIST';

export const SELECT_ROUTE = 'route/SELECT_ROUTE';

export const SET_SELECTED_ROUTE = 'route/SET_SELECTED_ROUTE';

export const RESET_ROUTE_LIST = 'route/RESET_ROUTE_LIST';

export const RESET_SELECTED_ROUTE = 'route/RESET_SELECTED_ROUTE';

export const CHANGE_ROUTE_SETTING = 'route/CHANGE_ROUTE_SETTING';

export const RESET_ROUTE_SETTING = 'route/RESET_ROUTE_SETTING';

export const RESTORE_ROUTE_SCALE = 'route/RESTORE_ROUTE_SCALE';

export const RESTORE_ROUTE_COORDINATES = 'route/RESTORE_ROUTE_COORDINATES';

export const HANDLE_ROUTE_UPLOAD = 'route/HANDLE_ROUTE_UPLOAD';

export const CHANGE_UPLOADED_ROUTE_FILE = 'route/CHANGE_UPLOADED_ROUTE_FILE';

export const CHANGE_UPLOADED_ROUTE_FILENAME = 'route/CHANGE_UPLOADED_ROUTE_FILENAME';

export const CHANGE_UPLOADED_ROUTE_ERROR = 'route/CHANGE_UPLOADED_ROUTE_ERROR';

export const REQUEST_ROUTE_UPLOAD = 'route/REQUEST_ROUTE_UPLOAD';

export const RESET_UPLOADED_ROUTE = 'route/RESET_UPLOADED_ROUTE';
