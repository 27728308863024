export enum ENV_NAME {
  PROD = 'production',
  ITE = 'ite',
  DEV = 'dev',
}

export const getEnvName = (): ENV_NAME => process.env.CMFA_APP_OS_STAGE as ENV_NAME;

const specyfikEnv = (mode: 'only' | 'not') => (name: ENV_NAME) => (onFulfilled: () => void, onReject?: () => void) => {
  if (mode === 'only' ? getEnvName() === name : getEnvName() !== name) onFulfilled();
  else onReject?.();
};

export const SPECYFIK_ENV = {
  ONLY: specyfikEnv('only'),
  NOT: specyfikEnv('not'),
};
