import * as React from 'react';
import { connect } from 'react-redux';
import Button, { ButtonType } from 'root/components/Button';
import OrderSummary from './components/OrderSummary';
import UserInfoModal from 'root/components/UserInfoModal';
import { createProductJourneyActions } from 'features/createProductJourney';
import { CreateProductJourneyAction } from 'root/model/createProductJourney/CreateProductJourney';

import styles from './index.module.scss';

interface IOrderConfirmationProps {
  dispatchJourneyAction: typeof createProductJourneyActions.dispatchJourneyAction;
}

const OrderConfirmation: React.FunctionComponent<IOrderConfirmationProps> = ({
  dispatchJourneyAction,
}) => {
  const goBack = () => {
    dispatchJourneyAction(CreateProductJourneyAction.REDIRECT);
  };

  return (
    <div className={styles['order-confirmation']}>
      <div className={styles['order-confirmation__container']}>
        <Button
          text="Back"
          onClick={goBack}
          type={ButtonType.LINK}
          className={styles['order-confirmation__button']}
        />
        <OrderSummary />
      </div>
      <UserInfoModal />
    </div>
  );
};

export default connect(null, {
  dispatchJourneyAction: createProductJourneyActions.dispatchJourneyAction,
})(OrderConfirmation);
