import * as React from 'react';
import { connect } from 'react-redux';
import proj4 from 'proj4';
import { datadogLogs } from '@datadog/browser-logs';

import { MAP_PROJECTION_CODE, MAP_PROJECTION_DEFS } from 'root/constants';
import { mediaQueryActions } from 'features/mediaQuery';
import { ENV_NAME, SPECYFIK_ENV } from 'root/appConfig/utils';
import { cookieBasedInitializer, ExternalCookies, OptionalCookies } from 'root/appConfig/cookies';
import { mapStylesActions } from 'features/mapStyles';
import { routeActions } from 'features/route';

export interface IAppInitializerProps {
    updateMediaQuery: typeof mediaQueryActions.updateMediaQuery;
    getMapStyles: typeof mapStylesActions.getMapStyles;
    getRouteList: typeof routeActions.getRouteList;
}

export class AppInitializer extends React.Component<IAppInitializerProps> {
    public componentDidMount() {
        this.initializeProjection();
        this.updateMediaQuery();
        this.props.getMapStyles();
        this.props.getRouteList();
        this.addEventListeners();

        SPECYFIK_ENV.ONLY(ENV_NAME.ITE)(this.initializeDataDog, () =>
            cookieBasedInitializer(OptionalCookies.performance, this.initializeDataDog, [ExternalCookies.DataDog])
        );
    }

    public componentWillUnmount() {
        this.removeEventListeners();
    }

    public render() {
        return null;
    }

    private initializeProjection = () => {
        proj4.defs(MAP_PROJECTION_CODE, MAP_PROJECTION_DEFS);
    };

    private addEventListeners = () => {
        window.addEventListener('resize', this.updateMediaQuery);
    };

    private removeEventListeners = () => {
        window.removeEventListener('resize', this.updateMediaQuery);
    };

    private updateMediaQuery = () => {
        this.props.updateMediaQuery(window.innerWidth);
    };

    private initializeDataDog = () => {
        datadogLogs.init({
            clientToken: 'pub1ea8f17f77527d0506d04b19aef93340',
            sampleRate: 100,
        });
    };
}
export default connect(null, {
    updateMediaQuery: mediaQueryActions.updateMediaQuery,
    getMapStyles: mapStylesActions.getMapStyles,
    getRouteList: routeActions.getRouteList,
})(AppInitializer);
