import { DRAWER_LAYER, LAYER } from './types';

export const TRACK_SEGMENT = 'TRACK_SEGMENT';

export const LINE_COLOR = 'line-color';

export const LINE_WIDTH = 'line-width';

export const LINE_OPACITY = 'line-opacity';

export const PROPERTIES_KIND = 'properties.kind';

export const GEOMETRY_COORDINATES = 'geometry.coordinates';

export const FEATURE_COLLECTION = 'FeatureCollection';

export const FEATURE = 'Feature';

export const LINE_STRING = 'LineString';

export const LAYER_ORDER = [
    LAYER.singleRouteLine,
    LAYER.routeDirections,
    DRAWER_LAYER.vertexCold,
    DRAWER_LAYER.vertexHot,
    LAYER.keyWaypoints,
    LAYER.keyWaypointsIndex,
    LAYER.selectedWaypoints,
    LAYER.singleRouteEndIcons,
    LAYER.createRouteStartIcon,
];
