import booleanIntersects from '@turf/boolean-intersects';
import { BBox, bboxPolygon, booleanContains } from '@turf/turf';
import { getBounding, getProportion } from 'root/components/Map/utils/utils';
import { MapType, Scale, Size } from 'root/model/map/MapEnums';
import { convertProjection, PROJECTIONS } from 'root/services/projection';

import { LatLng, Point } from './types';

const toPoint = ({ lat, lng }: LatLng): Point => ({ x: lat, y: lng });
const toLatLng = ({ x, y }: Point): LatLng => ({ lat: x, lng: y });

const isPoint = (point: Point | LatLng): point is Point => !!((point as Point).x || (point as Point).y);

const getPoint = (point: Point | LatLng): Point => (isPoint(point) ? point : toPoint(point));
const getLatLng = (point: Point | LatLng): LatLng => (!isPoint(point) ? point : toLatLng(point));

const converter = ({ x, y }: Point) => ({
    x,
    y,
    toArray: () => [x, y] as [number, number],
    point: getPoint({ x, y }),
    latLng: getLatLng({ x, y }),
});

export const PointConverter = {
    forward: (point: Point | LatLng) =>
        converter(convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, getPoint(point))),
    backward: (point: Point | LatLng) =>
        converter(convertProjection(PROJECTIONS.EPSG_4326, PROJECTIONS.EPSG_27700, getPoint(point))),
};

export const calculateBoundingBox = (bounding: number, proportion: Point, center: Point) => {
    const radius = {
        x: (bounding * proportion.x) / 2,
        y: (bounding * proportion.y) / 2,
    };

    return {
        leftBottom: {
            x: center.x - radius.x,
            y: center.y - radius.y,
        },
        rightTop: {
            x: center.x + radius.x,
            y: center.y + radius.y,
        },
    };
};

export const calcRotationAngle = ({ lat, lng }: LatLng) => {
    const [x, y] = PointConverter.backward({ x: lng, y: lat }).toArray();

    const a0 = y * 0.00000132952129 + 3.11308606;
    const m = (a0 - 0.00151) / 300000;

    let angle = m * x + (a0 - m * 700000);
    angle *= -1;

    if (angle < 0) {
        angle += 360;
    }

    return angle;
};

type CheckRoutePositionToAnotherLocationType = {
    mapScale: Scale;
    mapSize: Size;
    mapType: MapType;
    currentCoordinates: Point;
    routeBbox: BBox;
};

export const checkRoutePositionToAnotherLocation = ({
    mapScale,
    mapSize,
    mapType,
    currentCoordinates,
    routeBbox,
}: CheckRoutePositionToAnotherLocationType) => {
    const coordinates = calculateBoundingBox(
        getBounding(mapScale),
        getProportion(mapSize, mapType),
        currentCoordinates
    );

    const left = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, coordinates.leftBottom);
    const right = convertProjection(PROJECTIONS.EPSG_27700, PROJECTIONS.EPSG_4326, coordinates.rightTop);
    const boundingBox = [left.x, left.y, right.x, right.y] as BBox;

    const poly1 = bboxPolygon(boundingBox);
    const poly2 = bboxPolygon(routeBbox);
    const isRouteEntirelyVisible = booleanIntersects(poly1, poly2);
    const isRoutePartiallyVisible = booleanContains(poly1, poly2);

    return {
        isRouteEntirelyVisible,
        isRoutePartiallyVisible,
    };
};
