import React, { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAreaAndStyleTab } from 'root/features/map/actions';
import { getAreaAndStyleSelectedTab } from 'root/features/map/selectors';
import { useWindowSize } from 'root/hooks';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { Breakpoint } from 'root/modules/styles';

export const useAreaAndStyleTab = () => {
    const selectedTab = useSelector(getAreaAndStyleSelectedTab);
    const dispatch = useDispatch();
    const scrollElement = useRef<HTMLDivElement | null>(null);
    const width = useWindowSize();

    const executeScroll = useCallback(() => {
        if (width && width > Breakpoint.large) {
            return;
        }

        setTimeout(() => {
            if (scrollElement && scrollElement.current) {
                scrollElement.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'end',
                });
            }
        }, 0);
    }, [width, scrollElement]);

    const handleTileAction = useCallback(
        (selectedTab: AreaAndStyleSelectedTab) => {
            dispatch(setAreaAndStyleTab(selectedTab));
            executeScroll();
        },
        [selectedTab]
    );

    return { selectedTab, handleTileAction, scrollElement, executeScroll };
};
