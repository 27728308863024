import * as React from 'react';
import { connect } from 'react-redux';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

import Types from 'Types';
import Modal from 'root/components/Modal';
import { imageCropActions, imageCropSelectors } from 'root/features/imageCrop';
import { coverActions, coverSelectors } from 'root/features/cover';
import ImageControls from './components/ImageControls';
import Button, { ButtonType } from 'root/components/Button';

import styles from './index.module.scss';
import classNames from 'classnames';
import { mapSelectors } from 'root/features/map';
import { Size } from 'root/model/map/MapEnums';
import { FOLDED_MAP_SIZE_TO_CROP_RATIO } from './constants';
import { IPredefinedCoverImage } from 'root/model/product/ProductOption';
import { productOptionsSelectors } from 'features/productOptions';
import { productPreviewSelectors } from 'features/productPreview';
import { customisationActions } from 'features/customisation';
import { AlertMessage } from 'root/components/AlertMessage';

export interface IFoldedCoverImageCropProps {
    predefinedCoverImages: {
        [key in Size]: IPredefinedCoverImage[];
    };
    predefinedImage: number;
    setImageSize: typeof coverActions.setImageSize;
    isCroppingImage: boolean;
    isSavingImage: boolean;
    isSavingImageError: boolean;
    newCoverImage: string;
    crop: Crop;
    pixelCrop: PixelCrop;
    closeImageCropModal: typeof imageCropActions.resetImageCrop;
    handleCropSelection: typeof imageCropActions.handleCropSelection;
    handleSizeChange: typeof imageCropActions.handleSizeChange;
    setCoverUrl: typeof coverActions.setCoverUrl;
    mapSize: Size;
    setCropRatio: typeof imageCropActions.setCropRatio;
    getFullPredefinedImage: typeof coverActions.getFullPredefinedImage;
    productPreviewImageUrl: string;
    setLinkToImage: typeof customisationActions.setLinkToImage;
}

class FoldedCoverImageCrop extends React.Component<IFoldedCoverImageCropProps> {
    public componentDidMount() {
        this.props.setCropRatio(FOLDED_MAP_SIZE_TO_CROP_RATIO[this.props.mapSize as Size.LARGE | Size.SMALL]);
    }

    public render() {
        const { isCroppingImage, newCoverImage, crop, isSavingImage, isSavingImageError } = this.props;

        const buttons = () => (
            <React.Fragment>
                <Button
                    text="Cancel"
                    onClick={this.closeModal}
                    type={ButtonType.SECONDARY}
                    className={classNames(styles['crop-modal__button'], styles['crop-modal__button--cancel'])}
                />
                <Button
                    text="Place image"
                    onClick={this.onConfirm}
                    type={ButtonType.PRIMARY}
                    isLoading={isSavingImage}
                    className={styles['crop-modal__button']}
                />
            </React.Fragment>
        );

        return (
            <Modal
                buttons={buttons}
                showModal={isCroppingImage}
                closeModal={this.closeModal}
                performingConfirm={isSavingImage}
                onOpenOptions={{ disableDocumentScroll: true, scrollDocumentToTop: true }}
            >
                <div className={styles['crop-modal']}>
                    <p className={styles['crop-modal__title']}>Crop and place your cover image</p>
                    <p className={styles['crop-modal__description']}>
                        The dashed line separates part of the image which may get trimmed away during printing.
                    </p>
                    <div className={styles['crop-modal__crop-zone']}>
                        <ReactCrop
                            src={newCoverImage}
                            onChange={this.onChange}
                            onComplete={this.onComplete}
                            crop={crop}
                            locked={false}
                            keepSelection={true}
                            disabled={isSavingImage}
                        />
                    </div>
                </div>
                {isSavingImageError && (
                    <AlertMessage message="There was a problem saving the image. Please try again." />
                )}
                <ImageControls />
            </Modal>
        );
    }

    private onConfirm = () => {
        const { pixelCrop, handleCropSelection, newCoverImage } = this.props;
        if (newCoverImage !== '') {
            handleCropSelection(newCoverImage, pixelCrop);
        } else {
            this.props.closeImageCropModal();
            console.error('Image is invalid');
        }
    };

    private closeModal = () => {
        const { closeImageCropModal } = this.props;
        closeImageCropModal();
    };

    private onComplete = (crop: Crop, pixelCrop: PixelCrop) => {
        this.onChange(crop, pixelCrop);
    };

    private onChange = (crop: Crop, pixelCrop: PixelCrop) => {
        this.props.handleSizeChange(crop, pixelCrop);
    };
}

export default connect(
    (state: Types.RootState) => ({
        predefinedCoverImages: productOptionsSelectors.getPredefinedCoverImages(state),
        predefinedImage: coverSelectors.getPredefinedImageIndex(state),
        isCroppingImage: imageCropSelectors.getIsCroppingImage(state),
        isSavingImage: imageCropSelectors.getIsSavingImage(state),
        isSavingImageError: imageCropSelectors.getIsSavingImageError(state),
        crop: imageCropSelectors.getCrop(state),
        pixelCrop: imageCropSelectors.getPixelCrop(state),
        newCoverImage: coverSelectors.getNewCoverUrl(state),
        mapSize: mapSelectors.getMapSize(state),
        productPreviewImageUrl: productPreviewSelectors.getProductPreviewImageUrl(state),
    }),
    {
        closeImageCropModal: imageCropActions.resetImageCrop,
        handleCropSelection: imageCropActions.handleCropSelection,
        handleSizeChange: imageCropActions.handleSizeChange,
        setCoverUrl: coverActions.setCoverUrl,
        setCropRatio: imageCropActions.setCropRatio,
        getFullPredefinedImage: coverActions.getFullPredefinedImage,
        setImageSize: coverActions.setImageSize,
        setLinkToImage: customisationActions.setLinkToImage,
    }
)(FoldedCoverImageCrop);
