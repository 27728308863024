import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';
import {
    Scale,
    SelectedMapStyle,
    MapLanguage,
    MapType,
    Framing,
    ProductId,
    Size,
    Material,
    MapStyle,
} from 'root/model/map/MapEnums';
import {
    IMapLocationParams,
    IMapStyleParams,
    IMapOptionsParams,
    IProductParams,
    IAreaAndStyleTab,
    AreaAndStyleSelectedTab,
} from 'root/model/map/MapParams';

import * as actions from './actions';
import {
    SET_MAP_LOCATION_PARAMS,
    RESET_MAP_LOCATION_PARAMS,
    SET_MAP_STYLE_PARAMS,
    SET_MAP_OPTIONS_PARAMS,
    SET_PRODUCT_PARAMS,
    SET_AREA_AND_STYLE_TAB,
    SET_CENTRE_LOCATION_COORDINATES,
    SET_ROUTE_COORDINATES,
    TOGGLE_MINI_MAP,
} from './constants';
import { RESET_SELECTED_ROUTE } from '../route/constants';
import { RouteAction } from '../route/reducer';
import { defaultScaleParams } from 'root/components/Map/utils/scale-logic-map';

export interface IMapState {
    readonly mapLocationParams: IMapLocationParams;
    readonly mapStyleParams: IMapStyleParams;
    readonly mapOptionsParams: IMapOptionsParams;
    readonly productParams: IProductParams;
    readonly areaAndStyleTab: IAreaAndStyleTab;
}

export type MapAction = ActionType<typeof actions>;

export const initialMapLocationParams: IMapLocationParams = {
    query: '',
    coordinates: { x: 532473, y: 181219 },
    detailedViewCoordinates: { x: 532473, y: 181219 },
};

export const mapLocationParamsReducer = (state: IMapLocationParams = initialMapLocationParams, action: MapAction) => {
    switch (action.type) {
        case SET_MAP_LOCATION_PARAMS:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_MAP_LOCATION_PARAMS: {
            return { ...state, query: '' };
        }
        default:
            return state;
    }
};

export const mapStyleParamsReducer = (
    state = {
        mapScale: defaultScaleParams.mapScale,
        mapStyle: defaultScaleParams.mapStyle,
        selectedMapStyle: SelectedMapStyle.AUTO,
        mapLanguage: MapLanguage.ENGLISH,
        mapType: MapType.NONE,
        frameType: Framing.NONE,
    },
    action: MapAction
) => {
    switch (action.type) {
        case SET_MAP_STYLE_PARAMS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const initialMapOptionsParams: IMapOptionsParams = {
    productId: ProductId.NONE,
    framing: Framing.NONE,
    memorizedFraming: Framing.NONE,
    framings: [Framing.NONE],
    size: Size.NONE,
    material: Material.NONE,
};

export const mapOptionsParamsReducer = (state = initialMapOptionsParams, action: MapAction) => {
    switch (action.type) {
        case SET_MAP_OPTIONS_PARAMS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const mapProductReducer = (state = { sku: '', price: 0 }, action: MapAction) => {
    switch (action.type) {
        case SET_PRODUCT_PARAMS:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export const initialAreaAndStyleTab: IAreaAndStyleTab = {
    isMiniMapOpen: true,
    selectedTab: AreaAndStyleSelectedTab.None,
    centreLocationCoordinates: { x: 532473, y: 181219 },
    routeCoordinates: { x: 532473, y: 181219 },
};

export const areaAndStyleTabReducer = (state = initialAreaAndStyleTab, action: MapAction | RouteAction) => {
    switch (action.type) {
        case TOGGLE_MINI_MAP:
            return { ...state, isMiniMapOpen: action.payload !== undefined ? action.payload : !state.isMiniMapOpen };
        case SET_AREA_AND_STYLE_TAB:
            return { ...state, selectedTab: action.payload };
        case RESET_SELECTED_ROUTE:
            return { ...state, selectedTab: AreaAndStyleSelectedTab.None };
        case SET_CENTRE_LOCATION_COORDINATES:
            return { ...state, centreLocationCoordinates: action.payload };
        case SET_ROUTE_COORDINATES:
            return { ...state, routeCoordinates: action.payload };
        default:
            return state;
    }
};

export default combineReducers<IMapState, MapAction>({
    mapLocationParams: mapLocationParamsReducer,
    mapStyleParams: mapStyleParamsReducer,
    mapOptionsParams: mapOptionsParamsReducer,
    productParams: mapProductReducer,
    areaAndStyleTab: areaAndStyleTabReducer,
});
