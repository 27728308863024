import smallFoldedMap from 'src/components/MapSizeGuidePopup/assets/paperFoldedSmall.jpg';
import largeFoldedMap from 'src/components/MapSizeGuidePopup/assets/paperFoldedLarge.jpg';
import largeFlatMap from 'src/components/MapSizeGuidePopup/assets/paperFlat.jpg';

import smallFramedMap from 'src/components/MapSizeGuidePopup/assets/canvasAndFramedA3WoodSmall.jpg';
import mediumFramedMap from 'src/components/MapSizeGuidePopup/assets/canvasAndFramedA2Wood.jpg';
import largeFramedMap from 'src/components/MapSizeGuidePopup/assets/canvasAndFramedLargeFramedWood.jpg';
import largeCanvasFramed from 'src/components/MapSizeGuidePopup/assets/canvasAndFramedLargeCanvasFramedOak.jpg';
import largeCanvas from 'src/components/MapSizeGuidePopup/assets/canvasAndFramedLargeCanvas.jpg';

import backgroundDimensions from 'src/components/MapSizeGuidePopup/assets/dimensionsBackgroundPicture.gif';
import backgroundDimensionsShort from 'src/components/MapSizeGuidePopup/assets/dimensionsBackgroundPictureShort.gif';
import backgroundDimensionsSmallFoldedMap from 'src/components/MapSizeGuidePopup/assets/dimensionsForSmallFoldedMap.gif';
import backgroundDimensionsSmallFramedMap from 'src/components/MapSizeGuidePopup/assets/dimensionsForSmallFramedMap.gif';

export interface IMapSizeGuidePopupContent {
  name: string;
  imageDescription: string;
  foldedDimensions?: string;
  backgroundImageSrc: string;
  foregroundImageSrc: string;
  isRectangle: boolean;
  heightSize: string;
  widthSize: string;
}

export interface IMapSizeGuidePopupContents {
  IMapSizeGuidePopupContent: IMapSizeGuidePopupContent[];
}

export const MAP_SIZE_GUIDE_POPUP_CONTENT = {
  PAPER_MAP: [
    {
      name: 'Small Folded Map (A3)',
      imageDescription: 'Map Size: 42 cm x 29 cm (A3)',
      isRectangle: true,
      foldedDimensions: 'Folded Size: 10.5 cm x 14.5 cm x 2 mm',
      backgroundImageSrc: backgroundDimensionsSmallFoldedMap,
      foregroundImageSrc: smallFoldedMap,
      heightSize: '29 cm',
      widthSize: '42 cm',
    },
    {
      name: 'Large Folded Map',
      imageDescription: 'Map Size: 80 cm x 80 cm',
      foldedDimensions: 'Folded Size: 13.5 cm x 22.5 cm x 10 mm',
      isRectangle: false,
      backgroundImageSrc: backgroundDimensions,
      foregroundImageSrc: largeFoldedMap,
      heightSize: '89 cm',
      widthSize: '100 cm',
    },
    {
      name: 'Large Flat Map',
      imageDescription: 'Map Size: 80 cm x 80 cm',
      isRectangle: false,
      backgroundImageSrc: backgroundDimensions,
      foregroundImageSrc: largeFlatMap,
      heightSize: '89 cm',
      widthSize: '100 cm',
    },
  ],

  CANVAS_MAP: [
    {
      name: 'Small Framed Map (A3)',
      imageDescription: 'Map Size: 39 cm x 24 cm (A3)',
      isRectangle: true,
      backgroundImageSrc: backgroundDimensionsSmallFramedMap,
      foregroundImageSrc: smallFramedMap,
      heightSize: '45.5 cm',
      widthSize: '58 cm',
    },
    {
      name: 'Medium Framed Map (A2)',
      imageDescription: 'Map Size: 52 cm x 32 cm (A2)',
      isRectangle: true,
      backgroundImageSrc: backgroundDimensionsShort,
      foregroundImageSrc: mediumFramedMap,
      heightSize: '63 cm',
      widthSize: '80 cm',
    },
    {
      name: 'Large Framed Map',
      imageDescription: 'Map Size: 80 cm x 80 cm',
      isRectangle: false,
      backgroundImageSrc: backgroundDimensions,
      foregroundImageSrc: largeFramedMap,
      heightSize: '98 cm',
      widthSize: '109 cm',
    },
    {
      name: 'Large Canvas',
      imageDescription: 'Map Size: 80 cm x 80 cm',
      isRectangle: false,
      backgroundImageSrc: backgroundDimensions,
      foregroundImageSrc: largeCanvas,
      heightSize: '89 cm',
      widthSize: '101 cm',
    },
    {
      name: 'Large Canvas Framed',
      imageDescription: 'Map Size: 80 cm x 80 cm',
      isRectangle: false,
      backgroundImageSrc: backgroundDimensions,
      foregroundImageSrc: largeCanvasFramed,
      heightSize: '95 cm',
      widthSize: '105 cm',
    },
  ],
};
