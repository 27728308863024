import * as React from 'react';
import { FieldProps } from 'formik';
import StableUniqueId from 'react-stable-uniqueid';
import * as classNames from 'classnames';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import styles from './index.module.scss';

interface IFormFieldProps {
  control: React.ComponentType<FieldProps & IIdProp & IClassNameProp>;
  label?: string;
  info?: string;
  dispatch?: Dispatch<any>;
}

class FormField extends React.Component<IFormFieldProps & FieldProps> {
  private baseClassName = 'form-field';

  constructor(props: IFormFieldProps & FieldProps) {
    super(props);
  }

  public render() {
    const { field } = this.props;

    return (
      <StableUniqueId prefix={field.name} render={this.renderInnerContent} />
    );
  }

  private renderLabel = (uniqueId: string) => {
    const { field, form, label } = this.props;

    const labelClasses = classNames({
      [styles[`${this.baseClassName}__label`]]: true,
      [styles[`${this.baseClassName}__label--error`]]:
        form.touched && form.errors[field.name],
    });

    return (
      <label className={labelClasses} htmlFor={uniqueId}>
        <span className={styles[`${this.baseClassName}__label`]}>{label}</span>
      </label>
    );
  };

  private renderMessages = () => {
    const { field, form, info } = this.props;

    const infoMessageClasses = classNames(
      styles[`${this.baseClassName}__message`],
      styles[`${this.baseClassName}__message--info`]
    );

    const errorMessageClasses = classNames(
      styles[`${this.baseClassName}__message`],
      styles[`${this.baseClassName}__message--error`]
    );

    const isInvalid = form.touched[field.name] && form.errors[field.name];

    return (
      <React.Fragment>
        {info && !isInvalid && <div className={infoMessageClasses}>{info}</div>}
        {isInvalid && (
          <div className={errorMessageClasses}>{form.errors[field.name]}</div>
        )}
      </React.Fragment>
    );
  };

  private renderInnerContent = ({ uniqueId }: { uniqueId: string }) => {
    const {
      field,
      form,
      control: Control,
      label,
      dispatch,
      ...props
    } = this.props;

    const className = classNames(styles[this.baseClassName]);

    return (
      <div className={className}>
        <div className={styles[`${this.baseClassName}__label-container`]}>
          {label && this.renderLabel(uniqueId)}
          {this.renderMessages()}
        </div>
        <div className={styles[`${this.baseClassName}__controls`]}>
          <Control id={uniqueId} field={field} form={form} {...{ ...props }} />
        </div>
      </div>
    );
  };
}

export default connect(() => ({}))(FormField);
