import React, {
    CSSProperties,
    FC,
    HTMLAttributes,
    ReactNode,
    RefAttributes,
    RefObject,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import { isPointInRect, toArray } from 'root/modules/utils/functions';
import { PopupOn, PopupPosition } from './constants';
import * as P from './parts';

type ContentProps = {
    content?: ReactNode;
};

type UseTooltipProps<E extends HTMLElement> = ContentProps & {
    defaultOpen?: boolean;
    onEvent?: PopupOn | PopupOn[];
    position?: PopupPosition | PopupPosition[];
    closeOnDocumentClick?: boolean;
    triggerRef?: RefObject<E>;
    triggerBoundingRect?: DOMRect;
    rootStyles?: CSSProperties;
};

export const useTooltip = <E extends HTMLElement>({
    onEvent,
    defaultOpen = false,
    closeOnDocumentClick = false,
    content,
    triggerBoundingRect,
    rootStyles,
}: UseTooltipProps<E>) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [triggerRect, setTriggerRect] = useState<DOMRect>();
    const [containerRect, setContainerRect] = useState<DOMRect>();
    const triggerRef = useRef<E>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const toggleOpen = useCallback((isOpen?: boolean) => () => setIsOpen((prev) => isOpen ?? !prev), []);

    const triggerProps = useMemo(() => {
        const props: HTMLAttributes<E> & RefAttributes<E> = { ref: triggerRef };

        toArray(onEvent).forEach((event) => {
            switch (event) {
                case PopupOn.Click:
                    props.onClick = toggleOpen();
                    break;
                case PopupOn.Hover:
                    props.onMouseEnter = toggleOpen(true);
                    props.onMouseLeave = toggleOpen(false);
            }
        });

        return props;
    }, [onEvent, toggleOpen]);

    useLayoutEffect(() => {
        setTriggerRect(triggerBoundingRect ?? triggerRef.current?.getBoundingClientRect());
        setContainerRect(contentRef.current?.getBoundingClientRect());
    }, [isOpen, !!triggerBoundingRect]);

    useEffect(() => {
        const listener = (event: MouseEvent) => {
            if (!isOpen || !triggerRect) return;

            const { x, y } = event;
            if (!isPointInRect({ x, y }, triggerRect)) toggleOpen(false)();
        };

        if (closeOnDocumentClick) document.addEventListener('click', listener);

        return () => {
            document.removeEventListener('click', listener);
        };
    }, [closeOnDocumentClick, triggerRect, isOpen]);

    const Content = useCallback<FC>(
        ({ children }) => (
            <P.Root style={{ width: triggerRect?.width, height: triggerRect?.height, ...rootStyles }}>
                {isOpen && (
                    <P.Wrapper>
                        <P.Container ref={contentRef} containerRect={containerRect}>
                            <P.Content>{content ?? children}</P.Content>
                            <P.Arrow />
                        </P.Container>
                    </P.Wrapper>
                )}
            </P.Root>
        ),
        [isOpen, triggerRect, content, contentRef, rootStyles]
    );

    const Tooltip = useCallback<FC<Partial<ContentProps>>>(
        ({ children, content }) => (
            <>
                <Content>{content}</Content>
                {children}
            </>
        ),
        [Content]
    );

    return { isOpen, setIsOpen, triggerProps, TooltipContent: Content, Tooltip };
};
