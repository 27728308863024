import * as React from 'react';
import { useSelector } from 'react-redux';

import styles from './index.module.scss';
import Tooltip from 'src/components/Tooltip';
import { MAP_STYLE_TEXT } from 'root/components/Price/constants';
import { CURRENCY, CurrencyType } from 'root/model/map/Currency';
import {
    getAreaAndStyleSelectedTab,
    getMapCurrency,
    getMapShortDescription,
    getMapStyle,
    getPrice,
} from 'root/features/map/selectors';
import { getIsGpxUploaded } from 'root/features/route/selectors';
import { AreaAndStyleSelectedTab } from 'root/model/map/MapParams';
import { MapStyle } from 'root/model/map/MapEnums';

export const Price = () => {
    const mapStyle = useSelector(getMapStyle) as MapStyle;
    const price = useSelector(getPrice);
    const shortDescription = useSelector(getMapShortDescription) as string;
    const currency = useSelector(getMapCurrency) as CurrencyType;
    const isGpxUploaded = useSelector(getIsGpxUploaded);
    const areaAndStyleSelectedTab = useSelector(getAreaAndStyleSelectedTab);

    const shouldDisplayPriceWithRouteGpx = isGpxUploaded && areaAndStyleSelectedTab === AreaAndStyleSelectedTab.Route;

    const parsedPrice: string[] = price ? price.toString().split('.') : [];
    const selectedMapStyle = MAP_STYLE_TEXT[mapStyle];
    const selectedCurrency = currency ? CURRENCY[currency] : CURRENCY.GBP;
    const finalPrice = selectedCurrency.concat(' ', parsedPrice[0], '.', parsedPrice[1] ? parsedPrice[1] : '00');
    const finalPriceWithRouteGpx = finalPrice.concat(' + ', selectedCurrency, '2 (Custom Route)');

    return (
        <Tooltip text={selectedMapStyle.concat(', ', shortDescription)}>
            <div className={styles['product-summary']}>
                <div className={styles['product-summary--price']}>
                    {shouldDisplayPriceWithRouteGpx ? finalPriceWithRouteGpx : finalPrice}
                </div>
                <div className={styles['product-summary--scale']}>
                    {selectedMapStyle.concat(', ', shortDescription)}
                </div>
            </div>
        </Tooltip>
    );
};
