import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Edit } from '@styled-icons/material/Edit';

import { createProductJourneyActions, createProductJourneySelectors } from 'root/features/createProductJourney';
import { CreateProductJourneyAction } from 'root/model/createProductJourney/CreateProductJourney';
import { ButtonSize, ButtonStyle } from 'root/modules/components';
import { useTranslation } from 'root/modules/utils/locales';
import * as P from './parts';

const { dispatchJourneyAction, initSendOrder, sendOrder } = createProductJourneyActions;
const { getIsSendOrderInProgress } = createProductJourneySelectors;

export const ActionButtons: FC = () => {
    const isSendOrderInProgress = useSelector(getIsSendOrderInProgress);

    const dispatch = useDispatch();
    const [t] = useTranslation();

    const handleEditClick = useCallback(() => {
        dispatch(dispatchJourneyAction(CreateProductJourneyAction.REDIRECT));
    }, []);

    const handleAddToBasket = useCallback(() => {
        dispatch(initSendOrder());
        dispatch(sendOrder());
    }, [sendOrder, initSendOrder]);

    return (
        <P.ActionButtons>
            <P.EditButton
                text={t(({ button }) => button.edit)}
                onClick={handleEditClick}
                buttonStyle={ButtonStyle.Ghost}
                buttonSize={ButtonSize.large}
                Icon={Edit}
            />

            <P.AddToBagButton
                text={t(({ button }) => button.addToBag)}
                onClick={handleAddToBasket}
                isLoading={isSendOrderInProgress}
                buttonStyle={ButtonStyle.Accept}
                buttonSize={ButtonSize.large}
                id="add-to-basket-button"
            />
        </P.ActionButtons>
    );
};
