import { isNil, map, sum } from 'lodash';
import { ProductId, Size, SizeType } from 'root/model/map/MapEnums';

import { TEXT_TYPE, MAX_FIELD_WIDTH, ROUNDING_FACTOR, LetterModel } from './consts';
import { CHAR_WIDTHS_MAP } from './consts/charMap';

export const required = (value: string) => (isNil(value) || value === '' ? 'Required' : '');

export const validateAllCharacters = (
    sentence: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: TEXT_TYPE,
    isForFormCheck?: boolean
) => {
    if (!sentence) {
        return '';
    }
    for (const character of sentence) {
        const result = validateCharacter(character, size, fieldName);
        if (result === false) {
            return `Character ${character} is not supported`;
        }
    }
    return '';
};

export const validateCharacter = (char: string, size: SizeType, fieldName: TEXT_TYPE) => {
    return !!CHAR_WIDTHS_MAP?.[size]?.['value']?.[char]?.[fieldName];
};

export const validateTitleLength = (
    title: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: TEXT_TYPE,
    isForFormCheck?: boolean
) => {
    if (!title) {
        return '';
    }
    return validateLength(title.toUpperCase(), maxFieldWidth, size, fieldName, isForFormCheck)
        ? ''
        : 'Please shorten the text';
};

export const validateSubtitleLength = (
    subtitle: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: TEXT_TYPE,
    isForFormCheck?: boolean
) => {
    if (!subtitle) {
        return '';
    }
    return validateLength(subtitle, maxFieldWidth, size, fieldName, isForFormCheck) ? '' : 'Please shorten the text';
};

export const validateSpineLength = (
    title: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: TEXT_TYPE,
    isForFormCheck?: boolean
) => {
    if (!title) {
        return '';
    }
    return validateLength(title.toUpperCase(), MAX_FIELD_WIDTH, size, fieldName, isForFormCheck)
        ? ''
        : 'Please shorten the text';
};

const validateLength = (
    value: string,
    maxFieldWidth: number,
    size: SizeType,
    fieldName: TEXT_TYPE,
    isForFormCheck?: boolean
) => {
    const length = map(value, (char) => {
        const charWidths = CHAR_WIDTHS_MAP[size].value[char];
        return charWidths ? charWidths[fieldName] : maxFieldWidth;
    });
    const charLength = CHAR_WIDTHS_MAP[size].value[value.charAt(value.length - 1)] as LetterModel;
    const result = sum(length);

    return isForFormCheck
        ? maxFieldWidth >= Math.round(result * ROUNDING_FACTOR) / ROUNDING_FACTOR
        : maxFieldWidth >= Math.round((result - (charLength?.[fieldName] || 0)) * ROUNDING_FACTOR) / ROUNDING_FACTOR;
};

export const validateAll = (
    value: string,
    maxFieldWidth: number,
    size: SizeType,
    validators: ((
        value: string,
        maxFieldWidth: number,
        size: SizeType,
        fieldName: TEXT_TYPE,
        isForFormCheck?: boolean
    ) => string)[],
    fieldName: TEXT_TYPE,
    isForFormCheck?: boolean
) => {
    let error = '';
    const isRequired = validators.indexOf(required) > -1;
    if (!isRequired && !value) {
        return '';
    }
    for (const validator of validators) {
        error = validator(value, maxFieldWidth, size, fieldName, isForFormCheck);
        if (error !== '') {
            return error;
        }
    }
    return '';
};

export const validateTitleLargeFlat = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FLAT,
        [validateAllCharacters, validateTitleLength],
        TEXT_TYPE.TITLE,
        isForFormCheck
    );
};

export const validateTitleLargeFolded = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FOLDED,
        [validateAllCharacters, validateTitleLength],
        TEXT_TYPE.TITLE,
        isForFormCheck
    );
};

export const validateTitleA3Folded = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A3_FOLDED,
        [validateAllCharacters, validateTitleLength],
        TEXT_TYPE.TITLE,
        isForFormCheck
    );
};

export const validateTitleA3Flat = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A3_FLAT,
        [validateAllCharacters, validateTitleLength],
        TEXT_TYPE.TITLE,
        isForFormCheck
    );
};

export const validateTitleA2Flat = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A2_FLAT,
        [validateAllCharacters, validateTitleLength],
        TEXT_TYPE.TITLE,
        isForFormCheck
    );
};

export const validateSubTitleLargeFlat = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FLAT,
        [validateAllCharacters, validateSubtitleLength],
        TEXT_TYPE.SUBTITLE,
        isForFormCheck
    );
};

export const validateSubTitleLargeFolded = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FOLDED,
        [validateAllCharacters, validateSubtitleLength],
        TEXT_TYPE.SUBTITLE,
        isForFormCheck
    );
};

export const validateSubTitleA3Folded = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A3_FOLDED,
        [validateAllCharacters, validateSubtitleLength],
        TEXT_TYPE.SUBTITLE,
        isForFormCheck
    );
};

export const validateSubTitleA3Flat = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A3_FLAT,
        [validateAllCharacters, validateSubtitleLength],
        TEXT_TYPE.SUBTITLE,
        isForFormCheck
    );
};

export const validateSubTitleA2Flat = (value: string, isForFormCheck: boolean) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A2_FLAT,
        [validateAllCharacters, validateSubtitleLength],
        TEXT_TYPE.SUBTITLE,
        isForFormCheck
    );
};

export const validateSpineLargeFlat = (value: string) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FLAT,
        [required, validateAllCharacters, validateSpineLength],
        TEXT_TYPE.SPINE
    );
};

export const validateSpineLargeFolded = (value: string) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.LARGE_FOLDED,
        [required, validateAllCharacters, validateSpineLength],
        TEXT_TYPE.SPINE
    );
};

export const validateSpineA3Folded = (value: string) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A3_FOLDED,
        [required, validateAllCharacters, validateSpineLength],
        TEXT_TYPE.SPINE
    );
};

export const validateSpineA3Flat = (value: string) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A3_FLAT,
        [required, validateAllCharacters, validateSpineLength],
        TEXT_TYPE.SPINE
    );
};

export const validateSpineA2Flat = (value: string) => {
    return validateAll(
        value,
        MAX_FIELD_WIDTH,
        SizeType.A2_FLAT,
        [required, validateAllCharacters, validateSpineLength],
        TEXT_TYPE.SPINE
    );
};

const selectValidatorBasedOnProductType = (sizeType: SizeType, textType: TEXT_TYPE) => {
    switch (sizeType) {
        case SizeType.A2_FLAT: {
            if (textType === TEXT_TYPE.SPINE) {
                return validateSpineA2Flat;
            } else if (textType === TEXT_TYPE.SUBTITLE) {
                return validateSubTitleA2Flat;
            } else {
                return validateTitleA2Flat;
            }
        }

        case SizeType.A3_FLAT: {
            if (textType === TEXT_TYPE.SPINE) {
                return validateSpineA3Flat;
            } else if (textType === TEXT_TYPE.SUBTITLE) {
                return validateSubTitleA3Flat;
            } else {
                return validateTitleA3Flat;
            }
        }

        case SizeType.A3_FOLDED: {
            if (textType === TEXT_TYPE.SPINE) {
                return validateSpineA3Folded;
            } else if (textType === TEXT_TYPE.SUBTITLE) {
                return validateSubTitleA3Folded;
            } else {
                return validateTitleA3Folded;
            }
        }

        case SizeType.LARGE_FLAT: {
            if (textType === TEXT_TYPE.SPINE) {
                return validateSpineLargeFlat;
            } else if (textType === TEXT_TYPE.SUBTITLE) {
                return validateSubTitleLargeFlat;
            } else {
                return validateTitleLargeFlat;
            }
        }

        case SizeType.LARGE_FOLDED: {
            if (textType === TEXT_TYPE.SPINE) {
                return validateSpineLargeFolded;
            } else if (textType === TEXT_TYPE.SUBTITLE) {
                return validateSubTitleLargeFolded;
            } else {
                return validateTitleLargeFolded;
            }
        }

        default:
            if (textType === TEXT_TYPE.SPINE) {
                return validateSpineA3Flat;
            } else if (textType === TEXT_TYPE.SUBTITLE) {
                return validateSubTitleA3Flat;
            } else {
                return validateTitleA3Flat;
            }
    }
};

export const getValidator = (size: Size, productId: ProductId, textType: TEXT_TYPE) => {
    switch (size) {
        case Size.LARGE: {
            if (productId === ProductId.FLAT_MAP) {
                return selectValidatorBasedOnProductType(SizeType.LARGE_FLAT, textType);
            } else if (productId === ProductId.FOLDED_MAP) {
                return selectValidatorBasedOnProductType(SizeType.LARGE_FOLDED, textType);
            }
        }

        case Size.MEDIUM: {
            if (productId === ProductId.FLAT_MAP) {
                return selectValidatorBasedOnProductType(SizeType.A2_FLAT, textType);
            }
        }

        case Size.SMALL: {
            if (productId === ProductId.FLAT_MAP) {
                return selectValidatorBasedOnProductType(SizeType.A3_FLAT, textType);
            } else if (productId === ProductId.FOLDED_MAP) {
                return selectValidatorBasedOnProductType(SizeType.A3_FOLDED, textType);
            }
        }

        default:
            return selectValidatorBasedOnProductType(SizeType.A3_FLAT, textType);
    }
};
