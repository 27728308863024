import React, { Dispatch, FC, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { fromEvent, timer } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { getIsMiniMapOpen, getMapLocationCoordinates, getMapSize, getMapType } from 'root/features/map/selectors';

import { MilisTime } from 'root/modules/utils/constants';
import { useTranslation } from 'root/modules/utils/locales';
import { useTooltip, useMap, Point } from 'root/modules/components';
import * as P from './parts';
import { getMapStylesApi } from 'root/features/mapStyles/selectors';
import { Scale } from 'root/model/map/MapEnums';

const PROPORTION: Point = { x: 1, y: 1 };
const ZOOM = 6;
const BOUNDING = 0;

type OnClick<E extends HTMLElement> = (event: React.MouseEvent<E, MouseEvent>) => void;

type UKmapProps = {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const UKmap: FC<UKmapProps> = ({ isOpen, setIsOpen }) => {
    const center = useSelector(getMapLocationCoordinates);
    const mapSize = useSelector(getMapSize);
    const mapType = useSelector(getMapType);
    const mapStyleApi = useSelector(getMapStylesApi(Scale.OS_UK));
    const isMiniMapOpen = useSelector(getIsMiniMapOpen);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [t] = useTranslation();

    useLayoutEffect(() => {
        const subscription = fromEvent<UIEvent>(window, 'resize')
            .pipe(debounceTime(MilisTime.xShort))
            .subscribe(() => setSize({ width: window.innerWidth, height: window.innerHeight }));

        return () => subscription.unsubscribe();
    }, []);

    const { ref, resize } = useMap({
        view: { center, zoom: ZOOM, mapApi: mapStyleApi },
        proportion: PROPORTION,
        bounding: BOUNDING,
        interactive: false,
    });

    const { Tooltip, triggerProps, setIsOpen: setIsTooltipOpen } = useTooltip<HTMLDivElement>({});

    const tooltipContent = useMemo(
        () => (
            <>
                <b>{t(({ areaAndStyle: { ukMap } }) => ukMap.tooltip.part1)}</b>
                {t(({ areaAndStyle: { ukMap } }) => ukMap.tooltip.part2)}
            </>
        ),
        [t]
    );

    useEffect(() => {
        resize();
        const subscription = timer(MilisTime.short + MilisTime.xxShort).subscribe(() => {
            setIsTooltipOpen(isOpen);
        });

        return () => subscription.unsubscribe();
    }, [isOpen, size]);

    const handleOverlayClick = useCallback<OnClick<HTMLDivElement>>(() => setIsOpen(false), []);

    const handleCloseClick = useCallback<OnClick<HTMLButtonElement>>((event) => {
        event.stopPropagation();
        setIsOpen(false);
    }, []);

    const handleMapClick = useCallback<OnClick<HTMLDivElement>>((event) => {
        event.stopPropagation();
        setIsOpen(true);
    }, []);

    useEffect(() => {
        resize();
    }, [isMiniMapOpen]);

    return (
        <P.Overlay isOpen={isOpen} showMiniMap={isMiniMapOpen} onClick={handleOverlayClick}>
            <P.ModalLike>
                {isOpen && <P.CloseButton onClick={handleCloseClick} />}
                <P.MapWrapper isOpen={isOpen} {...size} onClick={handleMapClick}>
                    {center && mapSize && mapType && (
                        <>
                            <Tooltip content={tooltipContent}>
                                <P.Crosshair labelProps={triggerProps} isOpen={isOpen} />
                            </Tooltip>
                            <P.Map ref={ref} isOpen={isOpen} />
                        </>
                    )}
                </P.MapWrapper>
                {!isOpen && <P.OpenIndicator {...size} />}
            </P.ModalLike>
        </P.Overlay>
    );
};
