import styled, { css } from 'styled-components';

import LoadingIndicator from 'root/components/LoadingIndicator';

import { CssReturnType, fontSize, offset } from 'root/modules/styles';

export enum ButtonStyle {
    Primary = 'Primary',
    Secondary = 'Secondary',
    Third = 'Third',
    Accept = 'Accept',
    Ghost = 'Ghost',
    Error = 'Error',
}

export type ButtonStyleType = {
    buttonStyle: ButtonStyle;
};

export enum ButtonSize {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export type ButtonSizeType = {
    buttonSize: ButtonSize;
};

const buttonStyles: Record<ButtonStyle, CssReturnType> = {
    [ButtonStyle.Primary]: css`
        text-transform: uppercase;
        font-weight: 600;

        color: #ffffff;
        background-color: #453c90;

        &:hover:enabled,
        &:active {
            background-color: #292074;
        }
    `,
    [ButtonStyle.Secondary]: css`
        text-transform: uppercase;
        font-weight: 600;

        background-color: #f3f3f4;

        &:hover:enabled,
        &:active {
            background-color: #dddddd;
        }
    `,
    [ButtonStyle.Third]: css`
        font-weight: 600;

        background-color: #ffffff;
        color: #453c90;

        &:hover:enabled,
        &:active {
            color: #292074;
        }
    `,
    [ButtonStyle.Accept]: css`
        font-weight: 600;
        text-transform: uppercase;
        background-color: #018044;
        color: #ffffff;

        &:hover:enabled,
        &:active {
            background-color: #03522d;
            border-width: 0;
            box-shadow: none;
        }
    `,
    [ButtonStyle.Ghost]: css`
        text-transform: capitalize;

        padding: ${offset.small} ${offset.small};

        color: #453c90;
        background-color: #00000000;

        &:hover:enabled,
        &:active {
            color: #292074;
            background-color: #00000000;
        }
    `,
    [ButtonStyle.Error]: css``,
};

const disabled = css`
    &:disabled {
        cursor: not-allowed;
        background-color: #666666;
        color: #ffffff;
    }
`;

export const Button = styled.button<ButtonStyleType & ButtonSizeType>`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: ${offset.small} ${offset.large};
    border-width: 0;

    color: #000000;
    cursor: pointer;

    ${({ buttonSize }) => {
        switch (buttonSize) {
            case ButtonSize.small:
                return fontSize.medium;

            case ButtonSize.medium:
                return fontSize.large;

            case ButtonSize.large:
                return fontSize.xxLarge;
        }
    }}

    ${({ buttonStyle }) => buttonStyles[buttonStyle]}

  ${disabled}
`;

export const Loading = styled(LoadingIndicator)`
    position: relative;
    left: 0;
    top: 0;
    transform: translate(0, 0);
`;
