import React, { Dispatch, FC, SetStateAction, useCallback } from 'react';
import { ZoomIn } from '@styled-icons/material/ZoomIn';

import { useTranslation } from 'root/modules/utils/locales';
import { ButtonSize, ButtonStyle } from 'root/modules/components';
import { ProductPreview } from '../../ProductPreview';
import * as P from './parts';

type OrderSummaryLeftProps = {
  isPreviewOpen: boolean;
  setIsPreviewOpen: Dispatch<SetStateAction<boolean>>;
};

export const OrderSummaryLeft: FC<OrderSummaryLeftProps> = ({ isPreviewOpen, setIsPreviewOpen }) => {
  const [t] = useTranslation();

  const handleOpen = useCallback((open: boolean) => () => setIsPreviewOpen(open), []);

  return (
    <P.OrderSummaryLeft>
      <P.Header text={t(({ orderSummary }) => orderSummary.previewHeader)} />

      <P.ProductPreviewContainer>
        <P.ProductPreview wrapperProps={{ onClick: handleOpen(true) }} dispatchZoomRequest />

        <P.PreviewButton
          text={t(({ button }) => button.preview)}
          onClick={handleOpen(true)}
          buttonSize={ButtonSize.large}
          buttonStyle={ButtonStyle.Ghost}
          Icon={ZoomIn}
        />
      </P.ProductPreviewContainer>

      <P.ModalOverlayStyles>
        {(overlayClassName) => (
          <P.ModalContentStyles>
            {(contentClassName) => (
              <P.PreviewModal
                showModal={isPreviewOpen}
                closeModal={handleOpen(false)}
                overlayClassName={overlayClassName}
                contentClassName={contentClassName}
                onOpenOptions={{ disableDocumentScroll: true, scrollDocumentToTop: true }}
                onCloseOptions={{ scrollDocumentDown: true }}
              >
                <ProductPreview alternativeLayout />
              </P.PreviewModal>
            )}
          </P.ModalContentStyles>
        )}
      </P.ModalOverlayStyles>
    </P.OrderSummaryLeft>
  );
};
