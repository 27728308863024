import React, { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getMediaQuery } from 'root/features/mediaQuery/selectors';
import { useTranslation } from 'root/modules/utils/locales';
import { MediaQuery } from 'root/model/mediaQuery/MediaQueryEnums';
import { MobilePopper, Popper } from 'root/components/Popper';

import { ReactComponent as ScaleIcon } from '../../../../../../assets/scale.svg';
import { MapIconButton } from '../../MapIconButton';
import { SwitchStyleContent } from './components/SwitchStyleContent';
import { thinnerThan, widerThanOrEqual } from 'root/services/mediaQuery';

type SwitchStyleModalProps = {
    isSwitchMapStyleActive: boolean;
    setSwitchMapStyleActive: () => void;
};

export const SwitchStyleModal = ({ isSwitchMapStyleActive, setSwitchMapStyleActive }: SwitchStyleModalProps) => {
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [t] = useTranslation();
    const mediaQuery = useSelector(getMediaQuery);
    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

    const handleSwitchMapStyleChange = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setSwitchMapStyleActive();
    }, []);

    return (
        <>
            <MapIconButton
                ref={buttonRef}
                Icon={ScaleIcon}
                title="Switch map style"
                onClick={handleSwitchMapStyleChange}
                isActive={isSwitchMapStyleActive}
                isDisabled={false}
            />

            {widerThanOrEqual(mediaQuery, MediaQuery.MEDIUM) && (
                <Popper
                    anchorEl={buttonRef.current}
                    placement="left-start"
                    id={isSwitchMapStyleActive ? 'switch map style modal' : undefined}
                    open={isSwitchMapStyleActive}
                    onClose={handleSwitchMapStyleChange}
                    ariaLabel={t(({ ariaLabel }) => ariaLabel.switchStyleModal)}
                    title={t(({ modal }) => modal.switchStyleTitle)}
                    subtitle={t(({ modal }) => modal.switchStyleSubtitle)}
                >
                    <SwitchStyleContent />
                </Popper>
            )}

            {thinnerThan(mediaQuery, MediaQuery.MEDIUM) && (
                <MobilePopper
                    id={isSwitchMapStyleActive ? 'switch map style modal' : undefined}
                    open={isSwitchMapStyleActive}
                    onClose={handleSwitchMapStyleChange}
                    ariaLabel={t(({ ariaLabel }) => ariaLabel.switchStyleModal)}
                    title={t(({ modal }) => modal.switchStyleTitle)}
                    subtitle={t(({ modal }) => modal.switchStyleSubtitle)}
                >
                    <SwitchStyleContent />
                </MobilePopper>
            )}
        </>
    );
};
