import { useCallback } from 'react';

import { Framing, MapLanguage, MapStyle, Material, ProductId, Size } from 'root/model/map/MapEnums';

import {
  MAP_LANGUAGE,
  MAP_FRAME_COLOR,
  MAP_TYPES,
  CANVAS_MAP,
  FRAMED_MAP,
  MAP_FORMAT,
  MAP_SIZE,
  PAPER_MAP,
} from './mapTypes';

const mapLanguage = (language?: MapLanguage) => (language ? MAP_LANGUAGE[language] : undefined);

const mapFrameColor = (frame?: Framing) =>
  (frame === Framing.BLACK && MAP_FRAME_COLOR.BLACK) ||
  (frame === Framing.LIGHT_OAK && MAP_FRAME_COLOR.OAK) ||
  undefined;

const mapSize = (size?: Size) => (!size || size === Size.NONE ? undefined : MAP_SIZE[size]);

const mapStyle = (mapStyle?: MapStyle): MapStyle | undefined => {
  return (
    (mapStyle === MapStyle .OS_EXPLORER && MapStyle .OS_EXPLORER) ||
    (mapStyle === MapStyle .OS_LANDRANGER && MapStyle .OS_LANDRANGER) ||
    undefined
  )
}

const mapType = (productId?: ProductId, material?: Material, frame?: Framing) => {
  if (!productId || !material || !frame) return undefined;

  if (productId === ProductId.FOLDED_MAP) {
    return MAP_TYPES.PAPER;
  }
  if (material === Material.PAPER && frame === Framing.NONE) {
    return MAP_TYPES.PAPER;
  }
  if (material === Material.CANVAS) {
    return MAP_TYPES.CANVAS;
  }
  return MAP_TYPES.FRAMED;
};

const mapFramed = (type?: MAP_TYPES, size?: Size) => {
  if (!type || type !== MAP_TYPES.FRAMED || !size || size === Size.NONE) {
    return undefined;
  }

  return FRAMED_MAP[size];
};

const mapCanvas = (type?: MAP_TYPES, frame?: Framing) => {
  if (!type || type !== MAP_TYPES.CANVAS) {
    return undefined;
  }

  return !frame || frame === Framing.NONE ? CANVAS_MAP.UNFRAMED : CANVAS_MAP.FRAMED;
};

const mapPaper = (type?: MAP_TYPES, productId?: ProductId, size?: Size) => {
  if (!type || type !== MAP_TYPES.PAPER || !productId || !size || size === Size.MEDIUM || size === Size.NONE) {
    return undefined;
  }

  return productId === ProductId.FLAT_MAP ? PAPER_MAP.LARGE_FLAT : PAPER_MAP[size];
};

const mapFormat = (size?: Size) => {
  if (size === Size.LARGE) return MAP_FORMAT.SQUARE;
  if (size === Size.SMALL || size === Size.MEDIUM) return MAP_FORMAT.RECTANGLE;
  return undefined;
};

export const useMapHooks = () => {
  return {
    mapLanguage: useCallback(mapLanguage, []),
    mapFrameColor: useCallback(mapFrameColor, []),
    mapSize: useCallback(mapSize, []),
    mapStyle: useCallback(mapStyle, []),
    mapType: useCallback(mapType, []),
    mapFramed: useCallback(mapFramed, []),
    mapCanvas: useCallback(mapCanvas, []),
    mapPaper: useCallback(mapPaper, []),
    mapFormat: useCallback(mapFormat, []),
  };
};
