import { createBrowserHistory } from 'history';
import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { RoutePath } from 'root/appConfig/routes/enums';

import { createProductJourneyActions } from 'root/features/createProductJourney';
import { setCreateProductJourneyStep } from 'root/features/createProductJourney/actions';
import { CreateProductJourneyStep } from 'root/model/createProductJourney/CreateProductJourney';
import { useTranslation } from 'root/modules/utils/locales';
import * as P from './parts';

export const DefaultLocationWarning: FC = () => {
    const dispatch = useDispatch();
    const history = createBrowserHistory();
    const [t] = useTranslation();

    const goToAreaAndStyle = useCallback(() => {
        dispatch(createProductJourneyActions.redirectPage(RoutePath.ROOT));
        dispatch(setCreateProductJourneyStep(CreateProductJourneyStep.AREA_AND_STYLE));
        history.push('/areaAndStyle/');
    }, []);

    return (
        <P.WarningMessage>
            {t(({ orderSummary }) => orderSummary.warning.defaultLocation.part1)}
            <P.MessageLink onClick={goToAreaAndStyle}>
                {t(({ orderSummary }) => orderSummary.warning.defaultLocation.link)}
            </P.MessageLink>
            {t(({ orderSummary }) => orderSummary.warning.defaultLocation.part2)}
        </P.WarningMessage>
    );
};
