import { combineReducers } from 'redux';
import { RouteStyleType } from 'root/components/Map/utils/types';
import { LineColorType, LineThicknessType, RouteListVM, RouteVM } from 'root/model/route/Route';
import { ActionType } from 'typesafe-actions';

import * as actions from './actions';
import {
    SET_ROUTE_LIST,
    RESET_ROUTE_LIST,
    SET_SELECTED_ROUTE,
    RESET_SELECTED_ROUTE,
    CHANGE_ROUTE_SETTING,
    RESET_ROUTE_SETTING,
    CHANGE_UPLOADED_ROUTE_FILENAME,
    CHANGE_UPLOADED_ROUTE_ERROR,
    CHANGE_UPLOADED_ROUTE_FILE,
    RESET_UPLOADED_ROUTE,
} from './constants';

export type RouteAction = ActionType<typeof actions>;

type RouteSettingStateType = RouteStyleType;

export type UploadedRouteErrorType = {
    title: string;
    message: string;
};

type UploadedRouteType = {
    filename: string;
    file: RouteVM | null;
    error: UploadedRouteErrorType | null;
};

type RouteStateType = {
    readonly routeList: RouteListVM;
    readonly selectedRoute: RouteVM | null;
    readonly routeSetting: RouteSettingStateType;
    readonly uploadedRoute: UploadedRouteType;
};

export const initialRouteListState = [];

export const routeListReducer = (state: RouteListVM = initialRouteListState, action: RouteAction) => {
    switch (action.type) {
        case SET_ROUTE_LIST:
            return action.payload;
        case RESET_ROUTE_LIST: {
            return initialRouteListState;
        }
        default:
            return state;
    }
};

export const selectedRouteReducer = (state: RouteVM | null = null, action: RouteAction) => {
    switch (action.type) {
        case SET_SELECTED_ROUTE:
            return action.payload;
        case RESET_SELECTED_ROUTE: {
            return null;
        }
        default:
            return state;
    }
};

export const initialRouteSettingState: RouteSettingStateType = {
    lineColourHex: LineColorType.Black,
    lineOpacity: 1,
    lineThickness: LineThicknessType.Regular,
};

export const routeSettingReducer = (state: RouteSettingStateType = initialRouteSettingState, action: RouteAction) => {
    switch (action.type) {
        case CHANGE_ROUTE_SETTING: {
            return { ...state, ...action.payload };
        }
        case RESET_ROUTE_SETTING: {
            return initialRouteSettingState;
        }
        default:
            return state;
    }
};

export const initialUploadedRouteState: UploadedRouteType = {
    filename: '',
    file: null,
    error: null,
};

export const uploadedRouteReducer = (state: UploadedRouteType = initialUploadedRouteState, action: RouteAction) => {
    switch (action.type) {
        case CHANGE_UPLOADED_ROUTE_FILE: {
            return { ...state, file: action.payload };
        }
        case CHANGE_UPLOADED_ROUTE_FILENAME: {
            return { ...state, filename: action.payload };
        }
        case CHANGE_UPLOADED_ROUTE_ERROR: {
            return { ...state, error: action.payload };
        }
        case RESET_UPLOADED_ROUTE: {
            return initialUploadedRouteState;
        }
        case RESET_SELECTED_ROUTE: {
            return initialUploadedRouteState;
        }
        default:
            return state;
    }
};

export default combineReducers<RouteStateType, RouteAction>({
    routeList: routeListReducer,
    selectedRoute: selectedRouteReducer,
    routeSetting: routeSettingReducer,
    uploadedRoute: uploadedRouteReducer,
});
