import styled, { css } from 'styled-components';

import { MapFormatType, SourceType, backgroundImage, WidthType } from 'root/modules/styles';
import { MAP_FORMAT } from 'root/modules/types';
import { MapTitles } from '../MapTitles';

const rectangleWoodFrame = css`
    padding: 14%;
`;

const squareWoodFrame = css`
    width: 100%;

    padding-top: 4.89375%;
    padding-right: 4.7125%;
    padding-bottom: 4.89375%;
    padding-left: 5.61875%;
`;

const rectangleDescriptionFrame = css`
    position: relative;

    padding-top: 4.5%;
    padding-left: 4.5%;
    padding-right: 4.5%;
    padding-bottom: 10%;
`;

const squareDescriptionPadding = css`
    padding-top: 4.204%;
    padding-right: 15.1%;
    padding-bottom: 4.204%;
    padding-left: 4.084%;
`;

const frameBackground = css<SourceType>`
    ${backgroundImage};
    background-repeat: round;
    height: fit-content;
`;

export const WoodMapFrame = styled.div<MapFormatType & SourceType>`
    ${frameBackground}
    ${({ mapFormat }) => {
        switch (mapFormat) {
            case MAP_FORMAT.RECTANGLE:
                return rectangleWoodFrame;
            case MAP_FORMAT.SQUARE:
                return squareWoodFrame;
            default:
                return '';
        }
    }}
`;

export const DescriptionMapFrame = styled.div<MapFormatType & SourceType>`
    ${frameBackground}
    position: relative;

    ${({ mapFormat }) => {
        switch (mapFormat) {
            case MAP_FORMAT.RECTANGLE:
                return rectangleDescriptionFrame;
            case MAP_FORMAT.SQUARE:
                return squareDescriptionPadding;
            default:
                return '';
        }
    }}
`;

export const TitleWrapper = styled(MapTitles)<WidthType>`
    left: 88.62%;
    top: 4.2%;

    ${({ width }) => css`
        & > h3 {
            font-size: ${width * 0.0078}px;
            line-height: ${width * 0.0091}px;
            height: ${width * 0.0091}px;
        }

        & > h5 {
            font-size: ${width * 0.004375}px;
            line-height: ${width * 0.00625}px;
            height: ${width * 0.00725}px;
        }
    `}
`;
