import * as React from 'react';
import * as classNames from 'classnames';

import styles from './index.module.scss';

export interface IToggleProps<T> {
  leftText: string;
  leftValue: T;
  rightText: string;
  rightValue: T;
  selectedLeft: boolean;
  toggle: (value: T) => void;
  leftDisabled?: boolean;
  rightDisabled?: boolean;
}

export class Toggle<T> extends React.Component<IToggleProps<T>> {
  public render = () => {
    const { leftText, rightText, selectedLeft, leftDisabled = false, rightDisabled = false } = this.props;
    const leftClassName = classNames(
      styles['toggle__button'],
      styles['toggle__button--left'],
      {
        [styles['toggle__button--active']]: selectedLeft,
      },
      {
        [styles['toggle__button--disabled']]: leftDisabled,
      }
    );
    const rightClassName = classNames(
      styles['toggle__button'],
      styles['toggle__button--right'],
      {
        [styles['toggle__button--active']]: !selectedLeft,
      },
      {
        [styles['toggle__button--disabled']]: rightDisabled,
      }
    );

    return (
      <div className={styles['toggle']}>
        <button type="button" className={leftClassName} onClick={this.onLeftClick}>
          {leftText}
        </button>
        <button type="button" className={rightClassName} onClick={this.onRightClick}>
          {rightText}
        </button>
      </div>
    );
  };

  private onLeftClick = () => {
    const { selectedLeft, toggle, leftValue, leftDisabled } = this.props;
    if (!selectedLeft && !leftDisabled) {
      toggle(leftValue);
    }
  };

  private onRightClick = () => {
    const { selectedLeft, toggle, rightValue, rightDisabled } = this.props;
    if (selectedLeft && !rightDisabled) {
      toggle(rightValue);
    }
  };
}
