import React, { FC, forwardRef, HTMLAttributes, LabelHTMLAttributes, ReactNode, useCallback, useMemo } from 'react';
import { StyledIcon, StyledIconProps } from '@styled-icons/styled-icon';

import { ButtonProps, ButtonStyle, ButtonSize } from '../Button';
import * as P from './parts';

export type IconButtonProps = ButtonProps &
    Partial<P.BorderStyleType> & {
        label?: ReactNode;
        labelProps?: (LabelHTMLAttributes<HTMLLabelElement> | HTMLAttributes<HTMLDivElement>) & {
            isClickable?: boolean;
        };
        Icon: StyledIcon | FC;
        iconProps?: StyledIconProps;
    };

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    (
        {
            Icon,
            iconProps,
            label,
            className,
            text,
            children,
            labelProps,
            buttonStyle = ButtonStyle.Primary,
            borderStyle = P.BorderStyle.Circle,
            buttonSize = ButtonSize.medium,
            disabled,
            ...props
        },
        ref
    ) => {
        const Label = useCallback<FC>(
            ({ children: labelChildren }) => {
                if (!label && (text || children)) return <>{labelChildren}</>;

                const { isClickable, ...props } = labelProps || { isClickable: false };

                return isClickable ? (
                    <P.Label
                        className={className}
                        buttonStyle={buttonStyle}
                        disabled={disabled}
                        {...(props as LabelHTMLAttributes<HTMLLabelElement>)}
                    >
                        {labelChildren}
                    </P.Label>
                ) : (
                    <P.Wrapper
                        className={className}
                        buttonStyle={buttonStyle}
                        disabled={disabled}
                        hasLabel={!!label}
                        {...(props as HTMLAttributes<HTMLDivElement>)}
                    >
                        {labelChildren}
                    </P.Wrapper>
                );
            },
            [label, labelProps, className, buttonStyle, disabled]
        );

        const Text = useMemo(
            () => (text || children ? <P.Text buttonSize={buttonSize}>{text ?? children}</P.Text> : null),
            [text, children]
        );

        return (
            <Label>
                <P.Button
                    ref={ref}
                    buttonStyle={buttonStyle}
                    borderStyle={text || children ? P.BorderStyle.Square : borderStyle}
                    buttonSize={buttonSize}
                    className={!label && (text || children) ? className : undefined}
                    disabled={disabled}
                    {...props}
                >
                    <Icon size={'1em'} {...iconProps} />
                    {Text}
                </P.Button>
                {label}
            </Label>
        );
    }
);
