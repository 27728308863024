import * as React from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import * as Types from 'Types';
import cn from 'classnames';

import { coverActions } from 'features/cover';
import { userInfoModalActions } from 'features/userInfoModal';
import { ALLOWED_MIME_TYPE_FOR_IMAGE, MessageType } from 'root/services/fileHelpers/consts';
import { imageCropActions } from 'root/features/imageCrop';

import upload from './assets/upload.svg';
import styles from './index.module.scss';
import { FileRejection } from 'root/model/fileRejection/FIleRejection';

interface IImageControlsProps {
    handleCoverUpload: typeof coverActions.handleCoverUpload;
    showUserInfoModal: typeof userInfoModalActions.showUserInfoModal;
    rotateImage: typeof imageCropActions.rotateImage;
}

const ImageControls: React.FunctionComponent<IImageControlsProps> = ({
    handleCoverUpload,
    showUserInfoModal,
    rotateImage,
}) => {
    const baseClassName = 'image-controls';
    const controlClassName = `${baseClassName}__control`;
    const rotateClassName = cn(styles[controlClassName], styles[`${controlClassName}--rotate-control`]);

    const onDrop = async (accepted: File[], rejected: FileRejection[]) => {
        try {
            await new Promise((resolve, reject) => {
                handleCoverUpload(accepted, rejected, { resolve, reject });
            });
        } catch (error) {
            showUserInfoModal({
                contentType: MessageType.UPLOAD_IMAGE_ERROR,
                headerText: 'Error while uploading image',
            });
        }
    };

    return (
        <div className={styles[baseClassName]}>
            <div className={rotateClassName}>
                <a className={styles[`${baseClassName}__button`]} onClick={rotateImage}>
                    Rotate image right
                </a>
            </div>

            <Dropzone onDrop={onDrop} accept={ALLOWED_MIME_TYPE_FOR_IMAGE}>
                {({ getRootProps, getInputProps }) => (
                    <div className={styles[`${baseClassName}__control`]} {...getRootProps()}>
                        <img src={upload} />
                        <input {...getInputProps()} multiple={false} />
                        <a className={styles[`${baseClassName}__button`]}>Upload another image</a>
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default connect((state: Types.RootState) => ({}), {
    handleCoverUpload: coverActions.handleCoverUpload,
    showUserInfoModal: userInfoModalActions.showUserInfoModal,
    rotateImage: imageCropActions.rotateImage,
})(ImageControls);
