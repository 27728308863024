import { combineReducers } from 'redux';
import { ActionType } from 'typesafe-actions';

import { ILocation } from 'root/model/location/Location';

import { SET_LOCATIONS, SET_GEO_LOCALIZATION_ENABLED } from './constants';
import * as actions from './actions';

export interface ILocationState {
  readonly locations: ILocation[];
  readonly geoLocalizationEnabled: boolean;
}

export type LocationAction = ActionType<typeof actions>;

export const locationsReducer = (state = [], action: LocationAction) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return action.payload;
    default:
      return state;
  }
};

export const geoLocalizationEnabledReducer = (
  state = true,
  action: LocationAction
) => {
  switch (action.type) {
    case SET_GEO_LOCALIZATION_ENABLED:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers<ILocationState, LocationAction>({
  locations: locationsReducer,
  geoLocalizationEnabled: geoLocalizationEnabledReducer,
});
