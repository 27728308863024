export enum MilisTime {
  xxShort = 50,
  xShort = 100,
  short = 200,
  mediumShort = 500,
  medium = 1000,
}

export const DEFAULT_RECT: DOMRect = {
  bottom: 0,
  height: 0,
  left: 0,
  right: 0,
  top: 0,
  width: 0,
  x: 0,
  y: 0,
  toJSON: () => void 0,
};
