import styled from 'styled-components';

import { Button, Header3 } from 'root/modules/components';

export const CustomerCareText = styled.div`
  margin-bottom: 10px;
`;

export const CheckSummaryHeader = styled(Header3)``;

export const PreviewButton = styled(Button)`
  display: inline-flex;
  padding: 0;
  text-transform: lowercase;
  font-weight: bold;
`;

export const CheckSummaryText = styled.p``;
