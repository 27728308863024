import * as React from 'react';
import { Field } from 'formik';

import { LOCATION_FORM_FIELD_NAMES } from 'root/model/location/LocationFormValues';
import FormField from 'root/components/FormField';
import FormSearchInput from 'root/components/FormSearchInput';
import FormInput from 'root/components/FormInput';
import { LocationQueryFormSectionProps } from './model';
import { useLocationQueryFormSection } from './hooks';

export const LocationQueryFormSection = (props: LocationQueryFormSectionProps) => {
    const {
        isLoading,
        locationNames,
        onLocalizeMeClick,
        onLocationNameClick,
        localizationEnabled,
        onLocationQueryChange,
    } = useLocationQueryFormSection(props);

    return (
        <React.Fragment>
            <div>
                <Field
                    name={LOCATION_FORM_FIELD_NAMES.LOCATION_SEARCH}
                    component={FormField}
                    control={FormInput}
                    type="hidden"
                />
                <Field
                    name={LOCATION_FORM_FIELD_NAMES.LOCATION_VALUE}
                    component={FormField}
                    control={FormInput}
                    type="hidden"
                />
            </div>
            <Field
                name={LOCATION_FORM_FIELD_NAMES.LOCATION_QUERY}
                component={FormField}
                control={FormSearchInput}
                placeholder="Search for a location"
                options={locationNames}
                disabled={false}
                onLocalizeMeClick={onLocalizeMeClick}
                onElementClick={onLocationNameClick}
                isLoading={isLoading}
                isLocalizeMeButtonDisabled={!localizationEnabled}
                onChange={onLocationQueryChange}
                autoComplete={'off'}
            />
        </React.Fragment>
    );
};
