import React, { FC, useEffect } from 'react';

export type OnOpenOptions = {
  scrollDocumentToTop?: boolean;
  disableDocumentScroll?: boolean;
};

export type OnCloseOptions = {
  scrollDocumentDown?: boolean;
};

type OnModalOpenProps = OnOpenOptions & OnCloseOptions;

export const OnModalOpen: FC<OnModalOpenProps> = ({
  disableDocumentScroll,
  scrollDocumentToTop,
  scrollDocumentDown,
  children,
}) => {
  useEffect(() => {
    const x = window.pageXOffset;
    const y = window.pageYOffset;

    if (scrollDocumentToTop) window.scrollTo(0, 0);
    if (disableDocumentScroll) document.body.style.overflow = 'hidden';

    return () => {
      if (disableDocumentScroll) document.body.style.overflow = 'initial';
      if (scrollDocumentDown) {
        window.scrollTo(x, y);
      }
    };
  }, [disableDocumentScroll, scrollDocumentToTop, scrollDocumentDown]);

  return <>{children}</>;
};
